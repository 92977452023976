import axios from 'axios';
import { Model, ContentTypes } from 'types';

export const getEpiPage = async (
	apiUrl: string,
	route: string,
	querystring: string,
	hashstring: string
) => {
	const appendUrl = '.json';

	if (route === '') {
		route = 'index';
	}

	let url = `${apiUrl}${route}${appendUrl}`;

	if (querystring) {
		url += querystring;
	}

	const data = await axios.get<Model>(url);

	// Check if there have been any redirects and if so update the url.
	let responseURL = data.data.content?.url;

	if (querystring) {
		responseURL += querystring;
	}

	if (hashstring) {
		responseURL += hashstring;
	}

	if (
		responseURL &&
		responseURL !== window.location.href &&
		responseURL.substring(responseURL.length - 6) !== '/index'
	) {
		window.history.replaceState(null, '', responseURL);
	}

	if (data?.data?.languages?.culture) {
		window.__culture = data.data.languages.culture;
	}

	return data.data;
};

export const getEpiPageContent = async (
	apiUrl: string,
	contentLink: string
) => {
	const appendUrl = '.json';

	if (contentLink === '') {
		contentLink = 'index';
	}

	const url = `${apiUrl}/${contentLink}${appendUrl}`;

	const { data } = await axios.get<ContentTypes>(url);
	return data;
};
