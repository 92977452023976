/**
 * ServiceTabItem
 */

import clsx from 'clsx';
import React from 'react';
import { ServiceModel } from 'types';
import { Icon } from 'ui-component-library/base';

interface Props {
	/** Array with services  */
	service: ServiceModel;

	/** Boolean to indicate if the result is loaded */
	loading?: boolean;

	/** Boolean to indicate if the tab is active */
	isActive: boolean;

	/** The number of total search hits */
	numberOfHits?: number;

	/** Callback function for onClick */
	onClick?: Function;
}

/** Main description for this component. */
const ServiceTabItem: React.FC<Props> = ({
	service,
	loading = false,
	isActive,
	numberOfHits,
	onClick = () => {},
}) => {
	return (
		<button
			data-cid={service.name}
			key={service.url}
			role="tab"
			className={clsx(
				'filter-button border-2 z-10 relative bg-secondary text-primary-2 border-borderWidth border-borderColor px-4 pt-2 pb-2 rounded-lg rounded-b-none text-medium border-b-0 mr-1',
				isActive && 'filter-button--active'
			)}
			onClick={() => onClick(service)}
		>
			{service.name}
			{loading ? (
				<Icon icon="loader" animate={'spin'} className="ml-1" />
			) : (
				<span className="ml-1">{`(${numberOfHits})`}</span>
			)}
		</button>
	);
};

export default ServiceTabItem;
