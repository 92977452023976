/**
 * EmbedScript
 */

import React, { useEffect, useRef } from 'react';

interface Props {
	/** The script source url */
	src: string;
}

/** Helper function for doing the embed **/
const appendScript = (src: string, target: any) => {
	const defaultWrite = document.write;

	document.write = function () {
		// @ts-ignore
		target.innerHTML = [].concat.apply([], arguments).join('');
	};
	const script = document.createElement('script');
	script.src = src;
	script.async = true;
	script.onload = () => {
		document.write = defaultWrite;
	};
	document.body.appendChild(script);
	return () => {
		document.body.removeChild(script);
	};
};

/** Embeds a script with support for document.write. */
const EmbedScript: React.FC<Props> = ({ src }) => {
	const embedRef = useRef<any>();
	useEffect(() => {
		if (embedRef.current) {
			return appendScript(src, embedRef.current);
		}
	}, [embedRef, src]);

	return <span ref={embedRef} />;
};

export default EmbedScript;
