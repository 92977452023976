/**
 * StandardPage
 */

import React from 'react';
import BaseLayout from 'layouts/BaseLayout';
import Link from 'components/Link';
import { Grid, Cell } from 'ui-component-library/base';
import Text from 'components/Text';
import { StandardPageModel } from 'types';

const getHeadingTag = (level: number) => {
	switch (level) {
		case 1:
			return 'h1';
		case 2:
			return 'h2';
		case 3:
			return 'h3';
		default:
			return 'p';
	}
};

const renderFragment = (fragment: any, i: number) => {
	switch (fragment.modelType) {
		case 'HeadingFragment':
			return (
				<Text
					as={getHeadingTag(fragment.level)}
					text={fragment.raw}
					key={i}
				/>
			);
		case 'RawFragment':
			return <Text as="p" text={fragment.raw} key={i} />;
		default:
			return null;
	}
};

/** Main description for this component. */
const StandardPage: React.FC<StandardPageModel> = ({
	heading,
	preamble,
	text,
}) => {
	return (
		<BaseLayout>
			<Grid>
				<Cell span={12} desktop={7} className="ml-2/12">
					<p>
						<Link to="/">Hem</Link> / StandardPage
					</p>
					<Text as="h1" prop="heading" text={heading} />
					<Text as="p" prop="preamble" text={preamble} />
					{text && text.fragments && (
						<Text as="div" prop="text">
							{text.fragments.map(renderFragment)}
						</Text>
					)}
				</Cell>
			</Grid>
		</BaseLayout>
	);
};

export default StandardPage;
