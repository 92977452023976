import axios from 'axios';
import { createMachine, assign } from 'xstate';

type PaginationEventType = {
	type: 'FETCH';
	offset: number;
	fetch: number;
	sortOrder?: number;
	apiUrl: string;
	filter?: string;
};

type PaginationContext = {
	apiUrl: string;
	result?: any;
	error: any;
};

const resultData = async (
	apiUrl: string,
	_offset: number,
	fetch: number,
	sortOrder?: number,
	filter?: string
) => {
	let firstDelimiter = '?';

	if (apiUrl.indexOf('?') > -1) {
		firstDelimiter = '&';
	}

	let url = `${apiUrl}${firstDelimiter}fetch=${fetch}`;

	if (sortOrder) {
		url += `&sortOrder=${sortOrder}`;
	}

	if (filter) {
		url += `&${filter}`;
	}

	const { data } = await axios.get(url, {
		headers: { 'Content-Language': window.__culture },
	});
	return data;
};

export const showMorePaginationMachine = createMachine<
	PaginationContext,
	PaginationEventType
>({
	id: 'showMorePaginationMachine',
	initial: 'idle',
	context: {
		apiUrl: '',
		result: undefined,
		error: undefined,
	},
	states: {
		idle: {},
		loading: {
			invoke: {
				id: 'getData',
				src: (_context, event) =>
					resultData(
						event.apiUrl,
						event.offset,
						event.fetch,
						event.sortOrder,
						event.filter
					),
				onDone: {
					target: 'success',
					actions: assign({
						result: (_context, event) => event.data,
					}),
				},
				onError: {
					target: 'failure',
					actions: assign({
						error: (_context, event) => event.data,
						result: (_context, _event) => undefined,
					}),
				},
			},
		},
		success: {},
		failure: {},
	},
	on: {
		FETCH: [
			{
				target: 'loading',
				actions: [],
			},
		],
	},
});
