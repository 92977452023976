/**
 * DataTableCartRow
 */

import React from 'react';
import { ButtonSupportive, Icon } from 'ui-component-library/base';
import { DataTablePropertyModel, DataTableCartRowModel } from 'types';
import Link from 'components/Link';

interface Props {
	/** Pass an array with all table rows */
	row: DataTableCartRowModel;

	/** Pass array with headers */
	headers: DataTablePropertyModel[];

	/** Callback function to handle link click */
	handleLinkClick: any;
	/** Callback function to handle delete single criteria */
	handleDelete: Function;
}

/** Naviagation list with links. */
const DataTableCartRow: React.FC<Props> = ({
	row,
	headers,
	handleDelete,
	handleLinkClick,
}) => {
	return (
		<>
			<tr data-requirement={row.requirementName}>
				{headers.map((header, index) => {
					return (
						<React.Fragment key={index}>
							<td>
								{header.property === 'requirementName' ? (
									<>
										{row.url ? (
											<Link
												to={row.url}
												className="hover:underline"
												onClick={() => handleLinkClick()}
											>
												{(row as any)[header.property]}
											</Link>
										) : (
											<p>{(row as any)[header.property]}</p>
										)}
										{row.warningText && (
											<p className="text-redDark mt-1 whitespace-no-wrap">
												{row.warningText}
											</p>
										)}
									</>
								) : header.property === 'removeFromCartButtonText' ? (
									<ButtonSupportive
										data-button-action={(row as any)[header.property]}
										variant="squared"
										onClick={() => handleDelete(row.criteriaDescriptor)}
										className="float-right"
									>
										<Icon
											icon="trashCan"
											size={0.75}
											aria-hidden={true}
											className="mr-2 -mt-1"
										/>
										<span className="whitespace-no-wrap">
											{(row as any)[header.property]}
										</span>
									</ButtonSupportive>
								) : (
									(row as any)[header.property]
								)}
							</td>
						</React.Fragment>
					);
				})}
			</tr>
		</>
	);
};

export default DataTableCartRow;
