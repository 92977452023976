import React from 'react';
import { RadioButtonBase } from 'ui-component-library/base';

interface RadioFilterModel {
	name: string;
	path: string;
	buckets: Bucket[];
	type: string;
	onChange?: any;
	groupName: string;
}

interface Bucket {
	term: string;
	selected?: boolean;
}

/** Main description for this component. */
const RadioFilter: React.FC<RadioFilterModel> = ({
	name,
	type,
	path,
	buckets,
	onChange,
	groupName,
}) => {
	const onTermChange = (bucket: Bucket) => {
		const aggregation = Object.assign(
			{},
			{
				name,
				type,
				path,
				buckets: [Object.assign({}, bucket, { selected: true })],
			}
		);

		onChange(aggregation);
	};

	return (
		<>
			<fieldset className="px-3 mb-4">
				<legend className="sr-only">{name}</legend>
				{buckets?.map((bucket) => {
					return (
						<RadioButtonBase
							key={`${path}_${bucket.term}`}
							id={`${path}_${bucket.term}`}
							name={`${path}`}
							value={bucket.term}
							checked={bucket.selected}
							data-filter={groupName}
							onChange={() => onTermChange(bucket)}
						>
							{bucket.term}
						</RadioButtonBase>
					);
				})}
			</fieldset>
		</>
	);
};

export default RadioFilter;
