/**
 * DataTable
 */

import clsx from 'clsx';
import React, { useState } from 'react';
import { Transition } from 'react-transition-group';
import {
	DataTablePropertyModel,
	DataTableRowModel,
	SelectedRequirementsModel,
} from 'types';
import { Icon, Label, Select, Form } from 'ui-component-library/base';
import { Button } from 'ui-component-library/base/Form';
import { TransitionState } from 'ui-component-library/base/types';
import DataTableRow from './DataTableRow';
import DataTableSortButton from './DataTableSortButton';

interface Props {
	/** Pass optional classnames for the html */
	className?: string;

	/** Array with filters */
	filters?: any;

	query?: string;

	offset?: number;

	fetch?: number;

	numberOfHits?: number;

	items?: DataTableRowModel[];

	/** Possible to select a row by checking a ckeckbox? */
	selectable?: boolean;

	/** Pass a render function */
	RenderFunction?: Function;

	/** Heading to the export section */
	exportHeading?: string;

	checkedBoxes?: any;

	setCheckedBoxes?: any;

	/** An optional component to be used as link. */
	LinkComponent?: any;

	/** Table caption */
	caption?: string;

	/** Data tabel headers */
	properties?: DataTablePropertyModel[];

	onSortChange?: any;
}

type RowIds = { [key: string]: boolean };

/** Main description for this component. */
const DataTable: React.FC<Props> = ({
	className,
	filters,
	query,
	offset,
	fetch,
	numberOfHits,
	caption = 'Datatable',
	properties,
	onSortChange,
	items,
	selectable = false,
	RenderFunction,
	exportHeading,
	checkedBoxes,
	setCheckedBoxes,
	LinkComponent,
	children,
}) => {
	/** Logics for checkboxes */
	const [expanded, setExpanded] = useState<boolean>(false);

	const isAllChecked = () => {
		if (Object.keys(checkedBoxes).length === 0) {
			return false;
		}
		for (let id in checkedBoxes) {
			if (!checkedBoxes[id].selected) {
				return false;
			}
		}
		return true;
	};

	const handleCheckSingle = (id: string) => {
		if (checkedBoxes[id]) {
			setCheckedBoxes({
				[id]: {
					...checkedBoxes[id],
					selected: !checkedBoxes[id].selected,
				},
			});
		}
	};

	const handleCheckAll = () => {
		let newCheckedBoxes: SelectedRequirementsModel = {};

		if (isAllChecked()) {
			for (let id in checkedBoxes) {
				newCheckedBoxes[id] = { ...checkedBoxes[id], selected: false };
			}
		} else {
			for (let id in checkedBoxes) {
				newCheckedBoxes[id] = { ...checkedBoxes[id], selected: true };
			}
		}
		setCheckedBoxes(newCheckedBoxes);
	};

	const renderChildren = (children: any) => {
		const renderedChildren = React.Children.map(children, (child, index) => {
			const props: any = child && child.props ? { ...child.props } : {};
			props.checkedBoxes = checkedBoxes;

			return React.cloneElement(child, props);
		});

		return renderedChildren;
	};

	const expandable = true;

	return (
		<div className={className} data-cid="DataTableRequirements">
			<div
				className={clsx(
					className,
					'border border-solid border-greySemiLight rounded overflow-y-auto w-full relative'
				)}
			>
				{filters && filters.length > 0 && (
					<>
						<button
							className="w-full p-4 flex justify-between border-b border-greyDark"
							aria-expanded={expanded}
							aria-controls="table-filters"
							onClick={() => setExpanded(!expanded)}
						>
							<span>
								<Icon icon="sliders" size={2} className="mr-2 align-bottom" />
								Filtrera
							</span>
							<span className="text-teal text-xl">
								{expanded ? 'Dölj' : 'Visa'}
							</span>
						</button>
						<Transition in={expanded} timeout={400}>
							{(state: TransitionState) => (
								<div
									id="table-filters"
									hidden={state === 'exited'}
									className={clsx(
										state === 'exiting'
											? 'animate-fade-out'
											: 'animate-fade-in',
										'border-b border-greyDark'
									)}
								>
									<Form>
										<div className="md:mr-4/12 md:pb-10 px-4 pt-6 pb-4 column-count-full">
											{filters.map((filter: any) => {
												return (
													<div className="mb-5" key={filter.id}>
														<Label htmlFor={filter.id}>{filter.label}</Label>
														{filter.type === 'select' && (
															<Select
																name={filter.id}
																label={filter.id}
																id={filter.id}
																options={filter.options}
															/>
														)}
													</div>
												);
											})}
										</div>
										<div className="flex md:-mt-16 px-4 pb-4 flex-row-reverse">
											<Button type="reset" variant="secondary" size="small">
												Rensa
											</Button>
										</div>
									</Form>
								</div>
							)}
						</Transition>
					</>
				)}
				<table className="w-full border-collapse data-table">
					<caption className="sr-only">{caption}</caption>
					{properties && (
						<thead>
							<tr>
								{selectable && (
									<th scope="col">
										<input
											type="checkbox"
											aria-label="Välj alla rader"
											checked={isAllChecked()}
											onChange={handleCheckAll}
										/>
									</th>
								)}
								{properties.map((header, index) => {
									return (
										<th key={index} scope="col">
											<DataTableSortButton
												property={header.path}
												direction={header.direction}
												orderByText={header.orderByText}
												onChange={onSortChange}
											>
												{header.heading}
											</DataTableSortButton>
										</th>
									);
								})}
								{expandable && (
									<th className="sr-only" scope="col">
										Expandera rad för mer information
									</th>
								)}
							</tr>
						</thead>
					)}
					{items && properties && (
						<tbody>
							{items.map((row, index) => {
								return (
									<DataTableRow
										row={row}
										expandable={expandable}
										selectable={selectable}
										checked={
											checkedBoxes[row.documentId]
												? checkedBoxes[row.documentId].selected
												: false
										}
										handleCheck={handleCheckSingle}
										LinkComponent={LinkComponent}
										headers={properties}
										key={index}
									/>
								);
							})}
						</tbody>
					)}
				</table>
			</div>
			<div className="my-8">
				{exportHeading && (
					<h2 className="text-center text-xl leading-8 font-bold mb-4">
						{exportHeading}
					</h2>
				)}
				{children && (
					<div className="flex justify-center">{renderChildren(children)}</div>
				)}
			</div>
		</div>
	);
};

export default DataTable;
