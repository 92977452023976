import { createMachine } from 'xstate';

interface SearchFieldContext {}

type SearchFieldEventType =
	| { type: 'OPEN'; value: string; size: number }
	| { type: 'CLOSE'; value: string; size: number };

export const searchFieldMachine = createMachine<
	SearchFieldContext,
	SearchFieldEventType
>({
	id: 'searchFieldMachine',
	initial: 'idle',
	states: {
		idle: {
			on: {
				OPEN: {
					target: 'active',
				},
			},
		},
		active: {
			on: {
				CLOSE: {
					target: 'idle',
				},
			},
		},
	},
});
