/**
 * QuestionPortalDialogPage
 */

import React, { useEffect } from 'react';
import {
	Grid,
	Cell,
	ArticleHeader,
	ArticleHeaderCategory,
	Alert,
} from 'ui-component-library/base';
import { DialogBlockModel, QuestionPortalDialogPageModel } from 'types';
import BaseLayout from 'layouts/BaseLayout';
import Text from 'components/Text';
import EpiContentArea from 'components/EpiContentArea';
import { DialogBlock, EpiForms } from 'ui-component-library/uhmse';
import RichText from 'components/RichText';
import { useSelector } from 'react-redux';
import { selectContent, selectMetaData } from 'store/modules/model';
import Link from 'components/Link';
import { ArticleHeaderCategoryList } from 'ui-component-library/base/ArticleHeader';

/** QuestionPortalDialogPage component. */
const QuestionPortalDialogPage: React.FC<QuestionPortalDialogPageModel> = ({
	heading,
	articleHeader,
	apiUrl,
	text,
	topic,
	user,
	listingModel,
	commentForm,
	commentsLocked,
	contentArea,
	archived,
}) => {
	const pageContent = useSelector(selectContent);
	const metaData = useSelector(selectMetaData);
	const handleSubmit = () => {
		if (window && window.dataLayer && window.dataLayer.push) {
			window.dataLayer.push({
				event: 'CommentSubmit',
				questionTitle: heading,
			});
		}
	};

	useEffect(() => {
		const userComment = 1;
		let commentCount = listingModel.comments.length + userComment;

		let isOrganizationReply =
			listingModel.comments.find((x) => x.isOrganizationReply)
				?.isOrganizationReply || false;

		if (window && window.dataLayer && window.dataLayer.push) {
			window.dataLayer.push({
				event: 'Pageview',
				pagePath: window.location.pathname || pageContent?.url,
				pageTitle: metaData?.title,
				pageType: pageContent?.modelType,
				commentCount: commentCount,
				organisationAnswer: isOrganizationReply,
				questionCategory: topic,
			});
		}

		// Add meta tag to prevent search engine indexing

		if (archived && window) {
			const metaTag = document.createElement('meta');
			metaTag.setAttribute('name', 'robots');
			metaTag.setAttribute('content', 'noindex');
			document.head.appendChild(metaTag);
		}

		// Reset GTM scroll-tracker
		if (window.__ScrollTracker) {
			window.__ScrollTracker.reset();
		}
		// eslint-disable-next-line
	}, [pageContent]);

	return (
		<BaseLayout themeBorder={true}>
			<Grid
				padding={false}
				margin={false}
				className="lg:px-6 px-4 -mt-4 md:mt-0"
			>
				<Cell span={12} desktop={7}>
					<Text as="h1" prop="heading">
						{heading}
					</Text>
					{articleHeader && (
						<ArticleHeader
							published={articleHeader.published}
							social={articleHeader.social}
							className="mb-8"
						>
							{articleHeader.categories && articleHeader.categories.length > 0 && (
								<ArticleHeaderCategoryList>
									{articleHeader.categories.map((category, index) => {
										return (
											<ArticleHeaderCategory
												key={index}
												url={category.url}
												text={category.text}
												LinkComponent={Link}
											/>
										);
									})}
								</ArticleHeaderCategoryList>
							)}
						</ArticleHeader>
					)}
				</Cell>
			</Grid>
			<Grid padding={false} margin={false} className="lg:px-6 px-4 pt-4 mb-20">
				<Cell span={12} desktop={7}>
					<DialogBlock
						image={user.image}
						name={user.name}
						title={user.title}
						published={articleHeader?.published || ''}
						isOrganizationReply={false}
						className="mb-6 md:mb-9"
					>
						<RichText>
							<div
								dangerouslySetInnerHTML={{
									__html: text,
								}}
								className="dialog-block"
							/>
						</RichText>
					</DialogBlock>

					{listingModel.comments.map(
						(item: DialogBlockModel, index: number) => {
							return (
								<DialogBlock
									image={item.user.image}
									name={item.user.name}
									title={item.user.title}
									published={item.publishedDate}
									updated={item.updatedDate}
									isOrganizationReply={item.isOrganizationReply}
									className="mb-6 md:mb-9"
									key={index}
								>
									<RichText key={index}>
										<div
											dangerouslySetInnerHTML={{
												__html: item.text as string,
											}}
											className="dialog-block"
										/>
									</RichText>
								</DialogBlock>
							);
						}
					)}
					{commentForm && !commentsLocked && (
						<div className="mb-8 bg-greyLightest px-12 pt-6 pb-8 ml-8 lg:ml-0">
							<EpiForms {...commentForm} onEpiFormSubmit={handleSubmit} />
						</div>
					)}
					{commentsLocked && (
						<Alert
							variant="warning"
							className="md:inline-block mb-8 lg:mb-0"
							icon="lock"
						>
							<Text as="p" margin={false} className="mb-0">
								Tråden är låst för vidare kommentar.
							</Text>
						</Alert>
					)}
				</Cell>
				{contentArea && contentArea.length > 0 && (
					<Cell span={12} desktop={4} className="lg:ml-1/12">
						<EpiContentArea content={contentArea} />
					</Cell>
				)}
			</Grid>
		</BaseLayout>
	);
};

export default QuestionPortalDialogPage;
