/**
 * SearchResultList
 */

import Link from 'components/Link';
import React from 'react';
import {
	SearchListItemTypes,
	DialogSearchHitItemModel,
	SearchResultItemModel,
} from 'types';
import {
	SearchResultList as SearchResultListUI,
	DialogSearchHitItem,
	SearchResultListItem,
} from 'ui-component-library/uhmse';

interface Props {
	/** Description of this property */
	searchList: SearchListItemTypes[];
}

/** Main description for this component. */
const SearchResultList: React.FC<Props> = ({ searchList }) => {
	const getSearchItem = (item: SearchListItemTypes, index: number) => {
		switch (item.modelType) {
			case 'DialogSearchHit':
				const searchHitdata = item as DialogSearchHitItemModel;
				return (
					<DialogSearchHitItem
						key={index}
						url={searchHitdata.url}
						heading={searchHitdata.heading}
						tag={searchHitdata.categories.map((item) => item).join(', ')}
						comments={searchHitdata.numberOfComments}
						LinkComponent={Link}
					/>
				);
			default:
				const searchdata = item as SearchResultItemModel;
				return (
					<SearchResultListItem
						key={index}
						url={searchdata.url}
						category={searchdata.breadcrumbs?.breadcrumbs
							.map((bc) => bc.text)
							.join(' / ')}
						heading={searchdata.heading}
						text={searchdata.excerpt}
						LinkComponent={Link}
					/>
				);
		}
	};

	return (
		<SearchResultListUI>{searchList?.map(getSearchItem)}</SearchResultListUI>
	);
};

export default SearchResultList;
