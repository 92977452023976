/**
 * ValfrihetArticlePage
 */

import clsx from 'clsx';
import EpiContentArea from 'components/EpiContentArea';
import EpiFragments from 'components/EpiFragments';
import Link from 'components/Link';
import Text from 'components/Text';
import BaseLayout from 'layouts/BaseLayout';
import React from 'react';
import { ValfrihetArticlePageModel } from 'types';
import { Cell, Grid, Icon, IntroBlock } from 'ui-component-library/base';

/** Main description for this component. */
const ValfrihetArticlePage: React.FC<ValfrihetArticlePageModel> = ({
	id,
	url,
	mainHeading,
	mainPreamble,
	introBlock,
	subNavigation,
	text,
	contentArea,
}) => {
	return (
		<BaseLayout>
			<div className="-mt-10 md:-mt-8">
				<IntroBlock>
					<Grid padding={false} className="lg:pl-6 pl-4">
						<Cell span={12} desktop={7}>
							{introBlock?.heading && (
								<Text as="h1" margin={false} className="relative mb-2">
									{introBlock.icon && (
										<Icon
											icon={introBlock.icon}
											size={3}
											className="absolute top-0 left-0 mt-1 -ml-12 hidden xl:block"
											aria-hidden={true}
										/>
									)}
									{introBlock?.heading}
									<span className="sr-only">{mainHeading}</span>
								</Text>
							)}
							{introBlock?.preamble && (
								<Text as="p" margin={false}>
									{introBlock?.preamble}
								</Text>
							)}
						</Cell>
					</Grid>
				</IntroBlock>
			</div>

			<Grid padding={false} className="mt-5 lg:pl-6 pl-4">
				{subNavigation && (
					<Cell span={12} data-cid="Submenu links">
						<ul className="flex space-x-14">
							{subNavigation.map((item, index) => (
								<li key={index}>
									<Link
										href={item.url}
										className={clsx(
											item.isActive && 'border-b-2 border-teal',
											'text-teal font-medium pb-1'
										)}
									>
										{item.text}
									</Link>
								</li>
							))}
						</ul>
					</Cell>
				)}
				<Cell span={12} desktop={8} className="lg:pr-24 mt-14">
					{mainHeading && <Text as="h2">{mainHeading}</Text>}
					{mainPreamble && <Text as="p">{mainPreamble}</Text>}
				</Cell>
				<Cell span={12} desktop={8} className="lg:mb-14 lg:pr-24 mt-7">
					<EpiFragments prop="text" fragments={text?.fragments} />
				</Cell>
				<Cell span={12} desktop={4} className="mt-6 md:mt-14 mb-4 md:mb-0">
					{contentArea && contentArea.length > 0 && (
						<EpiContentArea content={contentArea} />
					)}
				</Cell>
			</Grid>
		</BaseLayout>
	);
};

export default ValfrihetArticlePage;
