import React from 'react';
import {
	SingleSelectDropdown,
	SingleSelectOption,
} from 'ui-component-library/base';

interface SingSelectFilterModel {
	name: string;
	path: string;
	buckets: Bucket[];
	onChange?: any;
	searchPlaceholder: string;
	groupName: string;
}

interface Bucket {
	term: string;
	selected?: boolean;
}

/** Main description for this component. */
const SingleSelectFilter: React.FC<SingSelectFilterModel> = ({
	name,
	path,
	buckets,
	onChange,
	searchPlaceholder,
	groupName,
}) => {
	const onTermChange = (bucket: Bucket) => {
		const aggregation = Object.assign(
			{},
			{
				name,
				path,
				buckets: [Object.assign({}, bucket, { selected: true })],
			}
		);

		onChange(aggregation);
	};

	const getLegend = () => {
		let selectedBucket = buckets.find((bucket) => bucket.selected);

		if (selectedBucket) {
			return selectedBucket.term;
		} else {
			return name;
		}
	};

	return (
		<SingleSelectDropdown
			legend={getLegend()}
			searchPlaceholder={searchPlaceholder}
			hasSearchField={true}
			className="mb-4 mx-3 relative"
		>
			{buckets.map((bucket, index) => (
				<SingleSelectOption
					key={index}
					text={bucket.term}
					value={bucket.term}
					data-filter={groupName}
					onChange={() => onTermChange(bucket)}
				></SingleSelectOption>
			))}
		</SingleSelectDropdown>
	);
};

export default SingleSelectFilter;
