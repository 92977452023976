import React from 'react';
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	ReferenceLine,
	LabelList,
	ResponsiveContainer,
	Label,
	Legend,
	// @ts-ignore
} from 'recharts';
import { CalculatedData } from './ClimateImpactEvaluationForm';

const ClimateImpactEvaluationDiagram: React.FC<{ data: CalculatedData }> = ({
	data,
}) => {
	const {
		co2AmountBuildTraditional,
		co2AmountOperationTraditional,
		co2AmountBuildEnvironmental,
		co2AmountOperationEnvironmental,
		measureType,
		formType,
	} = data;

	const ResultData = [
		{
			name: 'Utgångsläge',
			Bygg: co2AmountBuildTraditional,
			Drift: co2AmountOperationTraditional,
		},
		{
			name: measureType,
			Bygg: co2AmountBuildEnvironmental,
			Drift: co2AmountOperationEnvironmental,
		},
	];

	return (
		<div style={{ width: '100%', height: 400 }}>
			<ResponsiveContainer>
				<BarChart
					data={ResultData}
					barSize={100}
					barGap={0}
					margin={{
						top: 30,
						left: 3,
						right: 50,
						bottom: 50,
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="name" interval={0} dy={15} />
					<YAxis padding={{ top: 50, bottom: 50 }}>
						<Label value={'ton CO2e '} offset={10} position="top" />
					</YAxis>
					<Legend
						width={100}
						wrapperStyle={{
							top: 120,
							right: 30,
						}}
					/>
					<ReferenceLine y={0} stroke="#000" />
					<Bar dataKey="Bygg" stackId="x" fill="#6B2879">
						<LabelList dataKey="Bygg" fill="white" />
					</Bar>
					{formType !== 'Material' && co2AmountOperationEnvironmental !== 0 ? (
						<Bar dataKey="Drift" stackId="x" fill="#008A2B">
							<LabelList dataKey="Drift" fill="white" />
						</Bar>
					) : null}
				</BarChart>
			</ResponsiveContainer>
		</div>
	);
};

export default ClimateImpactEvaluationDiagram;
