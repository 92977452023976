import axios from 'axios';
import {
	DataTableModel,
	RequirementExportModel,
	RequirementModel,
	SelectedRequirementsModel,
} from 'types';

interface ApiParameterModel {
	productGroupId: number;
	productSubGroups: number[];
	culture: string;
	requirements: RequirementModel[];
}

export const generateReport = async (
	productGroupId: number,
	selectedRequirements: SelectedRequirementsModel,
	requirementsList: DataTableModel,
	language: string
) => {
	const url = `/api/upphandlingsmyndigheten/criteriaservice/generate/report/requirements`;
	let productSubGroups: number[] = [];
	let requirements: RequirementModel[] = [];
	
	for (let id in selectedRequirements) {
		if (selectedRequirements[id].selected) {
			requirements.push(selectedRequirements[id]);
		}
		
		if (requirementsList.items) {
			for (let i in requirementsList.items) {
				const productSubGroupId = requirementsList.items[i].productSubGroupId;
				if (
					requirementsList.items[i].documentId === id &&
					productSubGroupId &&
					!productSubGroups.includes(productSubGroupId)
				) {
					productSubGroups.push(productSubGroupId);
				}
			}
		}
	}

	const parameters: ApiParameterModel = {
		productGroupId: productGroupId,
		productSubGroups: productSubGroups,
		culture: language,
		requirements: requirements,
	};

	const { data } = await axios.post<RequirementExportModel>(url, parameters);

	return data;
};
