/**
 * DataTableProductGroupRow
 */

import clsx from 'clsx';
import React, { useState } from 'react';
import { Icon } from 'ui-component-library/base';
import { Transition } from 'react-transition-group';
import { TransitionState } from 'ui-component-library/base/types';
import { useUuid } from 'ui-component-library/hooks';
import { DataTablePropertyModel, DataTableProductGroupRowModel } from 'types';
import Link from 'components/Link';
import CriteriaCartAddButton from 'components/CriteriaCartAddButton';

interface Props {
	/** Pass an array with all table rows */
	row: DataTableProductGroupRowModel;

	/** Pass array with headers */
	headers: DataTablePropertyModel[];

	/** Optional content */

	pageContent?: any;
}

/** Naviagation list with links. */
const DataTableProductGroupRow: React.FC<Props> = ({
	row,
	headers,
	pageContent,
}) => {
	const [expanded, setExpanded] = useState<boolean>(false);
	const [getUuid] = useUuid();
	const id = getUuid();

	const getHeaderLength = () => {
		let headerLength = 0;

		return (headerLength += headers.length);
	};
	const renderCellData = (property: string) => {
		switch (property) {
			case 'requirementName':
				return <Link to={row.url}>{(row as any)[property]}</Link>;
			case 'addToCartButton':
				return (
					<CriteriaCartAddButton
						downloadAddedText={(row as any)[property].downloadAddedText}
						addToDownloadsText={(row as any)[property].addToDownloadsText}
						criteriaDescriptor={(row as any)[property].criteriaDescriptor}
						variant="table"
						requirementInfo={{
							criteriaProductArea:
								pageContent.requirementInfo.criteriaProductArea,
							criteriaProductGroup:
								pageContent.requirementInfo.criteriaProductGroup,
							criteriaProductSubgroup: (row as any)['productSubGroupName'],
							criteriaItem: (row as any)['requirementName'],
							criteriaLevel: (row as any)['requirementLevelLevel'],
						}}
					/>
				);
			case 'description':
				if (row.description) {
					return (
						<button
							aria-label={`Mer information om rad ${id}`}
							className="w-12 h-12"
							aria-expanded={expanded}
							aria-controls={`table-row-description-${id}`}
							id={`table-row-button-${id}`}
							onClick={() => {
								setExpanded(!expanded);
							}}
						>
							<Icon
								icon="chevron"
								direction={expanded ? 'down' : 'up'}
								className=""
								aria-hidden={true}
							/>
						</button>
					);
				} else break;
			default:
				return (row as any)[property];
		}
	};
	return (
		<>
			<tr
				data-requirement={row.requirementName}
				className={clsx(row.description && expanded && 'no-border')}
			>
				{headers.map((header, index) => {
					return (
						<React.Fragment key={index}>
							<td>{renderCellData(header.property)}</td>
						</React.Fragment>
					);
				})}
			</tr>
			{row.description && (
				<Transition in={expanded} timeout={400}>
					{(state: TransitionState) => (
						<tr
							id={`table-row-description-${id}`}
							role={'region'}
							aria-labelledby={`table-row-button-${id}`}
							hidden={state === 'exited'}
							className={clsx(
								state === 'exiting' ? 'animate-fade-out' : 'animate-fade-in',
								'productgroup-table__description'
							)}
						>
							<td colSpan={getHeaderLength()}>
								<div
									className="productgroup-table__description__text richtext"
									dangerouslySetInnerHTML={{
										__html:
											(row as DataTableProductGroupRowModel).description || '',
									}}
								/>
							</td>
						</tr>
					)}
				</Transition>
			)}
		</>
	);
};

export default DataTableProductGroupRow;
