import axios from 'axios';
import { createMachine, assign } from 'xstate';
import { SearchResultModel } from 'types';

type SearchEventType = { type: 'SEARCH'; value: string; size: number };

type SearchContext = {
	apiUrl: string;
	result?: SearchResultModel;
	error: any;
	query: string;
	size: number;
	debounce?: number;
};

const searchData = async (apiUrl: string, query: string, size: number = 10) => {
	const { data } = await axios.get(
		`${apiUrl}?query=${encodeURIComponent(query)}&size=${size}`,
		{
			headers: {
				'X-Content-Language': window.__culture,
				'Content-Language': window.__culture,
				'Accept-Language': window.__culture,
			},
		}
	);
	return data;
};

export const searchMachine = createMachine<SearchContext, SearchEventType>(
	{
		id: 'searchMachine',
		initial: 'idle',
		context: {
			apiUrl: '',
			result: undefined,
			error: undefined,
			query: '',
			size: 10,
			debounce: 0,
		},
		states: {
			idle: {},
			debouncing: {
				after: {
					DEBOUNCE_DELAY: { target: 'loading' },
				},
			},
			loading: {
				invoke: {
					id: 'getData',
					src: (context, _event) =>
						searchData(context.apiUrl, context.query, context.size),
					onDone: {
						target: 'success',
						actions: assign({
							result: (_context, event) => event.data,
						}),
					},
					onError: {
						target: 'failure',
						actions: assign({
							error: (_context, event) => event.data,
							result: (_context, _event) => undefined,
						}),
					},
				},
			},
			success: {},
			failure: {},
		},
		on: {
			SEARCH: [
				{
					target: 'debouncing',
					cond: 'searchValid',
					internal: false,
					actions: [
						assign({
							query: (_context, event) => event.value,
							size: (context, event) => event.size || context.size,
						}),
					],
				},
				{
					target: 'idle',
					actions: [
						assign({
							query: (_context, _event) => '',
							result: (_context, _event) => undefined,
						}),
					],
				},
			],
		},
	},
	{
		guards: {
			searchValid: (_context, event): any => {
				return event.value && event.value.length >= 3;
			},
		},
		delays: {
			DEBOUNCE_DELAY: (context, _event) => {
				return context.debounce || 0;
			},
		},
	}
);
