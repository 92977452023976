import axios from 'axios';
import {
	SearchAggregationModel,
	SearchFilterGroupModel,
	SearchSortingModel,
} from 'types';
import { createQueryString } from 'utils/helpers';
import { createMachine, assign } from 'xstate';

type SearchEventType =
	| {
			type: 'SEARCH';
			query: string;
			offset: number;
			fetch: number;
			aggregations: any[];
	  }
	| { type: 'RESET'; apiUrl: string; model: any };

type SearchContext = {
	url: string;
	apiUrl: string;
	query: string;
	numberOfHits: number;
	numberOfHitsPerPage: number;
	offset: number;
	fetch: number;
	orderBy: string | null;
	results: any;
	filterGroups: SearchFilterGroupModel[] | [];
	selectedAggregations: SearchAggregationModel[] | null;
	sorting: SearchSortingModel | null;
	error: any;
	querystring: string;
	router: any;
};

interface LoadApiData {
	(apiUrl: string, context?: SearchContext): Promise<any>;
}

const search: LoadApiData = async (apiUrl, context) => {
	const { data } = await axios.get(`${apiUrl}?${context?.querystring}`, {
		headers: {
			'X-Content-Language':
				window?.__model?.languages?.culture || window.__culture,
			'Content-Language':
				window?.__model?.languages?.culture || window.__culture,
			'Accept-Language':
				window?.__model?.languages?.culture || window.__culture,
		},
	});

	return { data };
};

export const searchMachine = createMachine<SearchContext>(
	{
		id: 'searchAdvance',
		initial: 'idle',
		context: {
			url: '',
			apiUrl: '',
			query: '',
			querystring: '',
			offset: 0,
			fetch: 0,
			orderBy: null,
			numberOfHits: 0,
			numberOfHitsPerPage: 0,
			results: [],
			filterGroups: [],
			selectedAggregations: [],
			sorting: null,
			error: undefined,
			router: null,
		},
		states: {
			idle: {},
			loading: {
				invoke: {
					id: 'getData',
					src: (context, event) => {
						return search(context.apiUrl, context);
					},
					onDone: {
						target: 'success',
						actions: 'setResponseData',
					},
					onError: {
						target: 'failure',
						actions: assign({
							error: (_, event) => {
								return `Error ${event.data.response.status} - ${event.data.response.statusText}`;
							},
						}),
					},
				},
			},
			success: {},
			failure: {},
			reseting: {
				entry: ['resetContext'],
				always: [{ target: 'idle' }],
			},
		},
		on: {
			INIT: {
				target: 'loading',
				actions: ['querySearch', 'setQuerystring'],
			},
			QUERY_SEARCH: {
				target: 'loading',
				actions: ['querySearch', 'setQuerystring', 'updateUrl'],
			},
			RESET: 'reseting',
			LOAD_MORE: {
				target: 'loading',
				actions: ['loadMore', 'setQuerystring', 'updateUrl'],
			},
			TAB_CHANGE: {
				actions: 'tabChange',
			},
			FILTER_CHANGE: {
				target: 'loading',
				actions: ['filterChange', 'setQuerystring', 'updateUrl'],
			},
			RESET_FILTERS: {
				target: 'loading',
				actions: ['resetFilters', 'setQuerystring', 'updateUrl'],
			},
			SORT: {
				target: 'loading',
				actions: ['sort', 'setQuerystring', 'updateUrl'],
			},
		},
	},
	{
		actions: {
			resetContext: assign({
				apiUrl: (context, event: any) => event.apiUrl || context.apiUrl,
				query: (context, event: any) => event.query || context.query,
				results: (context, event: any) => event.model || context.results,
			}),
			updateUrl: (context, event) => {
				if (context.querystring) {
					context.router.push(`${context.url}?${context.querystring}`);
				} else {
					context.router.push(`${context.url}`);
				}
			},
			setQuerystring: assign({
				querystring: (context, event) => {
					const querystring = createQueryString({
						query: context.query,
						fetch: context.fetch,
						aggregations: context.selectedAggregations,
						orderBy: context.orderBy,
					});

					return querystring || '';
				},
			}),
			setResponseData: assign({
				numberOfHits: (context, event) => event.data.data.numberOfHits,
				filterGroups: (context, event) => event.data.data.filterGroups,
				sorting: (context, event) => event.data.data.sorting,
				results: (context, event) => event.data.data.results,
			}),
			tabChange: (context, event) => {
				const querystring = createQueryString({
					query: context.query,
					fetch: event.fetch,
					aggregations: event.aggregations,
					orderBy: event.orderBy,
				});

				if (querystring) {
					context.router.push(`${event.url}?${querystring}`);
				} else {
					context.router.push(`${event.url}`);
				}
			},
			resetFilters: assign({
				fetch: (context, event) => context.numberOfHitsPerPage,
				selectedAggregations: (context, event) => null,
			}),
			querySearch: assign({
				fetch: (context, event) => context.numberOfHitsPerPage,
				query: (context, event) => event.query,
				selectedAggregations: (context, event) => {
					return event.selectedAggregations;
				},
			}),
			loadMore: assign({
				fetch: (context, event) => event.fetch,
				selectedAggregations: (context, event) => {
					return event.selectedAggregations;
				},
			}),
			filterChange: assign({
				fetch: (context, event) => context.numberOfHitsPerPage,
				selectedAggregations: (context, event) => {
					const { aggregation } = event;
					const existingAggregations = context.selectedAggregations || [];

					const updatedAggregations = existingAggregations.filter(
						(a) => a.path !== aggregation.path
					);

					updatedAggregations.push(aggregation);

					return updatedAggregations;
				},
			}),
			sort: assign({
				fetch: (context, event) => context.numberOfHitsPerPage,
				orderBy: (context, event) => event.orderBy,
			}),
		},
	}
);
