/**
 * CriteriaServiceComparisonPage
 */

import CriteriaComparisonColumn from 'components/CriteriaComparisonColumn';
import EpiContentArea from 'components/EpiContentArea';
import Text from 'components/Text';
import BaseLayout from 'layouts/BaseLayout';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import {
	ComparisonSelectModel,
	ComparisonSelectOptionModel,
	CriteriaServiceComparisonPageModel,
} from 'types';
import { Cell, Grid } from 'ui-component-library/base';
import { useMachine } from '@xstate/react';
import { criteriaComparisonMachine } from 'state-machines/criteriaComparison.machine';

/** Main description for this component. */
const CriteriaServiceComparisonPage: React.FC<CriteriaServiceComparisonPageModel> =
	({
		heading,
		preamble,
		apiUrl,
		url,
		columns,
		addToDownloadsText,
		downloadAddedText,
		contentArea,
	}) => {
		const location = useLocation();
		const history = useHistory();
		const urlQuery = queryString.parse(location.search);
		const [columnQueries, setColumnQueries] = useState<any>({
			column1: '',
			column2: '',
			column3: '',
		});

		const [state1, send1] = useMachine(criteriaComparisonMachine, {
			context: {
				apiUrl: apiUrl,
				model: columns[0],
			},
		});
		const [state2, send2] = useMachine(criteriaComparisonMachine, {
			context: {
				apiUrl: apiUrl,
				model: columns[1],
			},
		});
		const [state3, send3] = useMachine(criteriaComparisonMachine, {
			context: {
				apiUrl: apiUrl,
				model: columns[2],
			},
		});

		const handleChange = (
			columnIndex: number,
			selects: ComparisonSelectModel[]
		) => {
			let query: string[] = [];
			selects.forEach((select: ComparisonSelectModel) => {
				select.options?.forEach((option: ComparisonSelectOptionModel) => {
					if (option.selected) {
						query.push(option.value);
					}
				});
			});
			query.join(',');

			let queryFull: string[] = [];
			if (query) {
				Object.keys(columnQueries as any).forEach((key) => {
					if (key === `column${columnIndex}`) {
						queryFull = queryFull.concat([`${key}=${query}`]);
					} else if ((columnQueries as any)[key]) {
						queryFull = queryFull.concat([
							`${key}=${(columnQueries as any)[key]}`,
						]);
					}
				});
				history.replace(`${url}?${queryFull.join('&')}`);
			}
			setColumnQueries({
				...columnQueries,
				[`column${columnIndex}`]: `${query}`,
			});
		};

		useEffect(() => {
			setColumnQueries({ ...columnQueries, ...urlQuery });
			// eslint-disable-next-line
		}, []);

		return (
			<BaseLayout themeBorder={true}>
				<Grid
					padding={false}
					margin={false}
					className="px-4 lg:px-6 -mt-4 md:mt-0"
				>
					<Cell span={12} desktop={7}>
						<Text as="h1" prop="heading">
							{heading}
						</Text>
						<Text as="preamble" prop="preamble">
							{preamble}
						</Text>
					</Cell>
				</Grid>
				<Grid padding={false} className="pl-4 lg:pl-6 md:mt-0">
					<Cell span={12} tablet={6} desktop={4}>
						<CriteriaComparisonColumn
							column={state1.context.model}
							addToDownloadsText={addToDownloadsText}
							downloadAddedText={downloadAddedText}
							columnIndex={1}
							onChange={handleChange}
							send={send1}
							errorMessage={state1.context.error}
							loading={state1 && state1.matches('loading')}
						/>
					</Cell>
					<Cell span={12} tablet={6} desktop={4}>
						<CriteriaComparisonColumn
							column={state2.context.model}
							addToDownloadsText={addToDownloadsText}
							downloadAddedText={downloadAddedText}
							columnIndex={2}
							onChange={handleChange}
							send={send2}
							errorMessage={state2.context.error}
							loading={state2 && state2.matches('loading')}
						/>
					</Cell>
					<Cell span={12} tablet={6} desktop={4}>
						<CriteriaComparisonColumn
							column={state3.context.model}
							addToDownloadsText={addToDownloadsText}
							downloadAddedText={downloadAddedText}
							columnIndex={3}
							onChange={handleChange}
							send={send3}
							errorMessage={state3.context.error}
							loading={state3 && state3.matches('loading')}
						/>
					</Cell>
				</Grid>

				{contentArea && contentArea.length > 0 && (
					<EpiContentArea content={contentArea} />
				)}
			</BaseLayout>
		);
	};

export default CriteriaServiceComparisonPage;
