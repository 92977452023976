import Axios from 'axios';
import { CriteriaCartModel, CriteriaServiceCartPageModel } from 'types';
import { createMachine, assign } from 'xstate';

/*
 * types & interfaces
 */

type fetchCartPageEvent = {
	type: 'FETCH_CART_PAGE';
	api: string;
};
type addCriteriaEvent = {
	type: 'ADD_CRITERIA';
	item: string;
};
type removeCriteriaEvent = {
	type: 'REMOVE_CRITERIA';
	item: string;
};
type clearCartEvent = {
	type: 'CLEAR_CART';
};
type downloadCriteriasEvent = {
	type: 'DOWNLOAD_CRITERIAS';
	api: string;
	culture: string;
	format: string;
};

type criteriaCartEventType =
	| fetchCartPageEvent
	| addCriteriaEvent
	| removeCriteriaEvent
	| clearCartEvent
	| downloadCriteriasEvent;

type criteriaCartContext = CriteriaCartModel & {
	error: any;
};

interface GetCriteriaCart {
	(): string[];
}
interface AddCriteria {
	(item: string): Promise<string[]>;
}
interface RemoveCriteria {
	(item: string): Promise<string[]>;
}
interface ClearCart {
	(): Promise<string[]>;
}

/*
 * fetchCartPage service
 * - Fetchs the criteria cart content
 */
const fetchCartPage = async (apiUrl: string, criterias: any) => {
	const criteriaData = {
		criteriaDescriptors: criterias,
		culture: window.__culture,
	};

	const { data } = await Axios.post(apiUrl, criteriaData);

	return data;
};

/*
 * generateDownloadFile service
 * - Fetchs download file in selected format for all criterias in cart
 */
const generateDownloadFile = async (
	apiUrl: string,
	culture: string,
	format: string,
	criterias: any
) => {
	const criteriaData = {
		criteriaDescriptors: criterias,
		culture: culture,
		fileFormat: format,
	};
	const { data } = await Axios.post(apiUrl, criteriaData);

	return data;
};

/*
 * getCriteriaCart service
 * - Loads all criterias from localStorage.
 */
const getCriteriaCart: GetCriteriaCart = () => {
	let criteriaCart: any = null;
	if (typeof localStorage !== 'undefined') {
		criteriaCart = localStorage.getItem('criteriaCart');
	}

	if (criteriaCart != null) {
		criteriaCart = JSON.parse(criteriaCart);
	} else {
		criteriaCart = [];
	}

	return criteriaCart;
};

/*
 * addCriteria service
 * - adds a new criteria to localStorage.
 */
const addCriteria: AddCriteria = (item: string) => {
	return new Promise((resolve, reject) => {
		try {
			const criteriaCart = getCriteriaCart();
			criteriaCart.push(item);
			if (typeof localStorage !== 'undefined') {
				localStorage.setItem('criteriaCart', JSON.stringify(criteriaCart));
			}
			resolve(criteriaCart);
		} catch (e) {
			reject(e);
		}
	});
};

/*
 * removeCriteria service
 * - removes a criteria from localStorage.
 */
const removeCriteria: RemoveCriteria = (item) => {
	return new Promise((resolve, reject) => {
		try {
			const criteriaCart = getCriteriaCart();
			const updatedCriteriaCart = criteriaCart.filter(
				(criteria: string) => criteria !== item
			);
			if (typeof localStorage !== 'undefined') {
				localStorage.setItem(
					'criteriaCart',
					JSON.stringify(updatedCriteriaCart)
				);
			}
			resolve(updatedCriteriaCart);
		} catch (e) {
			reject(e);
		}
	});
};

/*
 * clearCart service
 * - removes all criterias from localStorage.
 */
const clearCart: ClearCart = () => {
	return new Promise((resolve, reject) => {
		try {
			if (typeof localStorage !== 'undefined') {
				localStorage.removeItem('criteriaCart');
			}
			resolve([]);
		} catch (e) {
			reject(e);
		}
	});
};

/*
 * xstate selectors
 */
export const selectCriterias = (current: any): string[] => {
	return current.context.criterias;
};

export const selectCriteriaCartModel = (
	current: any
): CriteriaServiceCartPageModel => {
	return current.context.model;
};

export const selectDownloadFileUrl = (current: any): string => {
	return current.context.downloadFileUrl;
};

export const selectCriteriaCartError = (current: any): string => {
	return current.context.error;
};

/*
 * criteriaCartMachine
 */
export const criteriaCartMachine = createMachine<
	criteriaCartContext,
	criteriaCartEventType
>({
	id: 'criteriaCartMachine',
	initial: 'idle',
	context: {
		model: undefined,
		criterias: getCriteriaCart(),
		donloadFileUrl: '',
		error: undefined,
	},
	states: {
		idle: {
			on: {
				FETCH_CART_PAGE: 'fetchingCartPage',
				ADD_CRITERIA: 'addingCriteria',
				REMOVE_CRITERIA: 'removingCriteria',
				CLEAR_CART: 'clearingCart',
				DOWNLOAD_CRITERIAS: 'downloadingCriterias',
			},
		},
		failure: {
			on: {
				FETCH_CART_PAGE: 'fetchingCartPage',
				ADD_CRITERIA: 'addingCriteria',
				REMOVE_CRITERIA: 'removingCriteria',
				CLEAR_CART: 'clearingCart',
				DOWNLOAD_CRITERIAS: 'downloadingCriterias',
			},
		},
		fetchingCartPage: {
			invoke: {
				id: 'fetchCartPage',
				src: (_context, _event) => {
					const event = _event as fetchCartPageEvent;
					return fetchCartPage(event.api, _context.criterias);
				},
				onDone: {
					target: 'idle',
					actions: assign({
						model: (_context, event) => event.data,
					}),
				},
				onError: {
					target: 'failure',
					actions: assign({
						error: (_context, event) => event.data.error,
					}),
				},
			},
		},
		addingCriteria: {
			invoke: {
				id: 'addCriteria',
				src: (_context, _event) => {
					const event = _event as addCriteriaEvent;
					return addCriteria(event.item || '');
				},
				onDone: {
					target: 'idle',
					actions: assign((_context, event) => {
						return {
							criterias: event.data,
						};
					}),
				},
				onError: {
					target: 'failure',
					actions: assign({
						error: (_context, event) => event.data.error,
					}),
				},
			},
		},
		removingCriteria: {
			invoke: {
				id: 'removeCriteria',
				src: (_context, _event) => {
					const event = _event as removeCriteriaEvent;

					return removeCriteria(event.item || '');
				},
				onDone: {
					target: 'idle',
					actions: assign((_context, event) => {
						return {
							criterias: event.data,
						};
					}),
				},
				onError: {
					target: 'failure',
					actions: assign({
						error: (_context, event) => event.data.error,
					}),
				},
			},
		},
		clearingCart: {
			invoke: {
				id: 'clearingCart',
				src: (_context, _event) => {
					return clearCart();
				},
				onDone: {
					target: 'idle',
					actions: assign((_context, event) => {
						return {
							criterias: event.data,
						};
					}),
				},
				onError: {
					target: 'failure',
					actions: assign({
						error: (_context, event) => event.data.error,
					}),
				},
			},
		},
		downloadingCriterias: {
			invoke: {
				id: 'downloadCriterias',
				src: (_context, _event) => {
					const event = _event as downloadCriteriasEvent;
					return generateDownloadFile(
						event.api,
						event.culture,
						event.format,
						_context.criterias
					);
				},
				onDone: {
					target: 'idle',
					actions: (_context, event) => {
						let a = document.createElement('a');
						a.classList.add('hidden');
						document.body.appendChild(a);
						a.href = event.data.reportUrl;
						a.download = event.data.reportUrl;
						a.click();
						a.remove();
					},
				},
				onError: {
					target: 'failure',
					actions: assign({
						error: (_context, event) => {
							return `Error ${event.data.response.status} - ${event.data.response.statusText}`;
						},
					}),
				},
			},
		},
	},
});
