import UpphandlingsmyndighetenStartPage from './StartPage';
import StandardPage from './StandardPage';
import ErrorPage404 from './ErrorPage404';
/* PLOP_INJECT_IMPORT */
import WebGuidanceArticlePage from './WebGuidanceArticlePage';
import WebGuidanceLandingPage from './WebGuidanceLandingPage';
import CriteriaServiceRequirementPageV2 from './CriteriaServiceRequirementPageV2';
import CriteriaServiceComparisonPage from './CriteriaServiceComparisonPage';
import QuestionPortalDialogPage from './QuestionPortalDialogPage';
import QuestionPortalCategoryPage from './QuestionPortalCategoryPage';
import QuestionPortalStartPage from './QuestionPortalStartPage';
import UpphandlingsmyndighetenPublicationPage from './PublicationPage';
import UpphandlingsmyndighetenPublicationsListingPage from './PublicationsListingPage';
import UpphandlingsmyndighetenCalendarEventPage from './CalendarEventPage';
import AttachmentPage from './AttachmentPage';
import RiskAnalysisPage from './RiskAnalysisPage';
import RiskAnalysisServicePage from './RiskAnalysisServicePage';
import CriteriaServiceRequirementPage from './CriteriaServiceRequirementPage';
import ProductSubGroupV2 from './ProductSubGroupPageV2';
import ProductSubGroup from './ProductSubGroupPage';
import CriteriaServicePage from './CriteriaServicePage';
import ProductGroupV2 from './ProductGroupPageV2';
import ProductGroup from './ProductGroupPage';
import PublicationsListingPage from './PublicationsListingPage';
import UpphandlingsmyndighetenSectionPage from './SectionPage';
import UpphandlingsmyndighetenNewsListingPage from './NewsListingPage';
import UpphandlingsmyndighetenCalendarEventListingPage from './CalendarEventListingPage';
import UpphandlingsmyndighetenInspirationPage from './InspirationPage';
import UpphandlingsmyndighetenSearchPage from './SearchPage';
import UpphandlingsmyndighetenSearchPageAdvance from './SearchPageAdvance';
import UpphandlingsmyndighetenArticlePage from './ArticlePage';
import UpphandlingsmyndighetenNavigationPage from './NavigationPage';
import UpphandlingsmyndighetenProcessPage from './ProcessPage';
import UpphandlingsmyndighetenStatisticServicePage from './StatisticServicePage';
import StatisticServiceStartPage from './StatisticServiceStartPage';
import StatisticServiceArticlePage from './StatisticServiceArticlePage';
import StatisticServiceArticleListingPage from './StatisticServiceArticleListingPage';
import StatisticServiceNavigationPage from './StatisticServiceNavigationPage';
import StatisticServiceSectionPage from './StatisticServiceSectionPage';
import ValfrihetArticlePage from './ValfrihetArticlePage';
import ValfrihetStartPage from './ValfrihetStartPage';
import ValfrihetAdPage from './ValfrihetAdPage';
import ValfrihetMonitorPage from './ValfrihetMonitorPage';

import { ContentTypes } from 'types';

const Pages: any = {
	StandardPage,
	ErrorPage404,
	/* PLOP_INJECT_PAGE */
	WebGuidanceArticlePage,
	WebGuidanceLandingPage,
	ProductSubGroupV2,
	ProductGroupV2,
	CriteriaServiceRequirementPageV2,
	CriteriaServiceComparisonPage,
	QuestionPortalDialogPage,
	QuestionPortalCategoryPage,
	QuestionPortalStartPage,
	UpphandlingsmyndighetenSectionPage,
	UpphandlingsmyndighetenPublicationPage,
	UpphandlingsmyndighetenPublicationsListingPage,
	UpphandlingsmyndighetenCalendarEventPage,
	AttachmentPage,
	RiskAnalysisPage,
	RiskAnalysisServicePage,
	CriteriaServiceRequirementPage,
	ProductSubGroup,
	CriteriaServicePage,
	ProductGroup,
	PublicationsListingPage,
	UpphandlingsmyndighetenNewsListingPage,
	UpphandlingsmyndighetenCalendarEventListingPage,
	UpphandlingsmyndighetenStartPage,
	UpphandlingsmyndighetenInspirationPage,
	UpphandlingsmyndighetenSearchPage,
	UpphandlingsmyndighetenSearchPageAdvance,
	UpphandlingsmyndighetenArticlePage,
	UpphandlingsmyndighetenNavigationPage,
	UpphandlingsmyndighetenProcessPage,
	UpphandlingsmyndighetenStatisticServicePage,
	StatisticServiceStartPage,
	StatisticServiceArticlePage,
	StatisticServiceArticleListingPage,
	StatisticServiceNavigationPage,
	StatisticServiceSectionPage,
	ValfrihetArticlePage,
	ValfrihetAdPage,
	ValfrihetStartPage,
	ValfrihetMonitorPage,
};

export const PagesMapper = (pageContent: ContentTypes | undefined) => {
	if (pageContent) {
		let page: any = Pages[pageContent.modelType];

		if (page) {
			return page;
		}
	}

	return ErrorPage404;
};
