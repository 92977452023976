/**
 * ProcessPage
 */

import React from 'react';
import {
	PhaseNavigation,
	PhaseNavigationItem,
} from 'ui-component-library/uhmse';
import { Grid, Cell, Toc, TocLink } from 'ui-component-library/base';
import { ProcessPageModel } from 'types';
import BaseLayout from 'layouts/BaseLayout';
import Text from 'components/Text';
import Link from 'components/Link';
import EpiFragments from 'components/EpiFragments';
import clsx from 'clsx';
import EpiContentArea from 'components/EpiContentArea';
import { useIsDesktop } from 'hooks/useMediaQuery';

/** ProcessPage component. */
const ProcessPage: React.FC<ProcessPageModel> = ({
	heading,
	preamble,
	text,
	tableOfContents,
	phase,
	contentArea,
}) => {
	const isDesktop = useIsDesktop();
	return (
		<BaseLayout>
			<Grid
				padding={false}
				margin={false}
				className="px-4 lg:px-6 -mt-4 md:mt-0"
			>
				<Cell span={12} desktop={7}>
					<Text as="h1" prop="heading">
						{heading}
					</Text>
				</Cell>
			</Grid>
			<Grid
				margin={false}
				padding={false}
				className={clsx(tableOfContents && 'flex-row-reverse', 'px-4 lg:px-6')}
			>
				{isDesktop && tableOfContents && (
					<Cell span={4} className="ml-1/12 pl-8">
						<Toc
							heading={tableOfContents.heading}
							items={tableOfContents.chapters}
							sticky={tableOfContents.isSticky}
						>
							{tableOfContents.titles.map((item, index) => (
								<TocLink href={`#${item.targetId}`} key={index}>
									{item.text}
								</TocLink>
							))}
						</Toc>
					</Cell>
				)}
				<Cell span={12} desktop={7}>
					<Text as="preamble" prop="preamble">
						{preamble}
					</Text>

					{!isDesktop && tableOfContents && (
						<Toc heading={tableOfContents.heading} items={[]} className="mb-8">
							{tableOfContents.titles.map((item, index) => (
								<TocLink href={`#${item.targetId}`} key={index}>
									{item.text}
								</TocLink>
							))}
						</Toc>
					)}
					<div className="mb-10">
						<EpiFragments prop="text" fragments={text?.fragments} />
					</div>

					{phase && (phase.next || phase.previous) && (
						<PhaseNavigation>
							{phase.next && (
								<PhaseNavigationItem
									name={phase.next.name}
									url={phase.next.url}
									type="next"
									label={phase.i18n.next}
									LinkComponent={Link}
								/>
							)}
							{phase.previous && (
								<PhaseNavigationItem
									name={phase.previous.name}
									url={phase.previous.url}
									type="previous"
									label={phase.i18n.previous}
									LinkComponent={Link}
								/>
							)}
						</PhaseNavigation>
					)}
				</Cell>
			</Grid>
			{contentArea && contentArea.length > 0 && (
				<EpiContentArea content={contentArea} />
			)}
		</BaseLayout>
	);
};

export default ProcessPage;
