/**
 * ExportDataOption
 */

import {
	exportDataByUrl,
	exportDataHighcharts,
} from 'components/StatisticService/helpers';
import React from 'react';

interface Props {
	/** Export option type */
	type: string;

	/** Export url */
	url?: string;

	/** Reference to highchart */
	chartRef?: any;

	/** OnClick callback */
	onClick?: any;

	/** Pass an optional className */
	className?: string;
}

/** Main description for this component. */
const ExportDataOption: React.FC<Props> = ({
	type,
	url,
	chartRef,
	onClick,
	className,
	children,
}) => {
	const handleExportData = (exportType: string, url?: string) => {
		switch (exportType) {
			case 'csv':
			case 'excel':
				if (url) {
					exportDataByUrl(url);
				}
				break;
			case 'svg':
				exportDataHighcharts(chartRef, 'image/svg+xml');
				break;
			case 'png':
			case 'jpeg':
				exportDataHighcharts(chartRef, `image/${exportType}`);
				break;
			default:
				break;
		}
	};

	return (
		<li className={className}>
			<button
				className="py-2 px-3 block w-full text-left hover:bg-greyLight"
				onClick={() => {
					(onClick && onClick()) || handleExportData(type, url);
				}}
			>
				{children}
			</button>
		</li>
	);
};

export default ExportDataOption;
