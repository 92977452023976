import React from 'react';
import { CalculatedData } from './ClimateImpactEvaluationForm';

const ClimateImpactEvaluationTable: React.FC<{ data: CalculatedData }> = ({
	data,
}) => {
	const {
		formType,
		unit,
		co2AmountBuildTraditional,
		co2AmountBuildDifference,
		co2AmountOperationTraditional,
		co2precentBuildDifference,
		referenceObjectType,
		co2AmountBuildEnvironmental,
		co2AmountOperationEnvironmental,
		measureType,
		inputAmount,
		co2AmountOperationDifference,
		co2precentOperationDifference,
	} = data;

	const co2AmountBuildTraditionalFormatted = new Intl.NumberFormat(
		'sv-SE'
	).format(co2AmountBuildTraditional);
	const co2AmountOperationTraditionalFormatted = new Intl.NumberFormat(
		'sv-SE'
	).format(co2AmountOperationTraditional);
	const co2AmountBuildEnvironmentalFormatted = new Intl.NumberFormat(
		'sv-SE'
	).format(co2AmountBuildEnvironmental);
	const co2AmountOperationEnvironmentalFormatted = new Intl.NumberFormat(
		'sv-SE'
	).format(co2AmountOperationEnvironmental);
	const co2AmountBuildDifferenceFormatted = new Intl.NumberFormat(
		'sv-SE'
	).format(co2AmountBuildDifference);

	let buildObjectTypesWithoutMaterial;

	switch (referenceObjectType) {
		case 'Flerbostadshus med betongstomme':
		case 'Kontor':
		case 'Skola':
		case 'Förskola':
			buildObjectTypesWithoutMaterial = 'Byggskede A1-A5';
			break;
		case 'Gång och cykelväg':
		case 'Tvåfältsväg':
			buildObjectTypesWithoutMaterial = 'Byggskede A1-A3';
			break;
	}

	let operationObjectTypes;

	switch (referenceObjectType) {
		case 'Flerbostadshus med betongstomme':
		case 'Kontor':
		case 'Skola':
		case 'Förskola':
			operationObjectTypes = 'Driftskede B6';
			break;
		case 'Tvåfältsväg':
			operationObjectTypes = 'Driftskede B2-B4';
			break;
	}

	return (
		<table>
			<caption className="sr-only">Resultat</caption>
			<thead>
				<tr>
					<th>{formType}</th>
					<th>
						{formType === 'Byggnad eller anläggning' ? 'Storlek' : 'Mängd'}
					</th>
					<th>
						{formType === 'Material'
							? 'Byggskede A1-A3'
							: buildObjectTypesWithoutMaterial}
					</th>

					{formType !== 'Material' &&
					referenceObjectType !== 'Gång och cykelväg' ? (
						<th>{operationObjectTypes}</th>
					) : null}

					{formType !== 'Material' ? <th></th> : null}
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>{referenceObjectType}</td>
					<td>
						{inputAmount} {unit}
					</td>
					<td>{co2AmountBuildTraditionalFormatted}</td>
					{formType !== 'Material' &&
					referenceObjectType !== 'Gång och cykelväg' ? (
						<td>{co2AmountOperationTraditionalFormatted}</td>
					) : null}
					{formType !== 'Material' ? <td>ton CO2e</td> : null}
				</tr>
				<tr>
					<td>{measureType}</td>
					<td>
						{inputAmount} {unit}
					</td>

					<td>{co2AmountBuildEnvironmentalFormatted}</td>

					{formType !== 'Material' &&
					referenceObjectType !== 'Gång och cykelväg' ? (
						<td>{co2AmountOperationEnvironmentalFormatted}</td>
					) : null}
					{formType !== 'Material' ? <td>ton CO2e</td> : null}
				</tr>
				<tr>
					<td>
						<p>Åtgärdseffekt</p>
						<p>%</p>
					</td>
					<td></td>
					<td className="w-1/4">
						{<p>- {co2AmountBuildDifferenceFormatted}</p>}
						{<p>- {co2precentBuildDifference} %</p>}
					</td>
					{formType !== 'Material' &&
					referenceObjectType !== 'Gång och cykelväg' ? (
						<td className="w-1/4">
							{<p>- {co2AmountOperationDifference}</p>}
							{<p>- {co2precentOperationDifference} %</p>}
						</td>
					) : null}
					{formType !== 'Material' ? <td>ton CO2e</td> : null}
				</tr>
			</tbody>
		</table>
	);
};
export default ClimateImpactEvaluationTable;
