import {
	ResponseResolver,
	RestRequest,
	DefaultRequestBody,
	RequestParams,
	RestContext,
} from 'msw';
import search from './search.json';

export const getMockSearch: ResponseResolver<
	RestRequest<DefaultRequestBody, RequestParams>,
	RestContext,
	any
> = (req, res, ctx) => {
	const query = req.url.searchParams.get('query');
	const fetch = req.url.searchParams.get('fetch');
	const siteType = req.url.searchParams.get('siteType');
	const lastUpdated = req.url.searchParams.get('lastUpdated');
	const tag = req.url.searchParams.get('tag');
	const tagMulti = req.url.searchParams.get('tagMulti');
	const orderBy = req.url.searchParams.get('orderBy');

	if (query != null) {
		search.query = query;
	}

	if (fetch != null) {
		search.fetch = parseInt(fetch);
	}
	// Radio filter - lastUpdated
	let aggs: any[] = search?.filterGroups[0]?.aggregations;

	let lastUpdatedBuckets = aggs.find(
		(filter) => filter?.path === 'lastUpdated'
	)?.buckets;
	if (lastUpdatedBuckets && lastUpdatedBuckets.length > 0) {
		lastUpdatedBuckets.forEach((bucket: any) => {
			if (bucket.term === lastUpdated) {
				bucket.selected = true;
			} else {
				bucket.selected = false;
			}
		});
	}

	if (lastUpdated) {
		search.filterGroups[0].selectedBuckets = 1;
	} else {
		search.filterGroups[0].selectedBuckets = 0;
	}

	// Radio filter - siteType
	aggs = search?.filterGroups[1]?.aggregations;

	let siteTypeBuckets = aggs.find(
		(filter) => filter?.path === 'siteType'
	)?.buckets;
	if (siteTypeBuckets && siteTypeBuckets.length > 0) {
		siteTypeBuckets.forEach((bucket: any) => {
			if (bucket.term === siteType) {
				bucket.selected = true;
			} else {
				bucket.selected = false;
			}
		});
		search.filterGroups[1].selectedBuckets = 1;
	}
	if (siteType) {
		search.filterGroups[1].selectedBuckets = 1;
	} else {
		search.filterGroups[1].selectedBuckets = 0;
	}

	// SingleSelect - tag
	aggs = search?.filterGroups[2]?.aggregations;

	let tagBuckets = aggs.find((filter) => filter?.path === 'tag')?.buckets;

	if (tagBuckets && tagBuckets.length > 0) {
		tagBuckets.forEach((bucket: any) => {
			if (tag != null && tag.length > 0) {
				if (tag.split(',').find((i) => i === bucket.term)) {
					bucket.selected = true;
				} else {
					bucket.selected = false;
				}
			} else {
				bucket.selected = false;
			}
		});
	}
	if (tag) {
		search.filterGroups[2].selectedBuckets = tag.split(',').length;
	} else {
		search.filterGroups[2].selectedBuckets = 0;
	}

	// Multiselect - tagMulti
	aggs = search?.filterGroups[3]?.aggregations;

	let tagMultiBuckets = aggs.find(
		(filter) => filter?.path === 'tagMulti'
	)?.buckets;

	if (tagMultiBuckets && tagMultiBuckets.length > 0) {
		tagMultiBuckets.forEach((bucket: any) => {
			if (tagMulti != null && tagMulti.length > 0) {
				if (tagMulti.split(',').find((i) => i === bucket.term)) {
					bucket.selected = true;
				} else {
					bucket.selected = false;
				}
			} else {
				bucket.selected = false;
			}
		});
	}
	if (tagMulti) {
		search.filterGroups[3].selectedBuckets = tagMulti.split(',').length;
	} else {
		search.filterGroups[3].selectedBuckets = 0;
	}

	// Sort - OrderBy

	if (orderBy) {
		search.sorting.options.forEach((option) => {
			if (option.path === orderBy) {
				option.selected = true;
			} else {
				option.selected = false;
			}
		});
	}

	// if (query === '403') {
	// 	return res(ctx.status(403), ctx.body(''));
	// }

	return res(ctx.status(200), ctx.body(JSON.stringify(search)));
};
