/**
 * StatisticService.chart
 */

import React, { useState, useEffect } from 'react';
import Highcharts from 'components/Highcharts';
// import * as Highcharts from 'highcharts';
// import HighchartsReact from 'highcharts-react-official';
//import { Result } from 'state-machines/statisticService.machine';
import { RadioButtonBase } from 'ui-component-library/base';
//import { getHighchartOptions } from './helpers';
//import { chartTypes, chartStackTypes } from './helpers/getHighchartOptions';

interface Props {
	/** Data to render */
	// data: Result;
	data: any;

	/** Last date when the statistics data was updated  */
	lastUpdated?: string | null;

	/** Send method from the machine */
	send: any;

	/** Ref to Highchart */
	chartRef?: any;

	/** Show diagram when content has been loaded */
	isLoading?: boolean;
}

/** StatisticServiceChart component. */
const StatisticServiceChart: React.FC<Props> = ({
	data,
	lastUpdated,
	send,
	chartRef,
	isLoading = false,
}) => {
	const {
		yAxisTitle,
		yAxisLabelFormat,
		xAxisCategories,
		tooltipValueDecimals,
		tooltipValuePrefix,
		tooltipValueSuffix,
		series,
	} = data;

	const [stacked, setStacked] = useState<boolean>(data.stacked);
	const [chartType, setChartType] = useState(data.chartType);

	useEffect(() => {
		setChartType(data.chartType);
	}, [data.chartType]);

	return (
		<div className="mt-10">
			{!isLoading && (
				<Highcharts
					chartType={chartType}
					stacked={stacked ? data.stacked : null}
					tooltipValueDecimals={tooltipValueDecimals}
					tooltipValuePrefix={tooltipValuePrefix}
					tooltipValueSuffix={tooltipValueSuffix}
					yAxisTitle={yAxisTitle || ''}
					yAxisLabelFormat={yAxisLabelFormat || '{value:,.0f}'}
					xAxisCategories={xAxisCategories}
					series={series}
					lastUpdated={lastUpdated}
					chartRef={chartRef}
					showSpinner={false}
				/>
			)}
			{(chartType === 'bar' || chartType === 'column') && (
				<form className="my-8 flex space-x-4">
					<fieldset>
						<legend className="font-bold mb-2">Diagramtyp</legend>
						<RadioButtonBase
							id="chart-type-column"
							name="chart-type"
							value="column"
							className="mr-4"
							checked={chartType === 'column'}
							onChange={(e: any) => {
								setChartType(e.target.value);
								// send({
								// 	type: 'SELECT_CHART_TYPE',
								// 	data: e.target.value,
								// });
							}}
							theme="teal"
						>
							Stående
						</RadioButtonBase>
						<RadioButtonBase
							id="chart-type-bar"
							name="chart-type"
							value="bar"
							className="mr-4"
							checked={chartType === 'bar'}
							onChange={(e: any) => {
								setChartType(e.target.value);
								// send({
								// 	type: 'SELECT_CHART_TYPE',
								// 	data: e.target.value,
								// });
							}}
							theme="teal"
						>
							Liggande
						</RadioButtonBase>
					</fieldset>
					<fieldset>
						<legend className="font-bold mb-2">Stapling</legend>
						<RadioButtonBase
							id="chart-stack-false"
							name="chart-stack"
							value="null"
							className="mr-4"
							checked={!stacked}
							onChange={() => {
								send('UNSET_CHART_STACKED');
								setStacked(false);
							}}
							theme="teal"
						>
							Ej staplat
						</RadioButtonBase>
						<RadioButtonBase
							id="chart-stack-true"
							name="chart-stack"
							value="normal"
							className="mr-4"
							checked={stacked}
							onChange={() => {
								send('SET_CHART_STACKED');
								setStacked(true);
							}}
							theme="teal"
						>
							Staplat
						</RadioButtonBase>
					</fieldset>
				</form>
			)}
		</div>
	);
	// const [chartType, setChartType] = useState<chartTypes>(initialChartType);
	// const [chartStacked, setChartStacked] = useState<chartStackTypes>(undefined);
	// const initialFormValues = {
	// 	'chart-type': 'column',
	// 	'chart-stack': 'null',
	// };
	// const options = getHighchartOptions(data, variable, chartType, chartStacked);
	// console.log(JSON.stringify(options, null, '\t'));

	// return (
	// 	<div className="mt-10">

	// 		<HighchartsReact highcharts={Highcharts} options={options} />
	// 		{(chartType === 'bar' || chartType === 'column') && (
	// 			<Form
	// 				initialValues={initialFormValues}
	// 				className="my-4 space-y-2"
	// 				onChange={(e: any) => {
	// 					switch (e.name) {
	// 						case 'chart-type':
	// 							setChartType(e.value);
	// 							return;
	// 						case 'chart-stack':
	// 							setChartStacked(e.value === 'null' ? undefined : e.value);
	// 							return;
	// 					}
	// 				}}
	// 			>
	// 				<fieldset className="flex">
	// 					<legend className="font-bold mb-2">Diagramtyp</legend>
	// 					<RadioButton
	// 						id="chart-type-column"
	// 						name="chart-type"
	// 						value="column"
	// 						label="Stående"
	// 						className="mr-4"
	// 					>
	// 						Stående
	// 					</RadioButton>
	// 					<RadioButton
	// 						id="chart-type-bar"
	// 						name="chart-type"
	// 						value="bar"
	// 						label="Liggande"
	// 						className="mr-4"
	// 					>
	// 						Liggande
	// 					</RadioButton>
	// 				</fieldset>
	// 				<fieldset className="flex">
	// 					<legend className="font-bold mb-2">Stapling</legend>
	// 					<RadioButton
	// 						id="chart-stack-false"
	// 						name="chart-stack"
	// 						value="null"
	// 						label="Stående"
	// 						className="mr-4"
	// 					>
	// 						Ej staplat
	// 					</RadioButton>
	// 					<RadioButton
	// 						id="chart-stack-true"
	// 						name="chart-stack"
	// 						value="normal"
	// 						label="Liggande"
	// 						className="mr-4"
	// 					>
	// 						Staplat
	// 					</RadioButton>
	// 				</fieldset>
	// 			</Form>
	// 		)}
	// 	</div>
	// );
};

export default StatisticServiceChart;
