/**
 * RiskTableLarge
 */

import React from 'react';
import { AssessmentModel } from 'types';
import { useUuid } from 'ui-component-library/hooks';
import NoneBouble from './svg/none.svg';

type ColumnHeader = {
	step: number;
	stepName: string;
};

interface Props {
	/** Table caption*/
	caption: string;

	/** Table column heading*/
	columnHeading: string;

	/** Table matrix with cell data */
	dataMatrix: { [key: number]: AssessmentModel };

	/** Array with column headers */
	columnHeaders: ColumnHeader[];

	/** Render assessment icon */
	getAssessmentIcon: Function;
}

const RiskTableSmall: React.FC<Props> = ({
	caption,
	columnHeading,
	dataMatrix,
	columnHeaders,
	getAssessmentIcon,
}) => {
	const [getUuid] = useUuid();
	const id = getUuid();
	return (
		<table className="risk-table min-w-132" data-cid="RiskTableSmall">
			<caption className="sr-only">{caption}</caption>
			<thead>
				<tr>
					<th
						scope="colgroup"
						colSpan={columnHeaders.length}
						id={`risktable-${id}-colgroup`}
						className="text-p pb-4 text-left"
					>
						{columnHeading}
					</th>
				</tr>
				<tr>
					{columnHeaders.map((col, index) => {
						return (
							<th
								scope="col"
								id={`risktable-${id}-col-${col.step}`}
								headers={`risktable-${id}-colgroup`}
								className="relative font-normal pt-4 px-3 mx-6 bg-greyLighter"
								key={index}
							>
								{col.stepName}
							</th>
						);
					})}
				</tr>
			</thead>
			<tbody>
				<tr>
					{columnHeaders.map((col, index) => {
						return (
							<td
								headers={`risktable-${id}-col-${col.step}`}
								className="text-center align-baseline py-4 px-3 mx-6 bg-greyLighter"
								key={index}
							>
								<img
									loading="lazy"
									className="w-auto m-auto mb-4 px-3"
									src={
										getAssessmentIcon(dataMatrix[col.step].assessmentValue) ||
										NoneBouble
									}
									alt=""
								/>
								<p className="max-w-28 mx-auto my-0 text-p">
									{dataMatrix[col.step].assessment}
								</p>
							</td>
						);
					})}
				</tr>
			</tbody>
		</table>
	);
};

export default RiskTableSmall;
