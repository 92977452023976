/**
 * StatisticTable
 */

import React from 'react';
import ScrollableTable from 'components/ScrollableTable';
import DataTableSortButton from 'components/DataTable/DataTableSortButton';
import { Button, Icon } from 'ui-component-library/base';
import { StatisticTableBaseModel, StatisticTableModel } from 'types';
import clsx from 'clsx';

interface Props {
	/** Data to render */
	data: StatisticTableModel | StatisticTableBaseModel;

	/** Last date when the statistics data was updated  */
	lastUpdated?: string | null;

	/** Text that describes the amount of table rows (of total) that is visible on the page */
	pagerText?: string | null;

	/** Should it be displayed as a matrix table */
	matrix?: boolean;

	/** Should sorting (via API) be enabled */
	enableSort?: boolean;

	/** Should pagination (via API) be enabled */
	enablePagination?: boolean;

	/** Table source text  */
	source?: string;

	/** Show more button text */
	showMoreButtonText?: string;

	/** Callback to handle sort */
	sortCallback?: (prop: string, dir: string) => void;

	/** Callback to handle pagination */
	paginationCallback?: () => void;

	/** If we should render */

	renderTotal?: boolean;
}

/** StatisticTable component. */
const StatisticTable: React.FC<Props> = ({
	data,
	lastUpdated,
	pagerText,
	matrix = false,
	enableSort = false,
	enablePagination = false,
	source,
	showMoreButtonText,
	sortCallback = () => {},
	paginationCallback = () => {},
	renderTotal,
}) => {
	if (!data.properties) {
		return null;
	}

	const enabledHeaders = data?.properties?.filter((prop: any) => prop.enabled);
	return (
		<div>
			{data.title && (
				<p className="text-lg text-center mb-2" aria-hidden={true}>
					{data.title}
				</p>
			)}
			{pagerText && (
				<p className="text-p text-greyDarker mb-2 ml-4">{pagerText}</p>
			)}
			{enabledHeaders.length > 0 && (
				<ScrollableTable matrix={matrix}>
					<table className={clsx('table--narrow', matrix && 'table--matrix')}>
						{data.title && <caption className="sr-only">{data.title}</caption>}
						<thead>
							<tr>
								{enabledHeaders?.map((header: any, headerIndex: number) => (
									<th key={headerIndex}>
										{enableSort && (
											<DataTableSortButton
												className={clsx(
													data.properties
														.find((item) => item.path === header.path)
														?.dataType.toLowerCase() === 'int64' && 'mx-auto'
												)}
												property={header.path}
												direction={header.direction}
												orderByText={`Sortera på: ${header.name}`}
												onChange={sortCallback}
											>
												{header.name}
											</DataTableSortButton>
										)}
										{!enableSort && <>{header.name}</>}
									</th>
								))}
							</tr>
						</thead>
						{data.rows.length > 0 && (
							<tbody>
								{data.rows.map((row: any, index: number) => (
									<tr
										key={index}
										className={
											renderTotal && index === data.rows.length - 1
												? 'font-bold'
												: undefined
										}
									>
										{enabledHeaders?.map((header: any, index: number) => {
											if (matrix && index === 0) {
												return (
													<th key={index}>
														{row[header.path] || row[header.name]}
													</th>
												);
											} else {
												return (
													<td
														key={index}
														title={row[header.path] || row[header.name]}
														className={clsx(
															data.properties.find(
																(item) => item.path === header.path
															)?.dataType &&
																data.properties
																	.find((item) => item.path === header.path)
																	?.dataType.toLowerCase() === 'int64' &&
																'text-right'
														)}
													>
														{row[header.path] || row[header.name]}
													</td>
												);
											}
										})}
									</tr>
								))}
							</tbody>
						)}
					</table>
				</ScrollableTable>
			)}
			{(source || lastUpdated) && (
				<div className="mt-2 mb-6 ml-4">
					{source && <p className="text-p text-greyDarker mb-4">{source}</p>}
					{lastUpdated && (
						<p className="text-p text-greyDarker">{`Statistikdatabasen uppdatering ${lastUpdated}`}</p>
					)}
				</div>
			)}
			{enablePagination && (
				<div className="flex justify-left mb-4">
					<Button
						type="button"
						color="magenta"
						onClick={paginationCallback}
						data-button-action="Ladda fler"
					>
						{showMoreButtonText || 'Ladda fler'}
						<Icon
							icon="chevron"
							direction="up"
							color="white"
							size={0.75}
							className="ml-2"
							aria-hidden={true}
						/>
					</Button>
				</div>
			)}
		</div>
	);
};

export default StatisticTable;
