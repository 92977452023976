import { createMachine, assign } from 'xstate';
import { stringify, parse } from 'query-string';
import axios, { CancelTokenSource } from 'axios';
import {
	exportDataByUrl,
	exportDataHighcharts,
} from 'components/StatisticService/helpers';
import Axios from 'axios';

export let cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
export const cancelRequest = () => {
	if (cancelTokenSource) {
		cancelTokenSource.cancel();
	}

	const CancelToken = axios.CancelToken;
	cancelTokenSource = CancelToken.source();
};

export interface DataModel {
	areas: OptionData[] | null;
	products: OptionData[] | null;
	units: OptionData[] | null;
	distributedUnits: OptionData[] | null;
	time: OptionDataTimeGroup | null;
	parameters: OptionDataParameterGroup[] | null;
	filters: OptionDataFiltersGroup[] | null;
	searchFilters: any;
	measurements: OptionData[] | null;
	variables: OptionData[] | null;
	// result: Result | null;
	result: any;
	resultError: errorModel | null;
	lastUpdated: string | null;
}

export interface errorModel {
	statusCode: number;
	message: string;
}

export interface OptionData {
	id: string;
	name: string;
	value: string;
	text: string;
	disabled: boolean;
	description?: string;
}

export interface OptionDataTimeGroup {
	years: OptionData[] | null;
	fragments: OptionData[] | null;
}

export interface OptionDataFiltersGroup {
	query?: string;
	offset?: number;
	fetch?: number;
	total?: number;
	apiUrl?: string;
	selectedBuckets?: number;
	text: string;
	type: 'multiselect' | 'multiselectSearch';
	items: OptionData[];
}

export interface OptionDataParameterGroup {
	heading?: string;
	text?: string;
	description?: string;
	dimensions?: OptionDataParameterDimension[];
}

export interface OptionDataParameterDimension {
	heading: string;
	text?: string;
	description?: string;
	properties: OptionData[];
}
export interface ResultData {
	CalendarYear: string;
	Amount: string;
	[key: string]: string;
}

export interface Result {
	headers: ResultData;
	data: ResultData[];
}

type resultFormats = 'table' | 'matrixtable' | 'chart';
type chartTypes = 'bar' | 'column' | 'pie' | 'line';
type filterType = { [key: string]: string[] };

interface Context extends DataModel {
	apiUrl: string;
	router: any;
	resultHeading: string;
	error: any;
	reqCancelled: boolean;
	selectedArea: string | null;
	selectedProduct: string | null;
	selectedUnit: string | null;
	selectedDistributedUnit: string | null;
	selectedYear: string[];
	selectedTimeFragment: string[];
	selectedMeasurement: string | null;
	selectedParameters: string[];
	selectedFilters: string[];
	selectedVariable: string | null;
	selectedResultFormat: resultFormats;
	selectedChartType: chartTypes;
	selectedChartStacked: boolean;
	order: string | null;
	numItemsToFetch: number;
	numItemsTotal: number;
	itemsPerPage: number;
	lastUpdated: string | null;
}

interface LoadApiData {
	(apiUrl: string, context?: Context): Promise<OptionData[]>;
}

interface SearchApiData {
	(
		apiUrl: string,
		context: Context,
		itemsToFetch: number,
		filter: string,
		searchterm?: string
	): Promise<OptionData>;
}

const getUrlQueries = (context: Context): string => {
	const query: any = {};

	if (context.selectedArea) {
		query.area = context.selectedArea;
	}
	if (context.selectedProduct) {
		query.product = context.selectedProduct;
	}
	if (context.selectedUnit) {
		query.unit = context.selectedUnit;
	}
	if (context.selectedDistributedUnit) {
		query.distributedUnit = context.selectedDistributedUnit;
	}
	if (context.selectedMeasurement) {
		query.measurement = context.selectedMeasurement;
	}
	if (context.selectedYear) {
		query.year = context.selectedYear;
	}
	if (
		context.selectedTimeFragment &&
		query.area &&
		query.product &&
		query.unit
	) {
		if (
			context.selectedTimeFragment.indexOf('Tidsperiod.Månad') !== -1 &&
			context.selectedResultFormat !== 'chart'
		) {
			query.timefragment = ['Tidsperiod.År', 'Tidsperiod.Månad'];
		} else {
			query.timefragment = context.selectedTimeFragment;
		}
	}
	if (context.selectedParameters) {
		query.param = context.selectedParameters;
	}
	if (context.selectedFilters) {
		query.filter = context.selectedFilters;
	}
	if (context.selectedVariable) {
		query.variable = context.selectedVariable;
	}
	query.resultFormat = context.selectedResultFormat;
	query.chartType = context.selectedChartType;
	query.chartStacked = context.selectedChartStacked;
	if (context.order) {
		query.orderBy = context.order;
	}
	if (context.numItemsToFetch) {
		query.fetch = context.numItemsToFetch;
	}

	return `?${stringify(query, { arrayFormat: 'none' })}`;
};

const loadApiData: LoadApiData = async (apiUrl, context) => {
	let query: string = '';
	if (context) {
		query = getUrlQueries(context);
	}
	const { data } = await axios({
		method: 'GET',
		url: `${apiUrl}${query}`,
		cancelToken: cancelTokenSource?.token,
	});

	return data;
};

const searchApiData: SearchApiData = async (
	apiUrl,
	context,
	itemsToFetch,
	filter,
	searchterm
) => {
	// uncomment this below to be able to test with prep api and disable cors in the browser to make it work
	// apiUrl =
	// 'http://webapi-prep.upphandlingsmyndigheten.se/api/statisticsbridge/filter';

	apiUrl = '/api/sv/statisticsservice/bridgeapi/filter';

	let url = `${apiUrl}?area=${context.selectedArea}&distributedUnit=${
		context.selectedDistributedUnit
	}&dimension=${context.selectedParameters.filter((f: string) =>
		f.includes(filter)
	)}&product=${context.selectedProduct}&unit=${context.selectedUnit}`;

	if (searchterm) url += `&searchterm=${searchterm}`;

	if (itemsToFetch) url += `&fetch=${itemsToFetch}`;

	const { data } = await axios({
		method: 'GET',
		url: url,
	});

	return data;
};

export const statisticServiceMachine = createMachine<Context>(
	{
		id: 'statisticServiceMachine',
		initial: 'idle',
		context: {
			apiUrl: '',
			router: null,
			resultHeading: 'Resultat',
			error: null,
			reqCancelled: false,
			areas: null,
			products: null,
			units: null,
			distributedUnits: null,
			time: null,
			parameters: null,
			filters: null,
			searchFilters: null,
			variables: null,
			measurements: null,
			selectedArea: null,
			selectedProduct: null,
			selectedUnit: null,
			selectedDistributedUnit: null,
			selectedYear: [],
			selectedTimeFragment: ['Tidsperiod.År'],
			selectedMeasurement: null,
			selectedParameters: [],
			selectedFilters: [],
			selectedVariable: null,
			selectedResultFormat: 'table',
			selectedChartType: 'column',
			selectedChartStacked: false,
			result: null,
			order: null,
			numItemsToFetch: 100,
			numItemsTotal: 0,
			itemsPerPage: 100,
			resultError: null,
			lastUpdated: null,
		},
		states: {
			idle: {
				on: {
					// INITIALIZE: 'loadingAreas',
					INITIALIZE: 'initialize',
				},
			},

			initialize: {
				entry: ['setContextFromQuery'],
				invoke: {
					id: 'initialize',
					src: (context, _event) => loadApiData(context.apiUrl, context),
					onDone: {
						target: 'initialized',
						actions: 'setData',
					},
					onError: {
						target: 'idle',
						actions: assign({
							error: (_context, event) => event.data.error,
						}),
					},
				},
			},

			initialized: {
				entry: ['createResultHeading'],
				always: [
					{ target: 'result.success', cond: 'hasResult' },
					{
						target: 'result.success',
						cond: 'hasDistributedUnits',
					},
					{ target: 'selectingUnit', cond: 'hasUnits' },
					{ target: 'selectingProduct', cond: 'hasProducts' },
					{ target: 'selectingArea', cond: 'hasAreas' },
					{ target: 'loadingAreas' },
				],
			},

			loadingAreas: {
				invoke: {
					id: 'getAreas',
					src: (context, _event) => loadApiData(context.apiUrl),
					onDone: {
						target: 'selectingArea',
						actions: 'setData',
					},
					onError: {
						target: 'errorArea',
						actions: assign({
							error: (_context, event) => {
								return `Error ${event.data.response.status} - ${event.data.response.statusText}`;
							},
							reqCancelled: (_context, event) => Axios.isCancel(event.data),
						}),
					},
				},
			},

			selectingArea: {
				// Pre-select area
				always: [
					{
						target: 'loadingProducts',
						actions: [
							{ type: 'clearContext', clearAfter: 'area' },
							'setPreSelectedArea',
						],
					},
				],
			},

			loadingProducts: {
				entry: ['updateUrl'],
				invoke: {
					id: 'getProducts',
					src: (context, _event) => loadApiData(context.apiUrl, context),
					onDone: {
						target: 'selectingProduct',
						actions: 'setData',
					},
					onError: {
						target: 'errorProduct',
						actions: assign({
							error: (_context, event) => {
								return `Error ${event.data.response.status} - ${event.data.response.statusText}`;
							},
							reqCancelled: (_context, event) => Axios.isCancel(event.data),
						}),
					},
				},
			},

			selectingProduct: {},

			loadingUnits: {
				entry: ['updateUrl'],
				invoke: {
					id: 'getUnits',
					src: (context, _event) => loadApiData(context.apiUrl, context),
					onDone: {
						target: 'selectingUnit',
						actions: 'setData',
					},
					onError: {
						target: 'errorUnit',
						actions: assign({
							error: (_context, event) => {
								return `Error ${event.data.response.status} - ${event.data.response.statusText}`;
							},
							reqCancelled: (_context, event) => Axios.isCancel(event.data),
						}),
					},
				},
			},

			selectingUnit: {},

			loadingDistributedUnits: {
				entry: ['updateUrl'],
				invoke: {
					id: 'getDistributedUnits',
					src: (context, _event) => loadApiData(context.apiUrl, context),
					onDone: {
						target: 'selectingDistributedUnit',
						actions: 'setData',
					},
					onError: {
						target: 'errorDistributedUnit',
						actions: assign({
							error: (_context, event) => {
								return `Error ${event.data.response.status} - ${event.data.response.statusText}`;
							},
							reqCancelled: (_context, event) => Axios.isCancel(event.data),
						}),
					},
				},
			},

			selectingDistributedUnit: {},

			result: {
				id: 'parameters',
				initial: 'loadingStatistics',
				states: {
					loadingStatistics: {
						entry: ['updateUrl'],
						invoke: {
							id: 'getResult',
							src: (context, _event) => {
								return loadApiData(context.apiUrl, context);
							},
							onDone: {
								target: 'success',
								actions: 'setData',
							},
							onError: {
								target: 'error',
								actions: assign({
									error: (_context, event) => event.data.message,
									reqCancelled: (_context, event) => Axios.isCancel(event.data),
								}),
							},
						},
					},

					error: {
						on: {
							RETRY: {
								target: 'loadingStatistics',
							},
						},
						always: [
							{
								target: 'loadingStatistics',
								cond: 'isCancelled',
							},
						],
					},
					success: {
						entry: ['createResultHeading'],
						// Pre-select parameter & years
						always: [
							{
								target: 'loadingStatistics',
								cond: 'canPreSelectParameter',
								actions: 'setPreSelectedParameter',
							},
							{
								target: 'loadingStatistics',
								cond: 'canPreSelectYear',
								actions: 'setPreSelectedYear',
							},
						],
					},

					loadingFilterItems: {
						invoke: {
							id: 'getFilterItems',
							src: (context, _event) => {
								return searchApiData(
									context.apiUrl,
									context,
									_event.itemsToFetch,
									_event.filter,
									_event.searchterm
								);
							},
							onDone: {
								target: 'success',
								actions: 'setFilterItems',
							},
							onError: {
								target: 'error',
								actions: assign({
									error: (_context, event) => event.data.message,
									reqCancelled: (_context, event) => Axios.isCancel(event.data),
								}),
							},
						},
					},
				},
				on: {
					SET_FILTER: {
						target: '.loadingStatistics',
						actions: ['setFilter', 'cancelPrevRequest'],
					},
					CLEAR_FILTER: {
						target: '.loadingStatistics',
						actions: ['clearFilter', 'cancelPrevRequest'],
					},
					RESET_FILTERS: {
						target: '.loadingStatistics',
						actions: ['resetFilters', 'cancelPrevRequest'],
					},
					ADD_YEAR: {
						target: '.loadingStatistics',
						actions: ['addYear', 'cancelPrevRequest'],
					},
					REMOVE_YEAR: {
						target: '.loadingStatistics',
						actions: ['removeYear', 'cancelPrevRequest'],
					},
					SET_TIME_FRAGMENT: {
						target: '.loadingStatistics',
						actions: ['setTimeFragment', 'cancelPrevRequest'],
					},
					SELECT_MEASUREMENT: {
						target: '.loadingStatistics',
						actions: ['selectMeasurement', 'cancelPrevRequest'],
					},
					SELECT_VARIABLE: {
						target: '.loadingStatistics',
						actions: ['selectVariable', 'cancelPrevRequest'],
					},
					ADD_PARAMETER: {
						target: '.loadingStatistics',
						actions: ['addParameter', 'cancelPrevRequest'],
					},
					REMOVE_PARAMETER: {
						target: '.loadingStatistics',
						actions: ['removeParameter', 'cancelPrevRequest'],
					},
					SELECT_SORT: '.loadingStatistics',
					SELECT_RESULT_FORMAT: {
						target: '.loadingStatistics',
						actions: ['selectResultFormat', 'cancelPrevRequest'],
					},
					SET_CHART_STACKED: {
						target: '.loadingStatistics',
						actions: ['setChartStacked', 'cancelPrevRequest'],
					},
					UNSET_CHART_STACKED: {
						target: '.loadingStatistics',
						actions: ['unsetChartStacked', 'cancelPrevRequest'],
					},
					SELECT_CHART_TYPE: {
						target: '.loadingStatistics',
						actions: ['selectChartType', 'cancelPrevRequest'],
					},
					CHANGE_ORDER: {
						target: '.loadingStatistics',
						actions: ['changeOrder', 'cancelPrevRequest'],
					},
					LOAD_MORE: {
						target: '.loadingStatistics',
						actions: ['loadMore', 'cancelPrevRequest'],
					},
					EXPORT_RESULTS: {
						actions: 'exportingResults',
					},
					SEARCH_FILTER: {
						target: '.loadingFilterItems',
					},
					LOAD_MORE_FILTERITEMS: {
						target: '.loadingFilterItems',
					},
				},
			},

			errorArea: {
				on: {
					RETRY: {
						target: 'loadingAreas',
					},
				},
			},

			errorProduct: {
				on: {
					RETRY: {
						target: 'loadingProducts',
					},
				},
			},

			errorUnit: {
				on: {
					RETRY: {
						target: 'loadingUnits',
					},
				},
			},
			errorDistributedUnit: {
				on: {
					RETRY: {
						target: 'loadingDistributedUnits',
					},
				},
			},

			errorParameters: {
				on: {
					RETRY: {
						target: 'result',
					},
				},
			},
		},
		on: {
			SELECT_AREA: {
				target: 'loadingProducts',
				actions: [
					{ type: 'clearContext', clearAfter: 'area' },
					'setSelectedArea',
					'cancelPrevRequest',
				],
			},
			SELECT_PRODUCT: {
				cond: 'canSelectProduct',
				target: 'loadingUnits',
				actions: [
					{ type: 'clearContext', clearAfter: 'product' },
					'setSelectedProduct',
					'cancelPrevRequest',
				],
			},
			SELECT_UNIT: {
				cond: 'canSelectUnit',
				target: 'loadingDistributedUnits',
				actions: [
					{ type: 'clearContext', clearAfter: 'unit' },
					'setSelectedUnit',
					'cancelPrevRequest',
				],
			},
			SELECT_DISTRIBUTED_UNIT: {
				cond: 'canSelectDistributedUnit',
				target: 'result',
				actions: [
					{ type: 'clearContext', clearAfter: 'distributedUnit' },
					'setSelectedDistributedUnit',
					'cancelPrevRequest',
				],
			},
			CLEAR_ALL: {
				target: 'loadingAreas',
				actions: [
					{ type: 'clearContext', clearAfter: 'all' },
					'cancelPrevRequest',
				],
			},
		},
	},
	{
		guards: {
			isCancelled: (context) => {
				return context.reqCancelled;
			},
			canSelectProduct: (context) => {
				return context.selectedArea !== null;
			},
			canSelectUnit: (context) => {
				return context.selectedProduct !== null;
			},
			canSelectDistributedUnit: (context) => {
				return context.selectedUnit !== null;
			},
			canPreSelectParameter: (context) => {
				if (
					context.selectedParameters.length === 0 &&
					context.selectedDistributedUnit &&
					(context.selectedDistributedUnit.includes(
						'Upphandlingar, Socialtyp.Antal socialt hållbara upphandlingar'
					) ||
						context.selectedDistributedUnit.includes(
							'Upphandlingar, Innovationstyp.Antal innovationsupphandlingar'
						) ||
						context.selectedDistributedUnit.includes(
							'Upphandlingar, Miljötyp.Antal miljömässigt hållbara upphandlingar'
						))
				) {
					return true;
				}

				return (
					context.selectedParameters.length === 0 &&
					context.parameters?.length === 1 &&
					context.parameters[0]?.dimensions?.length === 1 &&
					context.parameters[0]?.dimensions[0]?.properties?.length === 1
				);
			},
			canPreSelectYear: (context) => {
				return (
					context.selectedYear.length === 0 &&
					context.time?.years?.length !== undefined &&
					context.time?.years?.length >= 0
				);
			},
			hasResult: (context) => {
				return context.result !== null;
			},
			hasAreas: (context) => {
				return context.areas !== null;
			},
			hasProducts: (context) => {
				return context.products !== null;
			},
			hasUnits: (context) => {
				return context.units !== null;
			},
			hasDistributedUnits: (context) => {
				return context.distributedUnits !== null;
			},
		},
		actions: {
			setData: assign({
				areas: (_context, event) => event.data.areas,
				products: (_context, event) => event.data.products,
				units: (_context, event) => event.data.units,
				distributedUnits: (_context, event) => event.data.distributedUnits,
				time: (_context, event) => event.data.time,
				parameters: (_context, event) => event.data.parameters,
				filters: (_context, event) => event.data.filters,
				measurements: (_context, event) => event.data.measurements,
				variables: (_context, event) => event.data.variables,
				result: (_context, event) => event.data.result,
				resultError: (_context, event) => event.data.resultError,
				selectedMeasurement: (context, event) => {
					if (context.selectedMeasurement) {
						return context.selectedMeasurement;
					} else if (event.data.measurements && event.data.measurements[0]) {
						return event.data.measurements[0].value;
					}
					return null;
				},
				numItemsToFetch: (context, event) =>
					event.data.result?.fetch || context.numItemsToFetch,
				numItemsTotal: (context, event) =>
					event.data.result?.total || context.numItemsTotal,
				lastUpdated: (context, event) =>
					event.data.lastUpdated || context.lastUpdated,
				error: (context, event) => {
					return null;
				},
				reqCancelled: (context, event) => false,
				selectedTimeFragment: (context, event) => {
					if (context.selectedTimeFragment.length > 1) {
						return [context.selectedTimeFragment[1]];
					} else {
						return context.selectedTimeFragment;
					}
				},
			}),
			createResultHeading: assign({
				resultHeading: (context, event) => {
					let resultHeading = '';
					let filters: any = [];

					// if (context.selectedArea && context.areas) {
					// 	resultHeading += context.areas.filter(area => area.value === context.selectedArea)[0].text;
					// }
					// if (context.selectedProduct && context.products) {
					// 	resultHeading +=
					// 		context.products.filter(
					// 			(item) => item.value === context.selectedProduct
					// 		)[0].text + '. ';
					// }
					if (context.selectedUnit && context.units) {
						resultHeading +=
							context.units.filter(
								(item) => item.value === context.selectedUnit
							)[0].text + ' ';
					}
					if (context.selectedTimeFragment && context.time?.fragments) {
						filters.push(
							context.selectedTimeFragment[0].substring(
								context.selectedTimeFragment[0].indexOf('.') + 1
							)
						);
					}
					// if (context.selectedYear.length > 0) {
					// 	filters.push('år');
					// }
					if (context.selectedParameters && context.parameters) {
						for (const key in context.parameters) {
							const paramGroup: any = context.parameters[key].dimensions;
							for (const key2 in paramGroup) {
								const properties: any = paramGroup[key2].properties;
								const matches = properties.filter((item: OptionData) =>
									context.selectedParameters.indexOf(item.value) !== -1
										? item
										: null
								);
								if (matches.length > 0) {
									matches.forEach((item: any) => {
										filters.push(item.text);
									});
								}
							}
						}
					}

					filters = filters.map((item: any) => item.toLowerCase());
					if (filters.length > 1) {
						const lastFilter = filters.pop();
						resultHeading = `${resultHeading} efter ${filters.join(
							', '
						)} och ${lastFilter}`;
					} else if (filters.length === 1) {
						resultHeading = `${resultHeading} efter ${filters.join(', ')}`;
					}
					return resultHeading;
				},
			}),
			setSelectedArea: assign({
				selectedArea: (_context, event) => event.data,
			}),
			setSelectedProduct: assign({
				selectedProduct: (_context, event) => event.data,
			}),
			setSelectedUnit: assign({
				selectedUnit: (_context, event) => event.data,
			}),
			setSelectedDistributedUnit: assign({
				selectedDistributedUnit: (_context, event) => event.data,
			}),
			// Temporary actions - setPreSelectedArea
			setPreSelectedArea: assign({
				selectedArea: (_context, event) =>
					_context.areas && _context.areas[0].value,
			}),
			setFilter: assign({
				selectedFilters: (context, event) => {
					return [...context.selectedFilters, event.value];
				},
			}),
			setFilterItems: assign({
				filters: (context, event) => {
					context.filters?.map((filter: OptionDataFiltersGroup) => {
						if (event.data.items && filter.text === event.data.text) {
							filter.items = event.data.items;
						}
					});

					return context.filters;
				},
			}),
			clearFilter: assign({
				selectedFilters: (context, event) => {
					return context.selectedFilters.filter((val) => val !== event.value);
				},
			}),
			resetFilters: assign({
				selectedFilters: (context, event) => {
					return [];
				},
			}),
			addYear: assign({
				selectedYear: (context, event) => {
					return [...context.selectedYear, event.data];
				},
			}),
			removeYear: assign({
				selectedYear: (context, event) => {
					return context.selectedYear.filter((val) => val !== event.data);
				},
			}),
			selectMeasurement: assign({
				selectedMeasurement: (_context, event) => event.data,
			}),
			selectVariable: assign({
				selectedVariable: (_context, event) => event.data,
			}),
			selectResultFormat: assign({
				selectedResultFormat: (_context, event) => event.data,
			}),
			selectChartType: assign({
				selectedChartType: (_context, event) => event.data,
			}),
			changeOrder: assign({
				order: (_context, event) => `${event.orderBy}_${event.orderDirection}`,
			}),
			loadMore: assign({
				numItemsToFetch: (context, _event) =>
					context.numItemsToFetch + context.itemsPerPage,
			}),
			setChartStacked: assign({
				selectedChartStacked: (_context, _event) => true,
			}),
			unsetChartStacked: assign({
				selectedChartStacked: (_context, _event) => false,
			}),
			addParameter: assign({
				selectedParameters: (context, event) => {
					return [...context.selectedParameters, event.data];
				},
			}),
			removeParameter: assign({
				selectedParameters: (context, event) => {
					return context.selectedParameters.filter((val) => val !== event.data);
				},
				selectedFilters: (context, event) => {
					return context.selectedFilters.filter(
						(val: string) => !val.includes(`${event.data}|`)
					);
				},
			}),
			setPreSelectedParameter: assign({
				selectedParameters: (context, event) => {
					if (
						context.parameters &&
						context.selectedDistributedUnit &&
						context.selectedDistributedUnit.includes(
							'Upphandlingar, Socialtyp.Antal socialt hållbara upphandlingar'
						)
					) {
						return [
							'Typ av socialt hållbar upphandling.Typ av socialt hållbar upphandling',
						];
					} else if (
						context.parameters &&
						context.selectedDistributedUnit &&
						context.selectedDistributedUnit.includes(
							'Upphandlingar, Innovationstyp.Antal innovationsupphandlingar'
						)
					) {
						return [
							'Typ av innovationsupphandling.Typ av innovationsupphandling',
						];
					} else if (
						context.parameters &&
						context.selectedDistributedUnit &&
						context.selectedDistributedUnit.includes(
							'Upphandlingar, Miljötyp.Antal miljömässigt hållbara upphandlingar'
						)
					) {
						return [
							'Typ av miljömässigt hållbar upphandling.Typ av miljömässigt hållbar upphandling',
						];
					}

					if (
						context.parameters &&
						context.parameters[0].dimensions &&
						context.parameters[0].dimensions[0].properties &&
						context.parameters[0].dimensions[0].properties[0].value
					) {
						return [context.parameters[0].dimensions[0].properties[0].value];
					} else {
						return [];
					}
				},
			}),
			setPreSelectedYear: assign({
				selectedYear: (context, event) => {
					if (context.time && context.time.years && context.time.years[0]) {
						return [context.time.years[0].value];
					} else {
						return [];
					}
				},
			}),
			setTimeFragment: assign({
				selectedTimeFragment: (_context, event) => {
					return [event.data];
				},
			}),
			clearContext: assign((_context, _event, { action }) => {
				switch (action.clearAfter) {
					case 'all':
						return {
							areas: null,
							products: null,
							parameters: null,
							time: null,
							filters: null,
							searchFilters: null,
							measurements: null,
							units: null,
							distributedUnits: null,
							selectedProduct: null,
							selectedUnit: null,
							selectedDistributedUnit: null,
							selectedMeasurement: null,
							selectedVariable: null,
							selectedFilters: [],
							selectedYear: [],
							selectedTimeFragment: ['Tidsperiod.År'],
							selectedParameters: [],
							selectedResultFormat: 'table',
							selectedChartType: 'column',
							selectedChartStacked: false,
							numItemsToFetch: 20,
							result: null,
						};
					case 'area':
						return {
							products: null,
							parameters: null,
							time: null,
							filters: null,
							searchFilters: null,
							measurements: null,
							units: null,
							distributedUnits: null,
							selectedProduct: null,
							selectedUnit: null,
							selectedDistributedUnit: null,
							selectedMeasurement: null,
							selectedVariable: null,
							selectedFilters: [],
							selectedYear: [],
							selectedTimeFragment: ['Tidsperiod.År'],
							selectedParameters: [],
							selectedResultFormat: 'table',
							selectedChartType: 'column',
							selectedChartStacked: false,
							numItemsToFetch: 20,
							result: null,
							resultError: null,
						};
					case 'product':
						return {
							parameters: null,
							time: null,
							filters: null,
							searchFilters: null,
							measurements: null,
							units: null,
							distributedUnits: null,
							selectedUnit: null,
							selectedDistributedUnit: null,
							selectedMeasurement: null,
							selectedVariable: null,
							selectedFilters: [],
							selectedYear: [],
							selectedTimeFragment: ['Tidsperiod.År'],
							selectedParameters: [],
							selectedResultFormat: 'table',
							selectedChartType: 'column',
							selectedChartStacked: false,
							numItemsToFetch: 20,
							result: null,
							resultError: null,
						};
					case 'unit':
						return {
							parameters: null,
							time: null,
							filters: null,
							searchFilters: null,
							measurements: null,
							distributedUnits: null,
							selectedDistributedUnit: null,
							selectedMeasurement: null,
							selectedVariable: null,
							selectedFilters: [],
							selectedYear: [],
							selectedTimeFragment: ['Tidsperiod.År'],
							selectedParameters: [],
							selectedResultFormat: 'table',
							selectedChartType: 'column',
							selectedChartStacked: false,
							resultError: null,
						};
					case 'distributedUnit':
						return {
							parameters: null,
							time: null,
							filters: null,
							searchFilters: null,
							measurements: null,
							selectedMeasurement: null,
							selectedVariable: null,
							selectedFilters: [],
							selectedYear: [],
							selectedTimeFragment: ['Tidsperiod.År'],
							selectedParameters: [],
							selectedResultFormat: 'table',
							selectedChartType: 'column',
							selectedChartStacked: false,
							resultError: null,
						};
					case 'parameters':
						return {
							selectedMeasurement: null,
							selectedVariable: null,
							selectedYear: [],
							selectedTimeFragment: ['Tidsperiod.År'],
							selectedParameters: [],
							selectedFilters: [],
						};
					default:
						return {};
				}
			}),

			setContextFromQuery: assign((context, _event) => {
				const {
					area,
					product,
					unit,
					distributedUnit,
					measurement,
					variable,
					year,
					timefragment,
					param,
					resultFormat,
					chartType,
					chartStacked,
					orderBy,
					fetch: fetchProp,
					total,
					filter,
				} = parse(window.location.search);

				return {
					...context,
					selectedArea: (area as string) || null,
					selectedProduct: (product as string) || null,
					selectedUnit: (unit as string) || null,
					selectedDistributedUnit: (distributedUnit as string) || null,
					selectedMeasurement: (measurement as string) || null,
					selectedVariable: (variable as string) || null,
					selectedYear:
						(Array.isArray(year) && (year as string[])) ||
						(typeof year === 'string' && ([year] as string[])) ||
						[],
					selectedTimeFragment:
						(Array.isArray(timefragment) && (timefragment as string[])) ||
						(typeof timefragment === 'string' &&
							([timefragment] as string[])) ||
						[],
					selectedParameters:
						(Array.isArray(param) && (param as string[])) ||
						(typeof param === 'string' && ([param] as string[])) ||
						[],
					selectedFilters:
						(Array.isArray(filter) && (filter as string[])) ||
						(typeof filter === 'string' && ([filter] as string[])) ||
						[],
					selectedResultFormat: (resultFormat as resultFormats) || 'table',
					selectedChartType: (chartType as chartTypes) || 'column',
					selectedChartStacked: chartStacked === 'true' || false,
					order: (orderBy as string) || null,
					numItemsToFetch:
						parseInt(fetchProp as string, 10) || context.itemsPerPage,
					numItemsTotal: parseInt(total as string, 10) || 0,
				};
			}),
			// Update the url according to the pager
			updateUrl: (context, _event) => {
				if (context.router) {
					context.router.replace(getUrlQueries(context));
				}
			},
			cancelPrevRequest: (context, _event) => {
				cancelRequest();
			},
			exportingResults: (context, _event) => {
				let fileUrl = '';

				switch (_event.value) {
					case 'csv':
					case 'excel':
						if (_event.value === 'csv') {
							fileUrl = `${context.apiUrl}/export/csv${getUrlQueries(context)}`;
						} else {
							fileUrl = `${context.apiUrl}/export/excel${getUrlQueries(
								context
							)}`;
						}
						exportDataByUrl(fileUrl);
						break;
					case 'svg':
						exportDataHighcharts(_event.chartRef, 'image/svg+xml');
						break;
					case 'png':
					case 'jpeg':
						exportDataHighcharts(_event.chartRef, `image/${_event.value}`);
						break;
					default:
						break;
				}
			},
		},
	}
);
