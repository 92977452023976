/**
 * Link
 */

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectEpi } from 'store/modules/epi';

interface Props {
	/** The url the link should navigate to */
	to?: string;

	/** The url the link should navigate to */
	href?: string;

	/** Pass optional classnames for the html */
	className?: string;

	[htmlAttributes: string]: any;
}

const Link: React.FC<Props> = ({
	to,
	href,
	children,
	className = '',
	...htmlAttributes
}) => {
	const epi = useSelector(selectEpi);
	const url = to || href || '#';

	if (epi?.beta?.inEditMode || (url && url.charAt(0) !== '/')) {
		return (
			<a href={url} className={className} {...htmlAttributes}>
				{children}
			</a>
		);
	} else {
		return (
			<RouterLink to={url} className={className} {...htmlAttributes}>
				{children}
			</RouterLink>
		);
	}
};

export default Link;
