/**
 * StatisticChart
 */

import React, { useRef } from 'react';
import Highcharts from 'components/Highcharts';
import { HighchartsBaseModel, StatisticsDataExportOptionModel } from 'types';
import ExportDataDropdown from 'components/ExportDataDropdown';
import ExportDataOption from 'components/ExportDataDropdown/ExportDataOption';

interface Props {
	/** Chart heading */
	heading?: string;

	/** Chart source */
	source?: string;

	/** Chart properties */
	chartProperties?: HighchartsBaseModel;
}

/** Main description for this component. */
const StatisticChart: React.FC<Props> = ({
	heading,
	source,
	chartProperties,
}) => {
	const chartRef = useRef<any>(null);
	return (
		<div>
			{chartProperties?.exports && (
				<div className="flex flex-row-reverse mb-4">
					<ExportDataDropdown
						buttonText={chartProperties.exports.placeholder}
						dataTitle={chartProperties.title}
					>
						{chartProperties.exports.options.map(
							(option: StatisticsDataExportOptionModel, index: number) => {
								return (
									<ExportDataOption
										type={option.type}
										url={option.url}
										chartRef={chartRef}
										key={index}
									>
										{option.name}
									</ExportDataOption>
								);
							}
						)}
					</ExportDataDropdown>
				</div>
			)}
			<Highcharts
				heading={heading}
				source={source}
				chartType={chartProperties?.chartType}
				stacked={chartProperties?.stacked}
				title={chartProperties?.title}
				subtitle={chartProperties?.subtitle}
				tooltipValueDecimals={chartProperties?.tooltipValueDecimals}
				tooltipValuePrefix={chartProperties?.tooltipValuePrefix}
				tooltipValueSuffix={chartProperties?.tooltipValueSuffix}
				yAxisTitle={chartProperties?.yAxisTitle}
				yAxisLabelFormat={chartProperties?.yAxisLabelFormat}
				yAxisMin={chartProperties?.yAxisMin}
				yAxisMax={chartProperties?.yAxisMax}
				xAxisTitle={chartProperties?.xAxisTitle}
				xAxisCategories={chartProperties?.xAxisCategories}
				xAxisLabelFormat={chartProperties?.xAxisLabelFormat}
				showLegend={chartProperties?.showLegend}
				series={chartProperties?.series}
				chartHeight={
					chartProperties?.chartType === 'bar' &&
					chartProperties?.xAxisCategories &&
					chartProperties?.xAxisCategories.length > 16
						? 100 + '%'
						: null
				}
				chartRef={chartRef}
			/>
		</div>
	);
};

export default StatisticChart;
