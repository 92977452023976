import {
	ResponseResolver,
	RestRequest,
	DefaultRequestBody,
	RequestParams,
	RestContext,
} from 'msw';
import criteria_column from './comparison-column.json';

export const getMockCriteriaColumnData: ResponseResolver<
	RestRequest<DefaultRequestBody, RequestParams>,
	RestContext,
	any
> = async (req, res, ctx) => {
	const criteriaArea = req.url.searchParams.get('criteriaArea');
	const productGroup = req.url.searchParams.get('productGroup');
	const productSubGroup = req.url.searchParams.get('productSubGroup');
	const criteriaName = req.url.searchParams.get('criteriaName');
	const criteriaLevel = req.url.searchParams.get('criteriaLevel');

	let criteriaData = JSON.parse(JSON.stringify(criteria_column));

	const updateSelectedOption = (index: number, queryParam: string) => {
		criteriaData.selects[index]?.options?.forEach((option: any) => {
			if (option.value === queryParam) {
				option.selected = true;
			} else {
				option.selected = false;
			}
		});
	};
	if (
		criteriaArea &&
		!productGroup &&
		!productSubGroup &&
		!criteriaName &&
		!criteriaLevel
	) {
		updateSelectedOption(0, criteriaArea);

		criteriaData.selects.forEach((select: any, index: number) => {
			if (index > 1) {
				select.options = null;
			}
		});
		criteriaData.criteriaInfo = null;
	} else if (
		criteriaArea &&
		productGroup &&
		!productSubGroup &&
		!criteriaName &&
		!criteriaLevel
	) {
		updateSelectedOption(0, criteriaArea);
		updateSelectedOption(1, productGroup);

		criteriaData.selects.forEach((select: any, index: number) => {
			if (index > 2) {
				select.options = null;
			}
		});
		criteriaData.criteriaInfo = null;
	} else if (
		criteriaArea &&
		productGroup &&
		productSubGroup &&
		!criteriaName &&
		!criteriaLevel
	) {
		updateSelectedOption(0, criteriaArea);
		updateSelectedOption(1, productGroup);
		updateSelectedOption(2, productSubGroup);
		criteriaData.selects.forEach((select: any, index: number) => {
			if (index > 3) {
				select.options = null;
			}
		});
		criteriaData.criteriaInfo = null;
	} else if (
		criteriaArea &&
		productGroup &&
		productSubGroup &&
		criteriaName &&
		!criteriaLevel
	) {
		updateSelectedOption(0, criteriaArea);
		updateSelectedOption(1, productGroup);
		updateSelectedOption(2, productSubGroup);
		updateSelectedOption(3, criteriaName);
		criteriaData.criteriaInfo = null;
	} else if (
		criteriaArea &&
		productGroup &&
		productSubGroup &&
		criteriaName &&
		criteriaLevel &&
		criteriaLevel !== 'basniva'
	) {
		updateSelectedOption(0, criteriaArea);
		updateSelectedOption(1, productGroup);
		updateSelectedOption(2, productSubGroup);
		updateSelectedOption(3, criteriaName);
		updateSelectedOption(4, criteriaLevel);
	} else if (
		criteriaArea &&
		productGroup &&
		productSubGroup &&
		criteriaName &&
		criteriaLevel &&
		criteriaLevel === 'basniva'
	) {
		return res(ctx.status(404), ctx.body(''));
	}

	await new Promise<void>((resolve, reject) => {
		setTimeout(() => {
			resolve();
		}, 1000);
	});
	return res(ctx.status(200), ctx.json(criteriaData));
};
