/**
 * Highcharts
 */

import React, { useEffect, useState } from 'react';
import { Icon, Text } from 'ui-component-library/base';
import { HighchartsBaseModel } from 'types';
import { getOptionsFromProps } from './helpers/getOptionsFromProps';

interface Props extends HighchartsBaseModel {
	/** Chart heading */
	heading?: string;

	/** Chart source */
	source?: string;

	/** Last date when the statistics data was updated  */
	lastUpdated?: string | null;

	/** Ref to Highchart */
	chartRef?: any;

	/** Should the loading spinner be visible? */
	showSpinner?: boolean;
}

/** Integration with Highcharts.js. */
const Highcharts: React.FC<Props> = ({
	heading,
	source,
	lastUpdated,
	chartRef,
	showSpinner = true,
	...chartProperties
}) => {
	const [highchartsJs, setHighchartsJs] = useState<any>();
	const [HighchartsReact, setHighchartsReact] = useState<any>();
	const [options, setOptions] = useState<any>(
		getOptionsFromProps(chartProperties)
	);

	useEffect(() => {
		import('highcharts').then((data) => {
			setHighchartsJs(data.default);
			require('highcharts/modules/exporting')(data.default);
		});
		import('highcharts-react-official').then((data) =>
			setHighchartsReact(data.default)
		);
	}, []);

	useEffect(() => {
		setOptions(getOptionsFromProps(chartProperties));
	}, [
		chartProperties.stacked,
		chartProperties.series,
		chartProperties.title,
		chartProperties.subtitle,
		chartProperties.tooltipValueDecimals,
		chartProperties.tooltipValuePrefix,
		chartProperties.tooltipValueSuffix,
		chartProperties.chartType,
		chartProperties.yAxisTitle,
		chartProperties.yAxisLabelFormat,
		chartProperties.yAxisMin,
		chartProperties.yAxisMax,
		chartProperties.xAxisTitle,
		chartProperties.xAxisCategories,
		chartProperties.xAxisLabelFormat,
		chartProperties.chartHeight,
	]);
	// Stack with percent

	if (!highchartsJs || !HighchartsReact) {
		return (
			<div className="flex items-center justify-center py-4">
				<Text as="h2" text={heading} />
				{showSpinner && (
					<Icon
						icon="loader"
						size={2}
						animate="spin"
						color="#75726B"
						className="inline-block"
					/>
				)}
				{source && <p className="text-p text-greyDarker mb-4 ml-4">{source}</p>}
			</div>
		);
	} else {
		return (
			<div>
				<Text as="h2" text={heading} />
				<HighchartsReact
					ref={chartRef}
					highcharts={highchartsJs}
					options={options}
					immutable={true}
				/>
				{source && <p className="text-p text-greyDarker mb-4 ml-4">{source}</p>}
				{lastUpdated && (
					<p className="text-p text-greyDarker mb-4 ml-4">{`Statistikdatabasen uppdatering ${lastUpdated}`}</p>
				)}
			</div>
		);
	}
};

export default Highcharts;
