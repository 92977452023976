/**
 * CriteriaCart
 */

import React, { useContext, useEffect, useState } from 'react';
import { useSelector as useXstateSelector } from '@xstate/react';
import { GlobalStateContext } from 'App';
import {
	selectCriteriaCartModel,
	selectCriterias,
} from 'state-machines/criteriaCart.machine';
import {
	Alert,
	ButtonSupportive,
	Cell,
	Grid,
	Icon,
} from 'ui-component-library/base';
import { Transition } from 'react-transition-group';
import { TransitionState } from 'ui-component-library/base/types';
import clsx from 'clsx';
import Text from 'components/Text';
import DataTableCart from 'components/DataTableCart';
import DataTableCartRow from 'components/DataTableCart/DataTableCartRow';
import { DataTableCartRowModel, DownloadBlockModel } from 'types';
import CriteriaCartDownloadButton from './CriteriaCartDownloadButton';
import CriteriaCartToast from './CriteriaCartToast';

interface Props {
	/** Label for the criteria cart button showing on desktop/tablet */
	cartLabel: string;

	/** Label for open the criteria cart modal */
	closeCartText: string;

	/** If the current page has breadbrumbs */
	hasBreadcrumbs: boolean;

	/** If the current page has theme border */
	hasThemeBorder: boolean;

	/** Fetch cart api url */
	apiUrl: string;

	/** Toast message */
	toastText: string;

	/** Callback function when toogle cart modal */
	onToggle: any;
}

/** Demo of using the global criteriaCart machine/service. */
const CriteriaCart: React.FC<Props> = ({
	cartLabel,
	closeCartText,
	hasBreadcrumbs,
	hasThemeBorder,
	apiUrl,
	toastText,
	onToggle,
}) => {
	const globalServices = useContext(GlobalStateContext);
	const { state, send } = globalServices.criteriaCartService;
	const criteriasInCart = useXstateSelector(
		globalServices.criteriaCartService,
		selectCriterias
	);
	const content = useXstateSelector(
		globalServices.criteriaCartService,
		selectCriteriaCartModel
	);
	const [error, setError] = useState('');
	const [expanded, setExpanded] = useState(false);
	const [criterias, setCriterias] = useState<
		DataTableCartRowModel[] | undefined
	>(undefined);

	const handleRemoveCriteria = (criteria: string) => {
		send('REMOVE_CRITERIA', { item: criteria });

		const items = criterias?.filter(
			(item: DataTableCartRowModel) => item.criteriaDescriptor !== criteria
		);
		setCriterias(items);
	};

	const handleDisableExportButton = (culture: string) => {
		if (criteriasInCart.length === 0) {
			return true;
		} else if (culture === 'en') {
			return criterias?.every((item) => item.warningText);
		} else {
			return false;
		}
	};

	const handleClearCart = () => {
		send('CLEAR_CART');
		setCriterias(undefined);
	};

	useEffect(() => {
		setCriterias(content?.requirements?.items);
	}, [content]);

	const handleDownloadError = (message: string) => {
		setError(message);
	};

	return (
		<div>
			<button
				aria-controls={`criteria-cart`}
				aria-expanded={expanded}
				data-cid="CriteriaCart"
				onClick={() => {
					if (!expanded) {
						send('FETCH_CART_PAGE', { api: apiUrl });
					}
					setExpanded(!expanded);
					setError('');
					onToggle();
				}}
				className="align-middle"
			>
				<span className="mr-2 pl-1 hidden md:inline-block">{cartLabel}</span>
				<span className="relative">
					<Icon
						icon="shoppingBag"
						aria-hidden={true}
						size={1.25}
						className="text-black"
					/>
					<span className="inline-block absolute -mt-2 -ml-3 z-10 text-white text-sm w-8 mr-2 multieselect-dropdown-counter">
						{criteriasInCart.length || 0}
					</span>
				</span>
			</button>
			{toastText && (
				<CriteriaCartToast
					text={toastText}
					currentQuantity={criteriasInCart.length}
				/>
			)}
			<Transition in={expanded} timeout={600}>
				{(transState: TransitionState) => (
					<div
						className={clsx(
							hasBreadcrumbs && !hasThemeBorder
								? 'mt-18 md:mt-24 border-t-2'
								: hasBreadcrumbs && hasThemeBorder
								? 'mt-20 md:mt-28'
								: 'mt-8',
							' w-full min-h-full absolute bg-white z-50 left-0 border-b-2 border-greyLight pb-24'
						)}
						id={`criteria-cart`}
						hidden={transState === 'exited'}
					>
						<Grid
							padding={false}
							margin={false}
							className={clsx(
								transState === 'exiting'
									? 'animate-moveOutBottomHalf'
									: 'animate-moveInBottomHalf',
								'lg:px-6 px-4 -mt-4 md:mt-0 h-full'
							)}
						>
							<Cell
								span={12}
								margin={false}
								className="flex flex-row-reverse mt-4"
							>
								<button
									onClick={() => {
										setExpanded(false);
										setError('');
										onToggle();
									}}
								>
									<Icon icon="clear" aria-hidden={true} size={6} />
									<span className="sr-only">{closeCartText}</span>
								</button>
							</Cell>
							{state && state.matches('fetchingCartPage') ? (
								<Cell span={12} className="h-full">
									<div className="flex justify-center w-full mt-10">
										<Icon
											icon="loader"
											size={7}
											animate="spin"
											color="#75726B"
											aria-label="Laddar"
										/>
									</div>
								</Cell>
							) : (
								<>
									{content && (
										<Cell
											span={12}
											desktop={8}
											margin={false}
											className="h-full w-full"
										>
											{criterias && criterias.length > 0 ? (
												<>
													<Text as="h1" prop="heading">
														{content.heading.replace(
															'{0}',
															criteriasInCart.length.toString()
														)}
													</Text>
													{content?.preamble && (
														<div className="mb-7">
															<Text as="preamble" prop="preamble">
																{content.preamble}
															</Text>
														</div>
													)}
												</>
											) : (
												<>
													<Text as="h1" prop="heading">
														{content.noCriteriasHeading}
													</Text>
													{content.noCriteriasPreamble && (
														<div className="mb-7">
															<Text as="preamble" prop="preamble">
																{content.noCriteriasPreamble}
															</Text>
														</div>
													)}
												</>
											)}

											{criterias && criterias.length > 0 && (
												<>
													<DataTableCart
														properties={content?.requirements?.properties}
													>
														{content?.requirements?.properties &&
															criterias.map(
																(row: DataTableCartRowModel, index: number) => {
																	return (
																		<DataTableCartRow
																			row={row}
																			headers={
																				content?.requirements?.properties || []
																			}
																			handleDelete={handleRemoveCriteria}
																			handleLinkClick={() => {
																				setExpanded(false);
																				onToggle();
																			}}
																			key={index}
																		/>
																	);
																}
															)}
													</DataTableCart>
													<ButtonSupportive
														data-button-action={content.emptyCartText}
														variant="squared"
														onClick={() => handleClearCart()}
														className="float-right"
													>
														<Icon
															icon="trashCan"
															size={0.75}
															aria-hidden={true}
															className="mr-2 -mt-1"
														/>
														<span>{content.emptyCartText}</span>
													</ButtonSupportive>
												</>
											)}
										</Cell>
									)}

									{content &&
										content.downloadBlocks &&
										content.downloadBlocks.length > 0 && (
											<Cell
												span={12}
												tablet={6}
												desktop={4}
												margin={false}
												className="h-full lg:pl-16"
											>
												<h2 className="text-h1 mb-8 font-semibold">
													{content?.downloadHeading}
												</h2>

												<div className="border-2 border-greySemiLight p-5">
													{content.downloadBlocks.map(
														(block: DownloadBlockModel, blockIndex: number) => {
															return (
																<div
																	key={blockIndex}
																	className={clsx(blockIndex > 0 && 'mt-8')}
																>
																	<h3 className="font-bold mb-2">
																		{block.heading}
																	</h3>
																	{block.preamble && (
																		<p className="mb-2">{block.preamble}</p>
																	)}
																	{block.buttons.map(
																		(button: any, buttonIndex: number) => {
																			return (
																				<CriteriaCartDownloadButton
																					key={buttonIndex}
																					text={button.text}
																					progressText={
																						block.generateFileProgressText
																					}
																					culture={block.culture}
																					format={button.format}
																					api={block.createDownloadUrl}
																					criterias={criteriasInCart}
																					disabled={handleDisableExportButton(
																						block.culture
																					)}
																					className="mb-2"
																					onDownloadError={handleDownloadError}
																					items={content?.requirements?.items}
																				/>
																			);
																		}
																	)}
																</div>
															);
														}
													)}
												</div>
												<div aria-live="polite">
													{error && (
														<Alert variant="danger" className="mt-8">
															<Text as="p" margin={false} className="mb-0">
																{error}
															</Text>
														</Alert>
													)}
												</div>
											</Cell>
										)}
								</>
							)}
						</Grid>
					</div>
				)}
			</Transition>
		</div>
	);
};

export default CriteriaCart;
