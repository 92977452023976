import clsx from 'clsx';
import React from 'react';
import { SearchAggregationModel, SearchFilterGroupModel } from 'types';
import { FilterChip } from 'ui-component-library/base';
import { Filterings } from 'ui-component-library/uhmse';
import MultiselectFilter from './MultiselectFilter';
import RadioFilter from './RadioFilter';
import SingleSelectFilter from './SingleSelectFilter';

interface Props {
	filterGroups: SearchFilterGroupModel[];
	clearFilterText: string;
	onChange?: any;
}

type SelectedFilterType = {
	bucketId: string;
	bucketName: string;
	bucketPath: string;
	groupName: string;
};

/** Main description for this component. */
const SearchFilters: React.FC<Props> = ({
	filterGroups,
	onChange,
	clearFilterText,
}) => {
	const renderFilterType = (
		aggregation: SearchAggregationModel,
		groupName: string
	) => {
		switch (aggregation.aggregatorType) {
			case 'radio':
				return (
					<RadioFilter
						key={aggregation.path}
						onChange={onChange}
						name={aggregation.name}
						path={aggregation.path}
						buckets={aggregation.buckets}
						type={aggregation.type}
						groupName={groupName}
					/>
				);
			case 'multiselect':
				return (
					<MultiselectFilter
						key={aggregation.path}
						id={aggregation.path}
						onChange={onChange}
						name={aggregation.name}
						path={aggregation.path}
						buckets={aggregation.buckets}
						searchPlaceholder={aggregation.searchPlaceholder || ''}
						groupName={groupName}
					/>
				);
			case 'select':
				return (
					<SingleSelectFilter
						key={aggregation.path}
						name={aggregation.name}
						path={aggregation.path}
						buckets={aggregation.buckets}
						onChange={onChange}
						searchPlaceholder={aggregation.searchPlaceholder || ''}
						groupName={groupName}
					/>
				);

			default:
				break;
		}
	};

	const getSelectedFilters = (): SelectedFilterType[] => {
		let selectedFilters: SelectedFilterType[] = [];
		filterGroups.forEach((filterGroup) => {
			filterGroup.aggregations.forEach((filter) =>
				filter.buckets.forEach((bucket, index) => {
					if (bucket.selected) {
						selectedFilters.push({
							bucketId: `${filter.path}-${index}`,
							bucketName: bucket.term,
							bucketPath: filter.path,
							groupName: filterGroup.groupName,
						});
					}
				})
			);
		});
		return selectedFilters;
	};

	const onRemoveFilter = (bucket: SelectedFilterType) => {
		let groups = JSON.parse(JSON.stringify(filterGroups));
		let group = groups.find(
			(group: any) => group.groupName === bucket.groupName
		);
		let aggregation = group.aggregations.find(
			(a: any) => a.path === bucket.bucketPath
		);

		const selectedBuckets = aggregation.buckets.filter(
			(item: any) => item.term !== bucket.bucketName && item.selected
		);

		const agg = Object.assign(
			{},
			{
				name: aggregation.name,
				path: aggregation.path,
				buckets: selectedBuckets,
			}
		);

		onChange(agg);
	};

	return (
		<>
			<div className="flex flex-wrap mt-4">
				{getSelectedFilters().map((item, i) => (
					<FilterChip
						onClick={() => onRemoveFilter(item)}
						className="mr-2 mb-2 max-w-full flex justify-between items-center"
						title={item.bucketName}
						key={i}
						data-button-action={clearFilterText}
						value={item.bucketName}
					>
						<span className="sr-only">{clearFilterText}</span>
						<span className="truncate w-95/100">{item.bucketName}</span>
					</FilterChip>
				))}
			</div>
			{filterGroups.map((filterGroup, index) => {
				return (
					<Filterings
						heading={filterGroup.groupName}
						id={filterGroup.groupName}
						className={clsx(index === 0 && 'border-t mt-4')}
						key={index}
						selectedBuckets={filterGroup.selectedBuckets}
						expanded={true}
					>
						{filterGroup.aggregations.map((aggregation) =>
							renderFilterType(aggregation, filterGroup.groupName)
						)}
					</Filterings>
				);
			})}
		</>
	);
};

export default SearchFilters;
