/**
 * WebGuidanceIntroBlock
 */

import clsx from 'clsx';
import React from 'react';
import { GuidanceAreaModel } from 'types';
import hexagonConstruction from './svg/hexagon-construction.svg';
import hexagonSupplyChains from './svg/hexagon-sustainable-supply-chains.svg';
import hexagonMunicipalLand from './svg/hexagon-municipal-land.svg';
import hexagonDefault from './svg/hexagon-default.svg';
import work from './svg/hexagon-work.svg';

interface Props {
	/** Guidance area that sets the background-image */
	area: GuidanceAreaModel;

	/** Optional classname */
	className?: string;
}

/** Main description for this component. */
const WebGuidanceIntroBlock: React.FC<Props> = ({
	area,
	className,
	children,
}) => {
	const getSVG = {
		construction: hexagonConstruction,
		supplyChains: hexagonSupplyChains,
		municipalLand: hexagonMunicipalLand,
		work: work,
		default: hexagonDefault,
	};

	return (
		<div
			data-cid="GuidanceIntroBlock"
			className={clsx(
				className,
				'bg-greyLighter',
				'text-black',
				'pt-14 pb-16 bg-no-repeat guidance-intro-block'
			)}
			style={{ backgroundImage: `url(${getSVG[area]})` }}
		>
			{children}
		</div>
	);
};

export default WebGuidanceIntroBlock;
