/**
 * StatisticServiceSectionPage
 */

import React from 'react';

import { StatisticServiceSectionPageModel } from 'types';

import BaseLayout from 'layouts/BaseLayout';
import {
	Grid,
	Cell,
	Text,
	ArticleHeader,
	ArticleHeaderCategory,
} from 'ui-component-library/base';
import Link from 'components/Link';
import EpiContentArea from 'components/EpiContentArea';
import EpiFragments from 'components/EpiFragments';
import { ArticleHeaderCategoryList } from 'ui-component-library/base/ArticleHeader';

/** Main description for this component. */
const StatisticServiceSectionPage: React.FC<StatisticServiceSectionPageModel> =
	({ heading, preamble, articleHeader, contentArea, text }) => {
		return (
			<BaseLayout themeBorder={true}>
				<Grid
					padding={false}
					margin={false}
					className="lg:px-6 px-4 -mt-4 md:mt-0 md:pt-4"
				>
					<Cell span={12} desktop={7}>
						<Text as="h1" prop="heading">
							{heading}
						</Text>
						{articleHeader && (
							<ArticleHeader
								published={articleHeader.published}
								social={articleHeader.social}
								className="mb-8"
							>
								{articleHeader.categories &&
									articleHeader.categories.length > 0 && (
										<ArticleHeaderCategoryList>
											{articleHeader.categories.map((category, index) => {
												return (
													<ArticleHeaderCategory
														key={index}
														url={category.url}
														text={category.text}
														LinkComponent={Link}
													/>
												);
											})}
										</ArticleHeaderCategoryList>
									)}
							</ArticleHeader>
						)}
						<Text as="preamble" prop="preamble" margin={false} className="mb-2">
							{preamble}
						</Text>
						<EpiFragments prop="text" fragments={text?.fragments} />
					</Cell>
				</Grid>
				{contentArea && contentArea.length > 0 && (
					<EpiContentArea content={contentArea} />
				)}
			</BaseLayout>
		);
	};

export default StatisticServiceSectionPage;
