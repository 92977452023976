/**
 * CriteriaCartAddButton
 */

import { GlobalStateContext } from 'App';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCriteriaCart } from 'store/modules/model';
import { useSelector as useXstateSelector } from '@xstate/react';
import { selectCriterias } from 'state-machines/criteriaCart.machine';
import { Icon, ButtonSupportive } from 'ui-component-library/base';

interface Props {
	/** Download added button text */
	downloadAddedText: string;

	/** Add to download button text */
	addToDownloadsText: string;

	/** The descriptor for the criteria  */
	criteriaDescriptor: string;

	/** Variant of button */
	variant?: 'standard' | 'table';

	/** Pass an optional className */
	className?: string;

	/** Pass info about criteria */
	requirementInfo?: any;
}

/** Main description for this component. */
const CriteriaCartAddButton: React.FC<Props> = ({
	downloadAddedText,
	addToDownloadsText,
	criteriaDescriptor,
	variant = 'standard',
	className,
	requirementInfo,
}) => {
	const criteriaCart = useSelector(selectCriteriaCart);
	const globalServices = useContext(GlobalStateContext);
	const { send } = globalServices.criteriaCartService;
	const [isAdded, setIsAdded] = useState<boolean>(false);
	const criteriasInCart = useXstateSelector(
		globalServices.criteriaCartService,
		selectCriterias
	);

	const handleAddToCart = (e: any) => {
		e.preventDefault();
		if (isAdded) {
			send('REMOVE_CRITERIA', {
				item: criteriaDescriptor,
			});
		} else {
			send('ADD_CRITERIA', {
				item: criteriaDescriptor,
			});

			if (window.dataLayer && window.dataLayer.push) {
				window.dataLayer.push({
					event: 'saveCriteria',
					criteriaProductArea: requirementInfo.criteriaProductArea,
					criteriaProductGroup: requirementInfo.criteriaProductGroup,
					criteriaProductSubgroup: requirementInfo.criteriaProductSubgroup,
					criteriaItem: requirementInfo.criteriaItem,
					criteriaLevel: requirementInfo.criteriaLevel,
				});
			}
		}
		setIsAdded(!isAdded);
	};
	const getCriteriaStatusInCart = (criteria: string) => {
		let found = criteriasInCart.filter((item) => item === criteria);
		return found && found.length > 0 ? true : false;
	};

	useEffect(() => {
		if (criteriaDescriptor) {
			setIsAdded(getCriteriaStatusInCart(criteriaDescriptor));
		}
		// eslint-disable-next-line
	}, [criteriasInCart, criteriaDescriptor]);

	return (
		<>
			{criteriaCart && (
				<ButtonSupportive
					data-button-action="Spara för nedladdning"
					onClick={(e) => handleAddToCart(e)}
					color={isAdded ? 'teal' : 'grey'}
					variant="squared"
					className={className}
				>
					<Icon
						icon={isAdded ? 'checkSquare' : 'square'}
						aria-hidden={true}
						className="mr-2 -mt-1"
					/>
					<span className="whitespace-no-wrap">
						{isAdded ? downloadAddedText : addToDownloadsText}
					</span>
				</ButtonSupportive>
			)}
		</>
	);
};

export default CriteriaCartAddButton;
