/**
 * ModalButton
 */

import clsx from 'clsx';
import React from 'react';
import { useState } from 'react';
import { Button, Icon, Modal } from 'ui-component-library/base';

interface Props {
	/** Text for the button */
	buttonText: string;

	/** Close button text for the modal */
	closeModalButtonText: string;

	/** Id for IconButton */
	id?: string;

	/** Pass an optional className */
	className?: string;
}

/** Main description for this component. */
const ModalButton: React.FC<Props> = ({
	buttonText,
	closeModalButtonText,
	id,
	className,
	children,
}) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const onToggle = () => {
		setIsOpen(!isOpen);
	};
	return (
		<div className={clsx(className)}>
			<Button variant="primary" onClick={onToggle} id={id}>
				<span>
					<Icon icon="pen" direction="right" size={1} className="mr-3" />
				</span>
				{buttonText}
			</Button>

			{isOpen && (
				<Modal closeBtnLabel={closeModalButtonText} onToggle={onToggle}>
					{children}
				</Modal>
			)}
		</div>
	);
};

export default ModalButton;
