/**
 * CriteriaComparisonColumn
 */

import clsx from 'clsx';
import EpiFragments from 'components/EpiFragments';
import Link from 'components/Link';
import React, { useEffect, useState } from 'react';
import {
	ComparisonColumnModel,
	ComparisonSelectModel,
	ComparisonSelectOptionModel,
} from 'types';
import { Alert, Icon } from 'ui-component-library/base';
import CriteriaCartAddButton from 'components/CriteriaCartAddButton';
import Text from 'components/Text';

interface Props {
	/** column data of selects and criteriaInfo */
	column: ComparisonColumnModel;

	/** Add-to-donwload button text */
	addToDownloadsText: string;

	/** Donwload-added button text */
	downloadAddedText: string;

	/** Index of a criteria column */
	columnIndex: number;

	/** Callback function for select change */
	onChange: any;

	/**  Callback funktion that sends events to state machine */
	send: Function;

	/** Error message response from AJAX-request */
	errorMessage?: string;

	/** If the component is in loading state */
	loading?: boolean;
}

const createQueryString = (
	selects: ComparisonSelectModel[],
	columnIndex: number,
	id?: string,
	value?: string
) => {
	let query: string[] = [];
	let endLoop = false;
	for (let selectIndex in selects) {
		if (endLoop) break;

		const select = selects[selectIndex];
		for (let optionIndex in select.options) {
			const option = select.options[optionIndex as any];
			if (option.selected) {
				if (id === `column${columnIndex}-select-${select.path}`) {
					query.push(`${select.path}=${value}`);
					endLoop = true;
					break;
				} else {
					query.push(`${select.path}=${option.value}`);
				}
			}
		}
	}
	return query.join('&');
};

const hasOptionSelected = (options: ComparisonSelectOptionModel[]) => {
	let selectedOption = options.filter(
		(option: ComparisonSelectOptionModel) => option.selected === true
	);
	return selectedOption?.length > 0 || false;
};

/** Main description for this component. */
const CriteriaComparisonColumn: React.FC<Props> = ({
	column,
	addToDownloadsText,
	downloadAddedText,
	columnIndex,
	onChange,
	send,
	errorMessage,
	loading,
}) => {
	const { selects, criteriaInfo } = column;
	const [requirementInfo, setRequirementInfo] = useState({
		criteriaProductArea: null,
		criteriaProductGroup: null,
		criteriaProductSubgroup: null,
		criteriaItem: null,
		criteriaLevel: null,
	});
	const handleSelectChange = (e: any) => {
		const { id, value } = e.target;
		let selectsData = JSON.parse(JSON.stringify(selects));
		let endLoop = false;
		selectsData.forEach((select: any, index: number) => {
			if (`column${columnIndex}-select-${select.path}` === id) {
				select.options?.forEach((option: any) => {
					if (option.value === value) {
						option.selected = true;
					} else {
						option.selected = false;
					}
				});
				endLoop = true;
			} else if (endLoop) {
				select.options?.forEach((option: any) => {
					option.selected = false;
				});
			}
		});

		onChange(columnIndex, selectsData);

		send('FETCH', {
			query: createQueryString(selectsData, columnIndex, id, value),
		});
	};

	useEffect(() => {
		let selectsData = JSON.parse(JSON.stringify(selects));

		let criteriaProductArea =
			selectsData[0].options &&
			selectsData[0].options.find((opt: any) => opt.selected) &&
			selectsData[0].options.find((opt: any) => opt.selected).term;

		let criteriaProductGroup =
			selectsData[1].options &&
			selectsData[1].options.find((opt: any) => opt.selected) &&
			selectsData[1].options.find((opt: any) => opt.selected).term;

		let criteriaProductSubGroup =
			selectsData[2].options &&
			selectsData[2].options.find((opt: any) => opt.selected) &&
			selectsData[2].options.find((opt: any) => opt.selected).term;

		let criteriaItem =
			selectsData[3].options &&
			selectsData[3].options.find((opt: any) => opt.selected) &&
			selectsData[3].options.find((opt: any) => opt.selected).term;

		let criteriaLevel =
			selectsData[4].options &&
			selectsData[4].options.find((opt: any) => opt.selected) &&
			selectsData[4].options.find((opt: any) => opt.selected).term;

		if (window && window.dataLayer && window.dataLayer.push && criteriaInfo) {
			setRequirementInfo({
				criteriaProductArea: criteriaProductArea ?? '',
				criteriaProductGroup: criteriaProductGroup ?? '',
				criteriaProductSubgroup: criteriaProductSubGroup ?? '',
				criteriaItem: criteriaItem ?? '',
				criteriaLevel: criteriaLevel ?? '',
			});

			window.dataLayer.push({
				event: 'compareCriteria',
				criteriaProductArea: criteriaProductArea ?? '',
				criteriaProductGroup: criteriaProductGroup ?? '',
				criteriaProductSubGroup: criteriaProductSubGroup ?? '',
				criteriaItem: criteriaItem ?? '',
				criteriaLevel: criteriaLevel ?? '',
			});
		}
	}, [criteriaInfo]);

	return (
		<form className="relative">
			<fieldset>
				<legend className="sr-only inline-block">
					{`Jämför kriterie ${columnIndex}, formuläret skickas in automatiskt när ett alternativ har valts`}
				</legend>
				{selects.map((select: ComparisonSelectModel, selectIndex: number) => {
					return (
						<div
							key={selectIndex}
							className={clsx(!select.options && 'opacity-50', 'mb-4')}
						>
							<label
								htmlFor={`column${columnIndex}-select-${select.path}`}
								className="sr-only"
							>
								{`${select.name} för kriterie ${columnIndex}`}
							</label>
							<select
								name={`column${columnIndex}-select-${select.path}`}
								id={`column${columnIndex}-select-${select.path}`}
								disabled={!select.options}
								onChange={handleSelectChange}
								required
								className={clsx(
									'form-select appearance-none text-p font-medium leading-5 w-full pl-3 pr-8 py-2 border-2 border-greySemiLight focus:border-blue rounded transition-all focus:outline-none disabled:text-greyLight disabled:cursor-not-allowed placeholder-greyDarker placeholder-opacity-100'
								)}
							>
								{
									<option
										value=""
										disabled
										selected={
											!select.options || !hasOptionSelected(select.options)
										}
									>
										{select.name}
									</option>
								}
								{select.options?.map(
									(
										option: ComparisonSelectOptionModel,
										optionIndex: number
									) => {
										return (
											<option
												value={option.value}
												selected={option.selected}
												key={optionIndex}
											>
												{option.term}
											</option>
										);
									}
								)}
							</select>
						</div>
					);
				})}
				<button
					type="submit"
					className="sr-only"
					onClick={(e) => {
						e.preventDefault();
						send('FETCH', {
							query: createQueryString(selects, columnIndex),
						});
					}}
				>
					{`Skicka in formulär för kriterie ${columnIndex}`}
				</button>
				<div aria-live="polite" aria-atomic="true">
					{errorMessage && (
						<Alert variant="danger" className="mt-6">
							<Text as="p" margin={false} className="mb-0">
								{errorMessage}
							</Text>
						</Alert>
					)}
				</div>
				<div className="mt-6" aria-live="polite" aria-atomic="true">
					{criteriaInfo && (
						<>
							<CriteriaCartAddButton
								downloadAddedText={downloadAddedText}
								addToDownloadsText={addToDownloadsText}
								criteriaDescriptor={criteriaInfo.criteriaDescriptor}
								className="mb-4"
								requirementInfo={requirementInfo && requirementInfo}
							/>

							<h2 className="mb-4 h-24">
								<Link
									to={criteriaInfo.url}
									className="text-teal text-p hover:underline"
								>
									{criteriaInfo.heading}
								</Link>
							</h2>
							<EpiFragments
								prop="text"
								fragments={criteriaInfo?.text?.fragments}
							/>
						</>
					)}
				</div>
			</fieldset>
			{loading && (
				<div className="absolute top-0 pt-35 w-full h-full bg-white z-10 opacity-50 flex justify-center">
					<Icon icon="loader" size={2} animate="spin" aria-label="Laddar" />
				</div>
			)}
		</form>
	);
};

export default CriteriaComparisonColumn;
