import axios from 'axios';
import { AggregationsModel, SearchSortingModel } from 'types';
import { createQueryString } from 'utils/helpers';
import { createMachine, assign } from 'xstate';

type SearchEventType =
	| {
			type: 'SEARCH';
			query: string;
			offset: number;
			fetch: number;
			aggregations: any[];
	  }
	| { type: 'RESET'; apiUrl: string; model: any };

type SearchContext = {
	url: string;
	apiUrl: string;
	query: string;
	numberOfHits: number;
	numberOfHitsPerPage: number;
	offset: number;
	fetch: number;
	orderBy: string | null;
	results: any;
	aggregations: AggregationsModel[] | null;
	selectedAggregations: AggregationsModel[] | null;
	sorting: SearchSortingModel | null;
	error: any;
	querystring: string;
	router: any;
	submitResponse: {
		heading: string;
		text: string;
	};
};

interface LoadApiData {
	(apiUrl: string, context?: SearchContext): Promise<any>;
}

const search: LoadApiData = async (apiUrl, context) => {
	const { data } = await axios.get(`${apiUrl}?${context?.querystring}`, {
		headers: {
			'X-Content-Language':
				window?.__model?.languages?.culture || window.__culture,
			'Content-Language':
				window?.__model?.languages?.culture || window.__culture,
			'Accept-Language':
				window?.__model?.languages?.culture || window.__culture,
		},
	});

	return { data };
};

const submitForm = async (
	submitApiUrl: string,
	querystring: string,
	email: string
) => {
	const res = await fetch(submitApiUrl, {
		method: 'POST',
		body: JSON.stringify({ email: email, query: querystring }),
	});

	if (res.status === 200) {
		if (window.dataLayer && window.dataLayer.push) {
			window.dataLayer.push({
				event: 'createMonitoredSearch',
			});
		}
	}

	return await res.json();
};

export const valfrihetMachine = createMachine<SearchContext>(
	{
		id: 'valfrihet',
		initial: 'idle',
		context: {
			url: '',
			apiUrl: '',
			query: '',
			numberOfHits: 0,
			numberOfHitsPerPage: 0,
			offset: 0,
			fetch: 0,
			orderBy: null,
			results: [],
			aggregations: [],
			selectedAggregations: [],
			sorting: null,
			error: null,
			querystring: '',
			router: null,
			submitResponse: {
				heading: '',
				text: '',
			},
		},
		states: {
			idle: {},
			loading: {
				invoke: {
					id: 'getData',
					src: (context) => search(context.apiUrl, context),
					onDone: {
						target: 'success',
						actions: 'setResponseData',
					},
					onError: {
						target: 'failure',
						actions: assign({
							error: (_, event) => {
								return `Error: ${event.data.response.status} - ${event.data.response.statusText}`;
							},
						}),
					},
				},
			},
			submitting: {
				invoke: {
					id: 'submitForm',
					src: (context, event) =>
						submitForm(event.submitApiUrl, context.querystring, event.email),
					onDone: {
						target: 'submit_success',
						actions: assign({
							submitResponse: (context, event) => event.data,
						}),
					},
					onError: {
						target: 'submit_failure',
						actions: assign({
							submitResponse: (context, event) => event.data,
						}),
					},
				},
			},
			resetting: {
				entry: ['resetContext'],
				always: [{ target: 'idle' }],
			},
			success: {},
			failure: {},
			submit_success: {},
			submit_failure: {},
		},
		on: {
			INIT: {
				target: 'loading',
				actions: ['querySearch', 'setQuerystring'],
			},
			QUERY_SEARCH: {
				target: 'loading',
				actions: ['querySearch', 'setQuerystring', 'updateUrl'],
			},
			RESET: {
				target: 'resetting',
			},
			LOAD_MORE: {
				target: 'loading',
				actions: ['loadMore', 'setQuerystring', 'updateUrl'],
			},
			FILTER_CHANGE: {
				target: 'loading',
				actions: ['filterChange', 'setQuerystring', 'updateUrl'],
			},
			RESET_FILTERS: {
				target: 'loading',
				actions: ['resetFilters', 'setQuerystring', 'updateUrl'],
			},
			SORT: {
				target: 'loading',
				actions: ['sort', 'setQuerystring', 'updateUrl'],
			},
			SUBMIT: {
				target: 'submitting',
			},
			RESET_SUBMITFORM: {
				actions: 'resetForm',
			},
		},
	},
	{
		actions: {
			resetContext: assign({
				apiUrl: (context, event: any) => event.apiUrl || context.apiUrl,
				query: (context, event: any) => event.query || context.query,
				results: (context, event: any) => event.model || context.results,
			}),
			updateUrl: (context, event) => {
				if (context.querystring) {
					context.router.push(`${context.url}?${context.querystring}`);
				} else {
					context.router.push(`${context.url}`);
				}
			},
			setQuerystring: assign({
				querystring: (context, event) => {
					const querystring = createQueryString({
						query: context.query,
						fetch: context.fetch,
						aggregations: context.selectedAggregations,
						orderBy: context.orderBy,
					});

					return querystring || '';
				},
			}),
			setResponseData: assign({
				numberOfHits: (context, event) => event.data.data.numberOfHits,
				sorting: (context, event) => event.data.data.sorting,
				results: (context, event) => event.data.data.results,
				aggregations: (context, event) => event.data.data.aggregations,
			}),
			resetFilters: assign({
				fetch: (context, event) => context.numberOfHitsPerPage,
				selectedAggregations: (context, event) => null,
			}),
			querySearch: assign({
				fetch: (context, event) => context.numberOfHitsPerPage,
				query: (context, event) => event.query,
			}),
			loadMore: assign({
				fetch: (context, event) => event.fetch,
			}),
			filterChange: assign({
				fetch: (context, event) => context.numberOfHitsPerPage,
				selectedAggregations: (context, event) => {
					return event.aggregations;
				},
			}),
			sort: assign({
				fetch: (context, event) => context.numberOfHitsPerPage,
				orderBy: (context, event) => event.orderBy,
			}),
			resetForm: assign({
				submitResponse: (context, event) => event.submitResponse,
			}),
		},
	}
);
