/**
 * SearchPage
 */

import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMachine } from '@xstate/react';
import SearchFilters from 'components/SearchFilters';
import SearchResultList from 'components/SearchResultList';
import { ServiceTabs, ServiceTabItem } from 'components/ServiceTabs';
import { useFirstRender } from 'ui-component-library/hooks';
import { selectMetaData } from 'store/modules/model';
import {
	Grid,
	Cell,
	Button,
	Icon,
	SearchField,
	ButtonSupportive,
	Modal,
	Alert,
} from 'ui-component-library/base';
import {
	SearchItemNoResults,
	SearchResultList as SearchResultListUI,
} from 'ui-component-library/uhmse';
import {
	SearchAggregationModel,
	SearchFilterGroupModel,
	SearchPageAdvanceModel,
	ServiceModel,
	SortingOptionModel,
} from 'types';
import BaseLayout from 'layouts/BaseLayout';
import Text from 'components/Text';
import { searchMachine } from 'state-machines/searchAdvance.machine';
import queryString from 'query-string';
import clsx from 'clsx';
import SearchSortList from 'components/SearchSortList/SearchSortList';
import { useIsMobile, useIsTablet, useIsDesktop } from 'hooks/useMediaQuery';
import { getActiveAggregations } from 'utils/helpers';

/** SearchPageAdvance component. */
const SearchPageAdvance: React.FC<SearchPageAdvanceModel> = ({
	heading,
	services,
	numberOfHitsPerPage,
	showMoreButtonText,
	searchResult,
	url: defaultUrl,
	hitsInOtherTab,
	zeroHitsHeading,
	zeroHitsText,
	servicesResultText,
	filterHeading,
	clearFilterText,
	showResultText,
	closeFilterModalText,
}) => {
	const location = useLocation();
	const { query } = queryString.parse(location.search);
	const history = useHistory();
	const isFirstRender = useFirstRender();
	const searchfieldWrapperRef = useRef<any>(null);
	const searchfieldInputRef = useRef<any>(null);
	const isMobile = useIsMobile();
	const isTablet = useIsTablet();
	const isDesktop = useIsDesktop();
	const [modalExpanded, setModalExpanded] = useState(false);
	const [activeTabId, setActiveTabId] = useState(
		services.find((x) => x.isActive)?.id || 'web'
	);
	const getSelectedAggregations = (filterGroups: any) => {
		let aggs: any[] = [];
		filterGroups.forEach((filterGroup: any) => {
			aggs.concat(getActiveAggregations(filterGroup.aggregations));
		});

		return aggs;
	};

	const getInitContextData = (serviceId: 'web' | 'faq' | 'criteria') => {
		let service = services.find((x) => x.id === serviceId);

		return {
			url: service?.url,
			apiUrl: service?.apiUrl,
			query: query as string,
			numberOfHits: service?.isActive ? searchResult.numberOfHits : 0,
			numberOfHitsPerPage: numberOfHitsPerPage,
			fetch: searchResult.fetch,
			offset: searchResult.offset,
			filterGroups: service?.isActive ? searchResult.filterGroups : [],
			selectedAggregations: service?.isActive
				? getSelectedAggregations(searchResult.filterGroups)
				: null,
			sorting: service?.isActive ? searchResult.sorting : null,
			results: service?.isActive ? searchResult.results : {},
			router: history,
		};
	};

	const [stateWeb, sendWeb] = useMachine(searchMachine, {
		context: getInitContextData('web'),
	});

	const [stateFaq, sendFaq] = useMachine(searchMachine, {
		context: getInitContextData('faq'),
	});

	const [stateCriteria, sendCriteria] = useMachine(searchMachine, {
		context: getInitContextData('criteria'),
	});

	const machines: any = {
		web: {
			state: stateWeb,
			send: sendWeb,
		},
		faq: {
			state: stateFaq,
			send: sendFaq,
		},
		criteria: {
			state: stateCriteria,
			send: sendCriteria,
		},
	};

	// Functions to call the active stateMachine - state and send
	const sendGlobal = (action: any, payload: any) => {
		machines[activeTabId].send(action, payload);
	};

	const stateGlobal = () => {
		return machines[activeTabId].state;
	};

	const metaData = useSelector(selectMetaData);

	const { fetch, numberOfHits, orderBy, results, filterGroups, sorting } =
		stateGlobal().context || {};

	const onTabChange = (service: ServiceModel) => {
		const { url, id } = service;
		const {
			fetch: currentFetch,
			selectedAggregations: currentSelectedAggregations,
			orderBy: currentOrderBy,
		} = machines[id].state.context;

		sendGlobal('TAB_CHANGE', {
			url: url,
			aggregations: currentSelectedAggregations,
			fetch: currentFetch,
			orderBy: currentOrderBy,
		});

		setActiveTabId(id);
	};

	const onQueryChanged = (ev: any) => {
		sendGlobal('QUERY_SEARCH', {
			query: ev.searchfieldSearchPage,
			selectedAggregations: getActiveAggregations(
				stateGlobal().context.filterGroups[0].aggregations
			),
		});

		services.forEach((service) => {
			if (service.id !== activeTabId) {
				machines[service.id].send('INIT', {
					query: ev.searchfieldSearchPage,
				});
			}
		});
	};

	useEffect(() => {
		if (!isFirstRender) {
			sendGlobal('RESET', {
				query: searchResult.query,
				results: searchResult,
			});
			const id = services.find((x) => x.isActive)?.id;

			services.forEach((service) => {
				if (service.id !== id) {
					machines[service.id].send('SEARCH', {
						query,
						fetch: numberOfHitsPerPage,
					});
				}
			});

			if (id) {
				setActiveTabId(id);
			}
		}
		// eslint-disable-next-line
	}, [defaultUrl]);

	useEffect(() => {
		//Fetch search result in background for tabs that are not active
		services.forEach((service) => {
			if (service.id !== activeTabId) {
				machines[service.id].send('INIT', {
					query,
					fetch: numberOfHitsPerPage,
				});
			}
		});

		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (!isFirstRender) {
			if (query != null && query !== stateGlobal().context.query) {
				sendGlobal('INIT', {
					query,
				});

				services.forEach((service) => {
					if (service.id !== activeTabId) {
						machines[service.id].send('INIT', {
							query,
						});
					}
				});
			}
		}

		// eslint-disable-next-line
	}, [query]);

	useEffect(() => {
		const serviceString = services.filter(
			(service) => service.isActive === true
		)[0].name;
		let query = '';

		if (stateGlobal().context.querystring) {
			query = `${
				stateGlobal().context.querystring
			}&service=${encodeURIComponent(serviceString)}`;
		} else if (searchResult.query !== '') {
			query = `query=${searchResult.query}&service=${encodeURIComponent(
				serviceString
			)}`;
		} else {
			query = `service=${encodeURIComponent(serviceString)}`;
		}

		if (window.dataLayer && window.dataLayer.push) {
			window.dataLayer.push({
				event: 'Pageview',
				pagePath: `${location.pathname}?${query}`,
				pageTitle: metaData?.title,
			});
		}

		// Reset GTM scroll-tracker
		if (window.__ScrollTracker) {
			window.__ScrollTracker.reset();
		}

		// eslint-disable-next-line
	}, [query, defaultUrl]);

	useEffect(() => {
		if (numberOfHits === 0) {
			if (window.dataLayer && window.dataLayer.push) {
				window.dataLayer.push({
					event: 'ZeroHitSearch',
					searchQuery: query,
				});
			}
		}
		// eslint-disable-next-line
	}, [numberOfHits]);

	const hasOtherSearchHits =
		services.filter(
			(service) => machines[service.id].state.context?.numberOfHits > 0
		).length > 0;

	const getTotalSelectedAggregations = () => {
		let count = filterGroups.reduce(
			(accumulator: number, filterGroup: SearchFilterGroupModel) => {
				return accumulator + filterGroup.selectedBuckets;
			},
			0
		);

		return count > 0 ? `(${count})` : '';
	};

	return (
		<BaseLayout>
			<Grid
				padding={false}
				margin={false}
				className="px-4 lg:px-6 -mt-4 lg:pb-8 md:mt-0"
			>
				<Cell span={12} desktop={7}>
					<Text as="h1" prop="heading">
						{heading}
						{stateGlobal().value === 'loading' && (
							<>
								{' '}
								<Icon icon="loader" animate="spin" size={3} />
							</>
						)}
					</Text>
				</Cell>
				<Cell span={12} desktop={7}>
					<SearchField
						id="searchfieldSearchPage"
						wrapperRef={searchfieldWrapperRef}
						fieldRef={searchfieldInputRef}
						label={
							services.find((x) => x.id === activeTabId)?.performSearchText ||
							''
						}
						submitLabel="submit"
						className="text-black"
						defaultValue={query ? query.toString() : undefined}
						onSubmit={onQueryChanged}
					></SearchField>
					<div aria-live="polite" className="md:w-1/2">
						{stateGlobal().value === 'failure' && (
							<Alert variant="danger" className="mt-8">
								<Text as="p" margin={false} className="mb-0">
									{stateGlobal().context.error}
								</Text>
							</Alert>
						)}
					</div>
				</Cell>
			</Grid>

			<Grid
				padding={false}
				margin={false}
				className={clsx(
					'px-4 lg:px-6 pb-8 mt-4',
					filterGroups?.length > 0 && 'flex-row-reverse'
				)}
			>
				{isDesktop && filterGroups?.length > 0 && (
					<Cell span={12} desktop={4} className="lg:-mt-18 mb-8 lg:mb-0">
						<div className="flex justify-between items-center">
							<h2 className="text-p font-semibold">{filterHeading}</h2>
							<ButtonSupportive
								type="button"
								variant="rounded"
								onClick={() => sendGlobal('RESET_FILTERS', {})}
								data-button-action={clearFilterText}
							>
								{clearFilterText}
								<Icon
									icon="trashCan"
									aria-hidden={true}
									className="ml-2 -mt-1"
								/>
							</ButtonSupportive>
						</div>

						<SearchFilters
							filterGroups={filterGroups}
							clearFilterText={clearFilterText}
							onChange={(aggregation: SearchAggregationModel) =>
								sendGlobal('FILTER_CHANGE', {
									aggregation: aggregation,
								})
							}
						/>
					</Cell>
				)}
				{(isTablet || isMobile) && filterGroups?.length > 0 && (
					<Cell
						span={12}
						desktop={4}
						className="sm:flex justify-around lg:-mt-18 mb-6 mt-1 lg:mb-0"
					>
						<Button
							variant="secondary"
							className="w-full sm:w-64"
							onClick={() => setModalExpanded(!modalExpanded)}
						>
							<span>{`${filterHeading} ${getTotalSelectedAggregations()}`}</span>
							<Icon icon="filter" color="#00636A" className="ml-2" />
						</Button>
						{modalExpanded && (
							<Modal
								closeBtnLabel={closeFilterModalText}
								onToggle={() => setModalExpanded(!modalExpanded)}
							>
								<div className="relative flex flex-col mt-12 w-89vw h-75vh sm:h-80vh sm:w-116">
									<div className="overflow-y-scroll flex-grow">
										<div className="flex justify-between items-center">
											<h2 className="text-p font-semibold">{filterHeading}</h2>
											<ButtonSupportive
												type="button"
												variant="rounded"
												onClick={() => sendGlobal('RESET_FILTERS', {})}
											>
												{clearFilterText}
												<Icon
													icon="trashCan"
													aria-hidden={true}
													className="ml-2 -mt-1"
												/>
											</ButtonSupportive>
										</div>

										<SearchFilters
											filterGroups={filterGroups}
											clearFilterText={clearFilterText}
											onChange={(aggregation: SearchAggregationModel) =>
												sendGlobal('FILTER_CHANGE', {
													aggregation: aggregation,
												})
											}
										/>
									</div>
									<div className="flex justify-center mt-6 bg-white">
										<Button
											variant="primary"
											color="teal"
											className="w-full sm:w-64"
											onClick={() => setModalExpanded(!modalExpanded)}
										>
											{showResultText}
										</Button>
									</div>
								</div>
							</Modal>
						)}
					</Cell>
				)}
				<Cell span={12} desktop={7} className="lg:mr-1/12 mb-8 lg:mb-0">
					<div className="relative">
						<ServiceTabs className="-mb-0.5">
							{services?.map((service: ServiceModel, index: number) => {
								return (
									<ServiceTabItem
										service={service}
										onClick={onTabChange}
										isActive={activeTabId === service.id}
										loading={machines[service.id].state.value === 'loading'}
										numberOfHits={
											machines[service.id]?.state?.context?.numberOfHits
										}
										key={index}
									/>
								);
							})}
						</ServiceTabs>
						{sorting && sorting.options?.length > 0 && results?.length > 0 && (
							<div className="flex flex-row-reverse lg:inline-block lg:absolute right-0 top-0 mt-2">
								<SearchSortList
									buttonText={
										sorting.options.find(
											(option: SortingOptionModel) => option.path === orderBy
										)?.name || sorting.placeholder
									}
								>
									{sorting.options.map(
										(option: SortingOptionModel, index: number) => {
											return (
												<button
													className="px-3 py-2 w-full text-left whitespace-no-wrap hover:bg-greyLight"
													onClick={() => {
														sendGlobal('SORT', {
															orderBy: option.path,
														});
													}}
													data-filter={sorting.placeholder}
													value={option.name}
													key={index}
												>
													{option.name}
												</button>
											);
										}
									)}
								</SearchSortList>
							</div>
						)}
					</div>
					{results?.length > 0 ? (
						<>
							<SearchResultList searchList={results} />

							{fetch < numberOfHits && (
								<div className="flex justify-around">
									<Button
										data-button-action="Ladda fler"
										variant="primary"
										className="my-auto"
										disabled={stateGlobal().value === 'loading'}
										onClick={() =>
											sendGlobal('LOAD_MORE', {
												fetch: fetch + numberOfHitsPerPage,
												selectedAggregations: getActiveAggregations(
													searchResult.filterGroups[0].aggregations
												),
											})
										}
									>
										{showMoreButtonText}
										{stateGlobal().value === 'loading' && (
											<Icon
												icon="loader"
												color="white"
												animate="spin"
												size={0.75}
												className="ml-2"
												aria-hidden={true}
											/>
										)}
										{stateGlobal().value !== 'loading' && (
											<Icon
												icon="chevron"
												direction="up"
												color="white"
												size={0.75}
												className="ml-2"
												aria-hidden={true}
											/>
										)}
									</Button>
								</div>
							)}
						</>
					) : (
						<SearchResultListUI>
							<SearchItemNoResults
								heading={zeroHitsHeading}
								text={zeroHitsText.replace('{0}', (query || '') as string)}
							>
								{hasOtherSearchHits && (
									<>
										<p>{hitsInOtherTab}</p>
										<ul>
											{services.map((service: ServiceModel, index: number) => {
												return (
													<React.Fragment key={index}>
														{service.id !== activeTabId &&
															machines[service.id].state.context?.numberOfHits >
																0 && (
																<li>
																	{'-'}
																	<button
																		onClick={() => onTabChange(service)}
																		className="text-teal ml-2 group"
																	>
																		<span className="group-hover:underline">
																			{service.name}
																		</span>
																		<span className="ml-1 text-black">{`(${
																			machines[service.id].state.context
																				?.numberOfHits
																		} ${servicesResultText})`}</span>
																	</button>
																</li>
															)}
													</React.Fragment>
												);
											})}
										</ul>
									</>
								)}
							</SearchItemNoResults>
						</SearchResultListUI>
					)}
				</Cell>
			</Grid>
		</BaseLayout>
	);
};

export default SearchPageAdvance;
