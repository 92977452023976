import axios from 'axios';
import { getFilterSortQuery } from 'utils/helpers';
import { createMachine, assign } from 'xstate';

type FilterSortEventType = {
	type: 'FETCH';
	filterQuery: string;
	sortQuery: string;
	apiUrl: string;
};

type FilterSortContext = {
	apiUrl: string;
	result?: any;
	error: any;
};

const resultData = async (
	apiUrl: string,
	filterQuery?: string,
	sortQuery?: string
) => {
	const query = getFilterSortQuery(filterQuery, sortQuery);

	const { data } = await axios.get(`${apiUrl}${query}`, {
		headers: { 'Content-Language': window.__culture },
	});

	return data;
};

export const filterSortMachine = createMachine<
	FilterSortContext,
	FilterSortEventType
>({
	id: 'filterSortMachine',
	initial: 'idle',
	context: {
		apiUrl: '',
		result: undefined,
		error: undefined,
	},
	states: {
		idle: {},
		loading: {
			invoke: {
				id: 'getData',
				src: (_context, event) =>
					resultData(event.apiUrl, event.filterQuery, event.sortQuery),
				onDone: {
					target: 'success',
					actions: assign({
						result: (_context, event) => event.data,
					}),
				},
				onError: {
					target: 'failure',
					actions: assign({
						error: (_context, event) => {
							return `Error ${event.data.response.status} - ${event.data.response.statusText}`;
						},
					}),
				},
			},
		},
		success: {},
		failure: {},
	},
	on: {
		FETCH: [
			{
				target: 'loading',
				actions: [],
			},
		],
	},
});
