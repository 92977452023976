import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import initStore from 'store';
import { initialState as reactInitialState } from 'store/modules/react';
import Application from './App';
import { RenderingStates } from './types';
import { getLCP, getFID, getCLS, getFCP, getTTFB } from 'web-vitals';
import 'formdata-polyfill';
import { inspect } from '@xstate/inspect';

if (process.env.NODE_ENV === 'development') {
	if (
		process.env.REACT_APP_XSTATE_INSPECT === 'true' &&
		typeof window !== 'undefined'
	) {
		inspect({
			url: 'https://statecharts.io/inspect',
			iframe: false,
		});
	}
}

if (process.env.REACT_APP_ENABLE_MOCKING === 'true') {
	const { worker } = require('./mocks/browser');
	worker.start();
}

// Set default rendermethod and a basic model.
// eslint-disable-next-line
let renderMethod = ReactDOM.render;
let model = {
	head: {},
	react: reactInitialState,
};

// Replace our basic model if one exitst on the window-object,
// then we also change rendermethod to hydrate.
if (window.__model) {
	// eslint-disable-next-line
	renderMethod = ReactDOM.hydrate;
	model = window.__model;
	model.react.renderingState = RenderingStates.Hydrate;
}

let react = model.react;
delete model.react;

const preloadedState = {
	react,
	model: { model },
};

const reportWebVitals = (onPerfEntry: any) => {
	getCLS(onPerfEntry);
	getFID(onPerfEntry);
	getFCP(onPerfEntry);
	getLCP(onPerfEntry);
	getTTFB(onPerfEntry);
};

// Render the application to the DOM
const element = document.getElementById(react.containerId);
if (element) {
	renderMethod(
		<Provider store={initStore(preloadedState)}>
			<Application Router={BrowserRouter} />
		</Provider>,
		element,
		() => reportWebVitals(console.log)
	);
}
