/**
 * useEpiEvent
 */

import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPageContent, selectContent } from 'store/modules/model';
import { updateEpi } from 'store/modules/epi';
import { selectReact } from 'store/modules/react';

export const useEpiEvents = () => {
	const dispatch = useDispatch();
	const content = useSelector(selectContent);
	const { apiEditUrl } = useSelector(selectReact) || '/';

	useEffect(() => {
		const epiReady = () => {
			dispatch(updateEpi(window.epi));

			// If we're in an editable context we want to update the model on every change by the editor
			if (window.epi.beta.isEditable) {
				window.epi.subscribe('beta/contentSaved', (data: any) => {
					if (
						data.successful &&
						data.contentLink.split('_')[0] === content?.id
					) {
						dispatch(fetchPageContent(apiEditUrl, data.contentLink));
					}
				});
			}
		};

		const onLoad = () => {
			if (!window.epi) {
				return;
			}

			if (window.epi.beta && window.epi.beta.ready === true) {
				epiReady();
			} else if (window.epi.subscribe) {
				window.epi.subscribe('beta/epiReady', () => epiReady());
			}
		};

		if (typeof window !== 'undefined') {
			window.addEventListener('load', onLoad);

			return () => {
				window.removeEventListener('load', onLoad);
			};
		}
		// eslint-disable-next-line
	}, [dispatch]);
};
