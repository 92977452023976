const formData = [
  {
    heading: "Upphandlande organisation",
    content: [
      {
        name: "upphandlandeOrganisation",
        type: "select",
        label: "Upphandlande organisation",
        tooltip:
          "Här anges vilken aktör som den upphandlade organisationen tillhör. Används för att fördela kostnader kopplade till upphandlingen.",
        options: [
          {
            value: "Kommunal",
            label: "Kommunal",
          },
          {
            value: "Statlig",
            label: "Statlig",
          },
          {
            value: "Regional",
            label: "Regional",
          },
        ],
        defaultValue: "Kommunal",
        requiredMessage: "Vänligen fyll i detta fält",
      },
    ],
  },
  {
    heading: "Sysselsättningskravet",
    content: [
      {
        name: "sysselsattningskrav",
        type: "select",
        label: "Sysselsättningskrav",
        tooltip:
          "Här anges vilken typ av sysselsättningskrav som modellen ska utgå ifrån. Valet styr vilka parametrar som är relevanta att specifera (blåmarkerade).",
        options: [
          {
            value: "Anställning",
            label: "Anställning",
          },
          {
            value: "Lärling",
            label: "Lärling",
          },
          {
            value: "Praktik",
            label: "Praktik",
          },
        ],
        defaultValue: "Anställning",
        requiredMessage: "Vänligen fyll i detta fält",
      },
      {
        name: "antalManader",
        type: "number",
        label: "Antal månader",
        defaultValue: "",
        tooltip: "Antalet månader som sysselsättningskravet gäller.",
        requiredMessage: "Vänligen fyll i detta fält",
      },
      {
        name: "antalTjanster",
        type: "number",
        label: "Antal tjänster",
        defaultValue: "",
        tooltip: "Antalet tjänster som sysselsättningskravet gäller.",
        requiredMessage: "Vänligen fyll i detta fält",
      },
      {
        name: "omfattningAvTjanst",
        type: "number",
        label: "Omfattning av tjänst (procent)",
        defaultValue: "",
        tooltip: "Omfattningen sysselsättningskravet, om heltid anges 100%.",
        requiredMessage: "Vänligen fyll i detta fält",
        pattern: "^[0-9][0-9]?$|^100$",
        patternMessage: "Vänligen fyll i ett värde mellan 0-100",
      },
      {
        name: "bruttolonPerManadHeltid",
        type: "number",
        label: "Bruttolön per månad (heltid)",
        defaultValue: "",
        tooltip:
          "Här anges den uppskattade bruttolönen som modellen ska utgår från. Bruttolönen påverkar kostnaden för arbetsgivare och intäkter för individ och det offentliga.",
        requiredMessage: "Vänligen fyll i detta fält",
      },
      {
        name: "anstallningsstod",
        type: "select",
        label: "Anställningsstöd",
        tooltip:
          "Här anges om och i så fall vilket anställningsstöd som ska inkluderas i modellberäkningarna.",
        options: [
          {
            value: "nej",
            label: "Nej",
          },
          {
            value: "Introduktionsjobb",
            label: "Introduktionsjobb",
          },
          {
            value: "Nystartsjobb",
            label: "Nystartsjobb",
          },
          {
            value: "Yrkesintroduktion",
            label: "Yrkesintroduktion",
          },
        ],
        defaultValue: "nej",
      },
      {
        name: "produktionsfaktorAndelAvBruttolon",
        type: "number",
        label: "Produktionsfaktor (procent av bruttolön)",
        defaultValue: "",
        tooltip:
          "Här anges det relativa produktionsvärdet som ska användas i beräkningarna. Grundantagandet är att produktionsvärdet motsvarar den antagna lönen men produktionsvärdet går att justera.",
        requiredMessage: "Vänligen fyll i detta fält",
        pattern: "^[0-9][0-9]?$|^100$",
        patternMessage: "Vänligen fyll i ett värde mellan 0-100",
      },
      {
        name: "bruttolonPerManadHandledare",
        type: "number",
        label: "Bruttolön per månad, handledare",
        defaultValue: "",
        tooltip:
          "Här anges den antagna bruttolönen för handledare. Används i beräkningarna av kostnaden för handledning för lärlings- och praktikplatser.",
        requiredMessage: "Vänligen fyll i detta fält",
      },
      {
        name: "tidForHandledningAndelAvHeltidstjanst",
        type: "number",
        label: "Tid för handledning (andel av heltidstjänst)",
        defaultValue: "",
        tooltip:
          "Här anges den uppskattade tidsåtgången för handledning. Används i beräkningarna av kostnaden för handledning för lärlings- och praktikplatser och uttrycks som andel av heltidstjänst.",
        requiredMessage: "Vänligen fyll i detta fält",
        pattern: "^[0-9][0-9]?$|^100$",
        patternMessage: "Vänligen fyll i ett värde mellan 0-100",
      },
      {
        name: "administrationskostnadUpphandling",
        type: "number",
        label: "Administrationskostnad – arbetsgivare",
        defaultValue: "",
        tooltip:
          "Här ska arbetsgivarens administrationskostnad för upphandlingen med sysselsättningskrav anges. Observera att det är den ytterliggare kostnaden till följd av att ett sysselsättningskrav anställs som ska anges.",
        requiredMessage: "Vänligen fyll i detta fält",
      },
      {
        name: "upphandlingskostnad",
        type: "number",
        label: "Administrationskostnad – upphandlande organisation",
        defaultValue: "",
        tooltip:
          "Här ska upphandlade organisationens administrationskostnad för sysselsättningskrav anges. Observera att det är den ytterliggare kostnaden till följd av att ett sysselsättningskrav anställs som ska anges.",
        requiredMessage: "Vänligen fyll i detta fält",
      },
      {
        name: "produktionsvardePerManadPraktik",
        type: "number",
        label: "Produktionsvärde per månad (praktik)",
        defaultValue: "",
        tooltip:
          "Här anges produktionsvärdet i de fall sysselsättningen är oavlönad (praktikanställning).",
        requiredMessage: "Vänligen fyll i detta fält",
      },
      {
        name: "bonus",
        type: "number",
        label: "Bonus",
        defaultValue: "0",
        tooltip:
          "Här anges storleken på eventuell bonus från upphandlande organisation till arbetsgivare vid uppfyllnad av sysselsättningskravet.",
      },
    ],
  },
  {
    heading: "Målgrupp",
    content: [
      {
        name: "alder",
        type: "select",
        label: "Ålder",
        tooltip:
          "Här anges vilken åldersgrupp som är aktuell för målgruppen. Ålder påverkar typ och storlek på ersättning.",
        options: [
          {
            value: "20-23",
            label: "20-23",
          },
          {
            value: "24-25",
            label: "24-25",
          },
          {
            value: "26-65",
            label: "26-65",
          },
        ],
        defaultValue: "20-23",
        requiredMessage: "Vänligen fyll i detta fält",
      },
      {
        name: "tidIArbetsloshet",
        type: "select",
        label: "Tid i arbetslöshet",
        tooltip:
          "Här anges antalet månader som personer i målgruppen varit arbetslösa. Det påverkar storleken och möjligheten till ersättning.",
        options: [
          {
            value: "<6 mån",
            label: "<6 mån",
          },
          {
            value: "6-11 mån",
            label: "6-11 mån",
          },
          {
            value: "12-13 mån",
            label: "12-23 mån",
          },
          {
            value: "24-35 mån",
            label: "24-35 mån",
          },
          {
            value: "36- mån",
            label: "36- mån",
          },
        ],
        defaultValue: "<6 mån",
        requiredMessage: "Vänligen fyll i detta fält",
      },
      {
        name: "nyanland",
        type: "select",
        label: "Nyanländ",
        tooltip:
          "Här anges huruvida målgruppen för sysselsättningskravet är nyanlände eller inte. Nyanlända antas i modellen ha rätt till etableringsersättning.",
        options: [
          {
            value: "Ja",
            label: "Ja",
          },
          {
            value: "nej",
            label: "Nej",
          },
        ],
        defaultValue: "nej",
        requiredMessage: "Vänligen fyll i detta fält",
      },
      {
        name: "akassa",
        type: "select",
        label: "A-kassa",
        tooltip:
          "Här anges huruvida målgruppen bedöms ha rätt till ersättning från A-kassa eller inte. Ska endast anges om målgruppen uppfyller kriterierna för A-kassa och varit medlem i åtminstone tolv månader.",
        options: [
          {
            value: "Ja",
            label: "Ja",
          },
          {
            value: "nej",
            label: "Nej",
          },
        ],
        defaultValue: "nej",
        requiredMessage: "Vänligen fyll i detta fält",
      },
      {
        name: "bostadsbidrag",
        type: "select",
        label: "Bostadsbidrag",
        tooltip:
          "Här anges huruvida målgruppen bedöms ha rätt till bostadsbidrag från Försäkringskassan eller inte.",
        options: [
          {
            value: "Ja",
            label: "Ja",
          },
          {
            value: "nej",
            label: "Nej",
          },
        ],
        defaultValue: "nej",
        requiredMessage: "Vänligen fyll i detta fält",
      },
    ],
  },
];

const hiddenFields = {
  Anställning: ["produktionsvardePerManadPraktik"],
  Lärling: ["anstallningsstod", "produktionsvardePerManadPraktik"],
  Praktik: [
    "bruttolonPerManadHeltid",
    "anstallningsstod",
    "produktionsfaktorAndelAvBruttolon",
  ],
};

export { formData, hiddenFields };
