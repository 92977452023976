/**
 * SectionPage
 */

import React from 'react';

import { SectionPageModel } from 'types';

import BaseLayout from 'layouts/BaseLayout';
import { Grid, Cell, Text } from 'ui-component-library/base';

import EpiContentArea from 'components/EpiContentArea';

/** Main description for this component. */
const SectionPage: React.FC<SectionPageModel> = ({
	heading,
	preamble,
	contentArea,
}) => {
	return (
		<BaseLayout>
			<Grid
				padding={false}
				margin={false}
				className="lg:px-6 px-4 -mt-4 md:mt-0 md:pt-4"
			>
				<Cell span={12} desktop={7}>
					<Text as="h1" prop="heading">
						{heading}
					</Text>
					<Text as="preamble" prop="preamble">
						{preamble}
					</Text>
				</Cell>
			</Grid>
			{contentArea && contentArea.length > 0 && (
				<EpiContentArea content={contentArea} />
			)}
		</BaseLayout>
	);
};

export default SectionPage;
