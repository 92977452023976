const formData = {
	forms: [
		{
			name: 'Byggnad eller Anläggning',
			toolTip: '',
			referenceObjects: [
				{
					name: 'Flerbostadshus med betongstomme',
					toolTip:
						'Referensobjekten är konventionella typlösningar som motsvarar standardprojekt idag.',
					info: 'Klimatpåverkan från byggnation och drift under 50 år av ett flerbostadshus med betongstomme färdigställt 2010 som anses vara representativt (Blå Jungfrun, etapp 1,  i Hökarängen utanför Stockholm). Energianvändning enligt högsta tillåtna energianvändning enligt BBR. Byggnaden antas vara fjärrvärmevärmd. ',
					referenceData: {
						unit: 'm2 A-temp',
						amount: 331,
					},
					referenceDataOperation: {
						unit: 'm2 A-temp',
						amount: 7.27567,
					},
					lifeLengthReferenceData: {
						unit: 'År',
						lifeLength: 50,
					},
					inputObjectLifeLength: {
						info: 'Arean av samtliga våningsplan, vindsplan och källarplan för temperaturreglerade utrymmen, avsedda att värmas till mer än 10 °C, som begränsas av klimatskärmens insida. Area som upptas av innerväggar, öppningar för trappa, schakt och dylikt inräknas. Area för garage, inom byggnaden i bostadshus eller annan lokalbyggnad än garage, inräknas inte. Något lägre än måttet kvadratmeter bruttoarea, eftersom ytor som till exempel garage inte inkluderas.',
						objectLifeLengthUnit: 'År',
					},
					inputUnit: {
						info: 'Arean av samtliga våningsplan, vindsplan och källarplan för temperaturreglerade utrymmen, avsedda att värmas till mer än 10 °C, som begränsas av klimatskärmens insida. Area som upptas av innerväggar, öppningar för trappa, schakt och dylikt inräknas. Area för garage, inom byggnaden i bostadshus eller annan lokalbyggnad än garage, inräknas inte. Något lägre än måttet kvadratmeter bruttoarea, eftersom ytor som till exempel garage inte inkluderas.',
						unit: 'kvadratmeter A-temp',
					},
					measures: [
						{
							name: 'Trästomme istället för betongstomme',
							toolTip: '',
							info: 'Utbyte av betongstomme till stomme av korslimmat trä, med de förändringar i övrig konstruktion som anses behövas. Övrig konstruktion och material är exakt samma som i referensfall.',
							procurementReason:
								'Stommens material är en fråga som måste lyftas mycket tidigt för att kunna påverkas, och bör tas upp redan i förstudieskede och programhandlingar.',
							measureBuildVaule: 223,
							measeureOperationVaule: 7.27567,
						},
						{
							name: 'Klimatförbättrad betong',
							toolTip: '',
							info: 'Ett alternativ till att byta till en trästomme är att välja en klimatförbättrad betong med lägre klimatpåverkan. Hur stor reduktionen kan bli beror på hur mycket betong, och vilken betongtyp som används i byggnaden, och kan därmed variera.',
							procurementReason:
								'Krav bör ställas på både projektör och entreprenör att använda klimatförbättrad betong.',
							measureBuildVaule: 298,
							measeureOperationVaule: 7.27567,
						},
						{
							name: 'HVO och energieffektiva bodar på byggarbetsplats',
							toolTip: '',
							info: 'Alternativa bränslen kan användas i stället för diesel i arbetsmaskiner på byggarbetsplatsen vilket minskar utsläppen, och energieffektiva bodar minskar behov av el och värme. ',
							procurementReason:
								"Krav kan ställas på entreprenören att använda ett klimatsmart bränsle, se <a href='/kriterier'>kriteriedatabasens</a>.",
							measureBuildVaule: 319,
							measeureOperationVaule: 7.27567,
						},
						{
							name: 'Energieffektivisering spjutspetsnivå',
							toolTip: '',
							info: 'Visar effekten av att samma flerbostadshus med betongstomme skulle byggts med krav på hög energieffektivitet vad gäller uppvärmning i enlighet med upphandlingsmyndighetens krav på spjutspetsnivå. Upphandlingsmyndighetens kriterium för minskad energianvändning finns även i ambitionsnivåerna Bas och Avancerad. Klimatpåverkan från byggskedet är identiskt som i ursprungsfallet. Byggnaden antas värmas med fjärrvärme.',
							procurementReason:
								"Krav kan ställas på projektör och entreprenör att byggnaden skall hålla hög energiprestanda, se <a href='/kriterier'>kriteriedatabasens</a> energikrav.",
							measureBuildVaule: 331,
							measeureOperationVaule: 6.01455,
						},
					],
				},
				{
					name: 'Kontor',
					toolTip:
						'Referensobjekten är konventionella typlösningar som motsvarar standardprojekt idag.',
					info: 'Klimatpåverkan från byggnation och drift under 50 år baserat på genomsnitt av ett större urval kontorsbyggnader i Sverige med varierande storlek och konstruktion (KTH 2021, byggfas). Energianvändning är högsta tillåtna energianvändning enligt BBR. Byggnaden antas vara elvärmd.',
					referenceData: {
						unit: 'm2 A-temp',
						amount: 395,
					},
					referenceDataOperation: {
						unit: 'm2 A-temp',
						amount: 6.79062,
					},
					lifeLengthReferenceData: {
						unit: 'År',
						lifeLength: 50,
					},
					inputObjectLifeLength: {
						info: 'Arean av samtliga våningsplan, vindsplan och källarplan för temperaturreglerade utrymmen, avsedda att värmas till mer än 10 °C, som begränsas av klimatskärmens insida. Area som upptas av innerväggar, öppningar för trappa, schakt och dylikt inräknas. Area för garage, inom byggnaden i bostadshus eller annan lokalbyggnad än garage, inräknas inte. Något lägre än måttet kvadratmeter bruttoarea, eftersom ytor som till exempel garage inte inkluderas.',
						objectLifeLengthUnit: 'År',
					},
					inputUnit: {
						info: 'Arean av samtliga våningsplan, vindsplan och källarplan för temperaturreglerade utrymmen, avsedda att värmas till mer än 10 °C, som begränsas av klimatskärmens insida. Area som upptas av innerväggar, öppningar för trappa, schakt och dylikt inräknas. Area för garage, inom byggnaden i bostadshus eller annan lokalbyggnad än garage, inräknas inte. Något lägre än måttet kvadratmeter bruttoarea, eftersom ytor som till exempel garage inte inkluderas.',
						unit: 'kvadratmeter A-temp',
					},
					measures: [
						{
							name: 'Klimatförbättrade materialval',
							toolTip: '',
							info: 'Val av klimatsmart betong, konstruktionsstål och aluminium istället för konventionella produkter i enlighet med dagens utbud på marknaden.',
							procurementReason:
								'Krav bör ställas på både projektör och entreprenör att använda klimatförbättrade materialval.',
							measureBuildVaule: 333,
							measeureOperationVaule: 6.79062,
						},
						{
							name: 'Energieffektivisering spjutspetsnivå',
							toolTip: '',
							info: 'Visar effekten av att samma skolbyggnad med betongstomme skulle byggts med krav på hög energieffektivitet vad gäller uppvärmning i enlighet med upphandlingsmyndighetens krav på spjutspetsnivå. Upphandlingsmyndighetens kriterium för minskad energianvändning finns även i ambitionsnivåerna Bas och Avancerad. Klimatpåverkan från byggskedet är identiskt som i ursprungsfallet. Byggnaden antas värmas med fjärrvärme.',
							procurementReason:
								"Krav kan ställas på projektör och entreprenör att byggnaden skall hålla hög energiprestanda, se <a href='/kriterier'>kriteriedatabasens</a> energikrav.",
							measureBuildVaule: 395,
							measeureOperationVaule: 3.97736,
						},
					],
				},
				{
					name: 'Gång och cykelväg',
					toolTip:
						'Referensobjekten är konventionella typlösningar som motsvarar standardprojekt idag.',
					info: 'Byggnation av en typisk gång-och cykelväg med konventionell asfalt (ej drift och underhåll då bakgrundsdata saknas).',
					referenceData: {
						unit: 'kilometer väg',
						amount: 40671,
					},
					referenceDataOperation: {
						unit: 'm2 A-temp',
						amount: 0,
					},
					lifeLengthReferenceData: {
						unit: 'År',
						lifeLength: 50,
					},
					constructionPhaseData: {
						constructionPhase: 'Endast byggskede',
					},
					inputObjectLifeLength: {
						info: '',
						objectLifeLengthUnit: 'År',
					},
					inputUnit: {
						info: '',
						unit: 'kilometer',
					},
					measures: [
						{
							name: 'Klimatsmart asfalt',
							toolTip: '',
							info: 'Användning av samma asfaltssort, men som tillverkats med en viss andel återvunnen asfalt och högre energieffektiviet',
							procurementReason:
								'Det finns flertalet sätt att sänka klimatpåverkan från asfalt, och marknaden utvecklas kontinuerligt med nya produkter. Krav kan ställas på leverantören att asfalt med låg klimatpåverkan ska användas.',
							measureBuildVaule: 34615,
							measeureOperationVaule: 0,
						},
					],
				},
				{
					name: 'Tvåfältsväg',
					toolTip:
						'Referensobjekten är konventionella typlösningar som motsvarar standardprojekt idag.',
					info: 'Byggnation samt drift och underhåll i 60 år av en typisk tvåfältsväg från Trafikverkets klimatkalkyl med asfalt som tillverkas med fossila bränslen.',
					referenceData: {
						unit: 'kilometer väg',
						amount: 285208,
					},
					referenceDataOperation: {
						unit: 'm2 A-temp',
						amount: 1680,
					},
					lifeLengthReferenceData: {
						unit: 'År',
						lifeLength: 60,
					},
					inputObjectLifeLength: {
						info: '',
						objectLifeLengthUnit: 'År',
					},
					inputUnit: {
						info: '',
						unit: 'kilometer',
					},
					measures: [
						{
							name: 'Klimatsmart asfalt',
							toolTip: '',
							info: 'Användning av samma asfaltssort, men som tillverkats med en viss andel återvunnen asfalt och högre energieffektiviet',
							procurementReason:
								'Det finns flertalet sätt att sänka klimatpåverkan från asfalt, och marknaden utvecklas kontinuerligt med nya produkter. Krav kan ställas på leverantören att asfalt med låg klimatpåverkan ska användas.',
							measureBuildVaule: 253208,
							measeureOperationVaule: 1680,
						},
					],
				},
				{
					name: 'Skola',
					toolTip:
						'Referensobjekten är konventionella typlösningar som motsvarar standardprojekt idag.',
					info: 'Klimatpåverkan från byggnation och drift under 50 år baserat på genomsnitt av ett större urval skolbyggnader i Sverige med varierande storlek och konstruktion (KTH 2021, byggfas). Energianvändning är högsta tillåtna energianvändning enligt BBR. Byggnaden antas vara elvärmd.',
					referenceData: {
						unit: 'm2 A-temp',
						amount: 368,
					},
					referenceDataOperation: {
						unit: 'm2 A-temp',
						amount: 6.79062,
					},
					lifeLengthReferenceData: {
						unit: 'År',
						lifeLength: 50,
					},
					inputObjectLifeLength: {
						info: 'Arean av samtliga våningsplan, vindsplan och källarplan för temperaturreglerade utrymmen, avsedda att värmas till mer än 10 °C, som begränsas av klimatskärmens insida. Area som upptas av innerväggar, öppningar för trappa, schakt och dylikt inräknas. Area för garage, inom byggnaden i bostadshus eller annan lokalbyggnad än garage, inräknas inte. Något lägre än måttet kvadratmeter bruttoarea, eftersom ytor som till exempel garage inte inkluderas.',
						objectLifeLengthUnit: 'År',
					},
					inputUnit: {
						info: 'Arean av samtliga våningsplan, vindsplan och källarplan för temperaturreglerade utrymmen, avsedda att värmas till mer än 10 °C, som begränsas av klimatskärmens insida. Area som upptas av innerväggar, öppningar för trappa, schakt och dylikt inräknas. Area för garage, inom byggnaden i bostadshus eller annan lokalbyggnad än garage, inräknas inte. Något lägre än måttet kvadratmeter bruttoarea, eftersom ytor som till exempel garage inte inkluderas.',
						unit: 'kvadratmeter A-temp',
					},
					measures: [
						{
							name: 'Klimatförbättrade materialval',
							toolTip: '',
							info: 'Val av klimatsmart betong, konstruktionsstål och aluminium istället för konventionella produkter i enlighet med dagens utbud på marknaden.',
							procurementReason:
								'Krav bör ställas på både projektör och entreprenör att använda klimatförbättrade materialval.',
							measureBuildVaule: 328,
							measeureOperationVaule: 6.79062,
						},
						{
							name: 'Energieffektivisering spjutspetsnivå',
							toolTip: '',
							info: 'Visar effekten av att samma skolbyggnad med betongstomme skulle byggts med krav på hög energieffektivitet vad gäller uppvärmning i enlighet med upphandlingsmyndighetens krav på spjutspetsnivå. Upphandlingsmyndighetens kriterium för minskad energianvändning finns även i ambitionsnivåerna Bas och Avancerad. Klimatpåverkan från byggskedet är identiskt som i ursprungsfallet. Byggnaden antas värmas med fjärrvärme.',
							procurementReason:
								"Krav kan ställas på projektör och entreprenör att byggnaden skall hålla hög energiprestanda, se <a href='/kriterier'>kriteriedatabasens</a> energikrav.",
							measureBuildVaule: 368,
							measeureOperationVaule: 4.85044,
						},
					],
				},
				{
					name: 'Förskola',
					toolTip:
						'Referensobjekten är konventionella typlösningar som motsvarar standardprojekt idag.',
					info: 'Klimatpåverkan från byggnation och drift under 50 år baserat på genomsnitt av ett större urval förskolebyggnader i Sverige med varierande storlek och konstruktion (KTH 2021, byggfas). Energianvändning är högsta tillåtna energianvändning enligt BBR. Byggnaden antas vara elvärmd.',
					referenceData: {
						unit: 'm2 A-temp',
						amount: 343,
					},
					referenceDataOperation: {
						unit: 'm2 A-temp',
						amount: 6.79062,
					},
					lifeLengthReferenceData: {
						unit: 'År',
						lifeLength: 50,
					},
					inputObjectLifeLength: {
						info: 'Arean av samtliga våningsplan, vindsplan och källarplan för temperaturreglerade utrymmen, avsedda att värmas till mer än 10 °C, som begränsas av klimatskärmens insida. Area som upptas av innerväggar, öppningar för trappa, schakt och dylikt inräknas. Area för garage, inom byggnaden i bostadshus eller annan lokalbyggnad än garage, inräknas inte. Något lägre än måttet kvadratmeter bruttoarea, eftersom ytor som till exempel garage inte inkluderas.',
						objectLifeLengthUnit: 'År',
					},
					inputUnit: {
						info: 'Arean av samtliga våningsplan, vindsplan och källarplan för temperaturreglerade utrymmen, avsedda att värmas till mer än 10 °C, som begränsas av klimatskärmens insida. Area som upptas av innerväggar, öppningar för trappa, schakt och dylikt inräknas. Area för garage, inom byggnaden i bostadshus eller annan lokalbyggnad än garage, inräknas inte. Något lägre än måttet kvadratmeter bruttoarea, eftersom ytor som till exempel garage inte inkluderas.',
						unit: 'kvadratmeter A-temp',
					},
					measures: [
						{
							name: 'Klimatförbättrade materialval',
							toolTip: '',
							info: 'Val av klimatsmart betong, konstruktionsstål och aluminium istället för konventionella produkter i enlighet med dagens utbud på marknaden.',
							procurementReason:
								'Krav bör ställas på både projektör och entreprenör att använda klimatförbättrade materialval.',
							measureBuildVaule: 316,
							measeureOperationVaule: 6.79062,
						},
						{
							name: 'Energieffektivisering spjutspetsnivå',
							toolTip: '',
							info: 'Visar effekten av att samma förskola med betongstomme skulle byggts med krav på hög energieffektivitet vad gäller uppvärmning i enlighet med upphandlingsmyndighetens krav på spjutspetsnivå. Upphandlingsmyndighetens kriterium för minskad energianvändning finns även i ambitionsnivåerna Bas och Avancerad. Klimatpåverkan från byggskedet är identiskt som i ursprungsfallet. Byggnaden antas värmas med fjärrvärme.',
							procurementReason:
								"Krav kan ställas på projektör och entreprenör att byggnaden skall hålla hög energiprestanda, se <a href='/kriterier'>kriteriedatabasens</a> energikrav.",
							measureBuildVaule: 343,
							measeureOperationVaule: 4.85044,
						},
					],
				},
			],
		},
		{
			name: 'Materialval',
			referenceObjects: [
				{
					name: 'Konventionell byggbetong',
					toolTip:
						'Referensmaterialen är konventionella materialval som motsvarar valen i standardprojekt idag.',
					info: 'Tillverkning av konventionell byggbetong. Se metodblad för beskrivning av vilken betongtyp som används. ',
					referenceData: {
						unit: 'ton',
						amount: 105,
					},
					inputUnit: {
						info: '',
						unit: 'ton',
					},
					measures: [
						{
							name: 'Klimatförbättrad byggbetong',
							toolTip: '',
							info: 'Produktion av samma betongtyp, men med alternativa bindemedel använda istället för cement',
							procurementReason:
								"Krav att använda klimatförbättrad betong bör ställas på både projektör och entreprenör eftersom projekteringen kan behöva ta hänsyn till andra materialspecifikationer i den klimatförbättrade betongen. I vår <a href='/kriterier'>kriterietjänst</a> finns förslag på kriterier för klimatförbättrad betong, som kan användas i upphandling.",
							measureBuildVaule: 93,
							measeureOperationVaule: 0,
						},
					],
				},
				{
					name: 'Konventionell anläggningsbetong',
					toolTip:
						'Referensmaterialen är konventionella materialval som motsvarar valen i standardprojekt idag.',
					info: 'Tillverkning av konventionell anläggningsbetong. Se metodblad för beskrivning av vilken betongtyp som används.',
					referenceData: {
						unit: 'ton',
						amount: 169,
					},
					inputUnit: {
						info: '',
						unit: 'ton',
					},
					measures: [
						{
							name: 'Klimatförbättrad anläggningsbetong',
							toolTip: '',
							info: 'Produktion av samma betongtyp, men med alternativa bindemedel använda istället för cement',
							procurementReason:
								"Krav att använda klimatförbättrad betong bör ställas på både projektör och entreprenör eftersom projekteringen kan behöva ta hänsyn till andra materialspecifikationer i den klimatförbättrade betongen. I vår <a href='/kriterier'>kriterietjänst</a> finns kriterieförslag på klimatförbättrad betong som kan användas.",
							measureBuildVaule: 125,
							measeureOperationVaule: 0,
						},
					],
				},
				{
					name: 'Konventionell asfalt',
					toolTip:
						'Referensmaterialen är konventionella materialval som motsvarar valen i standardprojekt idag.',
					info: 'Tillverkning av konventionell asfalt. Se metodblad för beskrivning av vilken asfaltstyp som används.',
					referenceData: {
						unit: 'ton',
						amount: 49,
					},
					inputUnit: {
						info: '',
						unit: 'ton',
					},
					measures: [
						{
							name: 'Klimatsmart asfalt',
							toolTip: '',
							info: 'Produktion av samma asfalt, men med 20 % återvunnen asfalt och högre energieffektivitet',
							procurementReason:
								"Det finns flertalet sätt att sänka klimatpåverkan från asfalt, och marknaden utvecklas kontinuerligt med nya produkter. Krav kan ställas på leverantören att asfalt med låg klimatpåverkan ska användas. Se <a href='/kriterier'>kriteriedatabasens</a> för mer information.",
							measureBuildVaule: 31.4,
							measeureOperationVaule: 0,
						},
					],
				},
			],
		},
	],
};

export { formData };
