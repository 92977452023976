import React from "react";

const ResultTable = ({ data, antalTjanster }) => {
  console.log(data.totaltIndivid);
  const TotaltIndivid = parseInt(data.totaltIndivid.replace(/,/g, ""), 10);
  const TotaltKommun = parseInt(data.totaltKommun.replace(/,/g, ""), 10);
  const TotaltRegion = parseInt(data.totaltRegion.replace(/,/g, ""), 10);
  const TotaltStat = parseInt(data.totaltStat.replace(/,/g, ""), 10);
  const TotaltArbetsgivare = parseInt(
    data.totaltArbetsgivare.replace(/,/g, ""),
    10
  );

  const samhallet =
    TotaltIndivid +
    TotaltKommun +
    TotaltRegion +
    TotaltStat +
    TotaltArbetsgivare;

  const individ = Math.round(TotaltIndivid / parseInt(antalTjanster, 10));
  const kommun = Math.round(TotaltKommun / parseInt(antalTjanster, 10));
  const region = Math.round(TotaltRegion / parseInt(antalTjanster, 10));
  const stat = Math.round(TotaltStat / parseInt(antalTjanster, 10));
  const arbetsgivare = Math.round(
    TotaltArbetsgivare / parseInt(antalTjanster, 10)
  );
  const samhalletPerTjanst = Math.round(
    samhallet / parseInt(antalTjanster, 10)
  );

  return (
    <table>
      <caption className="sr-only">Resultat</caption>
      <thead>
        <tr>
          <th>Aktör</th>
          <th>Totalt</th>
          <th>Per individ</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Individ</td>
          <td>{Math.round(TotaltIndivid)}</td>
          <td>{isNaN(individ) ? "-" : individ}</td>
        </tr>
        <tr>
          <td>Kommun</td>
          <td>{Math.round(TotaltKommun)}</td>
          <td>{isNaN(kommun) ? "-" : kommun}</td>
        </tr>
        <tr>
          <td>Region</td>
          <td>{Math.round(TotaltRegion)}</td>
          <td>{isNaN(region) ? "-" : region}</td>
        </tr>
        <tr>
          <td>Stat</td>
          <td>{Math.round(TotaltStat)}</td>
          <td>{isNaN(stat) ? "-" : stat}</td>
        </tr>
        <tr>
          <td>Arbetsgivare</td>
          <td>{Math.round(TotaltArbetsgivare)}</td>
          <td>{isNaN(arbetsgivare) ? "-" : arbetsgivare}</td>
        </tr>
        <tr>
          <td className="font-bold">Samhället</td>
          <td>{Math.round(samhallet)}</td>
          <td>{isNaN(samhalletPerTjanst) ? "-" : samhalletPerTjanst}</td>
        </tr>
      </tbody>
    </table>
  );
};
export default ResultTable;
