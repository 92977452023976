export const objectsHaveSameKeys = (...objects: any) => {
	const allKeys = objects.reduce(
		(keys: any, object: any) => keys.concat(Object.keys(object)),
		[]
	);
	const union = new Set(allKeys);
	return objects.every(
		(object: any) => union.size === Object.keys(object).length
	);
};

export const createQueryString = (params: any) => {
	let querystring = '';
	for (const property in params) {
		const value = params[property];

		if (Array.isArray(value)) {
			if (value.length === 0) {
				continue;
			} else if (value[0].buckets) {
				for (const aggregation of value) {
					const values = aggregation.buckets
						.filter((b: any) => b.selected && b.term.length > 0)
						.map((b: any) => encodeURIComponent(b.term))
						.join(',');

					if (values && values.length > 0) {
						querystring += `&${aggregation.path}=${values}`;
					}
				}
			} else {
				querystring += `&${property}=${value
					.map((i) => encodeURIComponent(i))
					.join(',')}`;
			}
		} else if (value != null) {
			querystring += `&${property}=${encodeURIComponent(value)}`;
		}
	}

	if (querystring.length > 0) {
		return querystring.substring(1);
	}

	return null;
};

export const getFilterSortQuery = (
	filterQuery?: string,
	sortQuery?: string
) => {
	if (filterQuery && sortQuery) {
		return `?${filterQuery}&orderBy=${sortQuery}`;
	} else if (filterQuery) {
		return `?${filterQuery}`;
	} else if (sortQuery) {
		return `?orderBy=${sortQuery}`;
	} else return '';
};

export const getActiveAggregations = (aggregations: any[]) => {
	const aggs: any[] = [];

	for (const aggregation of aggregations) {
		if (aggregation.buckets) {
			const buckets: any[] = aggregation.buckets.filter((b: any) => b.selected);

			aggs.push(
				Object.assign(
					{},
					{
						name: aggregation.name,
						type: aggregation.type,
						path: aggregation.path,
						buckets,
					}
				)
			);
		}
	}

	return aggs;
};
