/**
 * ServiceTabs
 */
import clsx from 'clsx';
import React from 'react';

interface Props {
	className?: string;
}

/** Main description for this component. */
const ServiceTabs: React.FC<Props> = ({ className, children }) => {
	return (
		<div
			role="tablist"
			aria-label="tablist"
			className={clsx(
				className,
				'filter-button-group relative overflow-auto whitespace-no-wrap pt-1 pl-1 -mb-0.5'
			)}
		>
			{children}
		</div>
	);
};

export default ServiceTabs;
