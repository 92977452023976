import { rest } from 'msw';
import search from './Search/search.json';
import valfrihet from './Search/valfrihet.json';
import search_en from './Search/search_en.json';
import cpvSearch from './Search/cpv_search.json';
import faq from './Search/fragor-och-svar.json';
import faq_en from './Search/fragor-och-svar_en.json';
import criterias from './Search/kriterier.json';
import criterias_en from './Search/kriterier_en.json';
import { getMockCriteriaColumnData } from './CriteriaService/getMockCriteriaColumnData';
import { getMockCriteriaCartData } from './CriteriaService/getMockCriteriaCartData';
import { getMockStatistics } from './statistics/getMockStatistics';
import { paginateTable } from './statistics/helpers/paginateTable';
import resultTable from './statistics/result-table.json';
import { getMockSearch } from './Search/getMockSearch';

export const handlers = [
	// SearchPageAdvance - Get search result from tab "Webbsidor"
	rest.get('/api/search', getMockSearch),

	rest.get('/api/en/search', (req, res, ctx) => {
		const query = req.url.searchParams.get('query');
		const fetch = req.url.searchParams.get('fetch');

		if (query != null) {
			search.query = query;
		}

		if (fetch != null) {
			search.fetch = parseInt(fetch);
		}
		return res(ctx.status(200), ctx.body(JSON.stringify(search)));
	}),

	// SearchPageAdvance - Get search result from tab "Frågor och svar" & "Kriterier"
	rest.get('/api/search/:service', (req, res, ctx) => {
		const { service } = req.params;
		const query = req.url.searchParams.get('query');
		const fetch = req.url.searchParams.get('fetch');
		const tag = req.url.searchParams.get('tag');
		const categories = req.url.searchParams.get('categories');

		let serviceData;
		switch (service) {
			case 'fragor-och-svar':
				serviceData = faq;
				let aggs = serviceData?.filterGroups[0]?.aggregations;
				let tagBuckets = aggs.find((filter) => filter?.path === 'tag')?.buckets;

				if (tagBuckets && tagBuckets.length > 0) {
					tagBuckets.forEach((bucket: any) => {
						if (tag != null && tag.length > 0) {
							if (tag.split(',').find((i) => i === bucket.term)) {
								bucket.selected = true;
							} else {
								bucket.selected = false;
							}
						} else {
							bucket.selected = false;
						}
					});
				}

				let categoriesBuckets = aggs.find(
					(filter) => filter?.path === 'categories'
				)?.buckets;

				if (categoriesBuckets && categoriesBuckets.length > 0) {
					categoriesBuckets.forEach((bucket: any) => {
						if (categories != null && categories.length > 0) {
							if (categories.split(',').find((i) => i === bucket.term)) {
								bucket.selected = true;
							} else {
								bucket.selected = false;
							}
						} else {
							bucket.selected = false;
						}
					});
				}

				if (tag && !categories) {
					serviceData.filterGroups[0].selectedBuckets = tag.split(',').length;
				} else if (!tag && categories) {
					serviceData.filterGroups[0].selectedBuckets =
						categories.split(',').length;
				} else if (tag && categories) {
					serviceData.filterGroups[0].selectedBuckets =
						tag.split(',').length + categories.split(',').length;
				} else {
					serviceData.filterGroups[0].selectedBuckets = 0;
				}

				break;
			case 'kriterier':
				serviceData = criterias;
				break;
			default:
				serviceData = search;
		}
		if (query != null) {
			serviceData.query = query;
		}

		if (fetch != null) {
			serviceData.fetch = parseInt(fetch);
		}
		return res(ctx.status(200), ctx.body(JSON.stringify(serviceData)));
	}),

	// SearchPageAdvance - Get search result from tab "Frågor och svar" & "Kriterier"
	rest.get('/api/en/search/:service', (req, res, ctx) => {
		const { service } = req.params;
		const query = req.url.searchParams.get('query');
		const fetch = req.url.searchParams.get('fetch');

		let serviceData;
		switch (service) {
			case 'questionportal':
				serviceData = faq_en;
				break;
			case 'criterias':
				serviceData = criterias_en;
				break;
			default:
				serviceData = search_en;
		}
		if (query != null) {
			serviceData.query = query;
		}

		if (fetch != null) {
			serviceData.fetch = parseInt(fetch);
		}
		return res(ctx.status(200), ctx.body(JSON.stringify(serviceData)));
	}),

	// ValfrihetStart
	rest.get('/api/valfrihet', (req, res, ctx) => {
		const query = req.url.searchParams.get('query');
		const fetch = req.url.searchParams.get('fetch');
		const orderBy = req.url.searchParams.get('orderBy')?.split('_')[0]; // Order by

		let response = {
			...valfrihet,
		};

		if (query !== null) {
			response.query = query;
			response.results = response.results.filter(
				(item: any) =>
					item.excerpt.toLowerCase().indexOf(query.toLowerCase().trim()) !== -1
			);
			response.numberOfHits = response.results.length;
		}

		if (fetch != null) {
			response.results = response.results.slice(0, parseInt(fetch));
		}

		if (orderBy !== undefined) {
			response.results = response.results.sort((a: any, b: any) => {
				return a[orderBy] > b[orderBy] ? 1 : -1;
			});
		}

		return res(ctx.status(200), ctx.body(JSON.stringify(response)));
	}),
	// Valfrihet - create search monitoring

	rest.post('/api/valfrihet/monitor', async (req, res, ctx) => {
		console.log(req);

		let code = 200;

		code = Math.random() > 0.5 ? 200 : 501;

		return res(
			ctx.status(code),
			ctx.delay(1000),
			ctx.body(
				code === 200
					? JSON.stringify({
							heading: 'Aktivera bevakning',
							text: 'Vi har tagit emot din bla bla',
					  })
					: JSON.stringify({
							heading: 'Något gick fel',
							text: 'Denna tjänsten är inte implementerad än...',
					  })
			)
		);
	}),

	rest.get('/api/cpv-search', (req, res, ctx) => {
		const query = req.url.searchParams.get('query'); // query
		const fetch = req.url.searchParams.get('fetch'); // Number of items to load

		let response = {
			data: { ...cpvSearch },
			message: '',
		};

		if (query !== null) {
			response.data.items = response.data.items.filter(
				(item) =>
					item.text.toLowerCase().indexOf(query.toLowerCase().trim()) !== -1
			);
		}

		if (fetch !== null) {
			response.data.items = response.data.items.slice(0, parseInt(fetch));
		}

		if (response.data.items.length <= 0) {
			response.message = 'Hittade inget resultat';
		}

		return res(ctx.status(200), ctx.body(JSON.stringify(response)));
	}),

	// Statistic service mock api
	rest.get('/api/sv/statisticsservice/bridgeapi/statistics', getMockStatistics),

	// // Error 403"
	// rest.get('/api/search.json', (req, res, ctx) => {
	// 	const query = req.url.searchParams.get('query');

	// 	if (query === '403') {
	// 		return res(ctx.status(403), ctx.body(''));
	// 	}
	// }),

	// StatisticServiceDataBlock Table Pagination
	rest.get('/api/sv/statisticsservice/table', async (req, res, ctx) => {
		const fetch = req.url.searchParams.get('fetch'); // Number of items to load
		let resultTableData = {};
		if (fetch) {
			resultTableData = paginateTable(
				JSON.parse(JSON.stringify(resultTable)),
				parseInt(fetch)
			);
		}

		return res(ctx.status(200), ctx.body(JSON.stringify(resultTableData)));
	}),

	// CriteriaServiceComparisonPage
	rest.get('/api/criteriaservice/compare-criterias', getMockCriteriaColumnData),

	// CriteriaServiceCartPage - fetch page
	rest.post('/api/criteriaservice/criteira-cart', getMockCriteriaCartData),

	// CriteriaServiceCartPage - download file
	rest.post('/api/criteriaservice/generate/report', async (req, res, ctx) => {
		let body = req.body as any;
		await new Promise<void>((resolve, reject) => {
			setTimeout(() => {
				resolve();
			}, 2000);
		});

		if (body.culture === 'sv') {
			return res(
				ctx.status(200),
				ctx.body(
					JSON.stringify({
						reportUrl: '/Users/sofdam/Documents/Work/wcag-mall.docx',
					})
				)
			);
		} else {
			return res(ctx.status(400), ctx.body(''));
		}
	}),
	rest.get('/produktgrupp-v2', async (req, res, ctx) => {
		const subGroup = req.url.searchParams.get('productSubGroup.name'); // Number of items to load
		if (!subGroup) {
			return res(ctx.status(403), ctx.body(''));
		}
	}),
	rest.get('/produktundergrupp-v2', async (req, res, ctx) => {
		const subGroup = req.url.searchParams.get('productSubGroup.name'); // Number of items to load
		if (!subGroup) {
			return res(ctx.status(403), ctx.body(''));
		}
	}),
];
