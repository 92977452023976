/**
 * StatisticServicePage
 */

import React from 'react';
import { Grid, Cell } from 'ui-component-library/base';
import { StatisticServicePageModel } from 'types';
import BaseLayout from 'layouts/BaseLayout';
import Text from 'components/Text';
import StatisticService from 'components/StatisticService';
import EpiFragments from 'components/EpiFragments';

/** StatisticServicePage component. */
const StatisticServicePage: React.FC<StatisticServicePageModel> = ({
	heading,
	preamble,
	text,
}) => {
	return (
		<BaseLayout themeBorder={true}>
			<Grid
				padding={false}
				margin={false}
				className="-mt-4 md:mt-0 md:pt-2 lg:px-6 px-4"
			>
				<Cell span={12} desktop={7}>
					<Text as="h1" prop="heading">
						{heading}
					</Text>
				</Cell>
			</Grid>
			<Grid margin={false} padding={false} className="lg:px-6 px-4">
				<Cell span={12} desktop={7}>
					<Text
						as="preamble"
						prop="preamble"
						margin={text ? false : true}
						className="mb-4"
					>
						{preamble}
					</Text>
					{text && (
						<div className="mb-7">
							<EpiFragments prop="text" fragments={text?.fragments} />
						</div>
					)}
				</Cell>
			</Grid>
			<StatisticService />
		</BaseLayout>
	);
};

export default StatisticServicePage;
