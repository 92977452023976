import React, { useEffect, useRef, useState } from 'react';
import { Icon } from 'ui-component-library/base';
import { useOutsideClick } from 'ui-component-library/hooks';

interface Props {
	buttonText: string;
}

/** Main description for this component. */
const SearchSortList: React.FC<Props> = ({ buttonText, children }) => {
	const [expanded, setExpanded] = useState(false);
	const sortListRef = useRef<any>();
	useOutsideClick(sortListRef, () => {
		setExpanded(false);
	});

	const renderChildren = (children: any) => {
		return React.Children.map(children, (child, index) => {
			return (
				<li key={index}>{React.cloneElement(child, child.props || {})}</li>
			);
		});
	};

	useEffect(() => {
		setExpanded(false);
	}, [buttonText]);

	return (
		<div className="relative" ref={sortListRef}>
			<button
				onClick={() => setExpanded(!expanded)}
				aria-controls="sort-list"
				aria-expanded={expanded}
				className="text-teal p-2"
			>
				<span>{buttonText}</span>
				<Icon icon="sortArrows" color="#00636A" className="ml-1" />
			</button>
			{expanded && (
				<div
					id="sort-list"
					className="bg-white border border-teal absolute right-0"
				>
					<ul className="min-w-32">{renderChildren(children)}</ul>
				</div>
			)}
		</div>
	);
};

export default SearchSortList;
