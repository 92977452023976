/**
 * LanguageSelector
 */

import React from 'react';
import Icon, { iconTypes } from 'ui-component-library/base/Icon';

interface Props {
	/** Text for link item */
	text: string;

	/** url for link item */
	url: string;

	/** Optional icon next to text */
	icon?: iconTypes;

	/** Optional id for link item */
	id?: string;
}

/** Main description for this component. */
const LanguageSelector: React.FC<Props> = ({ text, url, icon, id }) => {
	return (
		<li className="ml-6">
			<a
				href={url}
				id={id}
				className="flex items-center text-teal hover:underline"
			>
				{icon && (
					<Icon icon={icon} size={1} className="mr-1" aria-hidden={true} />
				)}
				{text}
			</a>
		</li>
	);
};

export default LanguageSelector;
