/**
 * WebGuidanceArticlePage
 */

import React from 'react';
import {
	Grid,
	Cell,
	LinkList,
	LinkListItem,
	ArticleHeader,
	ArticleHeaderCategoryList,
	ArticleHeaderCategory,
	Toc,
	TocLink,
} from 'ui-component-library/base';
import BaseLayout from 'layouts/BaseLayout';
import Text from 'components/Text';
import EpiContentArea from 'components/EpiContentArea';
import { WebGuidanceArticlePageModel } from 'types';
import Link from 'components/Link';
import EpiFragments from 'components/EpiFragments';
import clsx from 'clsx';
import { useIsDesktop } from 'hooks/useMediaQuery';
import WebGuidanceIntroBlock from 'components/WebGuidanceIntroBlock';

/** WebGuidanceArticlePage component. */
const WebGuidanceArticlePage: React.FC<WebGuidanceArticlePageModel> = ({
	heading,
	preamble,
	area,
	articleHeader,
	tableOfContents,
	text,
	relatedLinks,
	contentArea,
}) => {
	const isDesktop = useIsDesktop();
	return (
		<BaseLayout>
			<div className="-mt-10 md:-mt-8">
				<WebGuidanceIntroBlock area={area} className="mb-10">
					<Grid padding={false} className="lg:pl-6 pl-4">
						<Cell span={12} desktop={7}>
							{heading && (
								<Text as="h1" prop="heading" margin={false} className="mb-2">
									{heading}
								</Text>
							)}
							{articleHeader && (
								<ArticleHeader
									social={articleHeader.social}
									buttonVariant="secondary"
								>
									{articleHeader.categories &&
										articleHeader.categories.length > 0 && (
											<ArticleHeaderCategoryList>
												{articleHeader.categories.map((category, index) => {
													return (
														<ArticleHeaderCategory
															key={index}
															url={category.url}
															text={category.text}
															LinkComponent={Link}
														/>
													);
												})}
											</ArticleHeaderCategoryList>
										)}
								</ArticleHeader>
							)}
							{preamble && (
								<p className="text-p md:text-h3 mt-6 leading-normal">
									{preamble}
								</p>
							)}
						</Cell>
					</Grid>
				</WebGuidanceIntroBlock>
				<Grid
					margin={false}
					padding={false}
					className={clsx(
						(tableOfContents || relatedLinks) && 'flex-row-reverse',
						'lg:px-6 px-4'
					)}
				>
					{isDesktop && (tableOfContents || relatedLinks) && (
						<Cell span={4} className="ml-1/12 pl-8">
							<div className="sticky toc">
								{tableOfContents && (
									<Toc
										heading={tableOfContents.heading}
										items={tableOfContents.chapters}
										className="mb-20"
									>
										{tableOfContents.titles.map((item, index) => (
											<TocLink href={`#${item.targetId}`} key={index}>
												{item.text}
											</TocLink>
										))}
									</Toc>
								)}
								{relatedLinks?.map((linkSection, index) => {
									return (
										<LinkList
											key={index}
											heading={linkSection?.heading}
											className="mb-12"
										>
											{linkSection.links?.map((item, subIndex) => (
												<LinkListItem
													text={item.text}
													url={item.href}
													icon={'arrow'}
													target={item.target}
													LinkComponent={Link}
													key={subIndex}
													external={item.external}
												/>
											))}
										</LinkList>
									);
								})}
							</div>
						</Cell>
					)}

					<Cell span={12} desktop={7}>
						{!isDesktop && tableOfContents && (
							<Toc
								heading={tableOfContents.heading}
								items={[]}
								className="mb-8"
							>
								{tableOfContents.titles.map((item, index) => (
									<TocLink href={`#${item.targetId}`} key={index}>
										{item.text}
									</TocLink>
								))}
							</Toc>
						)}
						{!isDesktop &&
							relatedLinks?.map((linkSection, index) => {
								return (
									<LinkList
										key={index}
										heading={linkSection?.heading}
										className="mb-12"
									>
										{linkSection.links?.map((item, subIndex) => (
											<LinkListItem
												text={item.text}
												url={item.href}
												icon={'arrow'}
												target={item.target}
												LinkComponent={Link}
												key={subIndex}
												external={item.external}
											/>
										))}
									</LinkList>
								);
							})}
						<EpiFragments prop="text" fragments={text?.fragments} />
					</Cell>
				</Grid>
			</div>

			{contentArea && contentArea.length > 0 && (
				<EpiContentArea content={contentArea} />
			)}
		</BaseLayout>
	);
};
export default WebGuidanceArticlePage;
