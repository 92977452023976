/**
 * RichText
 */

import React, { useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectEpi } from 'store/modules/epi';

/** Richtext components which handles links. */
const RichText: React.FC = ({ children }) => {
	const history = useHistory();
	const epi = useSelector(selectEpi);
	const nodeRef = useRef<HTMLDivElement>(null);

	function handleLinkClick(this: HTMLAnchorElement, event: MouseEvent): void {
		const href = this.getAttribute('href');
		const target = this.getAttribute('target');
		const isFile = this.getAttribute('data-type') === 'file';
		if (href && href.charAt(0) === '/' && target !== '_blank' && !isFile) {
			event.preventDefault();
			history.push(href);
		}
	}

	useEffect(() => {
		const nodeRefRef = nodeRef;
		if (nodeRef.current && !epi?.beta?.inEditMode) {
			nodeRef.current.querySelectorAll('a').forEach((a) => {
				a.addEventListener('click', handleLinkClick);
			});
		}

		return () => {
			if (nodeRefRef.current) {
				nodeRefRef.current.querySelectorAll('a').forEach((a) => {
					a.removeEventListener('click', handleLinkClick);
				});
			}
		};
		// eslint-disable-next-line
	}, []);

	return <div className="richtext" ref={nodeRef}>{children}</div>;
};

export default RichText;
