import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EpiEvent } from 'types';
import { RootState } from 'store';

let initialState: EpiEvent = {};

const slice = createSlice({
	name: 'epi',
	initialState,
	reducers: {
		updateEpi: (state, action: PayloadAction<EpiEvent>) => {
			return action.payload;
		},
	},
});

export const selectEpi = (state: RootState) => state.epi;
export const { updateEpi } = slice.actions;

export default slice.reducer;
