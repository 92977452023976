/**
 * DataTableProductGroup
 */

import clsx from 'clsx';
import React from 'react';
import { DataTablePropertyModel } from 'types';
import DataTableProductGroupSortButton from './DataTableProductGroupSortButton';

interface Props {
	/** Pass optional classnames for the html */
	className?: string;

	/** Table caption */
	caption?: string;

	/** Data tabel headers */
	properties?: DataTablePropertyModel[];

	onSortChange?: any;
}
/** Main description for this component. */
const DataTableProductGroup: React.FC<Props> = ({
	className,
	caption = 'DataTableProductGroup',
	properties,
	onSortChange,
	children,
}) => {
	return (
		<div
			className={clsx(className, 'relative overflow-y-auto w-full mb-6')}
			data-cid="DataTableRequirements"
		>
			<table className="productgroup-table">
				<caption className="sr-only">{caption}</caption>
				{properties && (
					<thead>
						<tr>
							{properties.map((header, index) => {
								if (
									header.property === 'addToCartButton' ||
									header.property === 'description'
								) {
									return (
										<th
											scope="col"
											className={clsx(
												header.property === 'description' && 'sr-only'
											)}
										>
											{header.heading}
										</th>
									);
								}
								return (
									<th key={index} scope="col">
										<DataTableProductGroupSortButton
											property={header.path}
											direction={header.direction}
											orderByText={header.orderByText}
											onChange={onSortChange}
										>
											{header.heading}
										</DataTableProductGroupSortButton>
									</th>
								);
							})}
						</tr>
					</thead>
				)}

				<tbody>{children}</tbody>
			</table>
		</div>
	);
};

export default DataTableProductGroup;
