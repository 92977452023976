export const sortTable = (
	table: any,
	orderBy?: string,
	orderDirection: string = 'DESC'
) => {
	if (!orderBy || !table.rows[0][orderBy]) {
		return table;
	}

	table.rows.sort((a: any, b: any) => {
		var nameA = a[orderBy].toUpperCase();
		var nameB = b[orderBy].toUpperCase();
		if (nameA < nameB) {
			return orderDirection === 'ASC' ? -1 : 1;
		}
		if (nameA > nameB) {
			return orderDirection === 'ASC' ? 1 : -1;
		}
		return 0;
	});

	return updateTableHeader(table, orderBy, orderDirection);
};

export const updateTableHeader = (
	table: any,
	orderBy?: string,
	orderDirection: string = 'DESC'
) => {
	if (!orderBy || !table.rows[0][orderBy]) {
		return table;
	}

	table.properties.map((prop: any) => {
		if (prop.path === orderBy) {
			prop.direction = orderDirection;
			// prop.direction = orderDirection === 'DESC' ? 'ASC' : 'DESC';
		} else {
			delete prop.direction;
		}
	});

	return table;
};
