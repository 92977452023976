/**
 * StepByStep
 */

import React from 'react';
import { StepModel } from 'types';
import {
	StepByStep as StepByStepUI,
	StepByStepHeader,
	StepByStepItem,
} from 'ui-component-library/uhmse';
import { ListType } from 'ui-component-library/uhmse/StepByStep';
import EpiFragments from 'components/EpiFragments';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';

interface Props {
	/** Steps that should be rendered. */
	steps: StepModel[];

	/** Type/design of the list. */
	listType?: ListType;
}

/** Main description for this component. */
const StepByStep: React.FC<Props> = ({ steps, listType = 'numbers' }) => {
	let hashstring = useLocation().hash;

	return (
		<StepByStepUI listType={listType}>
			{steps.map((step, i) => (
				<div
					className={clsx((!step.steps || step.steps?.length > 1) && 'mb-16')}
					key={i}
					id={step.id}
				>
					{step.heading && (
						<StepByStepHeader
							header={step.heading}
							subHeader={step.subHeading}
							number={i + 1}
							listType={listType}
							lastChild={
								steps.length === i + 1 &&
								(!step.steps || step.steps?.length === 0)
							}
						>
							<EpiFragments fragments={step.fragments} className="mb-10" />
						</StepByStepHeader>
					)}

					{step.steps &&
						step.steps.map((subStep, k) => (
							<StepByStepItem
								key={k}
								id={subStep.id}
								header={subStep.heading}
								open={
									(subStep.id && hashstring.includes(subStep.id)) ||
									subStep.isOpen
								}
								lastChild={
									k + 1 === step?.steps?.length &&
									i + 1 === steps.length &&
									k !== 0
								}
								openText="Visa"
								closeText="Dölj"
								borderTop={
									listType === 'none' && k === 0 && !step.heading && true
								}
								listType={listType}
								headingLevel={step.heading ? 'h3' : 'h2'}
								onToggle={(expanded: boolean, id: string) => {
									if (expanded && window.dataLayer && window.dataLayer.push) {
										window.dataLayer.push({
											expandedBlock: expanded,
										});
									}
								}}
							>
								<EpiFragments fragments={subStep.fragments} />
							</StepByStepItem>
						))}
				</div>
			))}
		</StepByStepUI>
	);
};

export default StepByStep;
