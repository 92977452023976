/**
 * MultiSelectFilter
 */

import React, { useState, useEffect } from 'react';
import { useFirstRender } from 'ui-component-library/hooks';
import { AggregationsModel } from 'types';
import FilterChip from 'ui-component-library/base/FilterChip';
import {
	MultiselectDropdown,
	MultiselectOption,
} from 'ui-component-library/base/MultiselectDropdown';

interface Props {
	/** The filters for the component*/
	filters: AggregationsModel[];

	/** Callback for on filter change */
	onChange?: any;

	/** Clear text for filters */
	clearText?: string;

	/** Text for expand button in dropdown */
	expandText?: string;
}

const createFilterQuery = (filters: AggregationsModel[]) => {
	let query: string[] = [];

	filters.forEach((filter) => {
		const selectedInBucket: string[] = [];
		filter.buckets.forEach((bucket) => {
			if (bucket.selected) {
				selectedInBucket.push(bucket.term);
			}
		});
		if (selectedInBucket.length > 0) {
			query.push(`${filter.path}=${selectedInBucket.join(',')}`);
		}
	});

	return query.join('&');
};

type SelectedFilterType = {
	bucketId: string;
	bucketName: string;
	selectedBuckets: number;
};

const getSelectedFilters = (
	filters: AggregationsModel[]
): SelectedFilterType[] => {
	let selectedFilters: SelectedFilterType[] = [];

	filters.forEach((filter) => {
		filter.buckets.forEach((bucket, index) => {
			if (bucket.selected) {
				selectedFilters.push({
					bucketId: `${filter.path}-${index}`,
					bucketName: bucket.term,
					selectedBuckets: filter.selectedBuckets,
				});
			}
		});
	});

	return selectedFilters;
};

/** Filter component. */
const MultiSelectFilter: React.FC<Props> = ({
	filters: defaultFilters,
	onChange,
	clearText,
	expandText,
}) => {
	const [filters, setFilters] = useState(defaultFilters);
	const isFirstRender = useFirstRender();

	const filterOnChange = (
		e?: React.FormEvent<HTMLInputElement>,
		uncheckFilter?: string
	) => {
		const newFilterObj: AggregationsModel[] = JSON.parse(
			JSON.stringify(filters)
		);

		if (e) {
			const { id, name, checked } = e.currentTarget;
			newFilterObj.forEach((filter) =>
				filter.buckets.forEach((bucket, index) => {
					if (filter.path === name && `${filter.path}-${index}` === id) {
						bucket.selected = checked;
						if (!checked) {
							--filter.selectedBuckets;
						} else {
							++filter.selectedBuckets;
						}
					}
				})
			);
		} else if (uncheckFilter) {
			newFilterObj.forEach((filter) =>
				filter.buckets.forEach((bucket, index) => {
					if (`${filter.path}-${index}` === uncheckFilter) {
						bucket.selected = false;
						--filter.selectedBuckets;
					}
				})
			);
		}

		setFilters(newFilterObj);
	};

	useEffect(() => {
		if (!isFirstRender) {
			onChange(filters, createFilterQuery(filters));
		}
		// eslint-disable-next-line
	}, [filters]);

	return (
		<form>
			<div className="flex flex-wrap flex-col md:flex-row">
				{filters.map((filter, i) => (
					<div className="mr-2 mb-3 md:mb-0" key={filter.path}>
						<MultiselectDropdown
							id={`filter-${i}`}
							legend={filter.name}
							buttonSrText={expandText}
							selectedBuckets={filter.selectedBuckets}
						>
							{filter.buckets.map((bucket, index) => (
								<MultiselectOption
									key={index}
									id={`${filter.path}-${index}`}
									checked={bucket.selected}
									name={filter.path}
									value={bucket.term}
									disabled={bucket.disabled}
									onChange={filterOnChange}
									count={bucket.count}
									data-filter={filter.name}
								>
									{bucket.term}
								</MultiselectOption>
							))}
						</MultiselectDropdown>
					</div>
				))}
			</div>

			<div className="flex flex-wrap mt-2">
				{getSelectedFilters(filters).map((item, i) => (
					<FilterChip
						onClick={() => {
							filterOnChange(undefined, item.bucketId);
						}}
						className="mr-2 mb-2"
						key={i}
					>
						<span className="sr-only">{clearText}</span>
						{item.bucketName}
					</FilterChip>
				))}
			</div>
		</form>
	);
};

export default MultiSelectFilter;
