/**
 * ValfrihetMonitorPage
 */

import BaseLayout from 'layouts/BaseLayout';
import React from 'react';
import { ValfrihetMonitorPageModel } from 'types';
import { Button, Cell, Grid, Icon, Link } from 'ui-component-library/base';

const ValfrihetMonitorPage: React.FC<ValfrihetMonitorPageModel> = ({
	heading,
	preamble,
	buttonText,
	buttonLink,
}) => {
	return (
		<BaseLayout>
			<div className="min-h-70vh">
				<div className="text-center bg-teal text-white pt-14 pb-18">
					<Icon icon="bell" size={3} aria-hidden />
					<h1 className="font-semibold text-center mt-3 mb-3">{heading}</h1>
				</div>

				<Grid className="mt-3">
					<Cell className="mx-auto text-center md:w-1/2 px-7">
						<p className="whitespace-pre-line">{preamble}</p>
						<Button
							LinkComponent={Link}
							className="mt-14"
							to={buttonLink}
							color="teal"
							as="a"
						>
							{buttonText}
						</Button>
					</Cell>
				</Grid>
			</div>
		</BaseLayout>
	);
};

export default ValfrihetMonitorPage;
