/**
 * BaseLayout
 * TODO: Använd tailwind theme för att hitta rätt mediaquery värde
 */

import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { useMachine } from '@xstate/react';
import { useOutsideClick } from 'ui-component-library/hooks';
import {
	selectBreadcrumbs,
	// selectQuicklinks,
	selectBetaBar,
	selectCookieBar,
	selectQuickLinksBar,
	selectSearch,
	selectFooterContact,
	selectFooterQuickLinks,
	selectFooterPopularPages,
	selectNavigation,
	selectSkipToMainContent,
	selectAlertBar,
	selectLanguageSelector,
	selectLogotype,
	selectContent,
	selectCriteriaCart,
} from 'store/modules/model';
import {
	InfoBar,
	Cookiebar,
	SearchField,
	Icon,
	Button,
	Grid,
	Cell,
	MobileSearch,
	MobileMenu,
	MobileMenuItem,
	SkipToMainContent,
	Text,
	QuickLinksBar,
	QuickLink,
	AlertBar,
	Breadcrumbs,
	BreadcrumbsItem,
} from 'ui-component-library/base';
import { iconTypes } from 'ui-component-library/base/Icon';

import {
	SearchFieldDropdown,
	SearchFieldDropdownItem,
} from 'ui-component-library/base/SearchField';
import {
	PageFooter,
	PageFooterBottom,
	PageHeader,
	SearchResultList,
	SearchResultListItem,
	SearchItemNoResults,
	// QuickLinks,
	// QuickLinksItem,
} from 'ui-component-library/uhmse';
import {
	FooterHeader,
	FooterText,
	FooterDescList,
	FooterLinkList,
	ContentWrapper,
	BottomContent,
} from 'ui-component-library/uhmse/PageFooter';
import Link from 'components/Link';
import Meta from 'components/Meta';
import EpiFragments from 'components/EpiFragments';
import { SearchListItemTypes, SearchResultItemModel } from 'types';
import { searchMachine } from 'state-machines/search.machine';
import { searchFieldMachine } from 'state-machines/searchField.machine';
import LanguageSelector from 'components/LanguageSelector';
import clsx from 'clsx';
import { useIsMobile, useIsTabletOrLarger } from 'hooks/useMediaQuery';
import CriteriaCart from 'components/CriteriaCart';
import 'utils/kundo-lazy-button.js';

interface Props {
	/** Set theme border under breadcrumbs */
	themeBorder?: boolean;
}

/** BaseLayout that adds header and footer to the page. */
const BaseLayout: React.FC<Props> = ({ themeBorder = false, children }) => {
	const isTabletOrLarger = useIsTabletOrLarger();
	const isMobile = useIsMobile();
	const searchfieldWrapperRef = useRef<any>(null);
	const searchfieldMobileWrapperRef = useRef<any>(null);
	const searchfieldInputRef = useRef<any>(null);
	const searchfieldMobileInputRef = useRef<any>(null);
	const location = useLocation();
	const history = useHistory();
	const { query } = queryString.parse(location.search);
	const breadcrumbs = useSelector(selectBreadcrumbs);
	// const quickLinks = useSelector(selectQuicklinks);
	const alertBar = useSelector(selectAlertBar);
	const betaBar = useSelector(selectBetaBar);
	const cookieBar = useSelector(selectCookieBar);
	const quickLinksBar = useSelector(selectQuickLinksBar);
	const skipToMainContent = useSelector(selectSkipToMainContent);
	const searchSettings = useSelector(selectSearch);
	const contact = useSelector(selectFooterContact);
	const quickLinksFooter = useSelector(selectFooterQuickLinks);
	const popularPages = useSelector(selectFooterPopularPages);
	const navigation = useSelector(selectNavigation);
	const logotype = useSelector(selectLogotype);
	const content = useSelector(selectContent);
	const criteriaCart = useSelector(selectCriteriaCart);

	const [showCookieBar, setShowCookieBar] = useState(false);
	const [cartExpanded, setCartExpanded] = useState(false);
	const [stateSearch, sendSearch] = useMachine(searchMachine, {
		context: {
			apiUrl: searchSettings?.apiUrl || '',
			size: isTabletOrLarger ? 5 : searchSettings?.numberOfHitsPerPage || 5,
			debounce: 500,
		},
	});
	const [stateField, sendField] = useMachine(searchFieldMachine);
	const languages = useSelector(selectLanguageSelector);
	useEffect(() => {
		setShowCookieBar(true);
	}, []);

	useOutsideClick(searchfieldWrapperRef, () => {
		if (searchSettings) {
			sendField('CLOSE');
		}
	});

	return (
		<div className={clsx(criteriaCart && 'relative')}>
			<Meta />
			{cookieBar && showCookieBar && (
				<Cookiebar
					cookieName="uhmBeta"
					hideIfCookieSet={true}
					open={true}
					buttonText={cookieBar.buttonText}
				>
					<div className="richtext">
						<h1 className="small">{cookieBar.heading}</h1>

						<EpiFragments prop="text" fragments={cookieBar.text?.fragments} />
					</div>
				</Cookiebar>
			)}
			<PageHeader
				title={logotype?.alt || 'Startsida'}
				logoLink={logotype?.url || '/'}
				logoSrc={logotype?.logo}
				SearchComponent={
					searchSettings && (
						<SearchField
							id="searchfieldDesktop"
							wrapperRef={searchfieldWrapperRef}
							fieldRef={searchfieldInputRef}
							label={searchSettings.placeholderText}
							submitLabel={searchSettings.performSearchText}
							showSpinner={stateSearch.value === 'loading'}
							defaultValue={query ? query.toString() : undefined}
							onChange={(e: any) => {
								stateField.value === 'active' &&
									sendSearch('SEARCH', {
										value: e.value,
									});
							}}
							onSubmit={(e: any) => {
								sendField('CLOSE');
								searchfieldInputRef?.current?.blur();
								history.push(
									`${searchSettings.searchPageUrl}?query=${encodeURIComponent(
										e.searchfieldDesktop
									)}`
								);
							}}
							onFocus={() => {
								setTimeout(() => {
									sendField('OPEN');
								}, 200);
							}}
						>
							{stateField.value === 'active' &&
								stateSearch.context.result &&
								stateSearch.context.query !== '' && (
									<SearchFieldDropdown>
										{stateSearch.context.result.results &&
											stateSearch.context.result.results.map(
												(item: SearchListItemTypes) => (
													<SearchFieldDropdownItem key={item.documentId}>
														<Link to={item.url}>
															<Icon
																icon="chevron"
																direction="left"
																aria-hidden={true}
																size={0.75}
															/>
															{item.heading}
														</Link>
													</SearchFieldDropdownItem>
												)
											)}
										{stateSearch.context.result.numberOfHits >
											stateSearch.context.result.size && (
											<SearchFieldDropdownItem>
												<Link
													to={`${
														searchSettings.searchPageUrl
													}?query=${encodeURIComponent(
														stateSearch.context.result.query
													)}`}
													onClick={() => {
														sendField('CLOSE');
													}}
												>
													{searchSettings.showMoreResultsText}
												</Link>
											</SearchFieldDropdownItem>
										)}
										{stateSearch.context.result.numberOfHits === 0 && (
											<SearchFieldDropdownItem>
												<p>Inga träffar hittades</p>
											</SearchFieldDropdownItem>
										)}
									</SearchFieldDropdown>
								)}
						</SearchField>
					)
				}
				SearchComponentMobile={
					searchSettings && (
						<MobileSearch
							openSearchPane="Öppna sök"
							closeSearchPane="Stäng sökfält"
							SearchField={
								<SearchField
									id="searchfieldMobile"
									wrapperRef={searchfieldMobileWrapperRef}
									fieldRef={searchfieldMobileInputRef}
									label={searchSettings.placeholderText}
									submitLabel={searchSettings.performSearchText}
									showSpinner={stateSearch.value === 'loading'}
									defaultValue={query ? query.toString() : undefined}
									onChange={(e: any) => {
										stateField.value === 'active' &&
											sendSearch('SEARCH', {
												value: e.value,
											});
									}}
									onSubmit={(e: any) => {
										sendField('CLOSE');
										searchfieldMobileInputRef?.current?.blur();
										history.push(
											`${
												searchSettings.searchPageUrl
											}?query=${encodeURIComponent(e.searchfieldMobile)}`
										);
									}}
									onFocus={() => {
										setTimeout(() => {
											sendField('OPEN');
										}, 200);
									}}
								/>
							}
							SearchResult={
								stateField.value === 'active' &&
								stateSearch.context.result &&
								stateSearch.context.query !== '' && (
									<>
										<SearchResultList>
											{stateSearch.context.result.results &&
												stateSearch.context.result.results.map(
													(item: SearchListItemTypes) => {
														const searchItem = item as SearchResultItemModel;
														return (
															<SearchResultListItem
																url={searchItem.url}
																heading={searchItem.heading}
																text={searchItem.excerpt}
																key={searchItem.documentId}
																LinkComponent={Link}
															/>
														);
													}
												)}
											{stateSearch.context.result.numberOfHits === 0 && (
												<SearchItemNoResults heading="Inga träffar hittades" />
											)}
										</SearchResultList>
										{stateSearch.context.result.numberOfHits >
											stateSearch.context.result.size && (
											<Button
												size="small"
												variant="primary"
												type="submit"
												onClick={() => {
													sendField('CLOSE');
													searchfieldInputRef?.current?.blur();
													history.push(
														`${
															searchSettings.searchPageUrl
														}?query=${encodeURIComponent(
															stateSearch.context.query
														)}`
													);
												}}
											>
												{searchSettings.showMoreResultsText}{' '}
												<Icon icon="chevron" direction="up" color="white" />
											</Button>
										)}
									</>
								)
							}
						/>
					)
				}
				MenuComponent={
					navigation &&
					navigation.items &&
					navigation.items.length > 0 && (
						<MobileMenu
							navigationButtonLabel={navigation.navigationButtonLabel}
							ariaLabel={navigation.accessibilityDescription}
							openMenuItemLabel={navigation.openNavigationItemLabel}
							closeMenuItemLabel={navigation.closeNavigationItemLabel}
							location={location}
							className="right-0 mt-4 md:mt-10"
						>
							{navigation.items.map((item, index) => {
								return (
									<MobileMenuItem
										menuLevel={0}
										title={item.title}
										url={item.url}
										isOpen={item.isOpen}
										isActive={item.isActive}
										LinkComponent={Link}
										key={index}
									>
										{item.items &&
											item.items.length > 0 &&
											item.items.map((subItem, index) => {
												return (
													<MobileMenuItem
														menuLevel={1}
														title={subItem.title}
														url={subItem.url}
														isOpen={subItem.isOpen}
														isActive={subItem.isActive}
														LinkComponent={Link}
														key={index}
													>
														{subItem.items &&
															subItem.items.length > 0 &&
															subItem.items.map((subSubItem, index) => {
																return (
																	<MobileMenuItem
																		menuLevel={2}
																		title={subSubItem.title}
																		url={subSubItem.url}
																		isOpen={subSubItem.isOpen}
																		isActive={subSubItem.isActive}
																		LinkComponent={Link}
																		key={index}
																	/>
																);
															})}
													</MobileMenuItem>
												);
											})}
									</MobileMenuItem>
								);
							})}
							{isMobile &&
								quickLinksBar &&
								quickLinksBar.links.length > 0 &&
								quickLinksBar.links.map((item, index) => {
									return (
										<MobileMenuItem
											key={index}
											isQuickLink={true}
											title={item.text}
											url={item.url}
											LinkComponent={Link}
										/>
									);
								})}
						</MobileMenu>
					)
				}
				CriteriaCartComponent={
					criteriaCart && (
						<CriteriaCart
							cartLabel={criteriaCart.cartLabel}
							closeCartText={criteriaCart.closeCartText}
							hasBreadcrumbs={(breadcrumbs && breadcrumbs.length > 1) || false}
							hasThemeBorder={themeBorder}
							apiUrl={criteriaCart.apiUrl}
							toastText={criteriaCart.toastCriteriaAdded}
							onToggle={() => setCartExpanded(!cartExpanded)}
						/>
					)
				}
			>
				{alertBar &&
					alertBar.alerts.map((item, index) => {
						return (
							<AlertBar
								key={index}
								alertbarName={`${item.id}`}
								hideIfAlertbarSet={true}
								isCloseable={item.isCloseable}
								closeButtonLabel={item.closeButtonLabel}
							>
								{item.text.fragments.map((fragment, index) => (
									<div
										dangerouslySetInnerHTML={{ __html: fragment.raw }}
										key={index}
									/>
								))}
							</AlertBar>
						);
					})}
				{skipToMainContent && skipToMainContent.text && (
					<SkipToMainContent href="#main-content">
						<Text as="span">{skipToMainContent.text}</Text>
					</SkipToMainContent>
				)}
				{betaBar && (
					<InfoBar>
						{betaBar.text.fragments.map((fragment, index) => (
							<div
								dangerouslySetInnerHTML={{ __html: fragment.raw }}
								key={index}
							/>
						))}
					</InfoBar>
				)}
				{quickLinksBar?.links && quickLinksBar.links.length > 0 && !isMobile && (
					<QuickLinksBar aria-label={quickLinksBar.label}>
						{quickLinksBar.links.map((item, index) => {
							return (
								<React.Fragment key={index}>
									{item.modelType === 'LanguageSelector' ? (
										languages?.languages &&
										languages.languages.length > 0 && (
											<LanguageSelector
												id={languages.languages[0].id}
												text={languages.languages[0].text}
												url={languages.languages[0].url}
											/>
										)
									) : (
										<QuickLink
											text={item.text}
											url={item.url}
											target={item.target}
											icon={item.icon}
											LinkComponent={Link}
										/>
									)}
								</React.Fragment>
							);
						})}
					</QuickLinksBar>
				)}
			</PageHeader>

			{breadcrumbs && breadcrumbs.length > 1 && (
				<Breadcrumbs
					aria-label="Brödsmulor"
					className={clsx(themeBorder && 'theme-border')}
				>
					{breadcrumbs.map((item, index) => (
						<BreadcrumbsItem
							key={index}
							url={!item.isActive && item.url ? item.url : ''}
							text={item.text}
						/>
					))}
				</Breadcrumbs>
			)}
			<main
				className={clsx(
					'pt-10 md:pt-8 global__page-content',
					cartExpanded && 'global__page-content__overlay'
				)}
				id="main-content"
			>
				{children}
			</main>
			<PageFooter className="global__page-footer">
				<Grid padding={false} className="px-4 lg:px-6">
					{contact && (
						<>
							<Cell desktop={3} span={12}>
								<FooterHeader>{contact.addresses.first?.name}</FooterHeader>
								<FooterText>
									{contact.addresses.first?.street}
									<br />
									{contact.addresses.first?.zipCode}{' '}
									{contact.addresses.first?.city}
								</FooterText>

								<FooterHeader>{contact.channels.heading}</FooterHeader>
								<FooterDescList>
									{contact.channels.items.map((item, index) => {
										return (
											<React.Fragment key={index}>
												<dt>{item.name}</dt>
												<dd>{item.value}</dd>
											</React.Fragment>
										);
									})}
								</FooterDescList>
							</Cell>

							<Cell desktop={3} span={12}>
								<FooterHeader>{contact.addresses.second?.name}</FooterHeader>
								<FooterText>
									{contact.addresses.second?.street}
									<br />
									{contact.addresses.second?.zipCode}{' '}
									{contact.addresses.second?.city}
								</FooterText>
							</Cell>
						</>
					)}

					{popularPages?.first && (
						<Cell desktop={3} span={12}>
							<FooterHeader>{popularPages.first.heading}</FooterHeader>
							<FooterLinkList>
								{popularPages.first.links.map((link, index) => {
									return (
										<React.Fragment key={index}>
											<li key={index}>
												<Link href={link.href} target={link.target}>
													{link.text}
												</Link>
											</li>
										</React.Fragment>
									);
								})}
							</FooterLinkList>
						</Cell>
					)}

					{popularPages?.second && (
						<Cell desktop={3} span={12}>
							<FooterHeader>{popularPages.second.heading}</FooterHeader>
							<FooterLinkList>
								{popularPages.second.links.map((link, index) => {
									return (
										<React.Fragment key={index}>
											<li key={index}>
												<Link href={link.href} target={link.target}>
													{link.text}
												</Link>
											</li>
										</React.Fragment>
									);
								})}
							</FooterLinkList>
						</Cell>
					)}
				</Grid>
				<PageFooterBottom>
					<Grid padding={false} className="px-4 lg:px-6">
						<Cell span={12}>
							<ContentWrapper>
								<div className="mb-2 lg:mb-0">
									{quickLinksFooter &&
										quickLinksFooter.quickLinks.map((link, index) => (
											<Link
												className="text-p footer__linklist footer__dl text-teal footer__link"
												href={link.href}
												key={index}
												target={link.target}
											>
												{link.text}
											</Link>
										))}
								</div>
								<BottomContent>
									{contact &&
										contact.social.map((item, index) => (
											<a href={item.value} title={item.name} key={index}>
												<Icon
													icon={item.type as iconTypes}
													aria-label={item.name}
													size={4}
													role="img"
													aria-hidden="true"
													className="px-1 py-2 border-2 rounded-full text-teal text-h2 bg-greySemiLight border-greySemiLight hover:bg-teal hover:border-teal hover:text-white"
												/>
											</a>
										))}
								</BottomContent>
							</ContentWrapper>
						</Cell>
					</Grid>
				</PageFooterBottom>
			</PageFooter>
			{/* @ts-ignore */}
			<kundo-lazy-button
				text="Chatta med oss"
				script="https://static-chat.kundo.se/chat-js/org/909/widget.js"
				/* @ts-ignore */
			></kundo-lazy-button>
		</div>
	);
};

export default BaseLayout;
