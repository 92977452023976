/**
 * StatisticServiceArticleListingPage
 */

import React, { useEffect, useState } from 'react';
import { useMachine } from '@xstate/react';
import {
	Grid,
	Cell,
	Button,
	Icon,
	NewsList,
	NewsListItem,
} from 'ui-component-library/base';
import { StatisticServiceArticleListingPageModel, NewsModel } from 'types';
import BaseLayout from 'layouts/BaseLayout';
import Text from 'components/Text';
import { showMorePaginationMachine } from 'state-machines/showMorePagination.machine';
import { useHistory, useLocation } from 'react-router-dom';
import EpiContentArea from 'components/EpiContentArea';
import Link from 'components/Link';

/** StatisticServiceArticleListingPage component. */
const StatisticServiceArticleListingPage: React.FC<StatisticServiceArticleListingPageModel> =
	({
		heading,
		preamble,
		showMoreButtonText,
		numberOfHitsPerPage,
		apiUrl,
		listingModel,
		contentArea,
	}) => {
		const location = useLocation();
		const history = useHistory();
		const [state, send] = useMachine(showMorePaginationMachine);
		const [click, setClick] = useState(0);

		if (state.context.result) {
			listingModel = state.context.result;
		}

		const fetch =
			listingModel.offset + listingModel.fetch + numberOfHitsPerPage;
		const hasMore =
			listingModel.offset + listingModel.fetch < listingModel.total;
		const sortOrder = listingModel.sortOrder;

		useEffect(() => {
			if (click > 0) {
				send('FETCH', {
					offset: 0,
					fetch: fetch,
					apiUrl,
					sortOrder,
				});
			}

			// eslint-disable-next-line
		}, [click]);

		return (
			<BaseLayout themeBorder={true}>
				<Grid
					padding={false}
					margin={false}
					className="lg:px-6 px-4 -mt-4 md:mt-0"
				>
					<Cell span={12} desktop={7}>
						<Text as="h1" prop="heading">
							{heading}
						</Text>
						<Text as="preamble" prop="preamble">
							{preamble}
						</Text>
					</Cell>
				</Grid>
				<Grid padding={false} margin={false} className="lg:px-6 px-4 mb-20">
					<Cell span={12} desktop={7} className="mb-10">
						<NewsList>
							{listingModel.items.map((event: NewsModel, index: number) => {
								return (
									<NewsListItem
										date={event.published}
										heading={event.heading}
										text={event.text}
										url={event.url}
										LinkComponent={Link}
										key={index}
									/>
								);
							})}
						</NewsList>
						{hasMore && (
							<div className="flex justify-around mt-8">
								<Button
									data-button-action={showMoreButtonText}
									className="my-auto"
									variant="primary"
									disabled={state.value === 'loading'}
									onClick={() => {
										setClick(click + 1);
										history.push(
											`${location.pathname}?fetch=${fetch}&sortOrder=${sortOrder}`
										);
									}}
								>
									{showMoreButtonText}
									{state.value === 'loading' && (
										<Icon
											icon="loader"
											color="white"
											animate="spin"
											size={0.75}
											className="ml-2"
											aria-hidden={true}
										/>
									)}
									{state.value !== 'loading' && (
										<Icon
											icon="chevron"
											direction="up"
											color="white"
											size={0.75}
											className="ml-2"
											aria-hidden={true}
										/>
									)}
								</Button>
							</div>
						)}
					</Cell>
					{contentArea && contentArea.length > 0 && (
						<Cell span={12} desktop={4} className="lg:ml-1/12">
							<EpiContentArea content={contentArea} />
						</Cell>
					)}
				</Grid>
			</BaseLayout>
		);
	};

export default StatisticServiceArticleListingPage;
