/**
 * CalendarEventListingPage
 */

import React, { useEffect, useState } from 'react';
import { useMachine } from '@xstate/react';
import {
	Grid,
	Cell,
	Button,
	Icon,
	CalendarEventList,
	CalendarEventListItem,
	FilterButton,
	FilterButtonGroup,
} from 'ui-component-library/base';
import {
	CalendarEventListingPageModel,
	CalendarEventModel,
	FilterButtonGroupAggregationsBucketModel,
} from 'types';
import BaseLayout from 'layouts/BaseLayout';
import Text from 'components/Text';
import { showMorePaginationMachine } from 'state-machines/showMorePagination.machine';
import { useHistory, useLocation } from 'react-router-dom';
import EpiContentArea from 'components/EpiContentArea';
import Link from 'components/Link';

/** CalendarEventListingPage component. */
const CalendarEventListingPage: React.FC<CalendarEventListingPageModel> = ({
	heading,
	preamble,
	showMoreButtonText,
	numberOfHitsPerPage,
	apiUrl,
	listingModel,
	contentArea,
}) => {
	const location = useLocation();
	const history = useHistory();

	const [state, send] = useMachine(showMorePaginationMachine);
	const [click, setClick] = useState(0);
	const [tabSelection, setTabSelection] = useState('');
	const [fetch, setFetch] = useState(numberOfHitsPerPage);

	if (state.context.result) {
		listingModel = state.context.result;
	}

	const showMoreFetch =
		listingModel.offset + listingModel.fetch + numberOfHitsPerPage;
	const hasMore = listingModel.offset + listingModel.fetch < listingModel.total;
	const sortOrder = listingModel.sortOrder;
	const tabs = listingModel.aggregations.find(
		(x) => x.path === 'calendarEvent.state'
	);

	useEffect(() => {
		if (click > 0 || tabSelection.length > 0) {
			send('FETCH', {
				offset: 0,
				fetch: fetch,
				sortOrder,
				apiUrl,
				filter: tabSelection,
			});
		}
		// eslint-disable-next-line
	}, [click, tabSelection]);

	return (
		<BaseLayout>
			<Grid
				padding={false}
				margin={false}
				className="lg:px-6 px-4 -mt-4 md:mt-0"
			>
				<Cell span={12} desktop={7}>
					<Text as="h1" prop="heading">
						{heading}
					</Text>
					<Text as="preamble" prop="preamble">
						{preamble}
					</Text>
				</Cell>
			</Grid>
			<Grid padding={false} margin={false} className="lg:px-6 px-4 mb-20">
				<Cell span={12} desktop={7} className="mb-8 lg:mb-0">
					<FilterButtonGroup>
						{tabs && (
							<>
								{tabs.buckets.map(
									(
										item: FilterButtonGroupAggregationsBucketModel,
										index: number
									) => {
										return (
											<FilterButton
												isActive={
													item.selected === true ? item.selected : false
												}
												text={item.name}
												key={index}
												onClick={(e: any) => {
													setTabSelection(`${tabs.path}=${item.term}`);
													setFetch(numberOfHitsPerPage);
													history.push(
														`${location.pathname}?fetch=${fetch}&${tabs.path}=${item.term}`
													);
												}}
											/>
										);
									}
								)}
							</>
						)}
					</FilterButtonGroup>
					<CalendarEventList>
						{listingModel.calendarEvents.map(
							(event: CalendarEventModel, index: number) => {
								return (
									<CalendarEventListItem
										startDay={event.start.day}
										startMonth={event.start.monthNameShort}
										endDay={event.end.day}
										endMonth={event.end.monthNameShort}
										heading={event.heading}
										category={event.category}
										text={event.text}
										url={event.url}
										hasEndDate={listingModel.hasMultiDayEvents}
										LinkComponent={Link}
										key={index}
									/>
								);
							}
						)}
					</CalendarEventList>

					{hasMore && (
						<div className="flex justify-around mt-6">
							<Button
								data-button-action={showMoreButtonText}
								className="my-auto"
								variant="primary"
								disabled={state.value === 'loading'}
								onClick={() => {
									setClick(click + 1);
									setFetch(showMoreFetch);
									history.push(
										`${location.pathname}?fetch=${fetch}&sortOrder=${sortOrder}`
									);
								}}
							>
								{`${showMoreButtonText} `}
								{state.value === 'loading' && (
									<Icon
										icon="loader"
										color="white"
										animate="spin"
										size={0.75}
										className="ml-2"
										aria-hidden={true}
									/>
								)}
								{state.value !== 'loading' && (
									<Icon
										icon="chevron"
										direction="up"
										color="white"
										size={0.75}
										className="ml-2"
										aria-hidden={true}
									/>
								)}
							</Button>
						</div>
					)}
				</Cell>
				{contentArea && contentArea.length > 0 && (
					<Cell span={12} desktop={4} className="lg:ml-1/12 mt-8">
						<EpiContentArea content={contentArea} />
					</Cell>
				)}
			</Grid>
		</BaseLayout>
	);
};

export default CalendarEventListingPage;
