import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
  LabelList,
  ResponsiveContainer,
} from "recharts";

const ResultDiagram = ({ data }) => {
  const TotaltIndivid = parseInt(data.totaltIndivid.replace(/,/g, ""), 10);
  const TotaltKommun = parseInt(data.totaltKommun.replace(/,/g, ""), 10);
  const TotaltRegion = parseInt(data.totaltRegion.replace(/,/g, ""), 10);
  const TotaltStat = parseInt(data.totaltStat.replace(/,/g, ""), 10);
  const TotaltArbetsgivare = parseInt(
    data.totaltArbetsgivare.replace(/,/g, ""),
    10
  );

  const samhallet =
    TotaltIndivid +
    TotaltKommun +
    TotaltRegion +
    TotaltStat +
    TotaltArbetsgivare;

  const ResultData = [
    {
      name: "Individ",
      value: Math.round(TotaltIndivid),
    },
    {
      name: "Kommun",
      value: Math.round(TotaltKommun),
    },
    {
      name: "Region",
      value: Math.round(TotaltRegion),
    },
    {
      name: "Stat",
      value: Math.round(TotaltStat),
    },
    {
      name: "Arbetsgivare",
      value: Math.round(TotaltArbetsgivare),
    },
    {
      name: "Samhället",
      value: Math.round(samhallet),
    },
  ];

  return (
    <div style={{ width: "100%", height: 400 }}>
      <ResponsiveContainer>
        <BarChart
          data={ResultData}
          barSize={50}
          barGap={0}
          margin={{
            top: 50,
            left: 10,
            bottom: 50,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" interval={0} angle={30} dy={15} />
          <YAxis
            padding={{ top: 50, bottom: 50 }}
            label={{ value: "Totalt (Tkr)", position: "top", offset: 20 }}
          />
          <ReferenceLine y={0} stroke="#000" />
          <Bar dataKey="value" fill="#B86EB7">
            <LabelList dataKey="value" position="top" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ResultDiagram;
