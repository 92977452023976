/**
 * RiskAnalysisServicePage
 */

import React, { useRef } from 'react';
import {
	Grid,
	Cell,
	Toc,
	TocLink,
	IntroBlock,
	Icon,
	SearchField,
	IntroBlockArrow,
} from 'ui-component-library/base';
import { RiskAnalysisServicePageModel, SearchListItemTypes } from 'types';
import BaseLayout from 'layouts/BaseLayout';
import Text from 'components/Text';
import clsx from 'clsx';
import { CategoryList, CategoryListItem } from 'ui-component-library/uhmse';
import EpiFragments from 'components/EpiFragments';
import EpiContentArea from 'components/EpiContentArea';
import { useIsDesktop, useIsTabletOrLarger } from 'hooks/useMediaQuery';
import {
	SearchFieldDropdown,
	SearchFieldDropdownItem,
} from 'ui-component-library/base/SearchField';
import Link from 'components/Link';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectSearch } from 'store/modules/model';
import queryString from 'query-string';
import { useMachine } from '@xstate/react';
import { searchMachine } from 'state-machines/search.machine';
import { searchFieldMachine } from 'state-machines/searchField.machine';
import { useOutsideClick } from 'ui-component-library/hooks';

/** RiskAnalysisServicePage component. */
const RiskAnalysisServicePage: React.FC<RiskAnalysisServicePageModel> = ({
	introBlock,
	heading,
	preamble,
	tableOfContents,
	categorySection,
	text,
	contentArea,
}) => {
	const isDesktop = useIsDesktop();
	const isTabletOrLarger = useIsTabletOrLarger();
	const location = useLocation();
	const history = useHistory();
	const searchSettings = useSelector(selectSearch);
	const { query } = queryString.parse(location.search);
	const searchfieldWrapperRef = useRef<any>(null);
	const searchfieldInputRef = useRef<any>(null);
	const [stateSearch, sendSearch] = useMachine(searchMachine, {
		context: {
			apiUrl: introBlock?.apiUrl || searchSettings?.apiUrl,
			size: isTabletOrLarger ? 5 : searchSettings?.numberOfHitsPerPage || 5,
			debounce: 500,
		},
	});
	const [stateField, sendField] = useMachine(searchFieldMachine);

	useOutsideClick(searchfieldWrapperRef, () => {
		if (searchSettings) {
			sendField('CLOSE');
		}
	});

	const renderCategoryList = (category: any) => {
		return (
			<CategoryList
				header={category.heading}
				open={category.isOpen}
				openCategoryLabel={categorySection.openCategoryLabel}
				closeCategoryLabel={categorySection.closeCategoryLabel}
				className="mb-8"
			>
				{category.items &&
					category.items.length > 0 &&
					category.items.map((item: any, index: number) => (
						<CategoryListItem key={index} text={item.text} url={item.url} />
					))}
			</CategoryList>
		);
	};

	return (
		<BaseLayout>
			{introBlock && (
				<div className="-mt-10 md:-mt-8">
					<IntroBlock>
						<Grid padding={false} className="lg:pl-6 pl-4">
							<Cell span={12} desktop={7}>
								{introBlock?.heading && (
									<Text as="h1" margin={false} className="relative mb-2">
										{introBlock.icon && (
											<Icon
												icon={introBlock.icon}
												size={3}
												className="absolute top-0 left-0 mt-1 -ml-12 hidden xl:block"
												aria-hidden={true}
											/>
										)}
										{introBlock?.heading}
									</Text>
								)}
								{introBlock?.preamble && (
									<Text as="p" margin={false}>
										{introBlock?.preamble}
									</Text>
								)}
								{introBlock?.hasSearch && (
									<>
										<SearchField
											id="searchfieldIntroBlock"
											label={introBlock.placeholderSearchText}
											submitLabel={introBlock.performSearchText}
											className="text-black mt-6"
											wrapperRef={searchfieldWrapperRef}
											fieldRef={searchfieldInputRef}
											showSpinner={stateSearch.value === 'loading'}
											defaultValue={query ? query.toString() : undefined}
											onChange={(e: any) => {
												stateField.value === 'active' &&
													sendSearch('SEARCH', {
														value: e.value,
													});
											}}
											onSubmit={(e: any) => {
												sendField('CLOSE');
												searchfieldInputRef?.current?.blur();
												history.push(
													`${
														introBlock.searchPageUrl
													}?query=${encodeURIComponent(
														e.searchfieldIntroBlock
													)}`
												);
											}}
											onFocus={() => {
												setTimeout(() => {
													sendField('OPEN');
												}, 200);
											}}
										>
											{stateField.value === 'active' &&
												stateSearch.context.result &&
												stateSearch.context.query !== '' && (
													<SearchFieldDropdown>
														{stateSearch.context.result.results &&
															stateSearch.context.result.results.map(
																(item: SearchListItemTypes) => (
																	<SearchFieldDropdownItem
																		key={item.documentId}
																	>
																		<Link to={item.url}>
																			<Icon
																				icon="chevron"
																				direction="left"
																				aria-hidden={true}
																				size={0.75}
																			/>
																			{item.heading}
																		</Link>
																	</SearchFieldDropdownItem>
																)
															)}
														{stateSearch.context.result.numberOfHits >
															stateSearch.context.result.size && (
															<SearchFieldDropdownItem>
																<Link
																	to={`${
																		introBlock?.searchPageUrl
																	}?query=${encodeURIComponent(
																		stateSearch.context.result.query
																	)}`}
																	onClick={() => {
																		sendField('CLOSE');
																	}}
																>
																	{searchSettings?.showMoreResultsText}
																</Link>
															</SearchFieldDropdownItem>
														)}
														{stateSearch.context.result.numberOfHits === 0 && (
															<SearchFieldDropdownItem>
																<p>Inga träffar hittades</p>
															</SearchFieldDropdownItem>
														)}
													</SearchFieldDropdown>
												)}
										</SearchField>
										<IntroBlockArrow />
									</>
								)}
							</Cell>
						</Grid>
					</IntroBlock>
				</div>
			)}
			{!introBlock && (
				<Grid
					padding={false}
					margin={false}
					className="lg:px-6 px-4 -mt-4 md:mt-0"
				>
					<Cell span={12} desktop={7}>
						<Text as="h1" prop="heading">
							{heading}
						</Text>
					</Cell>
				</Grid>
			)}
			<Grid
				margin={false}
				padding={false}
				className={clsx(
					tableOfContents && 'flex-row-reverse',
					introBlock && 'lg:mt-14',
					'lg:px-6 px-4'
				)}
			>
				{isDesktop && tableOfContents && (
					<Cell span={4} className="ml-1/12 pl-8">
						<Toc
							heading={tableOfContents.heading}
							items={tableOfContents.chapters}
							sticky={tableOfContents.isSticky}
						>
							{tableOfContents.titles.map((item, index) => (
								<TocLink href={`#${item.targetId}`} key={index}>
									{item.text}
								</TocLink>
							))}
						</Toc>
					</Cell>
				)}

				<Cell span={12} desktop={7}>
					{!introBlock && (
						<div className="mb-8">
							<Text as="preamble" prop="preamble">
								{preamble}
							</Text>
						</div>
					)}
					{!isDesktop && tableOfContents && (
						<Toc heading={tableOfContents.heading} items={[]} className="my-10">
							{tableOfContents.titles.map((item, index) => (
								<TocLink href={`#${item.targetId}`} key={index}>
									{item.text}
								</TocLink>
							))}
						</Toc>
					)}

					{categorySection &&
						categorySection.categories &&
						categorySection.categories.length > 0 && (
							<div id={categorySection.id}>
								<Grid padding={false}>
									<Cell span={12} tablet={6}>
										{categorySection.categories.map(
											(category: any, index: number) => {
												return (
													<React.Fragment key={index}>
														{index <
															Math.ceil(
																categorySection.categories.length / 2
															) && renderCategoryList(category)}
													</React.Fragment>
												);
											}
										)}
									</Cell>
									{categorySection.categories.length !== 1 && (
										<Cell span={12} tablet={6}>
											{categorySection.categories.map(
												(category: any, index: number) => {
													return (
														<React.Fragment key={index}>
															{index >=
																Math.ceil(
																	categorySection.categories.length / 2
																) && renderCategoryList(category)}
														</React.Fragment>
													);
												}
											)}
										</Cell>
									)}
								</Grid>
							</div>
						)}
					<EpiFragments fragments={text.fragments} />
				</Cell>
			</Grid>
			{contentArea && contentArea.length > 0 && (
				<EpiContentArea content={contentArea} />
			)}
		</BaseLayout>
	);
};

export default RiskAnalysisServicePage;
