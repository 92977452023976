import {
	ResponseResolver,
	RestRequest,
	DefaultRequestBody,
	RequestParams,
	RestContext,
} from 'msw';
import areas from './areas.json';
import products from './products.json';
import units from './units.json';
import distributedUnits from './distributed-units.json';
import cpvs from './cpvs.json';
import deliveryAreas from './delivery-areas.json';
import time from './time.json';
import parameters from './parameters-v2.json';
import filters from './filters.json';
import measurements from './measurements.json';
import result from './result.json';
import resultTable from './result-table.json';
import resultTableMatrix from './result-matrix-table.json';
import resultTableMatrix2 from './result-matrix-table-2.json';
import resultChart from './result-chart-bar-column.json';
import resultChartLine from './result-chart-line.json';
import resultChartPie from './result-chart-pie.json';
import variables from './variables.json';
import {
	DataModel,
	OptionDataFiltersGroup,
} from 'state-machines/statisticService.machine';
import { sortTable } from './helpers/sortTable';
import { paginateTable } from './helpers/paginateTable';

export const getMockStatistics: ResponseResolver<
	RestRequest<DefaultRequestBody, RequestParams>,
	RestContext,
	any
> = (req, res, ctx) => {
	let data: DataModel = {
		areas: null,
		products: null,
		units: null,
		distributedUnits: null,
		time: null,
		parameters: null,
		filters: null,
		searchFilters: null,
		measurements: null,
		variables: null,
		result: null,
		resultError: null,
		lastUpdated: '2022-02-18',
	};
	const area = req.url.searchParams.get('area');
	const product = req.url.searchParams.get('product');
	const unit = req.url.searchParams.get('unit');
	const distributedUnit = req.url.searchParams.get('distributedUnit');
	const years = req.url.searchParams.getAll('Tidsperiod.År');
	const params = req.url.searchParams.getAll('param');
	const filter = req.url.searchParams.getAll('geography');
	const resultFormat = req.url.searchParams.get('resultFormat');
	const chartType = req.url.searchParams.get('chartType');
	const chartStacked = req.url.searchParams.get('chartStacked');
	const order = req.url.searchParams.get('orderBy');
	const variable = req.url.searchParams.get('variable');
	const numToFetchString = req.url.searchParams.get('fetch');
	let numToFetch: any;
	const orderBy = order?.split('_')[0] || undefined;
	const orderDirection = order?.split('_')[1] || undefined;

	const getResult = () => {
		const resultData: any = JSON.parse(JSON.stringify(result));
		const resultTableData: any = paginateTable(
			sortTable(
				JSON.parse(JSON.stringify(resultTable)),
				orderBy,
				orderDirection
			),
			numToFetch
		);
		const resultTableMatrixData: any = paginateTable(
			sortTable(
				JSON.parse(
					JSON.stringify(
						params.indexOf('Köpare.Namn för köpare') !== -1
							? resultTableMatrix2
							: resultTableMatrix
					)
				),
				orderBy,
				orderDirection
			),
			numToFetch
		);
		const resultChartData: any = JSON.parse(JSON.stringify(resultChart));
		const resultChartLineData: any = JSON.parse(
			JSON.stringify(resultChartLine)
		);
		const resultChartPieData: any = JSON.parse(JSON.stringify(resultChartPie));
		const paramData: any = JSON.parse(JSON.stringify(parameters));
		if (years.length > 0) {
			resultData.data = resultData.data.filter((item: any) => {
				return years.indexOf(item.CalendarYear.toString()) !== -1;
			});
		}

		if (params.indexOf('whereAndWhy.cpv') !== -1) {
			paramData.whereAndWhy.otherProductClassifications.map((item: any) => {
				item.disabled = true;
			});
		}

		// Parameters
		if (
			distributedUnit === 'antal-upphandlingar-bas' ||
			(unit && !distributedUnit)
		) {
			data.parameters = paramData;
		} else if (distributedUnit === 'antal-upphandlingar-cpv') {
			const paramData: any = JSON.parse(JSON.stringify(cpvs));
			data.parameters = paramData;
		} else if (distributedUnit === 'antal-upphandlingar-leveransort') {
			const paramData: any = JSON.parse(JSON.stringify(deliveryAreas));
			data.parameters = paramData;
		}

		data.time = time;
		data.measurements = measurements;
		data.variables = variables;

		if (params.indexOf('Förfarande, metoder och krav.Direktivstyrd') === 0) {
			data.filters = filters as OptionDataFiltersGroup[];
			data.filters[0].selectedBuckets = filter.length; // Geography
		}

		if (resultFormat === 'table') {
			data.result = { ...resultTableData };
		} else if (resultFormat === 'matrixtable') {
			data.result = { ...resultTableMatrixData };
		} else if (resultFormat === 'chart') {
			// Return an error message if selected params is more than 5
			if (params.length > 5) {
				data.result = null;
				data.resultError = {
					statusCode: 404,
					message:
						'Max antal val under “Fler variabler” är fem. Minska dina val för att visa diagram.',
				};
			} else if (chartType === 'line') {
				data.result = { ...resultChartLineData, chartType };
			} else if (chartType === 'pie') {
				data.result = { ...resultChartPieData, chartType };
			} else {
				data.result = {
					...resultChartData,
					chartType,
					stacked: chartStacked === 'true' ? true : false,
				};
			}
		} else {
			data.result = resultData;
		}
	};

	if (numToFetchString) {
		numToFetch = parseInt(numToFetchString, 10);
	}

	// Handle areas
	if (area) {
		// areas.map((a) => (a.selected = a.value === area));
		data.products = products;
	}
	data.areas = areas;

	// Handlle product
	if (product) {
		// products.map((p) => (p.selected = p.value === product));
		data.units = units;
	}

	// Handlle unit
	if (unit) {
		// unit.map((p) => (p.selected = p.value === unit));
		if (
			unit === 'antal-upphandlingar' ||
			unit === 'uppskattat-varde' ||
			unit === 'kontrakterat-varde'
		) {
			data.distributedUnits = distributedUnits;
		} else if (unit === 'annonserat-varde') {
			return res(ctx.status(404), ctx.body(''));
		} else {
			getResult();
		}
	}

	// Handlle distributedUnit
	if (distributedUnit) {
		if (distributedUnit === 'antal-upphandlingar-cpv') {
			const paramData: any = JSON.parse(JSON.stringify(cpvs));
			data.parameters = paramData;
			if (params.length > 0) {
				getResult();
			}
		} else if (distributedUnit === 'antal-upphandlingar-leveransort') {
			const paramData: any = JSON.parse(JSON.stringify(deliveryAreas));
			data.parameters = paramData;
			if (params.length > 0) {
				getResult();
			}
		} else {
			getResult();
		}
	}

	// Return an error message if the request url is longer than 700 characters
	if (req.url.href.length > 700) {
		data.result = null;
		data.resultError = {
			statusCode: 404,
			message: 'Minska dina urval eller din filtrering för att visa diagram.',
		};
	}

	const delay =
		area === 'ovrigt' ||
		resultFormat === 'chart' ||
		resultFormat === 'matrixtable' ||
		params.length > 0
			? 2000
			: 200;
	// Demo 500 error
	if (product === 'annonserade-valfrihetssystem') {
		return res(
			ctx.delay(delay),
			ctx.status(500),
			ctx.json({ error: 'Generic server error' })
		);
	}

	return res(ctx.delay(delay), ctx.status(200), ctx.json(data));
};
