/**
 * StatisticsDataBlock
 */

import StatisticTable from 'components/StatisticTable';
import Text from 'components/Text';
import Highcharts from 'components/Highcharts';
import React, { useEffect, useRef, useState } from 'react';
import {
	StatisticsDataDiagramModel,
	StatisticsDataExportOptionModel,
	StatisticsDataTableModel,
	StatisticTableModel,
} from 'types';
import { FilterButton, FilterButtonGroup } from 'ui-component-library/base';
import { useMachine } from '@xstate/react';
import { showMorePaginationMachine } from 'state-machines/showMorePagination.machine';
import ExportDataDropdown from 'components/ExportDataDropdown';
import ExportDataOption from 'components/ExportDataDropdown/ExportDataOption';

interface Props {
	/** Heading for the block */
	heading: string;

	/** Properties and data for the table */
	tableData?: StatisticsDataTableModel;

	/** Properties and data for highcharts */
	diagramData?: StatisticsDataDiagramModel;

	/** Pass a unique id for the tabs */
	id: number | string;

	/** Pass a optional className */
	className?: string;
}

/** Main description for this component. */
const StatisticsDataBlock: React.FC<Props> = ({
	heading,
	tableData,
	diagramData,
	id,
	className,
}) => {
	const [state, send] = useMachine(showMorePaginationMachine);
	const chartRef = useRef<any>(null);
	const [activeTab, setActiveTab] = useState(
		(tableData?.isActive && `table-tab-${id}`) ||
			(diagramData?.isActive && `diagram-tab-${id}`)
	);
	const [tableProps, setTableProps] = useState<StatisticTableModel | null>(
		null
	);

	let totalFetch = 0;
	if (tableProps && tableData) {
		totalFetch =
			tableProps.offset + tableProps.fetch + tableData.numberOfHitsPerPage;
	}

	useEffect(() => {
		setTableProps(state.context.result);
	}, [state.context.result]);

	useEffect(() => {
		if (tableData) setTableProps(tableData.tableProperties);
	}, []);

	return (
		<div className={className} data-cid="StatisticsDataBlock">
			<div className="flex justify-between items-baseline mb-8">
				<Text as="h3">{heading}</Text>
			</div>
			{(diagramData || tableData) && (
				<FilterButtonGroup>
					{tableData && (
						<FilterButton
							text={tableData.tabName}
							id={`table-tab-${id}`}
							aria-controls={`table-tab-${id}-content`}
							aria-selected={true}
							isActive={activeTab === `table-tab-${id}`}
							onClick={() => {
								setActiveTab(`table-tab-${id}`);
							}}
						>
							{tableData.tabName}
						</FilterButton>
					)}
					{diagramData && (
						<FilterButton
							text={diagramData.tabName}
							id={`diagram-tab-${id}`}
							aria-controls={`diagram-tab-${id}-content`}
							aria-selected={true}
							isActive={activeTab === `diagram-tab-${id}`}
							onClick={() => {
								setActiveTab(`diagram-tab-${id}`);
							}}
						>
							{diagramData.tabName}
						</FilterButton>
					)}
				</FilterButtonGroup>
			)}
			<div role="tabpanel" id={`${activeTab}-content`} className="mt-4">
				{tableData && activeTab === `table-tab-${id}` && tableProps && (
					<>
						{tableData.exports && (
							<div className="flex flex-row-reverse mb-4">
								<ExportDataDropdown
									buttonText={tableData.exports.placeholder}
									dataTitle={tableProps.title}
								>
									{tableData.exports.options.map(
										(
											option: StatisticsDataExportOptionModel,
											index: number
										) => {
											return (
												<ExportDataOption
													type={option.type}
													url={option.url}
													chartRef={chartRef}
													key={index}
												>
													{option.name}
												</ExportDataOption>
											);
										}
									)}
								</ExportDataDropdown>
							</div>
						)}
						<StatisticTable
							lastUpdated={tableData.lastUpdated}
							source={tableData.source}
							data={tableProps}
							matrix={tableProps.matrix}
							showMoreButtonText={tableData.showMoreButtonText}
							enablePagination={tableProps.total > totalFetch}
							paginationCallback={() => {
								send('FETCH', {
									offset: 0,
									fetch: totalFetch,
									apiUrl: tableData.apiUrl,
								});
							}}
						/>
					</>
				)}
				{diagramData && activeTab === `diagram-tab-${id}` && (
					<>
						{diagramData.exports && (
							<div className="flex flex-row-reverse mb-4">
								<ExportDataDropdown
									buttonText={diagramData.exports.placeholder}
									dataTitle={diagramData.chartProperties.title}
								>
									{diagramData.exports.options.map(
										(
											option: StatisticsDataExportOptionModel,
											index: number
										) => {
											return (
												<ExportDataOption
													type={option.type}
													url={option.url}
													chartRef={chartRef}
													key={index}
												>
													{option.name}
												</ExportDataOption>
											);
										}
									)}
								</ExportDataDropdown>
							</div>
						)}
						<Highcharts
							chartType={diagramData.chartProperties.chartType}
							stacked={diagramData.chartProperties.stacked}
							title={diagramData.chartProperties.title}
							subtitle={diagramData.chartProperties.subtitle}
							tooltipValueDecimals={
								diagramData.chartProperties.tooltipValueDecimals
							}
							tooltipValuePrefix={
								diagramData.chartProperties.tooltipValuePrefix
							}
							tooltipValueSuffix={
								diagramData.chartProperties.tooltipValueSuffix
							}
							yAxisTitle={diagramData.chartProperties.yAxisTitle}
							yAxisLabelFormat={diagramData.chartProperties.yAxisLabelFormat}
							yAxisMin={diagramData.chartProperties.yAxisMin}
							yAxisMax={diagramData.chartProperties.yAxisMax}
							xAxisTitle={diagramData.chartProperties.xAxisTitle}
							xAxisCategories={diagramData.chartProperties.xAxisCategories}
							xAxisLabelFormat={diagramData.chartProperties.xAxisLabelFormat}
							showLegend={diagramData.chartProperties.showLegend}
							series={diagramData.chartProperties.series}
							lastUpdated={diagramData.lastUpdated}
							chartRef={chartRef}
							source={diagramData.source}
						/>
					</>
				)}
			</div>
		</div>
	);
};

export default StatisticsDataBlock;
