/**
 * RiskTable
 */

import clsx from 'clsx';
import React from 'react';
import { AssessmentModel } from 'types';
import HighBouble from './svg/high.svg';
import LowBouble from './svg/low.svg';
import MiddleToHighBouble from './svg/middleToHigh.svg';
import MiddleToLowBouble from './svg/middleToLow.svg';
import MiddleBouble from './svg/middle.svg';
import NoneBouble from './svg/none.svg';
import RiskTableLarge from './RiskTableLarge';
import RiskTableSmall from './RiskTableSmall';

interface Props {
	/** Table variant */
	variant?: string;

	/** Table caption*/
	caption: string;

	/** Table row heading*/
	rowHeading?: string;

	/** Table column heading*/
	columnHeading: string;

	/** Array with cell data*/
	assessments: AssessmentModel[];

	/** Pass optional classnames for the html */
	className?: string;
}

type RowHeader = {
	targetId: string;
	riskAreaName: string;
};

type ColumnHeader = {
	step: number;
	stepName: string;
};

export const getAssessmentIcon = (level?: string) => {
	const levelString = level?.toLowerCase();
	switch (levelString) {
		case 'low':
			return LowBouble;
		case 'middlelow':
			return MiddleToLowBouble;
		case 'medium':
			return MiddleBouble;
		case 'middlehigh':
			return MiddleToHighBouble;
		case 'high':
			return HighBouble;
		default:
			return NoneBouble;
	}
};

/** Main description for this component. */
const RiskTable: React.FC<Props> = ({
	variant,
	caption,
	rowHeading,
	columnHeading,
	assessments,
	className,
}) => {
	let rowHeaders: RowHeader[] = [];
	let columnHeaders: ColumnHeader[] = [];
	let dataMatrix: any = {};

	assessments.forEach((assessment) => {
		if (
			variant !== 'small' &&
			assessment.targetId &&
			assessment.riskAreaName &&
			!rowHeaders.some((item) => item.targetId === assessment.targetId)
		) {
			rowHeaders.push({
				targetId: assessment.targetId,
				riskAreaName: assessment.riskAreaName,
			});
		}

		if (!columnHeaders.some((item) => item.step === assessment.step)) {
			columnHeaders.push({
				step: assessment.step,
				stepName: assessment.stepName,
			});
		}

		if (
			variant !== 'small' &&
			assessment.targetId &&
			!(assessment.targetId in dataMatrix)
		) {
			dataMatrix[assessment.targetId] = {};
		}

		if (variant !== 'small' && assessment.targetId) {
			dataMatrix[assessment.targetId][assessment.step] = assessment;
		} else {
			dataMatrix[assessment.step] = assessment;
		}
	});

	columnHeaders.sort((a, b) => a.step - b.step);

	return (
		<div className={clsx(className, '-mx-6')}>
			<div className="overflow-y-auto w-full">
				{variant !== 'small' && rowHeading && (
					<RiskTableLarge
						caption={caption}
						rowHeading={rowHeading}
						columnHeading={columnHeading}
						dataMatrix={dataMatrix}
						rowHeaders={rowHeaders}
						columnHeaders={columnHeaders}
						getAssessmentIcon={getAssessmentIcon}
					/>
				)}
				{variant === 'small' && (
					<RiskTableSmall
						caption={caption}
						columnHeading={columnHeading}
						dataMatrix={dataMatrix}
						columnHeaders={columnHeaders}
						getAssessmentIcon={getAssessmentIcon}
					/>
				)}
			</div>
		</div>
	);
};

export default RiskTable;
