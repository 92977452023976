/**
 * Text
 */

import React from 'react';
import TextUi, { TextProps } from 'ui-component-library/base/Text';
import { useSelector } from 'react-redux';
import { selectEpi } from 'store/modules/epi';

interface Props extends TextProps {
	/** Epi property to use when edit the text */
	prop?: string;
}

/** Wrapper for the Text component for handeling Episerver on page editor. */
const Text: React.FC<Props> = ({ prop, ...allProps }) => {
	const epi = useSelector(selectEpi);

	if (epi?.beta?.isEditable && prop) {
		allProps['data-epi-property-name'] = prop;
		allProps['data-epi-property-render'] = 'none';

		if (!allProps.children && !allProps.text) {
			allProps.children = ' ';
		}
	}

	return React.createElement(TextUi, allProps);
};

export default Text;
