/**
 * SearchPage
 */

import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { useMachine } from '@xstate/react';
import {
	SearchResultList,
	SearchResultListItem,
} from 'ui-component-library/uhmse';
import {
	selectSearch,
	selectMetaData,
	selectContent,
} from 'store/modules/model';
import { Grid, Cell, Button, Icon } from 'ui-component-library/base';
import {
	SearchListItemTypes,
	SearchPageModel,
	SearchResultItemModel,
} from 'types';
import BaseLayout from 'layouts/BaseLayout';
import Link from 'components/Link';
import Text from 'components/Text';
import { usePageQuery } from 'hooks';
import { searchMachine } from 'state-machines/search.machine';
import EpiContentArea from 'components/EpiContentArea';

/** SearchPage component. */
const SearchPage: React.FC<SearchPageModel> = ({ heading, contentArea }) => {
	const location = useLocation();
	const pageContent = useSelector(selectContent);
	const metaData = useSelector(selectMetaData);
	const { query } = queryString.parse(location.search);
	const page = usePageQuery();
	const [currentPage, setCurrentPage] = useState(page);
	const searchSettings = useSelector(selectSearch);
	const history = useHistory();
	const size = (searchSettings?.numberOfHitsPerPage || 10) * currentPage;

	const [state, send] = useMachine(searchMachine, {
		context: {
			apiUrl: searchSettings?.apiUrl || '',
			size,
		},
	});

	useEffect(() => {
		send('SEARCH', { value: query, size });
		if (pageContent) {
			if (window.dataLayer && window.dataLayer.push) {
				window.dataLayer.push({
					event: 'Pageview',
					pagePath: `${pageContent.url}${location.search}`,
					pageTitle: metaData?.title,
				});
			}
		}
		// eslint-disable-next-line
	}, [query, size, send]);

	useEffect(() => {
		setCurrentPage(page);
	}, [page]);

	useEffect(() => {
		if (
			state?.context?.result?.results &&
			state.context.result.results.length === 0
		) {
			if (window.dataLayer && window.dataLayer.push) {
				window.dataLayer.push({
					event: 'ZeroHitSearch',
					searchQuery: query,
				});
			}
		}
		// eslint-disable-next-line
	}, [state]);

	return (
		<BaseLayout>
			<Grid
				padding={false}
				margin={false}
				className="px-4 lg:px-6 -mt-4 pb-8 md:mt-0 md:pt-4"
			>
				<Cell span={12} desktop={7}>
					<Text as="h1" prop="heading">
						{heading}
						{state.value === 'loading' && !state.context.result && (
							<>
								{' '}
								<Icon icon="loader" animate="spin" size={3} />
							</>
						)}
					</Text>
					{state.context.result && state.context.result.results && (
						<div className="mb-8">
							<Text as="preamble" prop="preamble" id="searchtext">
								{state.context.result.numberOfHits} sökresultat för ‘
								{state.context.query}’.
							</Text>
						</div>
					)}

					{state.context.result && state.context.result.results && (
						<>
							<SearchResultList>
								{state.context.result.results.map(
									(item: SearchListItemTypes) => {
										const searchItem = item as SearchResultItemModel;
										return (
											<SearchResultListItem
												key={searchItem.documentId}
												url={searchItem.url}
												LinkComponent={Link}
												category={searchItem.breadcrumbs?.breadcrumbs
													.map((bc) => bc.text)
													.join(' / ')}
												heading={searchItem.heading}
												text={searchItem.excerpt}
											/>
										);
									}
								)}
							</SearchResultList>
							{state.context.result.numberOfHits >
								state.context.result.size && (
								<Button
									displayWidth="dynamic"
									size="small"
									variant="primary"
									disabled={state.value === 'loading'}
									centered={true}
									onClick={() => {
										history.push(
											`${location.pathname}?query=${query}&page=${
												currentPage + 1
											}`
										);
									}}
								>
									{searchSettings?.showMoreResultsText}{' '}
									{state.value === 'loading' && (
										<Icon icon="loader" color="white" animate="spin" />
									)}
									{state.value !== 'loading' && (
										<Icon icon="chevron" direction="up" color="white" />
									)}
								</Button>
							)}
						</>
					)}
				</Cell>
			</Grid>
			{contentArea && contentArea.length > 0 && (
				<EpiContentArea content={contentArea} />
			)}
		</BaseLayout>
	);
};

export default SearchPage;
