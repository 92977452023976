import { BlockTypes } from 'types';

export const getSectionColumnDesktop = (modelType: BlockTypes): number => {
	switch (modelType) {
		case 'SectionColumnSmall':
			return 4;
		case 'SectionColumnMedium':
			return 8;
		case 'SectionColumnLarge':
			return 12;
		default:
			return 12;
	}
};
export const getSectionColumnTablet = (modelType: BlockTypes): number => {
	switch (modelType) {
		case 'SectionColumnSmall':
			return 6;
		default:
			return 12;
	}
};
export const getBlockColumnDesktop = (
	blockModelType: BlockTypes,
	itemModelType: BlockTypes
): number => {
	switch (itemModelType) {
		case 'DialogsLarge':
		case 'EditorsLarge':
		case 'TextBlock':
			return 12;
		case 'DialogsMedium':
		case 'EditorsMedium':
			return 8;
		case 'Dialogs':
		case 'DialogsSmall':
		case 'Editors':
		case 'EditorsSmall':
			return 4;
	}

	switch (blockModelType) {
		case 'SectionColumnSmall':
			return 12;
		case 'SectionColumnMedium':
			return 6;
		case 'SectionColumnLarge':
			return 4;
		default:
			return 12;
	}
};

export const getBlockColumnTablet = (
	blockModelType: BlockTypes,
	itemModelType: BlockTypes
): number => {
	switch (itemModelType) {
		case 'DialogsLarge':
		case 'EditorsLarge':
		case 'TextBlock':
			return 12;
	}

	switch (blockModelType) {
		case 'SectionColumnSmall':
			return 12;
		default:
			return 6;
	}
};

export const getPuffMarginBottom = (
	blockModelType: BlockTypes,
	isMobile: boolean,
	isTablet: boolean,
	itemsLength: number,
	itemIndex: number
): string => {
	const marginBottom = 'mb-10 md:mb-16';
	const isLastItem = itemIndex === itemsLength - 1;
	const isSecondLastItem = itemIndex === itemsLength - 2;
	const isThirdLastItem = itemIndex === itemsLength - 3;

	switch (blockModelType) {
		case 'SectionColumnSmall':
			return isLastItem ? marginBottom : '';

		case 'SectionColumnMedium':
			let remainderMedium = itemsLength % 2;

			if (isMobile) {
				return isLastItem ? marginBottom : '';
			} else {
				if (remainderMedium === 0) {
					if (isSecondLastItem || isLastItem) {
						return marginBottom;
					}
				} else if (remainderMedium === 1) {
					if (isLastItem) {
						return marginBottom;
					}
				}
			}
			break;

		case 'SectionColumnLarge':
			let remainderLarge = isMobile
				? itemsLength % 1
				: isTablet
				? itemsLength % 2
				: itemsLength % 3;

			if (isMobile) {
				return isLastItem ? marginBottom : '';
			} else if (isTablet) {
				if (remainderLarge === 0) {
					if (isSecondLastItem || isLastItem) {
						return marginBottom;
					}
				} else {
					return isLastItem ? marginBottom : '';
				}
			} else {
				if (remainderLarge === 0) {
					if (isThirdLastItem || isSecondLastItem || isLastItem) {
						return marginBottom;
					}
				} else if (remainderLarge === 1) {
					if (isLastItem) {
						return marginBottom;
					}
				} else {
					if (isSecondLastItem || isLastItem) {
						return marginBottom;
					}
				}
			}
			break;
		default:
			return '';
	}
	return '';
};
