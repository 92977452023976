const HTMLElementBase =
	typeof HTMLElement === 'undefined' ? function () {} : HTMLElement;

/**
 * KundoLazyButton
 * - web component that displays a chat button,
 *   when clicked it will lazy load the Kundo scripts
 *   and starts the chat.
 * @class
 * @constructor
 * @public
 */
class KundoLazyButton extends HTMLElementBase {
	constructor() {
		super();

		/**
		 * Events to listen for to determine if Kundo has loaded and initialized.
		 * @type {string[]}
		 */
		this.kundoInitEvents = [
			'kundo-chat:chat-available',
			'kundo-chat:chat-unavailable',
			'kundo-chat:chat-closed',
		];

		/**
		 * Bind methods to this
		 */
		this.chatInitialized = this.chatInitialized.bind(this);
		this.loadKundoScript = this.loadKundoScript.bind(this);
		this.startChat = this.startChat.bind(this);
		this.initiateKundoChat = this.initiateKundoChat.bind(this);

		/**
		 * @type {ShadowRoot}
		 */
		this.shadow = this.attachShadow({ mode: 'open' });

		/**
		 * @type {string}
		 */
		this.scriptSrc = '';
	}

	/**
	 * connectedCallback
	 * Render html/css & attatch event listeners
	 */
	connectedCallback() {
		/**
		 * @type {string}
		 */
		this.text = this.getAttribute('text') || 'Chat with us';

		/**
		 * @type {string}
		 */
		this.scriptSrc = this.getAttribute('script') || '';

		// Render
		this.render();

		/**
		 * @type {HTMLDivElement}
		 */
		this.loader = /** @type {HTMLDivElement} */ (
			this.shadow.getElementById('loader')
		);

		/**
		 * @type {HTMLButtonElement | null}
		 */
		this.button = this.shadow.querySelector('button');

		/**
		 * @type {HTMLSpanElement | null}
		 */
		this.textHolder = this.shadow.getElementById('text-holder');

		// Attach event listeners
		this.button &&
			this.button.addEventListener('click', this.initiateKundoChat);
		this.kundoInitEvents.forEach((e) =>
			document.addEventListener(e, this.chatInitialized)
		);
	}

	/**
	 * disconnectedCallback
	 * Remove listeners for detect when Kundo is initialized.
	 */
	disconnectedCallback() {
		this.kundoInitEvents.forEach((e) =>
			document.removeEventListener(e, this.chatInitialized)
		);
		this.button &&
			this.button.removeEventListener('click', this.initiateKundoChat);
	}

	/**
	 * Handles the button status.
	 * @param {boolean} isLoading - Is it currently loading.
	 */
	setButtonStatus(isLoading) {
		if (!this.textHolder || !this.loader) {
			return;
		}

		if (isLoading) {
			this.textHolder.setAttribute('hidden', 'true');
			this.loader.removeAttribute('hidden');
		} else {
			this.textHolder.removeAttribute('hidden');
			this.loader.setAttribute('hidden', 'true');
		}
	}

	/**
	 * Callback method when Kundo is loaded.
	 * Removes event listeners and calls startChat()
	 */
	chatInitialized() {
		this.kundoInitEvents.forEach((e) =>
			document.removeEventListener(e, this.chatInitialized)
		);
		this.startChat();
	}

	/**
	 * Loads the Kundo scripts,
	 * @param {string} fileUrl - The scripts url.
	 */
	loadKundoScript(fileUrl) {
		let scriptEle = document.createElement('script');
		scriptEle.setAttribute('src', fileUrl);
		scriptEle.setAttribute('type', 'text/javascript');
		document.body.appendChild(scriptEle);

		scriptEle.addEventListener('load', () => {
			window.$kundo_chat = window.$kundo_chat || {};
			window.$kundo_chat.disable_widget = true;
		});
		scriptEle.addEventListener('error', (ev) => {
			this.setButtonStatus(false);
			alert('An error occurred when loading Kundo, please try again later');
			console.log('Error on loading file', ev);
		});
	}

	/**
	 * Starts the chat.
	 * If it's not completely loaded it retries every 0.5s.
	 */
	startChat() {
		if (window.$kundo_chat?.start_chat) {
			this.setButtonStatus(false);
			window.$kundo_chat.start_chat();
		} else {
			setTimeout(this.startChat, 500);
		}
	}

	/**
	 * Initiate the chat, it will load the scripts if needed.
	 */
	initiateKundoChat() {
		if (window.$kundo_chat?.start_chat) {
			this.startChat();
		} else {
			this.setButtonStatus(true);
			this.loadKundoScript(this.scriptSrc);
		}
	}

	/**
	 * Renders the component html/css.
	 */
	render() {
		if (!this.scriptSrc) {
			console.warn('script-attribute is missing on kundo-lazy-button element.');
			return;
		}

		this.shadow.innerHTML = `
      <style>
        .kundo-chat-widget__start-button {
	        overflow: visible;
	        background-color: black;
	        color: white;
	        bottom: 16px;
	        border-radius: 25px;
	        box-sizing: inherit;
	        position: fixed;
	        z-index: 10000;
	        font-size: 16px;
	        font-weight: 500;
	        line-height: 24px;
	        white-space: nowrap;
	        cursor: pointer;
	        background-image: none;
	        border: 0;
	        box-shadow: rgba(0, 0, 0, 0.5) 0 2px 4px;
	        opacity: 1;
	        animation-name: kundoWidgetFadeIn;
	        animation-duration: 0.3s;
	        top: auto;
	        left: auto;
	        min-width: 56px;
	        padding: 12px;
	        transition: color 200ms, background-color 200ms, box-shadow 100ms, left 500ms,
		        right 500ms, bottom 200ms, transform 100ms, border-radius 300ms,
		        opacity 300ms, -webkit-box-shadow 100ms, -webkit-transform 100ms;
	        transition-timing-function: ease-in-out;
	        display: block;
	        right: 8px;
        }

        .kundo-chat-widget__start-button:hover {
	        background-color: white !important;
	        color: black !important;
        }

        .kundo-chat-widget__start-button-content {
	        display: flex;
	        -webkit-box-align: center;
	        align-items: center;
	        -webkit-box-pack: center;
	        justify-content: center;
	        min-width: 20px;
	        min-height: 20px;
	        -webkit-box-orient: horizontal;
        }

        .kundo-chat-widget__start-button-icon {
	        display: block;
	        fill: white !important;
	        box-sizing: inherit;
	        -webkit-box-flex: 0;
	        flex: 0 0 auto;
	        width: 20px;
	        height: 20px;
	        margin: 2px;
        }

        .kundo-chat-widget__start-button-text {
	        margin-right: 3px;
	        margin-left: 6px;
	        color: inherit;
	        transition: font-size 150ms ease-in-out;
        }
        
        .loader {
          display: inline-block;
          vertical-align: middle;
          width: 1.3rem;
          height: 1.3rem;
          border: 0.15rem solid #FFF;
          border-bottom-color: transparent;
          border-radius: 50%;
          box-sizing: border-box;
          animation: rotation 1s linear infinite;
        }
        .loader[hidden] {
          display: none;
        }
        .kundo-chat-widget__start-button:hover .loader {
          border-color: black;
        }
        
        .sr-only {
          position: absolute;
          width: 1px;
          height: 1px;
          padding: 0;
          margin: -1px;
          overflow: hidden;
          clip: rect(0, 0, 0, 0);
          white-space: nowrap;
          border-width: 0;
        }

        @keyframes kundoWidgetFadeIn {
	        from {
		        opacity: 0;
	        }

	        to {
		        opacity: 1;
	        }
        }

        @keyframes rotation {
          0% {
              transform: rotate(0deg);
          }
          100% {
              transform: rotate(360deg);
          } 
        }
      </style>
      
      <div id="kundo-chat">
        <div
          id="kundo-chat-widget"
          class="kundo-chat-widget"
          role="region"
          aria-label="Chat"
        >
          <button
            class="kundo-chat-widget__start-button kundo-chat-widget__start-button--with-transition"
            id="start-kundo-chat"
            aria-expanded="false"
          >
            <span class="kundo-chat-widget__start-button-content">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                class="kundo-chat-widget__start-button-icon"
              >
                <path
                  d="M5.195 11.895h-.043c.017.911.227 1.808.616 2.634a6.156 6.156 0 0 1-3.5 1.32c-.124 0-.364-.116-.364-.238a.438.438 0 0 1 .123-.36 4.932 4.932 0 0 0 1.233-2.635A6.02 6.02 0 0 1 0 7.466C0 3.871 3.506 1 7.974 1c4.227 0 7.61 2.634 7.974 5.988a7.897 7.897 0 0 0-3.747-.959c-3.863 0-7.006 2.634-7.006 5.866zM12.2 7.221c3.26 0 5.799 2.157 5.799 4.668a4.549 4.549 0 0 1-2.416 3.832c.069.712.365 1.384.845 1.918.123.123.123.123 0 .239 0 .122-.124.122-.24.122a4.617 4.617 0 0 1-3.143-1.558c-.364 0-.617.122-.968.122-3.26 0-5.799-2.157-5.799-4.669.124-2.633 2.656-4.668 5.916-4.668l.006-.006z"
                  fill-rule="nonzero"
                  fill="currentColor"
                ></path>
              </svg>
              <span class="kundo-chat-widget__start-button-text">
                <span id="text-holder">
                  ${this.text}
                </span>
				        <div class="loader" id="loader" hidden><span class="sr-only">Loading...</span></div>
              </span>
            </span>
          </button>
        </div>
        <div id="kundo-chat-app"></div>
      </div>
    `;
	}
}

if (typeof window !== 'undefined') {
	if (typeof window.customElements !== 'undefined') {
		window.customElements.define('kundo-lazy-button', KundoLazyButton);
	}
}
export {};
