/**
 * DataTableRow
 */

import clsx from 'clsx';
import React, { useState } from 'react';
import { Icon } from 'ui-component-library/base';
import { Transition } from 'react-transition-group';
import { TransitionState } from 'ui-component-library/base/types';
import { useUuid } from 'ui-component-library/hooks';
import { Link as LinkBase } from 'ui-component-library/base';
import { DataTablePropertyModel, DataTableRowModel } from 'types';

interface Props {
	/** Pass an array with all table rows */
	row: DataTableRowModel;

	/** Pass array with headers */
	headers: DataTablePropertyModel[];

	/** Possible to expand a table row for more information? */
	expandable?: boolean;

	/** Possible to select a row by checking a ckeckbox? */
	selectable?: boolean;

	/** Is the row is checked? */
	checked?: boolean;

	/** Callback function to handle single check */
	handleCheck?: Function;

	/** An optional component to be used as link. */
	LinkComponent?: any;
}

/** Naviagation list with links. */
const DataTableRow: React.FC<Props> = ({
	row,
	headers,
	expandable,
	selectable,
	checked,
	handleCheck,
	LinkComponent = LinkBase,
}) => {
	const [expanded, setExpanded] = useState<boolean>(false);
	const [getUuid] = useUuid();
	const id = getUuid();
	const Link = LinkComponent;

	const getHeaderLength = () => {
		let headerLength = 0;

		if (selectable) {
			headerLength += 1;
		}
		if (expandable) {
			headerLength += 1;
		}

		return (headerLength += headers.length);
	};

	return (
		<>
			<tr data-requirement={row.requirementName}>
				{selectable && (
					<td>
						<input
							type="checkbox"
							aria-label={`Välj tabellrad ${row.requirementName}`}
							checked={checked}
							onChange={() => {
								handleCheck && handleCheck(row.documentId);
							}}
						/>
					</td>
				)}

				{headers.map((header, index) => {
					return (
						<React.Fragment key={index}>
							<td>
								{header.property === 'requirementName' ? (
									<Link to={row.url}>{(row as any)[header.property]}</Link>
								) : (
									(row as any)[header.property]
								)}
							</td>
						</React.Fragment>
					);
				})}
				{expandable && (
					<td>
						{row.description && (
							<button
								aria-label={`Mer information om rad ${id}`}
								className="w-full h-full"
								aria-expanded={expanded}
								aria-controls={`table-row-description-${id}`}
								id={`table-row-button-${id}`}
								onClick={() => {
									setExpanded(!expanded);
								}}
							>
								<Icon
									icon="chevron"
									direction={expanded ? 'down' : 'up'}
									className=""
									aria-hidden={true}
								/>
							</button>
						)}
					</td>
				)}
			</tr>
			{expandable && (
				<Transition in={expanded} timeout={400}>
					{(state: TransitionState) => (
						<tr
							id={
								expandable && row.description && `table-row-description-${id}`
							}
							role={expandable && row.description && 'region'}
							aria-labelledby={
								expandable && row.description && `table-row-button-${id}`
							}
							hidden={state === 'exited' || (expandable && !row.description)}
							className={clsx(
								state === 'exiting' ? 'animate-fade-out' : 'animate-fade-in',
								'data-table__description'
							)}
						>
							<td colSpan={getHeaderLength()}>
								<div
									className="data-table__description__text richtext"
									dangerouslySetInnerHTML={{
										__html: (row as DataTableRowModel).description || '',
									}}
								/>
							</td>
						</tr>
					)}
				</Transition>
			)}
		</>
	);
};

export default DataTableRow;
