/**
 * ProductSubGroupPage
 */

import React, { useEffect, useState, useRef } from 'react';
import { useMachine } from '@xstate/react';
import { useHistory, useLocation } from 'react-router-dom';
import {
	Grid,
	Cell,
	LinkList,
	LinkListItem,
	Icon,
} from 'ui-component-library/base';
import {
	ProductSubGroupPageModel,
	RequirementExportModel,
	SelectedRequirementsModel,
	AggregationsModel,
} from 'types';
import BaseLayout from 'layouts/BaseLayout';
import Text from 'components/Text';
import EpiFragments from 'components/EpiFragments';
import { DataTable, DataTableExportButton } from 'components/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import MultiSelectFilter from 'components/MultiSelectFilter';
import {
	initSelectedRequirements,
	selectProductGroups,
	updateSelectedRequirements,
} from 'store/modules/requirements';
import { generateReport } from 'api/requirementApi';
import EpiContentArea from 'components/EpiContentArea';
import { selectLanguageSelector } from 'store/modules/model';
import Link from 'components/Link';
import { filterSortMachine } from 'state-machines/filterSort.machine';
import { getFilterSortQuery } from 'utils/helpers';
import clsx from 'clsx';

/** ProductSubGroupPage component. */
const ProductSubGroupPage: React.FC<ProductSubGroupPageModel> = ({
	heading,
	preamble,
	showMorePreamble,
	showLessPreamble,
	requirements: defaultRequirements,
	tableCaptionText,
	downloadHeading,
	generateFileButtonText,
	generateFileProgressText,
	downloadFileButtonText,
	text,
	contentArea,
	apiUrl,
	clearFilterText,
	expandFilterText,
	filterHeading,
	linkList,
}) => {
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();
	const [requirements, setRequirements] = useState(defaultRequirements);
	const [state, send] = useMachine(filterSortMachine);
	const ariaLiveRef = useRef<HTMLDivElement>(null);
	const [exportLoading, setExportLoading] = useState<boolean>(false);
	const [exportResult, setExportResult] = useState<RequirementExportModel>({});
	const languages = useSelector(selectLanguageSelector);
	const selectedGroups = useSelector(selectProductGroups);
	const [queries, setQueries] = useState({ filterQuery: '', sortQuery: '' });
	const [preambleExpanded, setPreambleExpanded] = useState<boolean>(false);
	const productGroupId: number =
		requirements &&
		requirements.items &&
		requirements.items.length > 0 &&
		requirements.items[0].productGroupId !== undefined
			? requirements.items[0].productGroupId
			: 0;
	const selectedRequirements: SelectedRequirementsModel =
		selectedGroups && selectedGroups[productGroupId]
			? selectedGroups[productGroupId].selectedRequirements
			: {};

	const setSelectedRequirements = (
		selectedRequirement: SelectedRequirementsModel
	) => {
		setExportResult({});
		dispatch(updateSelectedRequirements(productGroupId, selectedRequirement));
	};

	const showExportButton = (
		selectedRequirement: SelectedRequirementsModel
	): boolean => {
		for (let id in selectedRequirement) {
			if (selectedRequirement[id].selected) {
				return true;
			}
		}
		return false;
	};

	const requestExportFile = () => {
		setExportLoading(true);
		setExportResult({});

		if (requirements) {
			const data = generateReport(
				productGroupId,
				selectedRequirements,
				requirements,
				languages?.culture || 'sv-SE'
			);

			data
				.then((response) => {
					setExportResult(response);
					setExportLoading(false);
				})
				.catch(() => {
					setExportResult({
						message: 'Ett fel uppstod, försök igen',
					});
					setExportLoading(false);
				});
		}
	};

	useEffect(() => {
		setRequirements(defaultRequirements);
	}, [defaultRequirements]);

	useEffect(() => {
		if (state.context.result) {
			setRequirements(state.context.result);
		}
	}, [state.context.result]);

	useEffect(() => {
		if (requirements) {
			dispatch(
				initSelectedRequirements(
					productGroupId,
					requirements,
					selectedRequirements
				)
			);
		}
		// eslint-disable-next-line
	}, [productGroupId, requirements]);

	const onFilterChange = (filters: AggregationsModel, filterQuery: string) => {
		setQueries({ ...queries, filterQuery: filterQuery });
		send('FETCH', {
			apiUrl: apiUrl,
			filterQuery,
			sortQuery: queries.sortQuery,
		});

		let query = getFilterSortQuery(filterQuery, queries.sortQuery);
		history.push(`${location.pathname}${query}`);
	};

	const onSortChange = (property: string, direction: string) => {
		let sortQuery = '';
		if (direction) {
			sortQuery = `${property}_${direction}`;
		}

		setQueries({ ...queries, sortQuery: sortQuery });
		send('FETCH', {
			apiUrl: apiUrl,
			filterQuery: queries.filterQuery,
			sortQuery,
		});
		let query = getFilterSortQuery(queries.filterQuery, sortQuery);
		history.push(`${location.pathname}${query}`);
	};

	return (
		<BaseLayout themeBorder={true}>
			<div className="sr-only" aria-live="assertive" ref={ariaLiveRef}></div>
			<Grid
				padding={false}
				margin={false}
				className="lg:px-6 px-4 -mt-4 md:mt-0"
			>
				<Cell span={12} desktop={10} className="lg:ml-1/12">
					<Text as="h1" prop="heading">
						{heading}
					</Text>

					<div className="mb-8">
						{languages &&
							languages.languages &&
							languages.languages.length > 0 && (
								<div className="flex flex-col mb-4">
									{languages.languages.map((item, index) => {
										return (
											<Link
												to={item.url}
												key={index}
												className="text-teal text-m hover:underline"
											>
												{item.text}
											</Link>
										);
									})}
								</div>
							)}
						<Text
							as="preamble"
							prop="preamble"
							margin={false}
							className={clsx(
								!preambleExpanded &&
									showLessPreamble &&
									showLessPreamble &&
									'line-clamp-2',
								'lg:max-w-3xl'
							)}
							id="preamble"
						>
							{preamble}
						</Text>
						{showMorePreamble && showLessPreamble && (
							<button
								className="text-h3 text-teal"
								aria-expanded={preambleExpanded}
								aria-controls="preamble"
								onClick={() => setPreambleExpanded(!preambleExpanded)}
							>
								{preambleExpanded ? (
									<span>
										{showLessPreamble}
										<Icon icon="chevron" direction="down" className="ml-2" />
									</span>
								) : (
									<span>
										{showMorePreamble}
										<Icon icon="chevron" direction="up" className="ml-2" />
									</span>
								)}
							</button>
						)}
					</div>
					{requirements?.aggregations && requirements?.aggregations.length > 0 && (
						<div className="mb-6">
							{filterHeading && (
								<Text as="h2" styleAs="h4">
									{filterHeading}
								</Text>
							)}
							<MultiSelectFilter
								filters={requirements.aggregations}
								onChange={onFilterChange}
								clearText={clearFilterText}
								expandText={expandFilterText}
							/>
							{state.value === 'error' && <p>Ett fel uppstod...</p>}
						</div>
					)}
					<div className="flex flex-wrap md:-mx-4 sm:w-full lg:w-3/4">
						{linkList &&
							linkList.length > 0 &&
							linkList.map((list, index) => {
								return (
									<LinkList
										key={index}
										heading={list.heading}
										tooltipLabel={list.tooltipLabel}
										tooltipText={list.tooltipText}
										width={list.width}
										className={clsx(
											list.width === 50 && 'md:w-49/100',
											list.width === 100 && 'w-full',
											'md:px-4'
										)}
									>
										{list.items &&
											list.items.length > 0 &&
											list.items.map((item, index) => {
												return (
													<LinkListItem
														text={item.text}
														url={item.url}
														LinkComponent={Link}
														key={index}
														external={item.external}
													/>
												);
											})}
									</LinkList>
								);
							})}
					</div>
				</Cell>
				<Cell span={12} desktop={10} className="lg:ml-1/12">
					<DataTable
						{...requirements}
						caption={tableCaptionText}
						checkedBoxes={selectedRequirements}
						setCheckedBoxes={setSelectedRequirements}
						selectable
						className="my-14"
						exportHeading={downloadHeading}
						onSortChange={onSortChange}
					>
						<>
							<DataTableExportButton
								text={
									exportLoading
										? generateFileProgressText
										: generateFileButtonText
								}
								loading={exportLoading}
								onClick={() => requestExportFile()}
								disabled={!showExportButton(selectedRequirements)}
							/>
							{exportResult.reportUrl && (
								<a
									href={exportResult.reportUrl}
									className="inline-block outline-none rounded font-bold bg-white border-2 border-teal text-teal hover:bg-greyLight h-10 px-4 text-h5 mx-4 leading-9"
								>
									{downloadFileButtonText}
								</a>
							)}
							{exportResult.message && (
								<Text as="p">{exportResult.message}</Text>
							)}
						</>
					</DataTable>
				</Cell>
				<Cell span={12} desktop={7} className="lg:ml-2/12">
					<EpiFragments fragments={text.fragments} />
				</Cell>
			</Grid>
			{contentArea && contentArea.length > 0 && (
				<EpiContentArea content={contentArea} />
			)}
		</BaseLayout>
	);
};

export default ProductSubGroupPage;
