/**
 * StatisticServiceArticlePage
 */

import React from 'react';
import {
	Grid,
	Cell,
	Toc,
	TocLink,
	ArticleHeader,
	ArticleHeaderCategory,
	ImageHeader,
} from 'ui-component-library/base';
import { StatisticServiceArticlePageModel } from 'types';
import BaseLayout from 'layouts/BaseLayout';
import Text from 'components/Text';
import EpiFragments from 'components/EpiFragments';
import clsx from 'clsx';
import Link from 'components/Link';
import EpiContentArea from 'components/EpiContentArea';
import { useIsDesktop } from 'hooks/useMediaQuery';
import { ArticleHeaderCategoryList } from 'ui-component-library/base/ArticleHeader';

/** StatisticServiceArticlePage component. */
const StatisticServiceArticlePage: React.FC<StatisticServiceArticlePageModel> =
	({
		heading,
		preamble,
		text,
		tableOfContents,
		articleHeader,
		imageHeader,
		contentArea,
	}) => {
		const isDesktop = useIsDesktop();
		return (
			<BaseLayout themeBorder={true}>
				{imageHeader && (
					<ImageHeader
						src={imageHeader.src}
						alt={imageHeader.altText}
						figCaption={imageHeader.text}
						className="-mt-10 md:-mt-8"
					/>
				)}
				<Grid
					padding={false}
					margin={false}
					className={clsx(
						imageHeader ? 'pt-2 md:pt-8' : '-mt-4 md:mt-0',
						'lg:px-6 px-4'
					)}
				>
					<Cell span={12} desktop={7}>
						<Text as="h1" prop="heading">
							{heading}
						</Text>
						{articleHeader && (
							<ArticleHeader
								published={articleHeader.published}
								social={articleHeader.social}
								className="mb-8"
							>
								{articleHeader.categories &&
									articleHeader.categories.length > 0 && (
										<ArticleHeaderCategoryList>
											{articleHeader.categories.map((category, index) => {
												return (
													<ArticleHeaderCategory
														key={index}
														url={category.url}
														text={category.text}
														LinkComponent={Link}
													/>
												);
											})}
										</ArticleHeaderCategoryList>
									)}
							</ArticleHeader>
						)}
					</Cell>
				</Grid>
				<Grid
					margin={false}
					padding={false}
					className={clsx(
						tableOfContents && 'flex-row-reverse',
						'lg:px-6 px-4'
					)}
				>
					{isDesktop && tableOfContents && (
						<Cell span={4} className="ml-1/12 pl-8">
							<Toc
								heading={tableOfContents.heading}
								items={tableOfContents.chapters}
								sticky={tableOfContents.isSticky}
							>
								{tableOfContents.titles.map((item, index) => (
									<TocLink href={`#${item.targetId}`} key={index}>
										{item.text}
									</TocLink>
								))}
							</Toc>
						</Cell>
					)}

					<Cell span={12} desktop={7}>
						<Text as="preamble" prop="preamble" margin={false} className="mb-2">
							{preamble}
						</Text>
						{!isDesktop && tableOfContents && (
							<Toc
								heading={tableOfContents.heading}
								items={[]}
								className="mb-8"
							>
								{tableOfContents.titles.map((item, index) => (
									<TocLink href={`#${item.targetId}`} key={index}>
										{item.text}
									</TocLink>
								))}
							</Toc>
						)}
						<EpiFragments prop="text" fragments={text?.fragments} />
					</Cell>
				</Grid>
				{contentArea && contentArea.length > 0 && (
					<EpiContentArea content={contentArea} />
				)}
			</BaseLayout>
		);
	};

export default StatisticServiceArticlePage;
