/**
 * WebGuidanceLandingPage
 */

import React from 'react';
import {
	Grid,
	Cell,
	LinkList,
	LinkListItem,
	ArticleHeader,
	ArticleHeaderCategoryList,
	ArticleHeaderCategory,
} from 'ui-component-library/base';
import BaseLayout from 'layouts/BaseLayout';
import Text from 'components/Text';
import EpiContentArea from 'components/EpiContentArea';
import { WebGuidanceLandingPageModel } from 'types';
import Link from 'components/Link';
import EpiFragments from 'components/EpiFragments';
import WebGuidanceIntroBlock from 'components/WebGuidanceIntroBlock';

/** WebGuidanceLandingPage component. */
const WebGuidanceLandingPage: React.FC<WebGuidanceLandingPageModel> = ({
	heading,
	preamble,
	area,
	articleHeader,
	firstContentArea,
	secondContentArea,
	firstText,
	secondText,
	firstRelatedLinks,
	secondRelatedLinks,
}) => {
	return (
		<BaseLayout>
			<div className="-mt-10 md:-mt-8">
				<WebGuidanceIntroBlock area={area}>
					<Grid padding={false} className="lg:pl-6 pl-4">
						<Cell span={12} desktop={7}>
							{heading && (
								<Text as="h1" prop="heading" margin={false} className="mb-2">
									{heading}
								</Text>
							)}
							{articleHeader && (
								<ArticleHeader
									social={articleHeader.social}
									buttonVariant="secondary"
								>
									{articleHeader.categories &&
										articleHeader.categories.length > 0 && (
											<ArticleHeaderCategoryList>
												{articleHeader.categories.map((category, index) => {
													return (
														<ArticleHeaderCategory
															key={index}
															url={category.url}
															text={category.text}
															LinkComponent={Link}
														/>
													);
												})}
											</ArticleHeaderCategoryList>
										)}
								</ArticleHeader>
							)}
							{preamble && (
								<p className="text-p md:text-h3 mt-6 leading-normal">
									{preamble}
								</p>
							)}
						</Cell>
					</Grid>
				</WebGuidanceIntroBlock>
			</div>
			{((firstText?.fragments && firstText?.fragments.length > 0) ||
				(firstRelatedLinks && firstRelatedLinks.length > 0)) && (
				<Grid>
					<Cell span={12} desktop={7}>
						<EpiFragments prop="text" fragments={firstText?.fragments} />
					</Cell>
					<Cell span={12} desktop={4} className="lg:ml-1/12 lg:pl-8 mt-16">
						{firstRelatedLinks?.map((linkSection, index) => {
							return (
								<LinkList
									key={index}
									heading={linkSection?.heading}
									className="mb-12"
								>
									{linkSection.links?.map((item, subIndex) => (
										<LinkListItem
											text={item.text}
											url={item.href}
											icon={'arrow'}
											target={item.target}
											LinkComponent={Link}
											key={subIndex}
											external={item.external}
										/>
									))}
								</LinkList>
							);
						})}
					</Cell>
				</Grid>
			)}
			{firstContentArea && firstContentArea.length > 0 && (
				<EpiContentArea content={firstContentArea} />
			)}
			{((secondText?.fragments && secondText.fragments.length > 0) ||
				(secondRelatedLinks && secondRelatedLinks.length > 0)) && (
				<Grid>
					<Cell span={12} desktop={7}>
						<EpiFragments prop="text" fragments={secondText?.fragments} />
					</Cell>
					<Cell span={12} desktop={4} className="lg:ml-1/12 lg:pl-8 mt-16">
						{secondRelatedLinks?.map((linkSection, index) => {
							return (
								<LinkList key={index} heading={linkSection?.heading}>
									{linkSection.links?.map((item, subIndex) => (
										<LinkListItem
											text={item.text}
											url={item.href}
											icon={'arrow'}
											target={item.target}
											LinkComponent={Link}
											key={subIndex}
											external={item.external}
										/>
									))}
								</LinkList>
							);
						})}
					</Cell>
				</Grid>
			)}
			{secondContentArea && secondContentArea.length > 0 && (
				<EpiContentArea content={secondContentArea} />
			)}
		</BaseLayout>
	);
};
export default WebGuidanceLandingPage;
