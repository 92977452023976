/**
 * DataTableSortButton
 */

import clsx from 'clsx';
import React from 'react';
import { Icon } from 'ui-component-library/base';

interface Props {
	className?: string;

	property: string;

	direction?: string;

	orderByText: string;
	/** Callback for on sort change */
	onChange: any;
}

const DataTableSortButton: React.FC<Props> = ({
	property,
	direction,
	onChange,
	orderByText,
	children,
	className,
}) => {
	const getNextDirection = () => {
		switch (direction) {
			case 'ASC':
				return 'DESC';
			case 'DESC':
				return '';
			default:
				return 'ASC';
		}
	};

	return (
		<button
			className={clsx(className, 'font-semibold flex items-center')}
			onClick={() => onChange(property, getNextDirection())}
			data-button-action={`Sortera: ${children}`}
		>
			{children}
			<span className="sr-only">{orderByText}</span>
			{direction === 'ASC' ? (
				<Icon
					icon="sortDown"
					size={0.75}
					aria-hidden={true}
					className="ml-2"
					color="#8b8b8b"
				/>
			) : direction === 'DESC' ? (
				<Icon
					icon="sortUp"
					size={0.75}
					aria-hidden={true}
					className="ml-2"
					color="#8b8b8b"
				/>
			) : (
				<Icon
					icon="sort"
					size={0.75}
					aria-hidden={true}
					className="ml-2"
					color="#e1e0de"
				/>
			)}
		</button>
	);
};

export default DataTableSortButton;
