/**
 * PublicationsListingPage
 */

import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMachine } from '@xstate/react';

import {
	PublicationGroupModel,
	PublicationModel,
	PublicationsListingPageModel,
} from 'types';
import { showMorePaginationMachine } from 'state-machines/showMorePagination.machine';

import { Grid, Cell, Button, Icon } from 'ui-component-library/base';
import EpiContentArea from 'components/EpiContentArea';

import BaseLayout from 'layouts/BaseLayout';
import Text from 'components/Text';
import {
	PublicationGroup,
	PublicationList,
	PublicationListItem,
	PublicationListItemCategory,
} from 'ui-component-library/uhmse';
import Link from 'components/Link';

/** Main description for this component. */
const PublicationsListingPage: React.FC<PublicationsListingPageModel> = ({
	heading,
	preamble,
	showMoreButtonText,
	numberOfHitsPerPage,
	downloadText,
	apiUrl,
	listingModel,
	contentArea,
}) => {
	const location = useLocation();
	const history = useHistory();

	const [state, send] = useMachine(showMorePaginationMachine);
	const [click, setClick] = useState(0);

	if (state.context.result) {
		listingModel = state.context.result;
	}

	const fetch = listingModel.offset + listingModel.fetch + numberOfHitsPerPage;
	const hasMore = listingModel.offset + listingModel.fetch < listingModel.total;
	const sortOrder = listingModel.sortOrder;

	useEffect(() => {
		if (click > 0) {
			send('FETCH', {
				offset: 0,
				fetch: fetch,
				apiUrl,
				sortOrder,
			});
		}

		// eslint-disable-next-line
	}, [click]);

	return (
		<BaseLayout>
			<Grid
				padding={false}
				margin={false}
				className="lg:px-6 px-4 -mt-4 md:mt-0"
			>
				<Cell span={12} desktop={7}>
					<Text as="h1" prop="heading">
						{heading}
					</Text>
					<Text as="preamble" prop="preamble">
						{preamble}
					</Text>
				</Cell>
			</Grid>
			<Grid padding={false} margin={false} className="lg:px-6 px-4 mb-20">
				<Cell span={12} desktop={7} className="mb-8 lg:mb-0">
					{listingModel.groups.map(
						(event: PublicationGroupModel, index: number) => {
							return (
								<PublicationGroup heading={event.heading} key={index}>
									{event.items.map(
										(publication: PublicationModel, index: number) => {
											return (
												<PublicationList key={index}>
													<PublicationListItem
														url={publication.url}
														image={publication.image}
														alt={publication.alt || ''}
														publicationDate={publication.published}
														name={publication.name}
														downloadText={downloadText}
														publicationUrl={publication.publicationUrl}
														preamble={publication.description}
														LinkComponent={Link}
														key={index}
													>
														{publication.categories &&
															publication.categories.map((category, i) => {
																return (
																	<PublicationListItemCategory
																		key={i}
																		text={category}
																	/>
																);
															})}
													</PublicationListItem>
												</PublicationList>
											);
										}
									)}
								</PublicationGroup>
							);
						}
					)}
					{hasMore && (
						<div className="flex justify-around mt-6">
							<Button
								data-button-action={showMoreButtonText}
								className="my-auto"
								variant="primary"
								disabled={state.value === 'loading'}
								onClick={() => {
									setClick(click + 1);
									history.push(`${location.pathname}?fetch=${fetch}`);
								}}
							>
								{showMoreButtonText}
								{state.value === 'loading' && (
									<Icon
										icon="loader"
										color="white"
										animate="spin"
										size={0.75}
										className="ml-2"
										aria-hidden={true}
									/>
								)}
								{state.value !== 'loading' && (
									<Icon
										icon="chevron"
										direction="up"
										color="white"
										size={0.75}
										className="ml-2"
										aria-hidden={true}
									/>
								)}
							</Button>
						</div>
					)}
				</Cell>
				{contentArea && contentArea.length > 0 && (
					<Cell span={12} desktop={4} className="lg:ml-1/12">
						<EpiContentArea content={contentArea} />
					</Cell>
				)}
			</Grid>
		</BaseLayout>
	);
};

export default PublicationsListingPage;
