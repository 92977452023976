export const exportDataByUrl = (url: string) => {
	let a = document.createElement('a');
	a.classList.add('hidden');
	document.body.appendChild(a);
	a.href = url;
	a.download = url;
	a.click();
	a.remove();
};

export const exportDataHighcharts = (chartRef: any, exportType: string) => {
	if (chartRef.current !== null && exportType) {
		chartRef.current.chart?.exportChart({
			type: exportType,
		});
	}
};
