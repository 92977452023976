/**
 * CriteriaServiceRequirementPage
 */

import React from 'react';

import { Grid, Cell, Toc, TocLink } from 'ui-component-library/base';
import { CriteriaServiceRequirementPageModel } from 'types';
import BaseLayout from 'layouts/BaseLayout';
import Text from 'components/Text';
import clsx from 'clsx';
import EpiFragments from 'components/EpiFragments';
import EpiContentArea from 'components/EpiContentArea';
import { selectLanguageSelector } from 'store/modules/model';
import { useSelector } from 'react-redux';
import Link from 'components/Link';
import { useIsMobile } from 'hooks/useMediaQuery';

/** Main description for this component. */
const CriteriaServiceRequirementPage: React.FC<CriteriaServiceRequirementPageModel> =
	({ heading, preamble, tableOfContents, text, contentArea }) => {
		const languages = useSelector(selectLanguageSelector);
		const isMobile = useIsMobile();
		return (
			<BaseLayout themeBorder={true}>
				<Grid
					padding={false}
					margin={false}
					className="px-4 lg:px-6 -mt-4 md:mt-0"
				>
					<Cell span={12} desktop={7}>
						<Text as="h1" prop="heading">
							{heading}
						</Text>
						{languages &&
							languages.languages &&
							languages.languages.length > 0 && (
								<div className="flex flex-col mb-4">
									{languages.languages.map((item, index) => {
										return (
											<Link
												to={item.url}
												key={index}
												className="text-teal text-m hover:underline"
											>
												{item.text}
											</Link>
										);
									})}
								</div>
							)}
					</Cell>
				</Grid>
				<Grid
					margin={false}
					padding={false}
					className={clsx(
						tableOfContents && 'flex-row-reverse',
						'px-4 lg:px-6'
					)}
				>
					{!isMobile && tableOfContents && (
						<Cell span={4} className="ml-1/12 pl-8">
							<Toc
								heading={tableOfContents.heading}
								items={tableOfContents.chapters}
								sticky={tableOfContents.isSticky}
							>
								{tableOfContents.titles.map((item, index) => (
									<TocLink href={`#${item.targetId}`} key={index}>
										{item.text}
									</TocLink>
								))}
							</Toc>
						</Cell>
					)}

					<Cell span={12} desktop={7}>
						<div className="mb-10 md:mb-14">
							<Text as="preamble" prop="preamble">
								{preamble}
							</Text>
							{isMobile && tableOfContents && (
								<Toc
									heading={tableOfContents.heading}
									items={[]}
									className="mb-8"
								>
									{tableOfContents.titles.map((item, index) => (
										<TocLink href={`#${item.targetId}`} key={index}>
											{item.text}
										</TocLink>
									))}
								</Toc>
							)}
							<EpiFragments fragments={text.fragments} />
						</div>
					</Cell>
				</Grid>
				{contentArea && contentArea.length > 0 && (
					<EpiContentArea content={contentArea} />
				)}
			</BaseLayout>
		);
	};

export default CriteriaServiceRequirementPage;
