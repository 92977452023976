/**
 * DataTableCart
 */

import clsx from 'clsx';
import React from 'react';
import { DataTablePropertyModel } from 'types';
// import DataTableSortButton from './DataTableCartSortButton';

interface Props {
	/** Pass optional classnames for the html */
	className?: string;

	/** Table caption */
	caption?: string;

	/** Data tabel headers */
	properties?: DataTablePropertyModel[];

	onSortChange?: any;
}

type RowIds = { [key: string]: boolean };

/** Main description for this component. */
const DataTableCart: React.FC<Props> = ({
	className,
	caption = 'DataTableCart',
	properties,
	onSortChange,
	children,
}) => {
	return (
		<div
			className={clsx(className, 'overflow-y-auto w-full mb-6')}
			data-cid="DataTableCart"
		>
			<table className="cart-table w-full">
				<caption className="sr-only">{caption}</caption>
				{properties && (
					<thead>
						<tr>
							{properties.map((header, index) => {
								return (
									<th key={index} scope="col">
										{/* {header.path && header.orderByText ? (
											<DataTableSortButton
												property={header.path}
												direction={header.direction}
												orderByText={header.orderByText}
												onChange={onSortChange}
											>
												{header.heading}
											</DataTableSortButton>
										) : (
											<span className="sr-only">{header.heading}</span>
										)} */}
										<span
											className={clsx(
												index === properties.length - 1 && 'sr-only'
											)}
										>
											{header.heading}
										</span>
									</th>
								);
							})}
						</tr>
					</thead>
				)}
				<tbody>{children}</tbody>
			</table>
		</div>
	);
};

export default DataTableCart;
