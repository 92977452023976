/**
 * ClimateImpactEvaluationForm
 */

import React, { useState } from 'react';
import { formData } from './ClimateImpactEvaluationForm-data';
import {
	ErrorSpan,
	Field,
	Form,
	Label,
	Select,
	Text,
	Tooltip,
} from 'ui-component-library/base';
import { Button } from 'ui-component-library/base/Form';
import ClimateImpactEvaluationDiagram from './ClimateImpactEvaluationDiagram';
import ClimateImpactEvaluationTable from './ClimateImpactEvaluationTable';

export interface ClimateImpactEvaluationForms {
	forms: FormModel[];
}

export interface FormModel {
	name: string;
	toolTip: string;
	referenceObjects: ReferenceObject[];
}

export interface ReferenceObject {
	name: string;
	toolTip: string;
	info: string;
	referenceData: ReferenceData;
	lifeLengthReferenceData: LifeLengthReferenceData;
	inputObjectLifeLength: InputObjectLifeLength;
	inputUnit: InputUnit;
	measures: Measure[];
	constructionPhaseData: ConstructionPhaseData;
	referenceDataOperation: ReferenceDataOperation;
}

export interface InputUnit {
	info: string;
	unit: string;
}

export interface InputObjectLifeLength {
	info: string;
	objectLifeLengthUnit: string;
}

export interface Measure {
	name: string;
	info: string;
	toolTip: string;
	procurementReason: string;
	measureBuildVaule: number;
	measeureOperationVaule: number;
}

export interface ReferenceData {
	unit: string;
	amount: number;
}

export interface ReferenceDataOperation {
	unit: string;
	amount: number;
}

export interface LifeLengthReferenceData {
	unit: string;
	lifeLength: number;
}

export interface ConstructionPhaseData {
	constructionPhase: string;
}

export interface CalculatedData {
	formType: string;
	co2AmountBuildTraditional: number;
	co2AmountOperationTraditional: number;
	unit: string;
	co2precentBuildDifference: number;
	co2AmountBuildDifference: number;
	referenceObjectType: string;
	measureType: string;
	inputObjectLifeLengthAmount: number;
	inputObjectLifeLengthUnit: string;
	inputAmount: number;
	co2AmountBuildEnvironmental: number;
	co2AmountOperationEnvironmental: number;
	co2AmountOperationDifference: number;
	co2precentOperationDifference: number;
}

const getOptions = (data: { name: string }[], type: string) => {
	let options: { value: string; caption: string }[] = [];

	if (type === 'form') {
		options.push({
			value: '',
			caption: 'Välj vad du vill räkna på',
		});

		data.forEach((data: any) =>
			options.push({
				value: data.name,
				caption: data.name,
			})
		);
	}

	if (type === 'referenceObject' || type === 'referenceMaterial') {
		if (type === 'referenceObject') {
			options.push({
				value: '',
				caption: 'Välj referensobjekt',
			});
		} else {
			options.push({
				value: '',
				caption: 'Välj referensmaterial',
			});
		}

		data.forEach((data: any) =>
			options.push({
				value: data.name,
				caption: data.name,
			})
		);
	}

	if (type === 'objectMeasure' || type === 'materialMeasure') {
		if (type === 'objectMeasure') {
			options.push({
				value: '',
				caption: 'Välj klimatförbättrande åtgärd',
			});
		} else {
			options.push({
				value: '',
				caption: 'Välj klimatförbättrande material',
			});
		}

		data.forEach((data: any) =>
			options.push({
				value: data.name,
				caption: data.name,
			})
		);
	}

	return options;
};

function calculateData(
	inputAmount: number,
	referenceAmount: number,
	referenceAmountUnit: string,
	referenceObjectType: string,
	measureAmountBuild: number,
	measureType: string,
	formName: string,
	inputObjectLifeLengthAmount: number,
	inputObjectLifeLengthUnit: string,
	referenceAmountOperation: number,
	measureAmountOperation: number
) {
	let co2AmountTraditionalBuild = inputAmount * referenceAmount;

	let co2AmountEnvironmentalBuild = inputAmount * measureAmountBuild;

	let co2AmountTraditionalOperation =
		inputAmount * referenceAmountOperation * inputObjectLifeLengthAmount;

	let co2AmountEnvironmentalOperation =
		inputAmount * measureAmountOperation * inputObjectLifeLengthAmount;

	const co2AmountBuildTraditional = co2AmountTraditionalBuild / 1000;

	const co2AmountOperationTraditional = Math.round(
		co2AmountTraditionalOperation / 1000
	);

	const co2AmountBuildEnvironmental = co2AmountEnvironmentalBuild / 1000;

	const co2AmountOperationEnvironmental = Math.round(
		co2AmountEnvironmentalOperation / 1000
	);

	const co2AmountBuildDifference =
		co2AmountBuildTraditional - co2AmountBuildEnvironmental;

	const co2AmountOperationDifference =
		co2AmountOperationTraditional - co2AmountOperationEnvironmental;

	const precentBuildDifference = Math.round(
		(co2AmountBuildDifference / co2AmountBuildTraditional) * 100
	);

	const precentOperationDifference = Math.round(
		(co2AmountOperationDifference / co2AmountOperationTraditional) * 100
	);

	const formType =
		formName === 'Byggnad eller Anläggning'
			? 'Byggnad eller anläggning'
			: 'Material';

	const calculatedData: CalculatedData = {
		formType: formType,
		co2AmountBuildTraditional: co2AmountBuildTraditional,
		co2AmountOperationTraditional: co2AmountOperationTraditional,
		co2AmountBuildEnvironmental: co2AmountBuildEnvironmental,
		co2AmountOperationEnvironmental: co2AmountOperationEnvironmental,
		unit: referenceAmountUnit,
		co2precentBuildDifference: precentBuildDifference,
		co2AmountBuildDifference: co2AmountBuildDifference,
		referenceObjectType: referenceObjectType,
		measureType: measureType,
		inputObjectLifeLengthAmount: inputObjectLifeLengthAmount,
		inputObjectLifeLengthUnit: inputObjectLifeLengthUnit,
		inputAmount: inputAmount,
		co2AmountOperationDifference: co2AmountOperationDifference,
		co2precentOperationDifference: precentOperationDifference,
	};
	return calculatedData;
}

/** Main description for this component. */
const ClimateImpactEvaluationForm: React.FC = () => {
	const { forms } = formData as ClimateImpactEvaluationForms;

	const [allForms] = useState<FormModel[]>(forms);
	const [form, setForm] = useState<FormModel | null>(null);
	const [referenceObject, setReferenceObject] =
		useState<ReferenceObject | null>(null);
	const [measure, setMeasure] = useState<Measure | null>(null);

	const [calculatedData, setCalculatedData] = useState<CalculatedData | null>(
		null
	);

	const handleSubmit = (e: any) => {
		if (parseInt(e.amount) > 0 && form && referenceObject && measure) {
			const calculatedData = calculateData(
				e.amount,
				referenceObject.referenceData.amount,
				referenceObject.referenceData.unit,
				referenceObject?.name,
				measure.measureBuildVaule,
				measure.name,
				form.name,
				referenceObject?.lifeLengthReferenceData?.lifeLength,
				referenceObject?.lifeLengthReferenceData?.unit,
				referenceObject?.referenceDataOperation?.amount,
				measure.measeureOperationVaule
			);

			setCalculatedData(calculatedData);
		}
	};

	const handleReset = () => {
		setForm(null);
		setReferenceObject(null);
		setMeasure(null);
		setCalculatedData(null);
	};

	const handleFormChange = (e: { name: string; value: string }) => {
		if (e.name === 'formType') {
			const index = allForms.findIndex((form) => form.name === e.value);

			if (index !== undefined && index !== -1) {
				const form = forms[index] as FormModel;

				setForm(form);
				setReferenceObject(null);
				setMeasure(null);
				setCalculatedData(null);
			}
		}

		if (e.name === 'referenceObject') {
			const index = form?.referenceObjects.findIndex(
				(referenceObject) => referenceObject.name === e.value
			);

			if (index !== undefined && index !== -1) {
				const referenceObject = form?.referenceObjects[
					index
				] as ReferenceObject;
				setReferenceObject(referenceObject);
				setMeasure(null);
				setCalculatedData(null);
			}
		}

		if (e.name === 'measure') {
			const index = referenceObject?.measures.findIndex(
				(measure) => measure.name === e.value
			);

			if (index !== undefined && index !== -1) {
				const measure = referenceObject?.measures[index] as Measure;
				setMeasure(measure);
				setCalculatedData(null);
			}
		}
	};

	return (
		<div>
			<Form
				action="/"
				onSubmit={handleSubmit}
				onChange={handleFormChange}
				onReset={handleReset}
				dataName="Beräkning av klimatförbättrade byggkoncept"
			>
				<section>
					<header>
						<Text as="h3" text={'Gör en uppskattning av klimateffekt'} />
					</header>

					<div className="mb-4">
						<Label htmlFor={'formType'} required={true}>
							{'Vad vill du räkna på?'}
						</Label>
						{form?.toolTip && (
							<Tooltip label={'formType'} description={form.toolTip} />
						)}
						<Select
							id={'formType'}
							label={'formType'}
							name={'formType'}
							options={getOptions(forms, 'form')}
							required={true}
						/>
					</div>
				</section>
				{form && (
					<>
						<section>
							<header>
								<Text
									as="h3"
									text={
										form.name === 'Byggnad eller Anläggning'
											? 'Typ av byggnad eller anläggning'
											: 'Materialtyp'
									}
								/>
							</header>

							<div className="mb-4">
								<Label htmlFor={'referenceObject'} required={true}>
									{form.name === 'Byggnad eller Anläggning'
										? 'Typ av projekt'
										: 'Typ av material'}
								</Label>
								{referenceObject?.toolTip && (
									<Tooltip
										label={'referenceObject'}
										description={referenceObject.toolTip}
									/>
								)}
								<Select
									id={'referenceObject'}
									label={'referenceObject'}
									name={'referenceObject'}
									options={getOptions(
										form.referenceObjects,
										form.name === 'Byggnad eller Anläggning'
											? 'referenceObject'
											: 'referenceMaterial'
									)}
									required={true}
								/>
								{referenceObject?.info &&
								referenceObject?.constructionPhaseData?.constructionPhase !==
									undefined ? (
									<span
										className={'block mt-2'}
									>{`${referenceObject.info} ${referenceObject?.constructionPhaseData?.constructionPhase}.`}</span>
								) : (
									<span className={'block mt-2'}>{referenceObject?.info}</span>
								)}
							</div>
						</section>
						{referenceObject && (
							<>
								<section>
									<header>
										<Text
											as="h3"
											text={
												form.name === 'Byggnad eller Anläggning'
													? 'Storlek'
													: 'Mängd'
											}
										/>
									</header>
									<div className="mb-4">
										<Label htmlFor={'amount'} required={true}>
											{'Fyll i antal ' + referenceObject.inputUnit.unit}
										</Label>
										{referenceObject.inputUnit.info && (
											<Tooltip
												label={'amount'}
												description={referenceObject.inputUnit.info}
											/>
										)}

										<Field
											name={'amount'}
											label={'amount'}
											type={'number'}
											id={'amount'}
											required={true}
											validationMessage={'Var vänlig fyll i en mängd'}
										/>
										<ErrorSpan fieldId={'amount'} />
									</div>
								</section>
								<section>
									<header>
										<Text
											as="h3"
											text={
												form.name === 'Byggnad eller Anläggning'
													? 'Klimatförbättrande åtgärd'
													: 'Klimatförbättrande material'
											}
										/>
									</header>
									<div className="mb-4">
										<Label htmlFor={'measure'} required={true}>
											{form.name === 'Byggnad eller Anläggning'
												? 'Välj åtgärd'
												: 'Välj material'}
										</Label>
										{measure?.toolTip && (
											<Tooltip
												label={'measure'}
												description={measure?.toolTip}
											/>
										)}
										<Select
											id={'measure'}
											label={'measure'}
											name={'measure'}
											options={getOptions(
												referenceObject.measures,
												form.name === 'Byggnad eller Anläggning'
													? 'objectMeasure'
													: 'materialMeasure'
											)}
											required={true}
										/>

										{measure?.info && (
											<span className={'block mt-2'}>{measure.info}</span>
										)}
									</div>
								</section>
								{measure && (
									<div className="mb-8">
										<Button type="submit" size="small" className="mr-4">
											Beräkna
										</Button>
										<Button type="reset" size="small" variant="secondary">
											Rensa formulär
										</Button>
									</div>
								)}
							</>
						)}
					</>
				)}
			</Form>
			{calculatedData && form && (
				<div>
					<section>
						<header>
							<Text
								as="h3"
								text={
									form.name === 'Byggnad eller Anläggning'
										? 'Resultat: Klimatpåverkan jämfört med referensobjekt'
										: 'Resultat: Klimatpåverkan jämfört med referensmaterial'
								}
							/>
						</header>
					</section>
					{measure?.procurementReason && (
						<div className="mb-4">
							<p
								dangerouslySetInnerHTML={{
									__html: measure.procurementReason,
								}}
								className={'richtext'}
							/>
						</div>
					)}
					<div className="outline-none">
						<div className="richtext">
							<ClimateImpactEvaluationTable data={calculatedData} />
						</div>
						<ClimateImpactEvaluationDiagram data={calculatedData} />
					</div>
				</div>
			)}
		</div>
	);
};

export default ClimateImpactEvaluationForm;
