/**
 * ExportDataDropdown
 */

import clsx from 'clsx';
import { useUuid } from 'hooks';
import React, { useRef, useState } from 'react';
import { Icon } from 'ui-component-library/base';
import { useOutsideClick } from 'ui-component-library/hooks';

interface Props {
	/** Button text of the dropdown */
	buttonText: string;

	/** Title of the diagram or table */
	dataTitle?: string;

	/** If the element is disabled */
	disabled?: boolean;

	/** Pass an optional className */
	className?: string;
}

/** Main description for this component. */
const ExportDataDropdown: React.FC<Props> = ({
	buttonText,
	dataTitle,
	disabled,
	className,
	children,
}) => {
	const [getUuid] = useUuid();
	const id = getUuid();
	const exportResultsdRef = useRef<any>();
	const [exportResultExpanded, setExportResultExpanded] = useState(false);

	useOutsideClick(exportResultsdRef, () => {
		setExportResultExpanded(false);
	});

	return (
		<div
			className={clsx(className, 'relative md:ml-8')}
			ref={exportResultsdRef}
		>
			<button
				className={clsx(
					'appearance-none',
					'font-alt',
					'leading-5',
					'w-full',
					'px-3',
					'py-2',
					'border-2',
					'border-greySemiLight',
					'rounded',
					'transition-all',
					'focus:outline-none',
					'disabled:text-greyLight',
					'disabled:cursor-not-allowed',
					'placeholder-greyDarker',
					'placeholder-opacity-100',
					'flex justify-between'
				)}
				onClick={() => setExportResultExpanded(!exportResultExpanded)}
				aria-controls={`export-dropdown-${id}`}
				aria-expanded={exportResultExpanded}
				disabled={disabled}
			>
				<span className={clsx(!exportResultExpanded && 'text-greyDarker')}>
					{buttonText}
					{dataTitle && <span className="sr-only">{` ${dataTitle}`}</span>}
				</span>
				<span className="ml-6">
					<Icon
						icon="chevron"
						color="#00636A"
						aria-hidden="true"
						size={0.75}
						direction={!exportResultExpanded ? 'up' : 'down'}
					/>
				</span>
			</button>
			{exportResultExpanded && (
				<div
					id={`export-dropdown-${id}`}
					className="absolute w-full bg-white border-solid border border-teal z-20 max-h-172 w-full overflow-y-auto overflow-x-hidden"
				>
					<ul>{children}</ul>
				</div>
			)}
		</div>
	);
};

export default ExportDataDropdown;
