/**
 * ErrorPage404
 */

import React from 'react';
import { Grid } from 'ui-component-library/base';
import Text from 'components/Text';

interface Props {
	error?: string;
}

/** Main description for this component. */
const ErrorPage404: React.FC<Props> = ({ error }) => {
	return (
		<Grid>
			<Text as="h1">404</Text>
			<Text as="p">
				Sidan kunde inte hittas.
				<br />
				<a href="/">Till startsidan</a>.
			</Text>
			{/*error && <Text as="p">{error}</Text>*/}
		</Grid>
	);
};

export default ErrorPage404;
