/**
 * DataTableExportButton
 */

import clsx from 'clsx';
import React from 'react';
import { Icon } from 'ui-component-library/base';

interface Props {
	/** Button text */
	text?: string;

	/** Callback function */
	onClick: Function;

	/** Array with checked boxes */
	checkedBoxes?: any;

	loading?: boolean;

	disabled?: boolean;
}

/** Naviagation list with links. */
const DataTableExportButton: React.FC<Props> = ({
	text,
	onClick,
	checkedBoxes,
	loading = false,
	disabled = false,
}) => {
	return (
		<button
			className={clsx(
				disabled
					? 'bg-greyDark border-greyDark text-white'
					: 'font-bold bg-white border-teal text-teal hover:bg-greyLight',
				'inline-block outline-none rounded border-2 h-10 px-4 text-h5 mx-4'
			)}
			onClick={() => onClick()}
			disabled={loading || disabled}
		>
			<>
				{text}{' '}
				{loading && <Icon icon="loader" animate="spin" className="mb-1" />}
			</>
		</button>
	);
};

export default DataTableExportButton;
