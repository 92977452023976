/**
 * SearchFieldSelect
 */

import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { Text, Icon, Loader, Button } from 'ui-component-library/base';
import { useOutsideClick } from 'ui-component-library/hooks';
import StatisticsServiceFilterOption from './StatisticsServiceFilterOption';

interface Props {
	/** id/name for searchfield */
	id: string;

	/** Label for searchfield */
	label: string;

	/** Button text */
	showMoreButtonText?: string;

	/** Collapse button text */
	collapseButtonText: string;

	/** Button screen reader text */
	expandButtonText: string;

	/** Default value for searchfield */
	defaultValue?: string;

	/** Ref to be forwarded to the input element */
	fieldRef?: any;

	/** onFocus callback method */
	onFocus?: any;

	/** onBlur callback method */
	onBlur?: any;

	/** Pass optional classnames for the html */
	className?: string;

	/** Total items to fetch */
	itemsToFetch: number;

	/** Label for the search button */
	searchSubmitLabel: string;

	/** Placeholder for the search input */
	searchPlaceholder: string;

	selectedBuckets?: any;

	send: Function;
}

/** Searchfield Select component. */
const SearchFieldSelect: React.FC<Props> = ({
	label,
	showMoreButtonText,
	collapseButtonText,
	expandButtonText,
	searchSubmitLabel,
	searchPlaceholder,
	onFocus,
	onBlur,
	id,
	className,
	fieldRef,
	itemsToFetch,
	selectedBuckets,
	send,
	children,
}) => {
	const wrapperRef = useRef<any>();
	const [visibleOptions, setVisibleOptions] = useState(false);

	const [emptyQuery, setEmptyQuery] = useState(false);
	const [fetch, setFetch] = useState(itemsToFetch);

	const [query, setQuery] = useState('');

	useOutsideClick(wrapperRef, () => {
		setVisibleOptions(false);
	});

	const buttonBorder = visibleOptions
		? 'border-2 border-greySemiLight border-b-0 rounded-br-none rounded-bl-none'
		: 'border-2 border-greySemiLight';

	const handleGetData = (enterClick = false) => {
		send('SEARCH_FILTER', {
			filter: label,
			searchterm: query,
			itemsToFetch: itemsToFetch,
		});
		setFetch(itemsToFetch);
	};

	const handleLoadMore = (e: any) => {
		send('LOAD_MORE_FILTERITEMS', {
			filter: label,
			searchterm: query,
			itemsToFetch: fetch + 10,
		});
		setFetch(fetch + 10);
	};

	useEffect(() => {
		handleGetData();
	}, []);

	useEffect(() => {
		if ((query && query.length >= 3) || emptyQuery) {
			handleGetData();
		}
	}, [query]);

	return (
		<div
			ref={wrapperRef}
			className={clsx('relative searchfield__wrapper', className)}
		>
			<div
				className={clsx(
					'flex justify-between relative rounded border-solid',
					visibleOptions ? 'py-2 px-2 h-14' : 'py-2 px-3',
					buttonBorder
				)}
			>
				{visibleOptions && (
					<div className="relative w-full">
						<label className="sr-only" htmlFor={id}>
							{label}
						</label>
						<input
							type="text"
							id={id}
							name={id}
							placeholder={searchPlaceholder}
							onFocus={onFocus}
							onBlur={onBlur}
							ref={fieldRef}
							value={query}
							onChange={(e: any) => setQuery(e.target.value)}
							onKeyPress={(e: any) => e.key === 'Enter' && handleGetData()}
							className={clsx(
								'text-p',
								'leading-4',
								'h-10',
								'w-full',
								'px-3',
								'py-1',
								'border-2',
								'border-greySemiLight focus:border-blue',
								'rounded',
								'transition-all',
								'focus:outline-none',
								'disabled:text-greyLight',
								'disabled:cursor-not-allowed',
								'placeholder-greyDarker',
								'placeholder-opacity-100',
								'pr-10'
							)}
						/>
						<button
							className="absolute top-0 right-0 w-auto mt-2 mr-3"
							type="button"
							data-cid="SearchFieldSubmit"
							onClick={() => handleGetData()}
							id={id}
						>
							<Text as="span" styleAs="screenReader">
								{searchSubmitLabel}
							</Text>
							<Icon
								icon="search"
								size={1.25}
								aria-hidden={true}
								color="black"
							/>
						</button>{' '}
					</div>
				)}

				<button
					onClick={() => setVisibleOptions((visible) => !visible)}
					aria-expanded={visibleOptions}
					aria-controls={`${id}-dropdown`}
					type="button"
					className={clsx(
						'flex items-center',
						visibleOptions
							? 'justify-center ml-3 mr-1'
							: 'justify-between w-full'
					)}
				>
					{!visibleOptions && (
						<span className="pointer-events-none mr-4 text-p leading-5 text-greyDarker">
							{label}
						</span>
					)}
					{selectedBuckets > 0 && (
						<span className="inline-block relative z-10 text-white text-sm w-8 mr-2 multieselect-dropdown-counter">
							{selectedBuckets}
						</span>
					)}
					<Text as="span" styleAs="screenReader">
						{visibleOptions ? collapseButtonText : expandButtonText}
					</Text>
					<span>
						<Icon
							icon="chevron"
							color="#00636A"
							size={0.75}
							aria-hidden="true"
							direction={!visibleOptions ? 'up' : 'down'}
						/>
					</span>
				</button>
			</div>

			<div
				hidden={!visibleOptions}
				id={`${id}-dropdown`}
				className="absolute bg-white z-20 w-full"
			>
				<div className="absolute border-solid border-2 border-greySemiLight w-full bg-white max-h-160 overflow-auto">
					{children}

					<div className="flex items-center justify-center pt-3 pb-6 px-2">
						<Button
							size="small"
							variant="secondary"
							onClick={(e) => handleLoadMore(e)}
						>
							Visa fler
							<span className="ml-2">
								<Icon
									icon="chevron"
									color="#00636A"
									aria-hidden="true"
									direction="up"
									size={0.75}
								/>
							</span>
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SearchFieldSelect;
