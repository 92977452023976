import { configureStore, combineReducers, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import epiReducer from './modules/epi';
import reactReducer from './modules/react';
import modelReducer from './modules/model';
import requirementsReducer from './modules/requirements';
/* PLOP_INJECT_IMPORT */

const rootReducer = combineReducers({
	epi: epiReducer,
	react: reactReducer,
	model: modelReducer,
	requirements: requirementsReducer,
	/* PLOP_INJECT_REDUCER */
});
export type RootState = ReturnType<typeof rootReducer>;

export default (preloadedState: any) => {
	return configureStore({
		reducer: rootReducer,
		preloadedState,
	});
};

// export type AppDispatch = typeof store.dispatch
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
