import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

interface UsePageQuery {
	(): number;
}

const formatNumber = (value: any): number => {
	if (Array.isArray(value)) {
		value = value[0];
	}

	return parseInt(value, 10);
};
export const usePageQuery: UsePageQuery = () => {
	const location = useLocation();
	let { page } = queryString.parse(location.search);

	if (!page) {
		return 1;
	}

	return formatNumber(page);
};
