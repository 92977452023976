import React from 'react';
import {
	MultiselectDropdown,
	MultiselectOption,
} from 'ui-component-library/base';
import { useUuid } from 'ui-component-library/hooks';

interface MultiselectFilterModel {
	name: string;
	path: string;
	buckets: Bucket[];
	searchPlaceholder: string;
	onChange?: any;
	id: string;
	groupName: string;
}

interface Bucket {
	term: string;
	selected?: boolean;
}

/** Main description for this component. */
const MultiselectFilter: React.FC<MultiselectFilterModel> = ({
	name,
	searchPlaceholder,
	path,
	buckets,
	onChange,
	id,
	groupName,
}) => {
	const onSelectChange = (bucket: Bucket) => {
		const selectedOptions = buckets.filter(
			(item) => item.term !== bucket.term && item.selected
		);
		let selectBuckets = [];

		if (bucket.selected) {
			selectBuckets = [...selectedOptions];
		} else {
			selectBuckets = [
				...selectedOptions,
				Object.assign({}, bucket, { selected: true }),
			];
		}
		const aggregation = Object.assign(
			{},
			{
				name,
				path,
				buckets: selectBuckets,
			}
		);
		onChange(aggregation);
	};
	const [getUuid] = useUuid();
	const uid = getUuid();

	return (
		<MultiselectDropdown
			className="mb-4 mx-3 relative bg-white"
			buttonSrText="Expandera"
			id={`filter-${id}-${uid}`}
			legend={name}
			searchPlaceholder={searchPlaceholder}
			hasSearchField={true}
			dropdownClassName="w-full"
			selectedBuckets={buckets.filter((bucket) => bucket.selected).length}
		>
			{buckets.map((bucket, index) => (
				<MultiselectOption
					className="w-full"
					id={`filter-${id}-${index}`}
					key={index}
					value={bucket.term}
					name={path}
					checked={bucket.selected}
					data-filter={groupName}
					onChange={() => onSelectChange(bucket)}
				>
					{bucket.term}
				</MultiselectOption>
			))}
		</MultiselectDropdown>
	);
};

export default MultiselectFilter;
