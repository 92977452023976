/**
 * ArticlePage
 */

import React from 'react';
import {
	Grid,
	Cell,
	Toc,
	TocLink,
	ArticleHeader,
	ArticleHeaderCategory,
	ImageHeader,
	LinkList,
	LinkListItem,
} from 'ui-component-library/base';
import { ArticlePageModel } from 'types';
import BaseLayout from 'layouts/BaseLayout';
import Text from 'components/Text';
import EpiFragments from 'components/EpiFragments';
import clsx from 'clsx';
import Link from 'components/Link';
import EpiContentArea from 'components/EpiContentArea';
import { useIsDesktop } from 'hooks/useMediaQuery';
import { ArticleHeaderCategoryList } from 'ui-component-library/base/ArticleHeader';

/** ArticlePage component. */
const ArticlePage: React.FC<ArticlePageModel> = ({
	heading,
	preamble,
	text,
	tableOfContents,
	relatedLinks,
	articleHeader,
	imageHeader,
	contentArea,
}) => {
	const isDesktop = useIsDesktop();
	return (
		<BaseLayout>
			{imageHeader && (
				<ImageHeader
					src={imageHeader.src}
					alt={imageHeader.altText}
					figCaption={imageHeader.text}
					className="-mt-10 md:-mt-8"
				/>
			)}
			<Grid
				padding={false}
				margin={false}
				className={clsx(
					imageHeader ? 'pt-2 md:pt-8' : '-mt-4 md:mt-0',
					'lg:px-6 px-4'
				)}
			>
				<Cell span={12} desktop={7}>
					<Text as="h1" prop="heading">
						{heading}
					</Text>
					{articleHeader && (
						<ArticleHeader
							published={articleHeader.published}
							social={articleHeader.social}
							className="mb-8"
						>
							{articleHeader.categories && articleHeader.categories.length > 0 && (
								<ArticleHeaderCategoryList>
									{articleHeader.categories.map((category, index) => {
										return (
											<ArticleHeaderCategory
												key={index}
												url={category.url}
												text={category.text}
												LinkComponent={Link}
											/>
										);
									})}
								</ArticleHeaderCategoryList>
							)}
						</ArticleHeader>
					)}
				</Cell>
			</Grid>
			<Grid
				margin={false}
				padding={false}
				className={clsx(
					(tableOfContents || relatedLinks) && 'flex-row-reverse',
					'lg:px-6 px-4'
				)}
			>
				{isDesktop && (tableOfContents || relatedLinks) && (
					<Cell span={4} className="ml-1/12 pl-8">
						<div className="sticky toc">
							{tableOfContents && (
								<Toc
									heading={tableOfContents.heading}
									items={tableOfContents.chapters}
									className="mb-20"
								>
									{tableOfContents.titles.map((item, index) => (
										<TocLink href={`#${item.targetId}`} key={index}>
											{item.text}
										</TocLink>
									))}
								</Toc>
							)}
							{relatedLinks?.map((linkSection, index) => {
								return (
									<LinkList
										key={index}
										heading={linkSection?.heading}
										className="mb-12"
									>
										{linkSection.links?.map((item, subIndex) => (
											<LinkListItem
												text={item.text}
												url={item.href}
												icon={'arrow'}
												target={item.target}
												LinkComponent={Link}
												key={subIndex}
												external={item.external}
											/>
										))}
									</LinkList>
								);
							})}
						</div>
					</Cell>
				)}

				<Cell span={12} desktop={7}>
					<Text as="preamble" prop="preamble">
						{preamble}
					</Text>
					{!isDesktop && tableOfContents && (
						<Toc heading={tableOfContents.heading} items={[]} className="mb-8">
							{tableOfContents.titles.map((item, index) => (
								<TocLink href={`#${item.targetId}`} key={index}>
									{item.text}
								</TocLink>
							))}
						</Toc>
					)}
					{!isDesktop &&
						relatedLinks?.map((linkSection, index) => {
							return (
								<LinkList
									key={index}
									heading={linkSection?.heading}
									className="mb-12"
								>
									{linkSection.links?.map((item, subIndex) => (
										<LinkListItem
											text={item.text}
											url={item.href}
											icon={'arrow'}
											target={item.target}
											LinkComponent={Link}
											key={subIndex}
											external={item.external}
										/>
									))}
								</LinkList>
							);
						})}
					<EpiFragments prop="text" fragments={text?.fragments} />
				</Cell>
			</Grid>
			{contentArea && contentArea.length > 0 && (
				<EpiContentArea content={contentArea} />
			)}
		</BaseLayout>
	);
};

export default ArticlePage;
