/**
 * QuestionPortalCategoryPage
 */

import React, { useEffect, useRef, useState } from 'react';
import { useMachine } from '@xstate/react';
import {
	Grid,
	Cell,
	Button,
	Icon,
	SearchField,
} from 'ui-component-library/base';
import {
	DialogCategoryItemModel,
	QuestionPortalCategoryPageModel,
} from 'types';
import BaseLayout from 'layouts/BaseLayout';
import Text from 'components/Text';
import { showMorePaginationMachine } from 'state-machines/showMorePagination.machine';
import { useHistory, useLocation } from 'react-router-dom';
import EpiContentArea from 'components/EpiContentArea';
import Link from 'components/Link';
import { DialogList, DialogListItem } from 'ui-component-library/uhmse';

/** QuestionPortalCategoryPage component. */
const QuestionPortalCategoryPage: React.FC<QuestionPortalCategoryPageModel> = ({
	showMoreButtonText,
	numberOfHitsPerPage,
	apiUrl,
	listingModel,
	introBlock,
	contentArea,
}) => {
	const location = useLocation();
	const history = useHistory();

	const [state, send] = useMachine(showMorePaginationMachine);
	const [click, setClick] = useState(0);
	const searchfieldWrapperRef = useRef<any>(null);
	const searchfieldInputRef = useRef<any>(null);

	if (state.context.result) {
		listingModel = state.context.result;
	}

	const fetch = listingModel.offset + listingModel.fetch + numberOfHitsPerPage;
	const hasMore = listingModel.offset + listingModel.fetch < listingModel.total;

	useEffect(() => {
		if (click > 0) {
			send('FETCH', {
				offset: 0,
				fetch: fetch,
				apiUrl,
			});
		}

		// eslint-disable-next-line
	}, [click]);

	return (
		<BaseLayout themeBorder={true}>
			<Grid
				padding={false}
				margin={false}
				className="lg:px-6 px-4 -mt-4 md:mt-0"
			>
				<Cell span={12} desktop={7} className="lg:pr-4">
					{introBlock?.heading && (
						<Text as="h1" prop="heading">
							{introBlock.heading}
						</Text>
					)}
					{introBlock?.preamble && (
						<Text as="preamble" prop="preamble">
							{introBlock.preamble}
						</Text>
					)}
					{introBlock?.hasSearch && (
						<SearchField
							id="searchfieldCategoryPage"
							label={introBlock.placeholderSearchText}
							submitLabel={introBlock.performSearchText}
							className="text-black mb-8"
							wrapperRef={searchfieldWrapperRef}
							fieldRef={searchfieldInputRef}
							onSubmit={(e: any) => {
								searchfieldInputRef?.current?.blur();
								history.push(
									`${introBlock.searchPageUrl}?query=${encodeURIComponent(
										e.searchfieldCategoryPage
									)}`
								);
							}}
						></SearchField>
					)}
				</Cell>
			</Grid>
			<Grid padding={false} margin={false} className="lg:px-6 px-4 pt-4 mb-20">
				<Cell span={12} desktop={7} className="lg:pr-4 mb-8 lg:mb-0">
					<DialogList>
						{listingModel.items.map(
							(item: DialogCategoryItemModel, index: number) => {
								return (
									<DialogListItem
										key={index}
										updated={item.updated}
										heading={item.heading}
										numberOfComments={item.numberOfComments}
										url={item.url}
										headingLevel="h2"
										LinkComponent={Link}
									/>
								);
							}
						)}
					</DialogList>
					{hasMore && (
						<div className="flex justify-around mt-6">
							<Button
								data-button-action={showMoreButtonText}
								className="my-auto"
								variant="primary"
								disabled={state.value === 'loading'}
								onClick={() => {
									setClick(click + 1);
									history.push(`${location.pathname}?fetch=${fetch}`);
								}}
							>
								{showMoreButtonText}
								{state.value === 'loading' && (
									<Icon
										icon="loader"
										color="white"
										animate="spin"
										size={0.75}
										className="ml-2"
										aria-hidden={true}
									/>
								)}
								{state.value !== 'loading' && (
									<Icon
										icon="chevron"
										direction="up"
										color="white"
										size={0.75}
										className="ml-2"
										aria-hidden={true}
									/>
								)}
							</Button>
						</div>
					)}
				</Cell>
				{contentArea && contentArea.length > 0 && (
					<Cell span={12} desktop={4} className="lg:ml-1/12">
						<EpiContentArea content={contentArea} />
					</Cell>
				)}
			</Grid>
		</BaseLayout>
	);
};

export default QuestionPortalCategoryPage;
