/**
 * CriteriaCartDownloadButton
 */

import Axios from 'axios';
import clsx from 'clsx';
import React, { useState } from 'react';
import { Button, Icon } from 'ui-component-library/base';

interface Props {
	/** Button text */
	text: string;

	progressText: string;

	culture: string;

	format: string;

	api: string;

	criterias?: string[];

	disabled?: boolean;

	/** Pass an optional className */
	className?: string;

	onDownloadError?: any;

	items?: any;
}

const CriteriaCartDownloadButton: React.FC<Props> = ({
	text,
	progressText,
	culture,
	format,
	api,
	criterias,
	disabled,
	className,
	onDownloadError,
	items,
}) => {
	const [loading, setLoading] = useState<boolean>(false);
	const handleDownloadCriterias = async () => {
		setLoading(true);
		const criteriaData = {
			criteriaDescriptors: criterias,
			culture: culture,
			fileFormat: format,
		};

		try {
			const { data } = await Axios.post(api, criteriaData);
			let a = document.createElement('a');
			a.classList.add('hidden');
			document.body.appendChild(a);
			a.href = data.reportUrl;
			a.download = data.reportUrl;
			a.click();
			a.remove();
			onDownloadError('');

			if (window.dataLayer && window.dataLayer.push && items) {
				items.map((item: any) => {
					return window.dataLayer.push({
						event: 'criteriaDownload',
						criteriaProductArea: item.requirementInfo.criteriaProductArea,
						criteriaProductGroup: item.requirementInfo.criteriaProductGroup,
						criteriaProductSubgroup:
							item.requirementInfo.criteriaProductSubgroup,
						criteriaItem: item.requirementInfo.criteriaItem,
						criteriaLevel: item.requirementInfo.criteriaLevel,
						criteriaLanguage: culture,
						criteriaFormat: format,
					});
				});
			}
		} catch (error) {
			onDownloadError((error as any).toString());
		} finally {
			setLoading(false);
		}
	};
	return (
		<Button
			data-button-action="Kriterier nedladdning"
			data-download-item-count={criterias?.length}
			variant="primary"
			className={clsx('mr-3', className)}
			disabled={disabled || loading}
			onClick={() => handleDownloadCriterias()}
		>
			{loading ? (
				<span>
					<Icon
						icon="loader"
						animate="spin"
						className="mr-2 mb-1"
						aria-hidden={true}
					/>
					{progressText}
				</span>
			) : (
				<span>
					<Icon icon="download" className="mr-3 -mt-1" aria-hidden={true} />
					{text}
				</span>
			)}
		</Button>
	);
};

export default CriteriaCartDownloadButton;
