/**
 * SearchFieldSelectOption
 */

import React, { useEffect, useState } from 'react';
import { Icon } from 'ui-component-library/base';

interface Props {
	/** Should the search pane be open by default? */
	isOpen?: boolean;

	/** A item structured as JSON */
	item: any;

	/** On change function */
	onChange?: any;

	/** isCheck callback method, check whenever a checkbox is checked or not */
	isChecked?: any;
}

/** Search field select option. */
const StatisticsServiceFilterOption: React.FC<Props> = ({
	item,

	onChange,
	isOpen,
	isChecked,
	...props
}) => {
	const [checked, setChecked] = useState(isChecked(item));

	const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
		setChecked(!checked);
		onChange(e);
	};

	useEffect(() => {
		setChecked(isChecked(item));
	}, [item]);

	return (
		<li className="px-3 mt-4 text-p leading-5 transition-all duration-300">
			<div className="flex items-center justify-between">
				<label className="flex items-center" htmlFor={item.id}>
					<input
						type="checkbox"
						id={item.id}
						name={item.name}
						value={item.value}
						checked={checked}
						className="mr-2 sr-only checkbox"
						onChange={handleOnChange}
						disabled={item.disabled}
						{...props}
					/>
					<div className="flex-shrink-0 inline-flex items-center justify-center w-5 h-5 mr-2 border-grey border-2 border-solid rounded cursor-pointer checkbox__icon--teal transition-all duration-300 text-greyDark">
						{checked && <Icon icon="check" size={1.25} />}
					</div>
					{item.text}
				</label>
			</div>
		</li>
	);
};
export default StatisticsServiceFilterOption;
