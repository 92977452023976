/**
 * RiskTableLarge
 */

import React from 'react';
import { AssessmentModel } from 'types';
import { useUuid } from 'ui-component-library/hooks';
import NoneBouble from './svg/none.svg';

type RowHeader = {
	targetId: string;
	riskAreaName: string;
};

type ColumnHeader = {
	step: number;
	stepName: string;
};

interface Props {
	/** Table caption*/
	caption: string;

	/** Table row heading*/
	rowHeading: string;

	/** Table column heading*/
	columnHeading: string;

	/** Table matrix with cell data */
	dataMatrix: { [key: string]: { [key: number]: AssessmentModel } };

	/** Array with row headers */
	rowHeaders: RowHeader[];

	/** Array with column headers */
	columnHeaders: ColumnHeader[];

	/** Render assessment icon */
	getAssessmentIcon: Function;
}

const RiskTableLarge: React.FC<Props> = ({
	caption,
	rowHeading,
	columnHeading,
	dataMatrix,
	rowHeaders,
	columnHeaders,
	getAssessmentIcon,
}) => {
	const [getUuid] = useUuid();
	const id = getUuid();
	return (
		<table className="risk-table w-full min-w-168" data-cid="RiskTable">
			<caption className="sr-only">{caption}</caption>
			<thead>
				<tr>
					<td rowSpan={2} colSpan={1} aria-hidden={false}>
						&nbsp;
					</td>
					<th
						scope="colgroup"
						colSpan={columnHeaders.length}
						id={`risktable-${id}-colgroup`}
						className="text-p pb-4"
					>
						{columnHeading}
					</th>
				</tr>
				<tr>
					{columnHeaders.map((col, index) => {
						return (
							<th
								scope="col"
								id={`risktable-${id}-col-${col.step}`}
								headers={`risktable-${id}-colgroup`}
								className="risk-table__arrow relative font-normal pt-8 px-3 mx-6 bg-greyLighter"
								key={index}
							>
								{col.stepName}
								<span
									className="block text-h2 font-bold mt-4"
									aria-hidden={true}
								>
									{col.step}
								</span>
							</th>
						);
					})}
				</tr>
			</thead>
			<tbody>
				<tr>
					<th
						scope="rowgroup"
						id={`risktable-${id}-rowgroup`}
						className="text-left text-p"
					>
						{rowHeading}
					</th>
					{columnHeaders.map((col, index) => {
						return (
							<React.Fragment key={index}>
								{index < columnHeaders.length && (
									<td className="bg-greyLighter" aria-hidden={true}>
										&nbsp;
									</td>
								)}
							</React.Fragment>
						);
					})}
				</tr>
				{rowHeaders.map((row, index) => {
					return (
						<tr key={index}>
							<th
								scope="row"
								id={`risktable-${id}-row-${row.targetId}`}
								headers={`risktable-${id}-rowgroup`}
								className="text-left font-normal align-top pt-8"
							>
								<a
									href={`#${row.targetId}`}
									className="text-teal hover:underline"
								>
									{row.riskAreaName}
								</a>
							</th>
							{columnHeaders.map((col, index) => {
								const cellData = dataMatrix[row.targetId][col.step];
								return (
									<td
										headers={`risktable-${id}-row-${row.targetId} risktable-${id}-col-${cellData.step}`}
										className="text-center align-baseline py-8 px-3 mx-6 bg-greyLighter"
										key={index}
									>
										<img
											loading="lazy"
											className="w-auto m-auto mb-4 px-3"
											src={
												getAssessmentIcon(cellData.assessmentValue) ||
												NoneBouble
											}
											alt=""
										/>
										<p className="max-w-28 mx-auto my-0 text-p">
											{cellData.assessment}
										</p>
									</td>
								);
							})}
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

export default RiskTableLarge;
