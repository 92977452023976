/**
 * PublicationPage
 */

import React from 'react';
import {
	Grid,
	Cell,
	ArticleHeader,
	ArticleHeaderCategory,
} from 'ui-component-library/base';
import BaseLayout from 'layouts/BaseLayout';
import Text from 'components/Text';
import { PublicationPageModel } from 'types';
import EpiContentArea from 'components/EpiContentArea';
import { Publication, PublicationDetail } from 'ui-component-library/uhmse';
import { ArticleHeaderCategoryList } from 'ui-component-library/base/ArticleHeader';

/** Main description for this component. */
const PublicationPage: React.FC<PublicationPageModel> = ({
	heading,
	preamble,
	contentArea,
	articleHeader,
	publicationDetails,
	downloadText,
	publicationUrl,
	image,
	alt,
}) => {
	return (
		<BaseLayout>
			<Grid
				padding={false}
				margin={false}
				className="lg:px-6 px-4 -mt-4 md:mt-0"
			>
				<Cell span={12} desktop={7}>
					<Text as="h1" prop="heading">
						{heading}
					</Text>
					{articleHeader && (
						<ArticleHeader
							published={articleHeader.published}
							social={articleHeader.social}
							className="mb-8"
						>
							{articleHeader.categories && articleHeader.categories.length > 0 && (
								<ArticleHeaderCategoryList>
									{articleHeader.categories.map((category, index) => {
										return (
											<ArticleHeaderCategory
												key={index}
												url={category.url}
												text={category.text}
											/>
										);
									})}
								</ArticleHeaderCategoryList>
							)}
						</ArticleHeader>
					)}
					{image && alt && downloadText && publicationUrl && (
						<Publication
							image={image}
							alt={alt}
							downloadText={downloadText}
							publicationUrl={publicationUrl}
						>
							{publicationDetails &&
								publicationDetails.map((detail, index) => {
									return (
										<PublicationDetail
											heading={detail.heading}
											text={detail.text}
											key={index}
										/>
									);
								})}
						</Publication>
					)}
					<Text as="preamble" prop="preamble">
						{preamble}
					</Text>
				</Cell>
			</Grid>
			{contentArea && contentArea.length > 0 && (
				<EpiContentArea content={contentArea} />
			)}
		</BaseLayout>
	);
};

export default PublicationPage;
