/**
 * EditorList
 */

import React from 'react';
import { useState } from 'react';
import { Button } from 'ui-component-library/base';

interface Props {
	/** Number of editors to show when not expanded */
	showNumberOfEditors: number;

	/** Show more button text */
	showMoreButtonText: string;

	/** Show less button text */
	showLessButtonText: string;
}

/** Main description for this component. */
const EditorList: React.FC<Props> = ({
	showNumberOfEditors,
	showMoreButtonText,
	showLessButtonText,
	children,
}) => {
	const [expanded, setExpanded] = useState<boolean>(false);

	let totalEditors = 0;
	React.Children.forEach(children, () => {
		return totalEditors++;
	});

	const renderChildren = (children: any) => {
		return React.Children.map(children, (child, index) => {
			if (!child || index >= showNumberOfEditors) {
				return null;
			} else {
				return React.cloneElement(child, child.props || {});
			}
		});
	};

	return (
		<div data-cid="EditorList" className="mb-10 md:mb-16">
			<div className="flex flex-wrap mb-8" aria-live="polite">
				{expanded ? children : renderChildren(children)}
			</div>
			{totalEditors > showNumberOfEditors && (
				<Button
					variant="secondary"
					aria-expanded={!expanded}
					onClick={() => setExpanded(!expanded)}
				>
					{expanded ? showLessButtonText : showMoreButtonText}
				</Button>
			)}
		</div>
	);
};

export default EditorList;
