import React, { useState, useRef } from 'react';
import axios from 'axios';
import ResultTable from '../ResultTable/ResultTable';
import ResultDiagram from '../ResultDiagram/ResultDiagram';
import {
	Text,
	Form,
	Label,
	Field,
	ErrorSpan,
	Select,
	Icon,
	Tooltip,
} from 'ui-component-library/base';
import { Button } from 'ui-component-library/base/Form';
import { formData, hiddenFields } from './EkoForm-data';

const getDefaultValues = () => {
	const defaultValues = {};

	formData.forEach((section) => {
		section.content.forEach((item) => {
			if (item.defaultValue !== undefined) {
				defaultValues[item.name] = item.defaultValue;
			}
		});
	});

	return defaultValues;
};

const EkoForm = ({ apiUrl }) => {
	const [values, setValue] = useState(getDefaultValues());
	const [responseData, setResponseData] = useState(null);
	const [loading, setLoading] = useState(false);
	const resultRef = useRef(null);

	const handleInputChange = (field, formValues) => {
		setValue(formValues);
	};

	const handleSubmit = () => {
		setLoading(true);
		setResponseData(null);

		let newValues = { ...values };

		Object.keys(values).map((key) => {
			if (
				key === 'omfattningAvTjanst' ||
				key === 'tidForHandledningAndelAvHeltidstjanst' ||
				key === 'produktionsfaktorAndelAvBruttolon'
			) {
				newValues[key] = values[key] / 100;
			} else if (key === 'bonus' && values[key] === '') {
				newValues[key] = 0;
			}
			return false;
		});

		let query = Object.keys(newValues)
			.map(
				(k) => encodeURIComponent(k) + '=' + encodeURIComponent(newValues[k])
			)
			.join('&');

		axios
			.get(`${apiUrl}?` + query, {
				headers: {
					accepts: 'application/json',
				},
			})
			.then((response) => {
				setLoading(false);
				setResponseData(response.data);
				resultRef.current.focus();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleReset = () => {
		setResponseData(null);
		setLoading(false);
	};

	return (
		<>
			<Form
				action="/"
				initialValues={getDefaultValues()}
				onSubmit={handleSubmit}
				onChange={handleInputChange}
				onReset={handleReset}
			>
				{formData.map((section) => {
					return (
						<section key={section.heading}>
							<header>
								<Text as="h2" text={section.heading} />
							</header>

							{section.content.map((item) => {
								if (
									values['sysselsattningskrav'] &&
									hiddenFields[values['sysselsattningskrav']].indexOf(
										item.name
									) !== -1
								) {
									return false;
								} else {
									if (item.type === 'number') {
										return (
											<div key={item.name} className="mb-4">
												<Label
													htmlFor={item.name}
													required={item.requiredMessage ? true : false}
												>
													{item.label}
												</Label>
												<Tooltip label={item.name} description={item.tooltip} />
												<Field
													name={item.name}
													type={item.type}
													id={item.name}
													required={item.requiredMessage ? true : false}
													validationMessage={item.requiredMessage}
													pattern={item.pattern}
													patternMessage={item.patternMessage}
												/>
												<ErrorSpan fieldId={item.name} />
											</div>
										);
									} else {
										return (
											<div key={item.name} className="mb-4">
												<Label
													htmlFor={item.name}
													required={item.requiredMessage ? true : false}
												>
													{item.label}
												</Label>
												<Tooltip label={item.name} description={item.tooltip} />
												<Select
													id={item.name}
													name={item.name}
													options={item.options.map((option) => ({
														value: option.value,
														caption: option.label,
													}))}
													required={item.required ? true : false}
													validationMessage={item.required}
												/>
											</div>
										);
									}
								}
							})}
						</section>
					);
				})}
				<div className="mb-8">
					<Button type="submit" disabled={loading} className="mr-4">
						{loading ? (
							<Icon
								icon="loader"
								color="white"
								animate="spin"
								aria-hidden={true}
							/>
						) : (
							'Resultat'
						)}
					</Button>
					<Button type="reset" variant="secondary">
						Rensa formulär
					</Button>
				</div>
			</Form>
			<div className="outline-none" tabIndex="0" ref={resultRef}>
				{responseData ? (
					<div className="richtext">
						<ResultTable
							data={responseData}
							antalTjanster={values.antalTjanster}
						/>
					</div>
				) : null}
				{responseData ? <ResultDiagram data={responseData} /> : null}
			</div>
		</>
	);
};
export default EkoForm;
