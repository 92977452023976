import axios from 'axios';
import { assign, createMachine } from 'xstate';

type ComparisonEventType =
	| {
			type: 'FETCH';
			query: string;
	  }
	| { type: 'RESET'; apiUrl: string; model: any };

type ComparisonContext = {
	apiUrl: string;
	model: any;
	error: any;
};

const getCriteriaColumnData = async (apiUrl: string, querystring: any) => {
	const { data } = await axios.get(`${apiUrl}?${querystring}`, {
		headers: {
			'X-Content-Language':
				window?.__model?.languages?.culture || window.__culture,
			'Content-Language':
				window?.__model?.languages?.culture || window.__culture,
			'Accept-Language':
				window?.__model?.languages?.culture || window.__culture,
		},
	});

	return { data, querystring };
};

export const criteriaComparisonMachine = createMachine<
	ComparisonContext,
	ComparisonEventType
>(
	{
		id: 'criteriaComparisonMachine',
		initial: 'idle',
		context: {
			apiUrl: '',
			model: {},
			error: undefined,
		},
		states: {
			idle: {},
			loading: {
				invoke: {
					id: 'getData',
					src: (_context, event: any) => {
						return getCriteriaColumnData(_context.apiUrl, event.query);
					},
					onDone: {
						target: 'success',
						actions: assign({
							model: (_context, event) => event.data.data,
						}),
					},
					onError: {
						target: 'failure',
						actions: assign({
							error: (_context, event) => {
								return `Error ${event.data.response.status} - ${event.data.response.statusText}`;
							},
						}),
					},
				},
			},
			success: {},
			failure: {},
			reseting: {
				entry: ['resetContext'],
				always: [{ target: 'idle' }],
			},
		},
		on: {
			FETCH: [
				{
					target: 'loading',
					actions: [],
				},
			],
			RESET: 'reseting',
		},
	},
	{
		actions: {
			resetContext: assign({
				apiUrl: (context, event: any) => event.apiUrl || context.apiUrl,
				model: (context, event: any) => event.model || context.model,
			}),
		},
	}
);
