/**
 * ValfrihetModal
 */

import React, { useEffect, useState } from 'react';
import {
	Button,
	ErrorSpan,
	Field,
	Form,
	Icon,
	Label,
	Modal,
} from 'ui-component-library/base';

interface Props {
	heading?: string;

	preamble?: string;

	buttonText?: string;

	closeModal: Function;

	filters?: any;

	apiUrl?: string;

	state: any;

	send: any;
}

const ValfrihetModal: React.FC<Props> = ({
	heading,
	preamble,
	buttonText,
	filters,
	state,
	send,
	closeModal,
	apiUrl,
}) => {
	const [email, setEmail] = useState('');

	const EMAIL_REGEX =
		/(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi;

	return (
		<Modal
			className="md:-mr-8 md:-ml-8 w-full md:min-w-256"
			onToggle={() => {
				send('RESET_SUBMITFORM', {
					submitResponse: { heading: '', text: '' },
				});
				closeModal();
			}}
			closeBtnLabel={'Stäng'}
		>
			<div className="flex flex-col text-black">
				<div className="my-7 pl-7 pr-7 md:pl-24 md:pr-36">
					<h2 className="font-semibold">
						{state.context.submitResponse.heading
							? state.context.submitResponse.heading
							: heading}
					</h2>
				</div>
				<div className="pb-7 pl-7 pr-7 md:pl-24 md:pr-56">
					<div className="border-t absolute left-0 right-0" />
					<p className="pt-7">
						{state.context.submitResponse.text
							? state.context.submitResponse.text
							: preamble}
					</p>
					{!state.context.submitResponse.heading ? (
						<>
							{filters && (
								<div className="mt-12 mb-7 font-semibold">
									<Icon icon="bell" className="text-teal mr-3" aria-hidden />

									{filters.map((filter: any) => filter.bucketName).join(', ')}
								</div>
							)}

							<Form
								className="md:w-1/2"
								onSubmit={(e: any) => {
									send('SUBMIT', {
										submitApiUrl: apiUrl ?? '',
										email: email,
									});
								}}
								onChange={(e: { name: string; value: string }) => {
									setEmail(e.value);
								}}
							>
								<Label className="-ml-1" htmlFor={'email'} required={true}>
									E-post
								</Label>
								<Field
									name="email"
									type="email"
									id="email"
									label="E-post"
									pattern="(^$)|(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$)"
									patternMessage="Vänligen fyll i en giltig e-post"
									validationMessage={'Vänligen fyll i e-post'}
									value={email}
								/>
								<ErrorSpan fieldId={'email'} />

								<div className="mx-auto md:mx-0 pl-0 pt-14 pb-4">
									<div className="border-b absolute left-0 right-0" />
									{state.matches('submitting') ? (
										<Icon
											className="mt-7"
											icon="loader"
											size={3}
											animate="spin"
											color="#75726B"
											aria-label="Laddar"
										/>
									) : (
										<Button
											className="mt-7"
											disabled={!email.match(EMAIL_REGEX)}
											type="submit"
											color="teal"
										>
											{buttonText}
										</Button>
									)}
								</div>
							</Form>
						</>
					) : (
						<div className="mx-auto md:mx-0 pl-0 pt-14 pb-4">
							<div className="border-b absolute left-0 right-0" />
							<Button
								className="mt-7"
								color="teal"
								onClick={() => {
									send('RESET_SUBMITFORM', {
										submitResponse: { heading: '', text: '' },
									});
									closeModal();
								}}
							>
								Stäng
							</Button>
						</div>
					)}
				</div>
			</div>
		</Modal>
	);
};

export default ValfrihetModal;
