import { HighchartsBaseModel } from 'types';
import * as HighchartsJs from 'highcharts';

interface GetOptionsFromProps {
	(props: HighchartsBaseModel): HighchartsJs.Options;
}

export const getOptionsFromProps: GetOptionsFromProps = (props) => {
	const {
		chartType,
		title,
		subtitle,
		tooltipValueDecimals,
		tooltipValuePrefix,
		tooltipValueSuffix,
		yAxisTitle,
		yAxisLabelFormat,
		yAxisMin,
		yAxisMax,
		xAxisTitle,
		xAxisLabelFormat,
		xAxisCategories,
		series,
		stacked,
		showLegend,
		chartHeight,
	} = props;
	let stacking: 'normal' | 'percent' | undefined;

	switch (stacked) {
		case 'percent':
			stacking = 'percent';
			break;
		case true:
		case 'normal':
			stacking = 'normal';
			break;
		default:
			stacking = undefined;
			break;
	}

	const options: Highcharts.Options = {
		title: {
			text: title || undefined,
		},

		colors: [
			'#008A2B',
			'#6B2879',
			'#E05B27',
			'#00636A',
			'#779F32',
			'#231E13',
			'#DD2879',
			'#1F497D',
			'#7B7871',
			'#B4135B',
		],
		credits: {
			enabled: false,
		},
		subtitle: {
			text: subtitle || undefined,
		},
		chart: {
			type: chartType,
			style: {
				fontFamily: '"IBM Plex Sans", sans-serif',
			},
			height: chartHeight,
			marginRight: 15,
		},
		yAxis: {
			title: {
				text: yAxisTitle || undefined,
			},
			labels: {
				format: yAxisLabelFormat || undefined,
			},
			min: yAxisMin || undefined,
			max: yAxisMax || undefined,
			minTickInterval: 1,
		},
		xAxis: {
			title: {
				text: xAxisTitle || undefined,
			},
			categories: xAxisCategories || undefined,
			labels: {
				format: xAxisLabelFormat || undefined,
			},
			// categories: null,
		},
		plotOptions: {
			series: {
				stacking: stacking, //'normal',
			},
			pie: {
				dataLabels: {
					//alignTo: 'plotEdges',
					// 	padding: 0,
				},

				tooltip: {
					headerFormat: '<b>{point.key}</b><br/>',
					pointFormat: `${
						tooltipValueSuffix !== '%' ? 'Antal: <b>{point.y}</b><br/>' : ''
					}Andel: <b>{point.percentage:.1f} %</b>`,
				},
			},
		},
		legend: {
			enabled: showLegend !== false,
			maxHeight: 115,
		},
		tooltip: {
			valueDecimals: tooltipValueDecimals,
			valuePrefix: tooltipValuePrefix,
			valueSuffix: tooltipValueSuffix,
		},
		series: series,
		exporting: {
			sourceWidth: 1400,
			sourceHeight: 800,
			buttons: {
				contextButton: {
					enabled: false,
				},
			},
			chartOptions: {
				// specific options for the exported image
				plotOptions: {
					series: {
						dataLabels: {
							enabled: true,
						},
					},
					pie: {
						size: '50%',
						dataLabels: {
							distance: '120px',
							padding: 0,
							//alignTo: 'plotEdges',
							enabled: true,
							format: `{point.name}<br/>${
								tooltipValueSuffix !== '%' ? 'Antal: {point.y}<br/>' : ''
							}Andel: {point.percentage:.1f} %`,
						},
					},
				},
				legend: {
					navigation: {
						enabled: false,
					},
					itemDistance: 100,
				},
			},
		},
	};
	//console.log(options);

	return options;
};
