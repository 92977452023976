/**
 * ValfrihetAdPage
 */

import EpiFragments from 'components/EpiFragments';
import Link from 'components/Link';
import Text from 'components/Text';
import BaseLayout from 'layouts/BaseLayout';
import React from 'react';
import { ValfrihetAdPageModel } from 'types';
import { Button, Cell, Grid, Icon } from 'ui-component-library/base';

const ValfrihetAdPage: React.FC<ValfrihetAdPageModel> = ({
	heading,
	preamble,
	showBackLink,
	updated,
	logo,
	button,
	uhmNotice,
	regionType,
	regionName,
	businessAreas,
	startDate,
	endDate,
	contactPersons,
	registryNo,
}) => {
	return (
		<BaseLayout>
			<div className="-mt-10 md:-mt-8 pt-14 pb-48 -mb-24 bg-greyLightest">
				{showBackLink && (
					<Grid className="-mt-14">
						<Cell>
							<Link
								href="#"
								onClick={() => window.history.back()}
								className="text-teal flex items-center"
								data-cid="Back button"
							>
								<Icon
									aria-hidden={true}
									icon="chevron"
									size={0.75}
									className="mr-2"
								/>
								Tillbaka till din sökning
							</Link>
						</Cell>
					</Grid>
				)}
				<Grid
					padding={false}
					margin={false}
					className="lg:pl-10 pl-4 pr-4 lg:mx-0 mx-2 md:ml-4 md:mr-2"
				>
					<Cell>
						<Grid
							padding={false}
							className="px-7 py-14 bg-white shadow-sm rounded-sm md:flex-row text-black"
						>
							<Cell
								span={12}
								desktop={8}
								className="flex flex-col items-start justify-between lg:border-r-2 lg:pr-24"
							>
								<div>
									{heading && (
										<Text as="h1" margin={false} className="mb-2">
											{heading}
										</Text>
									)}
									{updated && (
										<p className="text-greyDarker leading-normal">
											Senast uppdaterad: {updated}
										</p>
									)}
									{preamble && (
										<Text as="p" className="my-7 break-words">
											{preamble}
										</Text>
									)}
									{button && (
										<Button
											color="teal"
											className="mt-7"
											as="a"
											href={button.url}
											target="_blank"
											rel="noopener noreferrer"
											data-button-action={button.text}
										>
											{button.text}
											<Icon className="ml-3" icon={button.icon} size={0.875} />
										</Button>
									)}
								</div>
								{uhmNotice && (
									<div className="lg:mr-32 mt-7 bg-greyLightest rounded-sm shadow-sm p-5 infobox">
										<EpiFragments prop="text" fragments={uhmNotice.fragments} />
									</div>
								)}
							</Cell>

							<Cell span={12} desktop={4}>
								<img
									loading="lazy"
									src={logo}
									alt={regionName}
									className="h-15 w-auto mt-14 lg:mt-0"
								/>
								<dl>
									<dt className="mt-24">{regionType}</dt>
									<dd className="font-semibold">{regionName}</dd>
									<dt className="mt-5">Verksamhetsområde</dt>
									<dd className="font-semibold">
										<ul>
											{businessAreas?.map((businessArea: any, i: number) => (
												<li key={i}>{businessArea}</li>
											))}
										</ul>
									</dd>
									{startDate && (
										<>
											<dt className="mt-5">Startdatum</dt>
											<dd className="font-semibold">{startDate}</dd>
										</>
									)}
									{endDate && (
										<>
											<dt className="mt-5">Slutdatum</dt>
											<dd className="font-semibold">{endDate}</dd>
										</>
									)}
									{registryNo && (
										<>
											<dt className="mt-5">Diarienummer</dt>
											<dd className="font-semibold">{registryNo}</dd>
										</>
									)}
								</dl>
								<Text as="h2" styleAs="p" margin={false} className="mt-14">
									{contactPersons.length > 1
										? 'Kontaktpersoner'
										: 'Kontaktperson'}
								</Text>
								<ul className="-mb-4">
									{contactPersons.map((cp: any, i: number) => (
										<li key={i}>
											<p className="font-semibold leading-normal">{cp.name}</p>
											<a
												title={cp.phone}
												className="text-teal"
												href={`tel:${cp.phone}`}
											>
												{cp.phone}
											</a>
											<a
												title={cp.email}
												className="text-teal block mb-4 truncate max-w-64 md:max-w-xs"
												href={`mailto:${cp.email}`}
											>
												{cp.email}
											</a>
										</li>
									))}
								</ul>
							</Cell>
						</Grid>
					</Cell>
				</Grid>
			</div>
		</BaseLayout>
	);
};

export default ValfrihetAdPage;
