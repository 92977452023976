/**
 * ValfrihetSearchPage
 */

import clsx from 'clsx';
import BaseLayout from 'layouts/BaseLayout';
import React, { useEffect, useState } from 'react';
import { useMachine } from '@xstate/react';
import {
	AggregationsModel,
	SortingOptionModel,
	ValfrihetStartPageModel,
} from 'types';
import {
	Button,
	ButtonSupportive,
	Cell,
	FilterChip,
	Grid,
	Icon,
	IntroBlock,
	MultiselectDropdown,
	MultiselectOption,
	SearchField,
	Text,
} from 'ui-component-library/base';
import { ValfrihetAdBlock } from 'ui-component-library/uhmse';
import queryString from 'query-string';
import { valfrihetMachine } from 'state-machines/valfrihet.machine';
import { useHistory, useLocation } from 'react-router-dom';
import SearchSortList from 'components/SearchSortList/SearchSortList';
import { useFirstRender } from 'ui-component-library/hooks';
import { getActiveAggregations } from 'utils/helpers';
import Link from 'components/Link';
import { useSelector } from 'react-redux';
import { selectContent, selectMetaData } from 'store/modules/model';
import ValfrihetMonitorModal from 'components/ValfrihetMonitorModal';

type SelectedFilterType = {
	bucketId: string;
	bucketName: string;
	selectedBuckets: number;
};

const getSelectedFilters = (
	filters: AggregationsModel[]
): SelectedFilterType[] => {
	let selectedFilters: SelectedFilterType[] = [];

	filters.forEach((filter) => {
		filter.buckets.forEach((bucket, index) => {
			if (bucket.selected) {
				selectedFilters.push({
					bucketId: `${filter.path}-${index}`,
					bucketName: bucket.name || bucket.term,
					selectedBuckets: filter.selectedBuckets,
				});
			}
		});
	});

	return selectedFilters;
};

const ValfrihetStartPage: React.FC<ValfrihetStartPageModel> = ({
	url: defaultUrl,
	apiUrl,
	loginButton,
	heading,
	preamble,
	introBlock,
	subNavigation,
	numberOfHitsPerPage,
	searchPlaceholder,
	searchResult,
	showMoreButtonText,
	searchMonitor,
}) => {
	const [showMoreFilters, setShowMoreFilters] = useState(false);
	const [modal, setModal] = useState(false);

	const pageContent = useSelector(selectContent);
	const metaData = useSelector(selectMetaData);
	const location = useLocation();
	const { query } = queryString.parse(location.search);
	const history = useHistory();
	const isFirstRender = useFirstRender();
	const [state, send] = useMachine(valfrihetMachine, {
		context: {
			apiUrl: apiUrl,
			query: query as string,
			sorting: searchResult?.sorting,
			results: searchResult?.results || [],
			aggregations: searchResult?.aggregations,
			selectedAggregations:
				searchResult?.aggregations &&
				getActiveAggregations(searchResult?.aggregations),
			fetch: searchResult?.fetch,
			numberOfHitsPerPage: numberOfHitsPerPage,
			router: history,
			numberOfHits: searchResult?.numberOfHits,
		},
	});

	const { fetch, orderBy, results, sorting, aggregations, numberOfHits } =
		state.context || {};

	const [filters, setFilters] = useState(aggregations || []);

	const onQueryChange = (e: any) => {
		send('QUERY_SEARCH', {
			query: e.searchfieldValfrihetStartPage,
		});
	};

	const resetFilters = () => {
		const newFilterObj: AggregationsModel[] = JSON.parse(
			JSON.stringify(filters)
		);
		send('RESET_FILTERS');
		newFilterObj.forEach((filter) =>
			filter.buckets.forEach((bucket, index) => {
				bucket.selected = false;
				--filter.selectedBuckets;
			})
		);

		setFilters(newFilterObj);
	};

	const filterOnChange = (
		e?: React.FormEvent<HTMLInputElement>,
		uncheckFilter?: string
	) => {
		const newFilterObj: AggregationsModel[] = JSON.parse(
			JSON.stringify(filters)
		);

		if (e) {
			const { id, name, checked } = e.currentTarget;
			newFilterObj.forEach((filter) =>
				filter.buckets.forEach((bucket, index) => {
					if (filter.path === name && `${filter.path}-${index}` === id) {
						bucket.selected = checked;
						if (!checked) {
							--filter.selectedBuckets;
						} else {
							++filter.selectedBuckets;
						}
					}
				})
			);
		} else if (uncheckFilter) {
			newFilterObj.forEach((filter) =>
				filter.buckets.forEach((bucket, index) => {
					if (`${filter.path}-${index}` === uncheckFilter) {
						bucket.selected = false;
						--filter.selectedBuckets;
					}
				})
			);
		}

		send('FILTER_CHANGE', {
			aggregations: newFilterObj,
		});

		setFilters(newFilterObj);
	};

	useEffect(() => {
		if (query && query.length > 0) {
			if (window.dataLayer && window.dataLayer.push) {
				window.dataLayer.push({
					event: 'hluAdSearch',
					searchQuery: query,
					totalSearchResults: numberOfHits,
				});
			}
		}

		// if (aggregations) {
		// 	send('FILTER_CHANGE', {
		// 		aggregations: aggregations,
		// 	});

		// 	aggregations && setFilters(aggregations);
		// }
	}, [numberOfHits]);

	useEffect(() => {
		if (isFirstRender && window.dataLayer && window.dataLayer.push) {
			window.dataLayer.push({
				event: 'Pageview',
				pagePath: `${location.pathname + location.search}`,
				pageTitle: metaData?.title,
				pageType: pageContent?.modelType,
			});
			// Reset GTM scroll-tracker
			if (window.__ScrollTracker) {
				window.__ScrollTracker.reset();
			}
		}
	}, []);

	useEffect(() => {
		if (!isFirstRender) {
			send('RESET', {
				query: searchResult?.query,
				results: searchResult,
			});

			send('SEARCH', {
				query,
				fetch: numberOfHitsPerPage,
			});
		}
		// eslint-disable-next-line
	}, [defaultUrl]);

	useEffect(() => {
		if (!isFirstRender) {
			if (query != null && query !== state.context.query) {
				send('INIT', {
					query,
				});
			}
		}

		// eslint-disable-next-line
	}, [query]);

	useEffect(() => {
		if (isFirstRender) {
			filters &&
				filters
					.filter((f) => f.hiddenByDefault)
					.map((f) => {
						f.buckets.map((b) => {
							b.selected && setShowMoreFilters(true);
						});
					});
		}
	});

	return (
		<BaseLayout>
			{modal && searchMonitor && (
				<ValfrihetMonitorModal
					filters={filters && getSelectedFilters(filters)}
					closeModal={() => setModal(false)}
					state={state}
					send={send}
					{...searchMonitor}
				/>
			)}

			<div className="-mt-10 md:-mt-8">
				<IntroBlock>
					<Grid padding={false} className="lg:pl-6 pl-4">
						<Cell span={12} desktop={7}>
							{introBlock?.heading && (
								<Text as="h1" margin={false} className="relative mb-2">
									{introBlock.icon && (
										<Icon
											icon={introBlock.icon}
											size={3}
											className="absolute top-0 left-0 mt-1 -ml-12 hidden xl:block"
											aria-hidden={true}
										/>
									)}
									{introBlock?.heading}
									<span className="sr-only">{heading}</span>
								</Text>
							)}
							{introBlock?.preamble && (
								<Text as="p" margin={false}>
									{introBlock?.preamble}
								</Text>
							)}
						</Cell>
						<Cell span={12} desktop={5} className="flex items-start">
							<a
								data-cid="Login button"
								className="border-2 rounded-full lg:mr-0 text-white lg:ml-auto mt-7 lg:mt-0 flex items-center px-4 py-2"
								href={loginButton?.url ?? ''}
							>
								{loginButton?.text} <Icon className="ml-2" icon="user" />
							</a>
						</Cell>
					</Grid>
				</IntroBlock>
			</div>
			<div className="bg-white">
				<Grid padding={false} className="lg:pl-6 pl-4">
					{subNavigation && (
						<Cell span={12} className="mt-5 mb-7" data-cid="Submenu links">
							<ul className="flex space-x-14">
								{subNavigation.map((item, index) => (
									<li key={index}>
										<Link
											to={item.url}
											className={clsx(
												item.isActive && 'border-b-2 border-teal',
												'text-teal font-medium pb-1'
											)}
										>
											{item.text}
										</Link>
									</li>
								))}
							</ul>
						</Cell>
					)}
				</Grid>
			</div>
			<div className="bg-greyLightest">
				<Grid padding={false} className="lg:pl-6 pl-4">
					<Cell span={12} desktop={8} className="mt-7">
						{heading && (
							<Text as="h2" className="text-black">
								{heading}
							</Text>
						)}
						{preamble && <Text as="p">{preamble}</Text>}

						<SearchField
							className="mt-8"
							id="searchfieldValfrihetStartPage"
							label={searchPlaceholder ?? 'Sök'}
							submitLabel="Genomför sökning"
							onSubmit={onQueryChange}
							defaultValue={query ? query.toString() : undefined}
							onChange={(e: any) =>
								e.value === '' &&
								send('QUERY_SEARCH', {
									query: '',
								})
							}
						/>
					</Cell>

					<Cell span={12} desktop={8}>
						<form>
							<div className="grid grid-cols-1 md:grid-cols-3 mt-7 md:my-5 gap-0 md:gap-5">
								{filters
									?.filter((a) => !a.hiddenByDefault)
									.map((filter, i) => (
										<div className="mb-3 md:mb-0" key={filter.path}>
											<MultiselectDropdown
												searchPlaceholder={'Sök ' + filter.name.toLowerCase()}
												hasSearchField={filter.buckets.length >= 10 && true}
												id={`${filter.path}-${i}`}
												legend={filter.name}
												className="bg-white"
												selectedBuckets={filter.selectedBuckets}
												dropdownClassName="left-4 right-4 md:right-auto md:left-auto"
											>
												{filter.buckets.map((bucket, index) => (
													<MultiselectOption
														key={index}
														id={`${filter.path}-${index}`}
														checked={bucket.selected}
														name={filter.path}
														value={bucket.term}
														disabled={bucket.disabled}
														onChange={filterOnChange}
														//count={bucket.count}
														data-filter={filter.name}
														data-filter-value={bucket.name}
													>
														{bucket.name}
													</MultiselectOption>
												))}
											</MultiselectDropdown>
										</div>
									))}
							</div>
							{showMoreFilters && (
								<p
									className="text-teal flex items-center cursor-pointer mb-2 md:mb-0 leading-normal"
									onClick={() => setShowMoreFilters(false)}
									data-filter-action="Hide filters"
								>
									Dölj fler filter
									<Icon
										icon="chevron"
										size={0.625}
										direction="down"
										className="ml-2"
									/>
								</p>
							)}
							{showMoreFilters && (
								<div className="grid grid-cols-1 md:grid-cols-3 mb-7 md:my-5 gap-0 md:gap-5">
									{filters
										?.filter((a) => a.hiddenByDefault)
										.map((filter, i) => (
											<div className="mb-3 md:mb-0" key={filter.path}>
												<MultiselectDropdown
													searchPlaceholder={'Sök ' + filter.name.toLowerCase()}
													hasSearchField={filter.buckets.length >= 10 && true}
													id={`${filter.path}-${i}`}
													legend={filter.name}
													className="bg-white"
													selectedBuckets={filter.selectedBuckets}
													dropdownClassName="left-4 right-4 md:right-auto md:left-auto"
												>
													{filter.buckets.map((bucket, index) => (
														<MultiselectOption
															key={index}
															id={`${filter.path}-${index}`}
															checked={bucket.selected}
															name={filter.path}
															value={bucket.term}
															disabled={bucket.disabled}
															onChange={filterOnChange}
															//count={bucket.count}
															data-filter={filter.name}
															data-filter-value={bucket.name}
														>
															{bucket.name}
														</MultiselectOption>
													))}
												</MultiselectDropdown>
											</div>
										))}
								</div>
							)}
							{!showMoreFilters && (
								<p
									className="text-teal flex items-center cursor-pointer mb-4 leading-normal"
									onClick={() => setShowMoreFilters(true)}
									data-filter-action="Show more filters"
								>
									Visa fler filter
									<Icon
										icon="chevron"
										size={0.625}
										direction="up"
										className="ml-2"
									/>
								</p>
							)}
							<div className="flex flex-wrap mt-2">
								{filters &&
									getSelectedFilters(filters).map((item, i) => (
										<FilterChip
											onClick={() => {
												filterOnChange(undefined, item.bucketId);
											}}
											className="mr-4 mb-2"
											key={i}
											data-button-action="Rensa filter"
										>
											<span className="sr-only">Rensa</span>
											{item.bucketName}
										</FilterChip>
									))}
								{filters && getSelectedFilters(filters).length > 0 && (
									<ButtonSupportive
										data-button-action="Rensa filter"
										aria-label="Rensa filter"
										className="pl-3 pr-2"
										onClick={() => {
											resetFilters();
										}}
									>
										Rensa filter{' '}
										<Icon
											aria-hidden={true}
											icon="trashCan"
											className="mb-1 ml-1"
										/>
									</ButtonSupportive>
								)}
							</div>
							{filters && getSelectedFilters(filters).length > 0 && (
								<ButtonSupportive
									onClick={(e) => {
										e.preventDefault();
										setModal(true);
									}}
									data-button-action="Monitor search"
									className="mt-6 pl-3 pr-2"
								>
									Bevaka sökning
									<Icon
										aria-hidden={true}
										icon="bell"
										size={0.75}
										className="ml-1"
									/>
								</ButtonSupportive>
							)}
						</form>
					</Cell>

					<Cell
						span={12}
						desktop={8}
						className="mt-3 flex flex-col space-y-4 mb-20"
					>
						<div className="-mb-4 pl-2 flex flex-row justify-between">
							{numberOfHits && numberOfHits > 0 ? (
								<div
									className={clsx(
										sorting && numberOfHits === 1 && 'mb-1',
										'text-greyDarker mt-3'
									)}
								>
									{numberOfHits} annons
									{numberOfHits > 1 ? 'er' : ''}
								</div>
							) : (
								<div
									className="text-greyDarker mt-3"
									id="Search results"
									data-cid="Zero results message"
								>
									Inget resultat.
								</div>
							)}

							{sorting && numberOfHits > 1 && (
								<SearchSortList
									buttonText={
										sorting.options.find(
											(option: SortingOptionModel) => option.path === orderBy
										)?.name || sorting.placeholder
									}
								>
									{sorting.options.map(
										(option: SortingOptionModel, index: number) => {
											return (
												<button
													className="px-3 py-2 w-full text-left whitespace-no-wrap hover:bg-greyLight"
													onClick={() => {
														send('SORT', {
															orderBy: option.path,
														});
													}}
													data-filter={sorting.placeholder}
													value={option.name}
													key={index}
												>
													{option.name}
												</button>
											);
										}
									)}
								</SearchSortList>
							)}
						</div>
						{results?.length > 0 &&
							results.map((value: any) => (
								<ValfrihetAdBlock
									key={value.documentId}
									url={value.url}
									heading={value.heading}
									regionName={value.regionName}
									excerpt={value.excerpt}
									businessAreas={value.businessAreas.join(', ')}
									updated={value.updated}
								/>
							))}

						{numberOfHits > fetch && (
							<div className="flex justify-around">
								<Button
									data-button-action={showMoreButtonText}
									color="teal"
									variant="primary"
									className="my-auto"
									disabled={state.value === 'loading'}
									onClick={() =>
										send('LOAD_MORE', {
											fetch: fetch + numberOfHitsPerPage,
										})
									}
								>
									{showMoreButtonText}
									{state.value === 'loading' && (
										<Icon
											icon="loader"
											color="white"
											animate="spin"
											size={0.75}
											className="ml-2"
											aria-hidden={true}
										/>
									)}
									{state.value !== 'loading' && (
										<Icon
											icon="chevron"
											direction="up"
											color="white"
											size={0.75}
											className="ml-2"
											aria-hidden={true}
										/>
									)}
								</Button>
							</div>
						)}
					</Cell>
				</Grid>
			</div>
		</BaseLayout>
	);
};

export default ValfrihetStartPage;
