/**
 * CriteriaCartToast
 */

import clsx from 'clsx';
import { usePrevious } from 'hooks';
import React, { useEffect, useRef, useState } from 'react';
import { Alert } from 'ui-component-library/base';
import { Transition } from 'react-transition-group';
import { TransitionState } from 'ui-component-library/base/types';
import Text from 'components/Text';

interface Props {
	/** Toast message */
	text: string;

	/** Current criteria cart quantity */
	currentQuantity: number;
}

const CriteriaCartToast: React.FC<Props> = ({ text, currentQuantity }) => {
	const previousQuantity = usePrevious(currentQuantity);
	const [visible, setVisible] = useState<boolean>(false);
	const timer = useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		if (currentQuantity > previousQuantity) {
			if (timer.current != null) {
				clearTimeout(timer.current);
			}

			setVisible(true);
			timer.current = setTimeout(() => {
				setVisible(false);
			}, 6000);
		}

		// clear on component unmount
		return () => {
			if (timer.current != null) {
				clearTimeout(timer.current);
				setVisible(false);
			}
		};
		// eslint-disable-next-line
	}, [currentQuantity]);

	return (
		<div
			className={clsx(
				visible && 'h-full',
				'fixed top-0 right-0 z-50 mt-8 mr-8'
			)}
			aria-live="polite"
		>
			{
				<Transition in={visible} timeout={600}>
					{(state: TransitionState) => (
						<div
							className={clsx(
								state === 'exiting'
									? 'animate-moveOutRight'
									: 'animate-moveInRight'
							)}
							hidden={state === 'exited'}
						>
							<Alert variant="success" onClick={() => setVisible(false)}>
								<Text as="p" margin={false} className="mb-0">
									{text}
								</Text>
							</Alert>
						</div>
					)}
				</Transition>
			}
		</div>
	);
};

export default CriteriaCartToast;
