/**
 * ProductGroupPageV2
 */

import React, { useEffect, useState, useRef } from 'react';
import { useMachine } from '@xstate/react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
	Grid,
	Cell,
	LinkList,
	LinkListItem,
	Icon,
	Button,
	Alert,
} from 'ui-component-library/base';
import Link from 'components/Link';
import { AggregationsModel, ProductGroupPageV2Model } from 'types';
import BaseLayout from 'layouts/BaseLayout';
import Text from 'components/Text';
import clsx from 'clsx';
import EpiFragments from 'components/EpiFragments';
import MultiSelectFilter from 'components/MultiSelectFilter';
import EpiContentArea from 'components/EpiContentArea';
import { selectContent, selectLanguageSelector } from 'store/modules/model';
import { filterSortMachine } from 'state-machines/filterSort.machine';
import { getFilterSortQuery } from 'utils/helpers';
import { DataTableProductGroup } from 'components/DataTableProductGroup';
import DataTableProductGroupRow from 'components/DataTableProductGroup/DataTableProductGroupRow';

/** ProductGroupPageV2 component. */
const ProductGroupPageV2: React.FC<ProductGroupPageV2Model> = ({
	heading,
	preamble,
	showMorePreamble,
	showLessPreamble,
	linkList,
	requirements: defaultRequirements,
	tableCaptionText,
	text,
	contentArea,
	apiUrl,
	clearFilterText,
	expandFilterText,
	filterHeading,
	compareButton,
}) => {
	const location = useLocation();
	const history = useHistory();
	const [requirements, setRequirements] = useState(defaultRequirements);
	const [state, send] = useMachine(filterSortMachine);
	const ariaLiveRef = useRef<HTMLDivElement>(null);
	const [preambleExpanded, setPreambleExpanded] = useState<boolean>(false);
	const languages = useSelector(selectLanguageSelector);
	const pageContent = useSelector(selectContent);
	const [queries, setQueries] = useState({ filterQuery: '', sortQuery: '' });

	useEffect(() => {
		setRequirements(defaultRequirements);
	}, [defaultRequirements]);

	useEffect(() => {
		if (state.context.result) {
			setRequirements(state.context.result);
		}
	}, [state.context.result]);

	const onFilterChange = (filters: AggregationsModel, filterQuery: string) => {
		setQueries({ ...queries, filterQuery: filterQuery });
		send('FETCH', {
			apiUrl: apiUrl,
			filterQuery,
			sortQuery: queries.sortQuery,
		});

		let query = getFilterSortQuery(filterQuery, queries.sortQuery);
		history.push(`${location.pathname}${query}`);
	};

	const onSortChange = (property: string, direction: string) => {
		let sortQuery = '';
		if (direction) {
			sortQuery = `${property}_${direction}`;
		}

		setQueries({ ...queries, sortQuery: sortQuery });
		send('FETCH', {
			apiUrl: apiUrl,
			filterQuery: queries.filterQuery,
			sortQuery,
		});
		let query = getFilterSortQuery(queries.filterQuery, sortQuery);
		history.push(`${location.pathname}${query}`);
	};

	return (
		<BaseLayout themeBorder={true}>
			<div className="sr-only" aria-live="assertive" ref={ariaLiveRef}></div>
			<Grid
				padding={false}
				margin={false}
				className="lg:px-6 px-4 -mt-4 md:mt-0"
			>
				<Cell span={12} desktop={10} className="lg:ml-1/12">
					<Text as="h1" prop="heading">
						{heading}
					</Text>

					<div className="mb-8">
						{languages &&
							languages.languages &&
							languages.languages.length > 0 && (
								<div className="flex flex-col mb-4">
									{languages.languages.map((item, index) => {
										return (
											<Link
												to={item.url}
												key={index}
												className="text-teal text-m hover:underline"
											>
												{item.text}
											</Link>
										);
									})}
								</div>
							)}
						<Text
							as="preamble"
							prop="preamble"
							margin={false}
							className={clsx(
								!preambleExpanded &&
									showLessPreamble &&
									showLessPreamble &&
									'line-clamp-2',
								'lg:max-w-3xl'
							)}
							id="preamble"
						>
							{preamble}
						</Text>
						{showMorePreamble && showLessPreamble && (
							<button
								className="text-h3 text-teal"
								aria-expanded={preambleExpanded}
								aria-controls="preamble"
								onClick={() => setPreambleExpanded(!preambleExpanded)}
							>
								{preambleExpanded ? (
									<span>
										{showLessPreamble}
										<Icon icon="chevron" direction="down" className="ml-2" />
									</span>
								) : (
									<span>
										{showMorePreamble}
										<Icon icon="chevron" direction="up" className="ml-2" />
									</span>
								)}
							</button>
						)}
					</div>
					{requirements?.aggregations && requirements?.aggregations.length > 0 && (
						<div className="mb-6">
							{filterHeading && (
								<Text as="h2" styleAs="h4">
									{filterHeading}
								</Text>
							)}
							<MultiSelectFilter
								filters={requirements.aggregations}
								onChange={onFilterChange}
								clearText={clearFilterText}
								expandText={expandFilterText}
							/>
							<div aria-live="polite" className="md:w-1/2">
								{state.value === 'failure' && (
									<Alert variant="danger" className="mt-4">
										<Text as="p" margin={false} className="mb-0">
											{state.context.error}
										</Text>
									</Alert>
								)}
							</div>
						</div>
					)}
					<div className="flex flex-wrap md:-mx-4 sm:w-full lg:w-3/4 mb-6">
						{linkList &&
							linkList.length > 0 &&
							linkList.map((list, index) => {
								return (
									<LinkList
										key={index}
										heading={list.heading}
										tooltipLabel={list.tooltipLabel}
										tooltipText={list.tooltipText}
										width={list.width}
										className={clsx(
											list.width === 50 && 'md:w-49/100',
											list.width === 100 && 'w-full',
											'md:px-4'
										)}
									>
										{list.items &&
											list.items.length > 0 &&
											list.items.map((item, index) => {
												return (
													<LinkListItem
														text={item.text}
														url={item.url}
														LinkComponent={Link}
														key={index}
														external={item.external}
													/>
												);
											})}
									</LinkList>
								);
							})}
					</div>
					{compareButton && (
						<Button
							data-button-action={compareButton.text}
							as="a"
							LinkComponent={Link}
							href={compareButton.url}
						>
							{compareButton.text}
						</Button>
					)}
				</Cell>
				<Cell span={12} desktop={10} className="lg:ml-1/12">
					{requirements?.properties &&
						requirements.properties.length > 0 &&
						requirements?.items &&
						requirements?.items.length > 0 && (
							<DataTableProductGroup
								{...requirements}
								caption={tableCaptionText}
								className="mt-6 mb-14"
								onSortChange={onSortChange}
							>
								{requirements?.items?.map((row, index) => {
									return (
										<DataTableProductGroupRow
											row={row}
											headers={requirements.properties || []}
											key={index}
											pageContent={pageContent}
										/>
									);
								})}
							</DataTableProductGroup>
						)}
				</Cell>
				<Cell span={12} desktop={7} className="lg:ml-2/12">
					<EpiFragments fragments={text.fragments} />
				</Cell>
			</Grid>
			{contentArea && contentArea.length > 0 && (
				<EpiContentArea content={contentArea} />
			)}
		</BaseLayout>
	);
};

export default ProductGroupPageV2;
