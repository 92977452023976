/**
 * StartPage
 */

import React, { useRef } from 'react';
import queryString from 'query-string';
import {
	Grid,
	Cell,
	IntroBlock,
	SearchField,
	IntroBlockArrow,
	Icon,
} from 'ui-component-library/base';
import BaseLayout from 'layouts/BaseLayout';
import Text from 'components/Text';
import EpiContentArea from 'components/EpiContentArea';
import {
	SearchFieldDropdown,
	SearchFieldDropdownItem,
} from 'ui-component-library/base/SearchField';
import { SearchListItemTypes, StartPageModel } from 'types';
import { useMachine } from '@xstate/react';
import { searchMachine } from 'state-machines/search.machine';
import { useSelector } from 'react-redux';
import { selectSearch } from 'store/modules/model';
import { useHistory, useLocation } from 'react-router-dom';
import { searchFieldMachine } from 'state-machines/searchField.machine';
import Link from 'components/Link';
import { useOutsideClick } from 'ui-component-library/hooks';
import { useIsTabletOrLarger } from 'hooks/useMediaQuery';

/** StartPage component. */
const StartPage: React.FC<StartPageModel> = ({
	heading,
	preamble,
	introBlock,
	navigationList,
	contentArea,
}) => {
	const isTabletOrLarger = useIsTabletOrLarger();
	const location = useLocation();
	const history = useHistory();
	const searchSettings = useSelector(selectSearch);
	const { query } = queryString.parse(location.search);
	const searchfieldWrapperRef = useRef<any>(null);
	const searchfieldInputRef = useRef<any>(null);
	const [stateSearch, sendSearch] = useMachine(searchMachine, {
		context: {
			apiUrl: introBlock?.apiUrl || searchSettings?.apiUrl,
			size: isTabletOrLarger ? 5 : searchSettings?.numberOfHitsPerPage || 5,
			debounce: 500,
		},
	});
	const [stateField, sendField] = useMachine(searchFieldMachine);

	useOutsideClick(searchfieldWrapperRef, () => {
		if (searchSettings) {
			sendField('CLOSE');
		}
	});

	return (
		<BaseLayout>
			{introBlock && (
				<div className="-mt-10 md:-mt-8">
					<IntroBlock>
						<Grid padding={false} className="lg:pl-6 pl-4">
							<Cell span={12} desktop={7}>
								{introBlock?.heading && (
									<Text as="h1" margin={false} className="mb-2">
										{introBlock?.heading}
									</Text>
								)}
								{introBlock?.preamble && (
									<Text as="p" margin={false}>
										{introBlock?.preamble}
									</Text>
								)}
								{introBlock?.hasSearch && (
									<>
										<SearchField
											id="searchfieldIntroBlock"
											label={introBlock.placeholderSearchText}
											submitLabel={introBlock.performSearchText}
											className="text-black mt-6"
											wrapperRef={searchfieldWrapperRef}
											fieldRef={searchfieldInputRef}
											showSpinner={stateSearch.value === 'loading'}
											defaultValue={query ? query.toString() : undefined}
											onChange={(e: any) => {
												stateField.value === 'active' &&
													sendSearch('SEARCH', {
														value: e.value,
													});
											}}
											onSubmit={(e: any) => {
												sendField('CLOSE');
												searchfieldInputRef?.current?.blur();
												history.push(
													`${
														introBlock.searchPageUrl
													}?query=${encodeURIComponent(
														e.searchfieldIntroBlock
													)}`
												);
											}}
											onFocus={() => {
												setTimeout(() => {
													sendField('OPEN');
												}, 200);
											}}
										>
											{stateField.value === 'active' &&
												stateSearch.context.result &&
												stateSearch.context.query !== '' && (
													<SearchFieldDropdown>
														{stateSearch.context.result.results &&
															stateSearch.context.result.results.map(
																(item: SearchListItemTypes) => (
																	<SearchFieldDropdownItem
																		key={item.documentId}
																	>
																		<Link to={item.url}>
																			<Icon
																				icon="chevron"
																				direction="left"
																				aria-hidden={true}
																				size={0.75}
																			/>
																			{item.heading}
																		</Link>
																	</SearchFieldDropdownItem>
																)
															)}
														{stateSearch.context.result.numberOfHits >
															stateSearch.context.result.size && (
															<SearchFieldDropdownItem>
																<Link
																	to={`${
																		searchSettings?.searchPageUrl
																	}?query=${encodeURIComponent(
																		stateSearch.context.result.query
																	)}`}
																	onClick={() => {
																		sendField('CLOSE');
																	}}
																>
																	{searchSettings?.showMoreResultsText}
																</Link>
															</SearchFieldDropdownItem>
														)}
														{stateSearch.context.result.numberOfHits === 0 && (
															<SearchFieldDropdownItem>
																<p>Inga träffar hittades</p>
															</SearchFieldDropdownItem>
														)}
													</SearchFieldDropdown>
												)}
										</SearchField>
										<IntroBlockArrow />
									</>
								)}
							</Cell>
						</Grid>
					</IntroBlock>
				</div>
			)}
			{!introBlock && (
				<Grid>
					<Cell span={12}>
						<>
							<Text as="h1" prop="heading">
								{heading}
							</Text>
							<div className="mb-24">
								<Text as="preamble" prop="preamble">
									{preamble}
								</Text>
							</div>
						</>
					</Cell>
				</Grid>
			)}
			{contentArea && contentArea.length > 0 && (
				<EpiContentArea content={contentArea} />
			)}
		</BaseLayout>
	);
};
export default StartPage;
