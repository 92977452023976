/**
 * ScrollableTable
 */

import React, { useEffect, useRef, useState } from 'react';
import { useWindowWidth } from 'hooks';
import clsx from 'clsx';

interface Props {
	/** Set the first column to sticky */
	stickyColumn?: boolean;

	/** Pass an optional className */
	className?: string;

	children?: any;

	matrix?: any;

	disableShadows?: boolean;
}

/** A scrollable table that shows a shadow on the sides when there are more content. */
const ScrollableTable: React.FC<Props> = ({
	stickyColumn,
	className,
	children,
	matrix,
	disableShadows,
}: Props) => {
	const scrollRef = useRef<HTMLDivElement>(null);

	const [isScrollable, setIsScrollable] = useState<boolean>(true);
	const [isStickyColumn, setIsStickyColumn] = useState<boolean>(true);
	const windowWidth = useWindowWidth();

	useEffect(() => {
		if (scrollRef.current) {
			const scrollDiv = scrollRef.current;

			const tableWidth = scrollDiv.querySelector('table thead')?.clientWidth;
			const tableHeight = scrollDiv.querySelector('table')?.clientHeight;
			const parentWidth = scrollDiv.parentElement?.clientWidth;

			if (parentWidth && tableWidth && tableWidth > parentWidth) {
				// Check whether the table body is wider than the body and set the first column to sticky
				scrollDiv.style.width = parentWidth + 'px';
				setIsStickyColumn(true);
			} else {
				if (parentWidth && tableWidth && tableHeight) {
					if (parentWidth > tableWidth) {
						// If the table doesn't take up the whole width
						scrollDiv.style.width = tableWidth + 'px';
					}
					if (scrollDiv.scrollWidth > scrollDiv.clientWidth) {
						if (tableHeight > 753) {
							scrollDiv.style.width = tableWidth + 16 + 'px';

							setIsScrollable(true);
						}
					} else {
						setIsScrollable(false);
					}

					setIsStickyColumn(false);
				}
			}

			if (tableHeight && tableHeight > 752) {
				setIsScrollable(true);
			}
		}
	}, [windowWidth, children, matrix]);

	return (
		<div className={clsx(className, 'richtext')}>
			<div
				className={clsx(
					isScrollable && 'table-scrollable',
					matrix && 'table-scrollable',
					stickyColumn && 'table-sticky-col',
					isStickyColumn && 'table-sticky-col'
				)}
				ref={scrollRef}
			>
				{children}
			</div>
		</div>
	);
};

export default ScrollableTable;
