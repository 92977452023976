/**
 * NavigationPage
 * TODO: Line 55 should have margin bottom 7rem.
 */

import React from 'react';
import {
	NavigationBlockItem,
	NavigationBlocks,
	NavigationList,
	NavigationListItem,
} from 'ui-component-library/uhmse';
import { Grid, Cell } from 'ui-component-library/base';
import BaseLayout from 'layouts/BaseLayout';
import Link from 'components/Link';
import Text from 'components/Text';
import EpiContentArea from 'components/EpiContentArea';
import { NavigationPageModel } from 'types';

/** NavigationPage component. */
const NavigationPage: React.FC<NavigationPageModel> = ({
	heading,
	preamble,
	navigationList,
	contentArea,
}) => {
	return (
		<BaseLayout>
			<Grid padding={false} className="lg:pl-6 pl-4">
				<Cell span={12} desktop={navigationList.layout === 'grid' ? 12 : 7}>
					<Text as="h1" prop="heading">
						{heading}
					</Text>
					<Text as="preamble" prop="preamble">
						{preamble}
					</Text>
					{navigationList && (
						<>
							{navigationList.layout === 'grid' && (
								<NavigationBlocks
									aria-label={navigationList.accessibilityDescription}
								>
									{navigationList.items.map((link, index) => (
										<NavigationBlockItem
											LinkComponent={Link}
											url={link.url}
											heading={link.heading}
											text={link.text}
											key={index}
											icon={
												link.url && link.url.charAt(0) === '/'
													? 'arrow'
													: link.url &&
													  link.url.indexOf('upphandlingsmyndigheten.se') !==
															-1
													? 'switchLink'
													: 'externalLink'
											}
											borderTop={index < 3 ? false : true}
										/>
									))}
								</NavigationBlocks>
							)}
							{navigationList.layout === 'list' && (
								<NavigationList
									aria-label={navigationList.accessibilityDescription}
								>
									{navigationList.items.map((link, index) => (
										<NavigationListItem
											LinkComponent={Link}
											url={link.url}
											heading={link.heading}
											text={link.text}
											key={index}
											icon={
												link.url && link.url.charAt(0) === '/'
													? 'arrow'
													: link.url &&
													  link.url.indexOf('upphandlingsmyndigheten.se') !==
															-1
													? 'switchLink'
													: 'externalLink'
											}
										/>
									))}
								</NavigationList>
							)}
						</>
					)}
				</Cell>
			</Grid>
			{contentArea && contentArea.length > 0 && (
				<EpiContentArea content={contentArea} />
			)}
		</BaseLayout>
	);
};

export default NavigationPage;
