/**
 * ChartPuff
 */

import React from 'react';
import clsx from 'clsx';
import { HeadingLevels } from 'ui-component-library/base/types';
import { HighchartsBaseModel } from 'types';
import Highcharts from 'components/Highcharts';

interface Props {
	/** The puff image src */
	src?: string;

	/** The puff image alt */
	alt?: string;

	/** The puff heading */
	heading: string;

	/** The puff text */
	text?: string;

	/** Publish date of the article */
	published?: string;

	/** url to the article */
	url: string;

	/** Optional other heading-level for the navigation items. */
	headingLevel?: HeadingLevels;

	/** An optional component to be used as link. */
	LinkComponent?: any;

	/** Chart properties that passes into HighCharts component */
	chartProperties?: HighchartsBaseModel;

	/** Pass optional classnames for the html */
	className?: string;
}

/** Main description for this component. */
const ChartPuff: React.FC<Props> = ({
	heading,
	text,
	published,
	className,
	headingLevel = 'h3',
	url,
	LinkComponent,
	chartProperties,
	children,
}) => {
	const Heading = headingLevel;
	const Link = LinkComponent;
	return (
		<article data-cid="ChartPuff">
			<div className={clsx(!chartProperties && 'h-52 mb-4')}>
				{chartProperties && (
					<Highcharts
						chartType={chartProperties.chartType}
						stacked={chartProperties.stacked}
						tooltipValueDecimals={chartProperties.tooltipValueDecimals}
						tooltipValuePrefix={chartProperties.tooltipValuePrefix}
						tooltipValueSuffix={chartProperties.tooltipValueSuffix}
						yAxisTitle={chartProperties.yAxisTitle}
						yAxisLabelFormat={chartProperties.yAxisLabelFormat}
						yAxisMin={chartProperties.yAxisMin}
						yAxisMax={chartProperties.yAxisMax}
						xAxisTitle={chartProperties.xAxisTitle}
						xAxisCategories={chartProperties.xAxisCategories}
						xAxisLabelFormat={chartProperties.xAxisLabelFormat}
						showLegend={false}
						series={chartProperties.series}
						chartHeight={(49 / 80) * 100 + '%'}
					/>
				)}
			</div>
			<div className={clsx(className, 'relative')}>
				<Link to={url} className="group puff__link">
					<Heading className="mt-0 mb-1 font-alt text-h3 text-teal group-hover:underline">
						{heading}
					</Heading>
				</Link>
				{(published || children) && (
					<div className="flex mb-1">
						{published && (
							<span className="whitespace-no-wrap text-p font-alt text-greyDarker">
								{published}
							</span>
						)}
						{children && (
							<ul className="font-alt puff__categories">{children}</ul>
						)}
					</div>
				)}
				<p className="text-p font-standard text-black">{text}</p>
			</div>
		</article>
	);
};

export default ChartPuff;
