import {
	ResponseResolver,
	RestRequest,
	DefaultRequestBody,
	RequestParams,
	RestContext,
} from 'msw';
import criteria_cart_data from './criteria-cart.json';

export const getMockCriteriaCartData: ResponseResolver<
	RestRequest<DefaultRequestBody, RequestParams>,
	RestContext,
	any
> = async (req, res, ctx) => {
	await new Promise<void>((resolve, reject) => {
		setTimeout(() => {
			resolve();
		}, 1000);

		let body = req.body as any;

		let items: any = [];
		body.criteriaDescriptors?.forEach((criteria: any, index: number) => {
			let item = {
				requirementName: `Kriterie ${index}`,
				productSubGroupName: criteria === '44321_sv' ? 'Ljudnivå' : 'Griskött',
				requirementLevelLevel:
					criteria === '1234_sv'
						? 'Bas'
						: criteria === '5678_sv'
						? 'Spjutspets'
						: criteria === '7890_sv' || criteria === '44321_sv'
						? 'Avancerad'
						: '',
				removeFromCartButtonText: 'Ta bort',
				url: '/kriteriesida',
				warningText: criteria === '5678_sv' ? 'Finns ej på Engelska' : null,
				criteriaDescriptor: criteria,
			};

			items.push(item);
		});
		criteria_cart_data.requirements.items = items;
	});

	return res(ctx.status(200), ctx.body(JSON.stringify(criteria_cart_data)));
};
