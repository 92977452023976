import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from 'store';
import {
	DataTableModel,
	ProductGroupSelectedRequirementsModel,
	RequirementModel,
	SelectedRequirementsModel,
} from 'types';
import { objectsHaveSameKeys } from 'utils/helpers';

interface RequirementState {
	productGroups?: ProductGroupSelectedRequirementsModel;
}

interface ProductGroupState {
	productGroupId: number;
	selectedRequirements: SelectedRequirementsModel;
}

const initialState: RequirementState = {
	productGroups:
		typeof localStorage !== 'undefined' && localStorage.requirements
			? JSON.parse(localStorage.requirements)
			: {},
};

const slice = createSlice({
	name: 'requirements',
	initialState,
	reducers: {
		setInitialRequirements: (
			state,
			action: PayloadAction<ProductGroupSelectedRequirementsModel>
		) => {
			state.productGroups = { ...state.productGroups, ...action.payload };
		},
		updateRequirements: (state, action: PayloadAction<ProductGroupState>) => {
			if (state.productGroups && action.payload.productGroupId !== undefined) {
				state.productGroups[
					action.payload.productGroupId
				].selectedRequirements = {
					...state.productGroups[action.payload.productGroupId]
						.selectedRequirements,
					...action.payload.selectedRequirements,
				};
			}
		},
	},
});

export const initSelectedRequirements = (
	productGroupId: number,
	requirements: DataTableModel,
	selectedRequirements: SelectedRequirementsModel
): AppThunk => (dispatch) => {
	if (requirements.items) {
		let newRequirements: SelectedRequirementsModel = {};

		for (let row of requirements.items) {
			newRequirements[row.documentId] = {
				...(row.requirementWithLevel as RequirementModel),
				selected: false,
			};
		}

		const productGroup: ProductGroupSelectedRequirementsModel = {
			[productGroupId]: {
				selectedRequirements: newRequirements,
			},
		};

		if (!localStorage.requirements) {
			localStorage.requirements = JSON.stringify(productGroup);
		}

		let lsRequirements: ProductGroupSelectedRequirementsModel = JSON.parse(
			localStorage.requirements
		);

		if (!lsRequirements[productGroupId]) {
			lsRequirements = { ...lsRequirements, ...productGroup };
			localStorage.requirements = JSON.stringify(lsRequirements);
			lsRequirements = JSON.parse(localStorage.requirements);
		}

		if (!objectsHaveSameKeys(selectedRequirements, newRequirements)) {
			lsRequirements = { ...lsRequirements, ...productGroup };
			localStorage.requirements = JSON.stringify(lsRequirements);
			lsRequirements = JSON.parse(localStorage.requirements);
			dispatch(setInitialRequirements(lsRequirements));
		}
	}
};

export const updateSelectedRequirements = (
	productGroupId: number,
	selectedRequirements: SelectedRequirementsModel
): AppThunk => (dispatch) => {
	if (localStorage.requirements && productGroupId !== undefined) {
		const lsRequirements: ProductGroupSelectedRequirementsModel = JSON.parse(
			localStorage.requirements
		);

		lsRequirements[productGroupId].selectedRequirements = {
			...lsRequirements[productGroupId].selectedRequirements,
			...selectedRequirements,
		};

		localStorage.requirements = JSON.stringify(lsRequirements);
	}

	dispatch(
		updateRequirements({
			productGroupId,
			selectedRequirements,
		})
	);
};

export const selectProductGroups = (state: RootState) =>
	state.requirements.productGroups;

export const { setInitialRequirements, updateRequirements } = slice.actions;

export default slice.reducer;
